export const SESSION_KEY = 'SESSION_EVENTS_DATA';
export const BOOK_SESSION_KEY = 'BOOK_SESSION_EVENTS_DATA';

export const BOOK_KEY = 'BOOK_EVENTS_DATA';

export const CHAPTER_SESSION_KEY = 'CHAPTER_SESSION_EVENTS_DATA';

export const CHAPTER_KEY = 'CHAPTER_EVENTS_DATA';

export const TOPIC_KEY = 'TOPIC_EVENTS_DATA';

export const TOPIC_SESSION_KEY = 'TOPIC_SESSION_EVENTS_DATA';

export const LESSON_PLAN_KEY = 'LESSON_PLAN_EVENTS_DATA';

export const LESSON_PLAN_SESSION_KEY = 'LESSON_PLAN_SESSION_EVENTS_DATA';

export const RESOURCE_KEY = 'RESOURCE_EVENTS_DATA';

export const RESOURCE_SESSION_KEY = 'RESOURCE_SESSION_EVENTS_DATA';

export const RECOMMENDATION_KEY = 'RECOMMENDATION_EVENTS_DATA';

export const RECOMMENDATION_SESSION_KEY = 'RECOMMENDATION_SESSION_EVENTS_DATA';

export const RESOURCE_PAGE_CONTENT_KEY = 'RESOURCE_PAGE_EVENTS_DATA';

export const RESOURCE_PAGE_CONTENT_SESSION_KEY =
  'RESOURCE_PAGE_SESSION_EVENTS_DATA';

export const HOMEWORK_KEY = 'HOMEWORK_EVENTS_DATA';

export const HOMEWORK_SESSION_KEY = 'HOMEWORK_SESSION_EVENTS_DATA';

export const REVIEW_HOMEWORK_KEY = 'REVIEW_HOMEWORK_EVENTS_DATA';

export const CONTENT_KEY = 'CONTENT_EVENTS_DATA';

export const CONTENT_SESSION_KEY = 'CONTENT_SESSION_EVENTS_DATA';

export const RPQUESTION_KEY = 'RPQUESTION_EVENTS_DATA';

export const RPQUESTION_SESSION_KEY = 'RPQUESTION_SESSION_EVENTS_DATA';

export const INSTRUCTION_KEY = 'INSTRUCTION_EVENTS_DATA';

export const INSTRUCTION_SESSION_KEY = 'INSTRUCTION_SESSION_EVENTS_DATA';

export const PERFORMANCE_KEY = 'PERFORMANCE_EVENTS_DATA';

export const PERFORMANCE_SESSION_KEY = 'PERFORMANCE_SESSION_EVENTS_DATA';

export const KeyRegex = new RegExp(
  `^(${SESSION_KEY}|${BOOK_SESSION_KEY}|((${BOOK_KEY})!([^\\s]+)))$`
);

export const dataKeysRegex = new RegExp(`^(${BOOK_KEY})!([^\\s]+)$`);
