import { Code, ConnectError } from '@bufbuild/connect';
import {
  IStyles,
  Loader,
  addOfflineUserRequest,
  findOfflineUserRequestByParamsFromCache,
  generatePasswordHash,
  getDeviceType,
  isValidEmail,
  isValidMobileNumber,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import { LoginTypeEnum } from '@protos/user_management/ums.db_pb';
import {
  StudentLoginResponse,
  StudentLoginResponseType,
  StudentLoginWithPhoneOrEmailPasswordResponse,
} from '@protos/user_management/ums.login.apis_pb';
// import useNotificationPermission from 'libs/shared-ui/src/commonUtils/Notification';
import { getSessionData } from '@events/student/eventsCreator/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CHOOSE_PROFILE,
  HOME,
} from '../../../routeHandling/RoutesNomenclature';
import {
  setIsLoggedInOffline,
  setMultipleProfiles,
  setUserInfo,
} from '../reducer/auth.slice';
import { aiLoginEvent, interactionEvent } from './login_logout.events';

const styles: IStyles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'flex-start', md: 'center' },
    position: 'relative',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(5),
    textAlign: { xs: 'left', md: 'center' },
    flexGrow: 1,
    marginBottom: { xs: pxToRem(25), md: pxTovW(41) },
  },
  inputContainer: {
    mt: { xs: pxToRem(50), md: pxTovW(60) },
  },
  secondaryButtonBox: {
    width: { xs: '100%', md: pxTovW(517) },
    height: { xs: pxToRem(55), md: pxTovW(77) },
    margin: 'auto',
    mt: { xs: pxToRem(91), md: pxTovW(40) },
  },
  helperText: {
    bgcolor: '#FFDFDF',
    color: 'error.main',
    mt: pxToRem(4),
    padding: { xs: pxToRem(10), md: `${pxTovW(12)} ${pxTovW(30)}` },
  },
  helperText2: {
    height: { xs: pxToRem(12), md: pxTovW(20) },
  },
};
interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

// Declare the Android object
declare global {
  interface Window {
    Android: any;
  }
}

export const LoginUidPass = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = new URLSearchParams(location.search).get('uid') || undefined;
  const password =
    new URLSearchParams(location.search).get('password') || undefined;
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { permissionGranted, requestPermission } =
    useNotificationPermission('student');
  const [passwordError, setPasswordError] = useState('');
  const loginUser = () => {
    if (uid && password) {
      let loginType = LoginTypeEnum.LOGIN_TYPE_USERNAME;
      if (isValidMobileNumber(uid)) {
        loginType = LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER;
      } else if (isValidEmail(uid)) {
        loginType = LoginTypeEnum.LOGIN_TYPE_EMAIL;
      } else {
        loginType = LoginTypeEnum.LOGIN_TYPE_USERNAME;
      }
      handlePasswordLogin(loginType, uid, password);
    }
  };

  // // Check device type
  const deviceType = getDeviceType();
  const android = window.Android;
  function pushUserDetailsToAndroid(uToken: string, userID: string): void {
    try {
      if (deviceType === 'android') {
        android?.pushUserDetails(uToken, userID);
      }
    } catch (error) {
      console.error('Error pushing user details to Android', error);
    }
  }

  const getCurrentTime = () => new Date().getTime();
  const handlePasswordLogin = async (
    login_Type: LoginTypeEnum,
    username: string,
    password: string
  ) => {
    setLoading('loading');
    const passwordHash = generatePasswordHash(password);
    try {
      let response:
        | StudentLoginWithPhoneOrEmailPasswordResponse
        | StudentLoginResponse
        | undefined;
      if (login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithUsername(
            {
              userName: username,
              password: password,
            }
          );
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithEmailPassword(
            {
              email: username,
              password: password,
            }
          );
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithPhonePassword(
            {
              phoneNumber: username,
              password: password,
            }
          );
      } else {
        response = undefined;
      }
      if (response) {
        if (response.activeSessionId) {
          setLocalStorage('activeSession', response.activeSessionId.toString());
          const currentTime = getCurrentTime();
          setLocalStorage('lastUpdatedTime', String(currentTime));
        }

        if (response.data && response.status !== 0) {
          setLoading('completed');
          if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
              const user = response.data[i];
              await addOfflineUserRequest(user, passwordHash);
            }
            if (response.data.length === 1) {
              const userInfo = response.data[0];
              console.log(userInfo);

              dispatch(setUserInfo(userInfo));
              setLocalStorage('userId', userInfo.studentProfileId.toString());
              setLocalStorage('role', 'student');
              setLocalStorage('auth', 'true');
              const accessKey = userInfo.studentProfileId
                ? 'student-' + userInfo.studentProfileId.toString()
                : 'geneo';
              await storeOfflineAccessKeyInCache(accessKey);
              navigate(HOME);
              setTimeout(async () => {
                await interactionEvent({
                  url: 'student_login',
                  context: 'navigation_bar',
                  name: 'Login',
                });
              }, 3000);
              await aiLoginEvent({
                studentId: Number(userInfo.studentProfileId),
                sessionId: getSessionData().sessionId,
              });
              pushUserDetailsToAndroid(
                userInfo.token,
                userInfo.studentProfileId.toString()
              );
              navigate(HOME);
            } else {
              dispatch(setMultipleProfiles(response.data));
              navigate(CHOOSE_PROFILE);
            }
          } else {
            const userInfo = response.data;

            dispatch(setUserInfo(userInfo));
            await addOfflineUserRequest(userInfo, passwordHash);
            setLocalStorage('userId', userInfo.studentProfileId.toString());
            setLocalStorage('role', 'student');
            setLocalStorage('auth', 'true');
            const accessKey = userInfo.studentProfileId
              ? 'student-' + userInfo.studentProfileId.toString()
              : 'geneo';
            await storeOfflineAccessKeyInCache(accessKey);
            setTimeout(async () => {
              await interactionEvent({
                url: 'student_login',
                context: 'navigation_bar',
                name: 'Login',
              });
            }, 3000);
            await aiLoginEvent({
              studentId: Number(userInfo.studentProfileId),
              sessionId: getSessionData().sessionId,
            });
            pushUserDetailsToAndroid(
              userInfo.token,
              userInfo.studentProfileId.toString()
            );
            navigate(HOME);
          }
          await requestPermission();
        } else {
          setLoading('error');
          setPasswordError('Incorrect Password');
        }
      } else {
        setLoading('error');
        console.error('Invalid request');
        return;
      }
    } catch (error: any) {
      if (error instanceof ConnectError) {
        if (error.code === Code.Unknown || error.code === Code.Internal) {
          if (login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
            const searchParams = {
              username: username,
              passwordHash,
            };
            const user = await findOfflineUserRequestByParamsFromCache(
              searchParams
            );
            if (user) {
              const userFound = new StudentLoginResponseType().fromJsonString(
                user || ''
              );
              dispatch(setUserInfo(userFound));
              setLocalStorage('userId', userFound.studentProfileId.toString());
              setLocalStorage('role', 'teacher');
              setLocalStorage('auth', 'true');
              const accessKey = userFound.studentProfileId.toString()
                ? 'student-' + userFound.studentProfileId.toString()
                : 'geneo';
              await storeOfflineAccessKeyInCache(accessKey);
              dispatch(setIsLoggedInOffline(true));
              await aiLoginEvent({
                studentId: Number(userFound.studentProfileId),
                sessionId: getSessionData().sessionId,
              });
              await interactionEvent({
                url: 'student_Offlinelogin',
                context: 'LoginOffline_Navigation_bar',
                name: 'Login',
              });
              setLoading('completed');
              navigate(HOME);
              return;
            }
          }
        }
      }
      setLoading('error');
      const message = error.message
        ? error.message.replace(/.*\]\s*/, '').trim()
        : undefined;
      if (message) {
        setPasswordError(message);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (uid && password) {
      loginUser();
    }
  }, []);

  if (loading === 'loading') {
    return <Loader />;
  }
  if (!uid || !password) {
    return null;
  }

  return null;
};
