// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.teach.apis.proto (package geneo.lms.lesson.teach.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { CreationStatusEnum, Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { PaginationResult, TeacherLessonInfo } from "@protos/learning_management/lms.lesson.common.apis_pb";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { SessionModeEnum, SessionStatusEnum, TeacherLessonSessionMetrics } from "@protos/learning_management/lms.db_pb";

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherDeleteLessonRequest
 */
export class TeacherDeleteLessonRequest extends Message<TeacherDeleteLessonRequest> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<TeacherDeleteLessonRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherDeleteLessonRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherDeleteLessonRequest {
    return new TeacherDeleteLessonRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherDeleteLessonRequest {
    return new TeacherDeleteLessonRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherDeleteLessonRequest {
    return new TeacherDeleteLessonRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherDeleteLessonRequest | PlainMessage<TeacherDeleteLessonRequest> | undefined, b: TeacherDeleteLessonRequest | PlainMessage<TeacherDeleteLessonRequest> | undefined): boolean {
    return proto3.util.equals(TeacherDeleteLessonRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherDeleteLessonResponse
 */
export class TeacherDeleteLessonResponse extends Message<TeacherDeleteLessonResponse> {
  /**
   * Status of the response
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Code of the response
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Message of the response
   *
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<TeacherDeleteLessonResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherDeleteLessonResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherDeleteLessonResponse {
    return new TeacherDeleteLessonResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherDeleteLessonResponse {
    return new TeacherDeleteLessonResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherDeleteLessonResponse {
    return new TeacherDeleteLessonResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherDeleteLessonResponse | PlainMessage<TeacherDeleteLessonResponse> | undefined, b: TeacherDeleteLessonResponse | PlainMessage<TeacherDeleteLessonResponse> | undefined): boolean {
    return proto3.util.equals(TeacherDeleteLessonResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherUpsertLessonRequest
 */
export class TeacherUpsertLessonRequest extends Message<TeacherUpsertLessonRequest> {
  /**
   * Unique identifier for the lesson
   *
   * @generated from field: optional string lesson_id = 1;
   */
  lessonId?: string;

  /**
   * ID of the teacher
   *
   * @generated from field: optional int64 teacher_id = 2;
   */
  teacherId?: bigint;

  /**
   * Title of the lesson
   *
   * @generated from field: optional string title = 3;
   */
  title?: string;

  /**
   * ID of the course
   *
   * @generated from field: optional int32 subject_id = 4;
   */
  subjectId?: number;

  /**
   * ID of the module
   *
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum module_category = 6;
   */
  moduleCategory?: Module_ModuleCategoryEnum;

  /**
   * URL of the poster image
   *
   * @generated from field: optional string poster_image_url = 7;
   */
  posterImageUrl?: string;

  /**
   * IDs of associated resources
   *
   * @generated from field: repeated string resource_ids = 8;
   */
  resourceIds: string[] = [];

  /**
   * ID of the source lesson
   *
   * @generated from field: optional string source_lesson_id = 9;
   */
  sourceLessonId?: string;

  /**
   * File upload request
   *
   * @generated from field: optional geneo.lms.lesson.teach.apis.FileUploadRequest upload_file = 10;
   */
  uploadFile?: FileUploadRequest;

  /**
   * @generated from field: optional geneo.content.db.CreationStatusEnum creation_status = 11;
   */
  creationStatus?: CreationStatusEnum;

  /**
   * Estimated time for the lesson plan
   *
   * @generated from field: optional int32 estimated_time_in_min = 12;
   */
  estimatedTimeInMin?: number;

  /**
   * Array of learning outcome IDs
   *
   * @generated from field: repeated string learningOutcomesIds = 13;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Section Id
   *
   * @generated from field: optional int32 school_class_section_id = 14;
   */
  schoolClassSectionId?: number;

  constructor(data?: PartialMessage<TeacherUpsertLessonRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherUpsertLessonRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
    { no: 7, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "source_lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "upload_file", kind: "message", T: FileUploadRequest, opt: true },
    { no: 11, name: "creation_status", kind: "enum", T: proto3.getEnumType(CreationStatusEnum), opt: true },
    { no: 12, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "learningOutcomesIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherUpsertLessonRequest {
    return new TeacherUpsertLessonRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherUpsertLessonRequest {
    return new TeacherUpsertLessonRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherUpsertLessonRequest {
    return new TeacherUpsertLessonRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherUpsertLessonRequest | PlainMessage<TeacherUpsertLessonRequest> | undefined, b: TeacherUpsertLessonRequest | PlainMessage<TeacherUpsertLessonRequest> | undefined): boolean {
    return proto3.util.equals(TeacherUpsertLessonRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.FileUploadRequest
 */
export class FileUploadRequest extends Message<FileUploadRequest> {
  /**
   * Size of the file
   *
   * @generated from field: string file_size = 1;
   */
  fileSize = "";

  /**
   * Content type of the file
   *
   * @generated from field: string content_type = 2;
   */
  contentType = "";

  /**
   * Original name of the file
   *
   * @generated from field: string original_file_name = 3;
   */
  originalFileName = "";

  /**
   * Path of the file
   *
   * @generated from field: string file_path = 4;
   */
  filePath = "";

  constructor(data?: PartialMessage<FileUploadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.FileUploadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "original_file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileUploadRequest {
    return new FileUploadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileUploadRequest {
    return new FileUploadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileUploadRequest {
    return new FileUploadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FileUploadRequest | PlainMessage<FileUploadRequest> | undefined, b: FileUploadRequest | PlainMessage<FileUploadRequest> | undefined): boolean {
    return proto3.util.equals(FileUploadRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherUpsertLessonResponse
 */
export class TeacherUpsertLessonResponse extends Message<TeacherUpsertLessonResponse> {
  /**
   * Status of the response
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Code of the response
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Message of the response
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Data for the response
   *
   * @generated from field: geneo.lms.lesson.common.apis.TeacherLessonInfo data = 4;
   */
  data?: TeacherLessonInfo;

  constructor(data?: PartialMessage<TeacherUpsertLessonResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherUpsertLessonResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherLessonInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherUpsertLessonResponse {
    return new TeacherUpsertLessonResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherUpsertLessonResponse {
    return new TeacherUpsertLessonResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherUpsertLessonResponse {
    return new TeacherUpsertLessonResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherUpsertLessonResponse | PlainMessage<TeacherUpsertLessonResponse> | undefined, b: TeacherUpsertLessonResponse | PlainMessage<TeacherUpsertLessonResponse> | undefined): boolean {
    return proto3.util.equals(TeacherUpsertLessonResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.GetTeacherLessonListRequest
 */
export class GetTeacherLessonListRequest extends Message<GetTeacherLessonListRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course (optional)
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * ID of the module (optional)
   *
   * @generated from field: optional int32 module_id = 4;
   */
  moduleId?: number;

  /**
   * @generated from field: optional int32 page_number = 5;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 items_per_page = 6;
   */
  itemsPerPage?: number;

  constructor(data?: PartialMessage<GetTeacherLessonListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.GetTeacherLessonListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherLessonListRequest {
    return new GetTeacherLessonListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherLessonListRequest {
    return new GetTeacherLessonListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherLessonListRequest {
    return new GetTeacherLessonListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherLessonListRequest | PlainMessage<GetTeacherLessonListRequest> | undefined, b: GetTeacherLessonListRequest | PlainMessage<GetTeacherLessonListRequest> | undefined): boolean {
    return proto3.util.equals(GetTeacherLessonListRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/list
 *
 * @generated from message geneo.lms.lesson.teach.apis.GetTeacherLessonListResponse
 */
export class GetTeacherLessonListResponse extends Message<GetTeacherLessonListResponse> {
  /**
   * Status of the response
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Code of the response
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Message of the response
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Data for the response
   *
   * @generated from field: geneo.lms.lesson.teach.apis.TeacherLessonList data = 4;
   */
  data?: TeacherLessonList;

  constructor(data?: PartialMessage<GetTeacherLessonListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.GetTeacherLessonListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherLessonList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherLessonListResponse {
    return new GetTeacherLessonListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherLessonListResponse {
    return new GetTeacherLessonListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherLessonListResponse {
    return new GetTeacherLessonListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherLessonListResponse | PlainMessage<GetTeacherLessonListResponse> | undefined, b: GetTeacherLessonListResponse | PlainMessage<GetTeacherLessonListResponse> | undefined): boolean {
    return proto3.util.equals(GetTeacherLessonListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherLessonList
 */
export class TeacherLessonList extends Message<TeacherLessonList> {
  /**
   * List of lesson information
   *
   * @generated from field: repeated geneo.lms.lesson.common.apis.TeacherLessonInfo lesson_list = 1;
   */
  lessonList: TeacherLessonInfo[] = [];

  /**
   * @generated from field: geneo.lms.lesson.common.apis.PaginationResult pagination_data = 2;
   */
  paginationData?: PaginationResult;

  constructor(data?: PartialMessage<TeacherLessonList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherLessonList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_list", kind: "message", T: TeacherLessonInfo, repeated: true },
    { no: 2, name: "pagination_data", kind: "message", T: PaginationResult },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLessonList {
    return new TeacherLessonList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLessonList {
    return new TeacherLessonList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLessonList {
    return new TeacherLessonList().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLessonList | PlainMessage<TeacherLessonList> | undefined, b: TeacherLessonList | PlainMessage<TeacherLessonList> | undefined): boolean {
    return proto3.util.equals(TeacherLessonList, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.GetTeacherPreviousLessonSessionRequest
 */
export class GetTeacherPreviousLessonSessionRequest extends Message<GetTeacherPreviousLessonSessionRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  constructor(data?: PartialMessage<GetTeacherPreviousLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.GetTeacherPreviousLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherPreviousLessonSessionRequest {
    return new GetTeacherPreviousLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherPreviousLessonSessionRequest {
    return new GetTeacherPreviousLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherPreviousLessonSessionRequest {
    return new GetTeacherPreviousLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherPreviousLessonSessionRequest | PlainMessage<GetTeacherPreviousLessonSessionRequest> | undefined, b: GetTeacherPreviousLessonSessionRequest | PlainMessage<GetTeacherPreviousLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(GetTeacherPreviousLessonSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherPreviousLessonSessionResponse
 */
export class TeacherPreviousLessonSessionResponse extends Message<TeacherPreviousLessonSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.lesson.teach.apis.TeacherPreviousLessonSession data = 4;
   */
  data?: TeacherPreviousLessonSession;

  constructor(data?: PartialMessage<TeacherPreviousLessonSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherPreviousLessonSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherPreviousLessonSession },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherPreviousLessonSessionResponse {
    return new TeacherPreviousLessonSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherPreviousLessonSessionResponse {
    return new TeacherPreviousLessonSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherPreviousLessonSessionResponse {
    return new TeacherPreviousLessonSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherPreviousLessonSessionResponse | PlainMessage<TeacherPreviousLessonSessionResponse> | undefined, b: TeacherPreviousLessonSessionResponse | PlainMessage<TeacherPreviousLessonSessionResponse> | undefined): boolean {
    return proto3.util.equals(TeacherPreviousLessonSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherPreviousLessonSession
 */
export class TeacherPreviousLessonSession extends Message<TeacherPreviousLessonSession> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  /**
   * @generated from field: repeated string completed_resource_ids = 3;
   */
  completedResourceIds: string[] = [];

  constructor(data?: PartialMessage<TeacherPreviousLessonSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherPreviousLessonSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "completed_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherPreviousLessonSession {
    return new TeacherPreviousLessonSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherPreviousLessonSession {
    return new TeacherPreviousLessonSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherPreviousLessonSession {
    return new TeacherPreviousLessonSession().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherPreviousLessonSession | PlainMessage<TeacherPreviousLessonSession> | undefined, b: TeacherPreviousLessonSession | PlainMessage<TeacherPreviousLessonSession> | undefined): boolean {
    return proto3.util.equals(TeacherPreviousLessonSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.CreateTeacherLessonSessionRequest
 */
export class CreateTeacherLessonSessionRequest extends Message<CreateTeacherLessonSessionRequest> {
  /**
   * ID of the teacher associated with the lesson session.
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 4;
   */
  academicYear = 0;

  /**
   * Grade of the teacher.
   *
   * @generated from field: int32 class_id = 5;
   */
  classId = 0;

  /**
   * Section of the teacher.
   *
   * @generated from field: string section = 6;
   */
  section = "";

  /**
   * Subject of the lesson session.
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 7;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * ID of the teacher's lesson session. Nullable field.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 8;
   */
  teacherLessonSessionId?: number;

  /**
   * List of resource IDs associated with the session.
   *
   * @generated from field: repeated string session_resource_ids = 9;
   */
  sessionResourceIds: string[] = [];

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 10;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 11;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Mode of the resource session (e.g., online, offline, etc.)
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 12;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  constructor(data?: PartialMessage<CreateTeacherLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.CreateTeacherLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 8, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "session_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "start_time", kind: "message", T: Timestamp },
    { no: 11, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 12, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTeacherLessonSessionRequest {
    return new CreateTeacherLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTeacherLessonSessionRequest {
    return new CreateTeacherLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTeacherLessonSessionRequest {
    return new CreateTeacherLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTeacherLessonSessionRequest | PlainMessage<CreateTeacherLessonSessionRequest> | undefined, b: CreateTeacherLessonSessionRequest | PlainMessage<CreateTeacherLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateTeacherLessonSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.TeacherLessonSessionResponse
 */
export class TeacherLessonSessionResponse extends Message<TeacherLessonSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 teacher_lesson_session_id = 4;
   */
  teacherLessonSessionId = 0;

  constructor(data?: PartialMessage<TeacherLessonSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.TeacherLessonSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLessonSessionResponse {
    return new TeacherLessonSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLessonSessionResponse {
    return new TeacherLessonSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLessonSessionResponse {
    return new TeacherLessonSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLessonSessionResponse | PlainMessage<TeacherLessonSessionResponse> | undefined, b: TeacherLessonSessionResponse | PlainMessage<TeacherLessonSessionResponse> | undefined): boolean {
    return proto3.util.equals(TeacherLessonSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.teach.apis.UpdateTeacherLessonSessionRequest
 */
export class UpdateTeacherLessonSessionRequest extends Message<UpdateTeacherLessonSessionRequest> {
  /**
   * Unique identifier for the teacher's lesson session.
   *
   * @generated from field: int32 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId = 0;

  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: string lesson_id = 3;
   */
  lessonId = "";

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 4;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * End time of the lesson session. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp end_time = 5;
   */
  endTime?: Timestamp;

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: optional geneo.lms.db.TeacherLessonSessionMetrics metrics = 6;
   */
  metrics?: TeacherLessonSessionMetrics;

  constructor(data?: PartialMessage<UpdateTeacherLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.teach.apis.UpdateTeacherLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 5, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "metrics", kind: "message", T: TeacherLessonSessionMetrics, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeacherLessonSessionRequest {
    return new UpdateTeacherLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeacherLessonSessionRequest {
    return new UpdateTeacherLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeacherLessonSessionRequest {
    return new UpdateTeacherLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeacherLessonSessionRequest | PlainMessage<UpdateTeacherLessonSessionRequest> | undefined, b: UpdateTeacherLessonSessionRequest | PlainMessage<UpdateTeacherLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTeacherLessonSessionRequest, a, b);
  }
}

