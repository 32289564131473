import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import {
  cmsTransport,
  cmsTransportWithLogoutHandler,
  ResponseStatusCodeFunction,
} from './transports';
import { AdminUploadContentAPIServiceV1 } from '@protos/content_management/cms.admin.content.apis_connect';

export const CmsAdminUploadContentAPIServiceV1 = createPromiseClient(
  AdminUploadContentAPIServiceV1,
  cmsTransport
);

export const useAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    AdminUploadContentAPIServiceV1,
    cmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
