import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LessonCommonAPIServiceV1 } from '@protos/learning_management/lms.lesson.common.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LessonCommonAPIServiceV1Client = createPromiseClient(
  LessonCommonAPIServiceV1,
  lmsTransport
);

//LessonCommonAPIServiceV1
export const useLessonCommonAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LessonCommonAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
