import {
  ContactPopup,
  IStyles,
  Loader,
  PrimaryButton,
  UserDetailField,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ProfileVerificationData } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  REGISTRATION,
  SET_PASSWORD,
} from '../../../routeHandling/RoutesNomenclature';
import { setToastInfo } from '../reducer/auth.slice';
const styles: IStyles = {
  root: {
    height: { md: '100vh' },
    paddingX: { xs: 0, md: pxTovW(60) },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { md: 'center' },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(26) },
  },
  Heading: {
    display: 'flex',
    justifyContent: { xs: 'flex-start', md: 'center' },
    gap: { xs: pxToRem(10), md: pxTovW(15) },
  },
  MiddleContainer: {
    // height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(40), md: pxTovW(26) },
    justifyContent: 'space-between',
  },
  DetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(25) },
  },
  BottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(16) },
  },
  bottomText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: { xs: 'center', md: 'center' },
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
};
//teacher_1
//teacher@1
export const VerifyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [actionPopupState, setActionPopupState] = useState(false);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const { user_validation_info, userInfo } = deserify(
    useAppSelector((state) => state.auth)
  );
  const [UserVerificationInfo, setUserVerificationInfo] =
    useState<ProfileVerificationData>();
  const getUserInfo = async (profileId?: bigint) => {
    if (profileId) {
      setLoading(loading);
      try {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.userProfileVerification(
            {
              profileId: profileId,
              role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            }
          );
        // dispatch(setUserInfo(response.data));
        if (response.data) {
          setLoading('completed');
          setUserVerificationInfo(response.data);
          console.log(response);
        }
      } catch (error) {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Please enter valid username',
            variant: 'error',
            open: true,
          })
        );
        navigate(REGISTRATION);

        console.log(error);
      }
    } else {
      dispatch(
        setToastInfo({
          label: 'Please enter valid username',
          variant: 'error',
          open: true,
        })
      );
      navigate(REGISTRATION);
    }
  };

  useEffect(() => {
    getUserInfo(user_validation_info?.profileId);
  }, []);
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        navigate(SET_PASSWORD);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.Heading}>
          <Typography variant="h1">Verify Your</Typography>
          <Typography variant="h1" color={'primary.main'}>
            Profile
          </Typography>
        </Box>
        <Box sx={styles.MiddleContainer}>
          <Box sx={styles.DetailsContainer}>
            <Box sx={styles.Texts}>
              <Typography variant="h2" paddingBottom={1}>
                Personal Details
              </Typography>
              <Box>
                <UserDetailField
                  label="Name"
                  value={`${UserVerificationInfo?.studentProfile?.firstName} ${UserVerificationInfo?.studentProfile?.lastName}`}
                />
                <UserDetailField
                  label="Email"
                  value={`${UserVerificationInfo?.studentProfile?.email}`}
                />
              </Box>
            </Box>
            <Box sx={styles.Texts}>
              <Typography variant="h2" paddingBottom={1}>
                School Details
              </Typography>
              <UserDetailField
                label="School Name"
                value={`${UserVerificationInfo?.studentProfile?.schoolDetails?.schoolName}`}
              />
              <UserDetailField
                label="Your Board"
                value={`${UserVerificationInfo?.studentProfile?.boardMediumDetails?.boardName}`}
              />
              <UserDetailField
                label="Your Medium"
                value={`${UserVerificationInfo?.studentProfile?.boardMediumDetails?.mediumName}`}
              />
              <UserDetailField
                label="Class & Section"
                value={`${UserVerificationInfo?.studentProfile?.classSectionDetails?.className}${UserVerificationInfo?.studentProfile?.classSectionDetails?.sectionName}`}
              />
              <Box>
                <UserDetailField
                  label="Subjects"
                  value={`${UserVerificationInfo?.studentProfile?.learnSubjects.map(
                    (elem) => elem.subjectName
                  )}`}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={styles.BottomContainer}>
            <PrimaryButton
              fullWidth
              onClick={() => navigate(SET_PASSWORD)}
              disabled={
                UserVerificationInfo?.studentProfile?.firstName ? false : true
              }
            >
              Verify
            </PrimaryButton>
            <Box sx={styles.bottomText}>
              <Typography variant="bodyText" fontWeight={600}>
                Feel you need to change any Details?
              </Typography>
              <Typography
                variant="bodyText"
                fontWeight={600}
                color={'primary.main'}
                onClick={() => setActionPopupState(true)}
                sx={{ cursor: 'pointer' }}
              >
                Contact School Admin
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName="costumer-service"
        popupText="Please Contact Your School Admin for Registration"
        handleClose={() => setActionPopupState(false)}
        ctaHandler={() => setActionPopupState(false)}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      /> */}
      <ContactPopup
        open={actionPopupState}
        setOpen={() => setActionPopupState(false)}
      />
    </Box>
  );
};
