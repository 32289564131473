import {
  IStyles,
  Loader,
  PasswordInputField,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { PasswordTypeEnum } from '@protos/user_management/ums.login.apis_pb';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  REGISTRATION,
  VERIFY_PHONE,
} from '../../../routeHandling/RoutesNomenclature';
import { useAuthentication } from '../../../utils/customHooks';
import { setToastInfo, setUserInfo } from '../reducer/auth.slice';
const styles: IStyles = {
  root: {
    height: { md: '100%' },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(30), md: pxTovW(57) },
    justifyContent: { xs: 'start', md: 'center' },
  },
  heading: {
    textAlign: { xs: 'start', md: 'center' },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(91), md: pxTovW(42) },
  },
  Passwordcontainer: {
    display: 'flex',
    flexDirection: 'column',
    // gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  buttonContainer: {
    width: { xs: '100%', md: pxTovW(517) },
    display: 'flex',
    alignSelf: 'center',
  },
};
export const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_validation_info } = deserify(
    useAppSelector((state) => state.auth)
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const passwordRef = useRef<any | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { permissionGranted, requestPermission } =
    useNotificationPermission('student');
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useAuthentication();
  const confirmClickHandler = async () => {
    if (confirmPassword !== newPassword) {
      setPasswordError(true);
      return;
    }
    if (user_validation_info?.profileId) {
      setLoading('loading');
      try {
        setLoading('loading');

        const profileVerification =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateStudentProfile(
            {
              studentProfileId: user_validation_info.profileId,
              isProfileVerified: true,
            }
          );
        // console.log('Profile Verification status', profileVerification);
        if (profileVerification.data) {
          dispatch(setUserInfo(profileVerification.data));
        } else {
          throw new Error('Profile Verification failed');
        }
        const settingPassword =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updatePassword({
            profileId: user_validation_info.profileId,
            newPassword: newPassword,
            role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            passwordType: PasswordTypeEnum.PASSWORD_TYPE_SET,
          });

        if (settingPassword) {
          const accessKey = profileVerification.data.studentProfileId
            ? 'student-' + profileVerification.data.studentProfileId.toString()
            : 'geneo';
          await storeOfflineAccessKeyInCache(accessKey);
          setLocalStorage('userId', user_validation_info?.profileId.toString());
          setLocalStorage('role', 'student');
          setLocalStorage('auth', 'true');
          dispatch(
            setToastInfo({
              label: 'Password Updated Successfully',
              variant: 'success',
              open: true,
            })
          );
          if (settingPassword.activeSessionId) {
            setLocalStorage(
              'activeSession',
              settingPassword.activeSessionId.toString()
            );
            const currentTime = new Date().getTime();
            setLocalStorage('lastUpdatedTime', String(currentTime));
          }
          navigate(VERIFY_PHONE);
          await requestPermission();
        } else {
          throw new Error('Failed to set password please try again');
        }

        setLoading('completed');
      } catch (err) {
        console.log(err);
        setLoading('error');
        if (err instanceof Error) {
          dispatch(
            setToastInfo({
              label: `${err.message.replace(/\[.*?\]/g, '')}`,
              variant: 'error',
              open: true,
            })
          );
        }
      }
    } else {
      navigate(REGISTRATION);
    }
  };

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1" paddingTop={1}>
          Protect Your Account
        </Typography>
        <Typography
          variant="h1"
          fontWeight={'bold'}
          color={'primary.main'}
          paddingTop={2}
        >
          {' '}
          Set a New Password
        </Typography>
      </Box>
      <Box sx={styles.body}>
        <Box sx={styles.Passwordcontainer}>
          <PasswordInputField
            topLabel="New Password"
            value={newPassword}
            onChange={(e) => {
              setPasswordError(false);
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setNewPassword(e.target.value);
              }
            }}
            type="password"
            // helperTextvariant="error"
            // helperText={
            //   confirmPassword !== newPassword
            //     ? 'new password and confirm password must be same'
            //     : undefined
            // }
            onEnter={() => {
              if (passwordRef.current) {
                // console.log('hii');
                passwordRef.current.focus();
              }
            }}
          />
          <PasswordInputField
            topLabel="Confirm Password"
            value={confirmPassword}
            onChange={(e) => {
              setPasswordError(false);
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setConfirmPassword(e.target.value);
              }
            }}
            type="password"
            helperTextvariant="error"
            helperText={passwordError ? 'Passwords does not match' : undefined}
            onEnter={confirmClickHandler}
            ref={passwordRef}
          />
        </Box>
        <Box sx={styles.buttonContainer}>
          <PrimaryButton
            fullWidth
            onClick={confirmClickHandler}
            disabled={newPassword === '' || confirmPassword === ''}
          >
            <Typography variant="h2" color={'white'}>
              Create Password
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};
