import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsHomewokTeacherAPIServiceV2 } from '@protos/learning_management/lms.hw.teacher.apis_connect';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

export const LmsHomewokTeacherAPIServiceV2Client = createPromiseClient(
  LmsHomewokTeacherAPIServiceV2,
  lmsTransport
);

export const useLmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsHomewokTeacherAPIServiceV2,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );

//LmsHomewokTeacherAPIServiceV1
