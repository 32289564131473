// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.self_registration.apis.proto (package geneo.ums.self_registration.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProfileRolesEnum, ProfileVerificationStatusEnum, Remark } from "@protos/user_management/ums.db_pb";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * @generated from enum geneo.ums.self_registration.apis.ListType
 */
export enum ListType {
  /**
   * @generated from enum value: APPROVAL_PENDING = 0;
   */
  APPROVAL_PENDING = 0,

  /**
   * @generated from enum value: APPROVAL_COMPLETED = 1;
   */
  APPROVAL_COMPLETED = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ListType)
proto3.util.setEnumType(ListType, "geneo.ums.self_registration.apis.ListType", [
  { no: 0, name: "APPROVAL_PENDING" },
  { no: 1, name: "APPROVAL_COMPLETED" },
]);

/**
 * @generated from message geneo.ums.self_registration.apis.ValidateUserSelfRegistrationRequest
 */
export class ValidateUserSelfRegistrationRequest extends Message<ValidateUserSelfRegistrationRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<ValidateUserSelfRegistrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ValidateUserSelfRegistrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserSelfRegistrationRequest {
    return new ValidateUserSelfRegistrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserSelfRegistrationRequest {
    return new ValidateUserSelfRegistrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserSelfRegistrationRequest {
    return new ValidateUserSelfRegistrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserSelfRegistrationRequest | PlainMessage<ValidateUserSelfRegistrationRequest> | undefined, b: ValidateUserSelfRegistrationRequest | PlainMessage<ValidateUserSelfRegistrationRequest> | undefined): boolean {
    return proto3.util.equals(ValidateUserSelfRegistrationRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.ValidateUserSelfRegistrationResponse
 */
export class ValidateUserSelfRegistrationResponse extends Message<ValidateUserSelfRegistrationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string verification_code = 4;
   */
  verificationCode = "";

  constructor(data?: PartialMessage<ValidateUserSelfRegistrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ValidateUserSelfRegistrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserSelfRegistrationResponse {
    return new ValidateUserSelfRegistrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserSelfRegistrationResponse {
    return new ValidateUserSelfRegistrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserSelfRegistrationResponse {
    return new ValidateUserSelfRegistrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserSelfRegistrationResponse | PlainMessage<ValidateUserSelfRegistrationResponse> | undefined, b: ValidateUserSelfRegistrationResponse | PlainMessage<ValidateUserSelfRegistrationResponse> | undefined): boolean {
    return proto3.util.equals(ValidateUserSelfRegistrationResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.ClassSectionSubject
 */
export class ClassSectionSubject extends Message<ClassSectionSubject> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: int32 section_id = 2;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  constructor(data?: PartialMessage<ClassSectionSubject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ClassSectionSubject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSectionSubject {
    return new ClassSectionSubject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSectionSubject {
    return new ClassSectionSubject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSectionSubject {
    return new ClassSectionSubject().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSectionSubject | PlainMessage<ClassSectionSubject> | undefined, b: ClassSectionSubject | PlainMessage<ClassSectionSubject> | undefined): boolean {
    return proto3.util.equals(ClassSectionSubject, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SelfRegisterTeacherRequest
 */
export class SelfRegisterTeacherRequest extends Message<SelfRegisterTeacherRequest> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 4;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string phone_number = 5;
   */
  phoneNumber = "";

  /**
   * @generated from field: repeated geneo.ums.self_registration.apis.ClassSectionSubject class_section_subjects = 6;
   */
  classSectionSubjects: ClassSectionSubject[] = [];

  constructor(data?: PartialMessage<SelfRegisterTeacherRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SelfRegisterTeacherRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 5, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "class_section_subjects", kind: "message", T: ClassSectionSubject, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfRegisterTeacherRequest {
    return new SelfRegisterTeacherRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfRegisterTeacherRequest {
    return new SelfRegisterTeacherRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfRegisterTeacherRequest {
    return new SelfRegisterTeacherRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SelfRegisterTeacherRequest | PlainMessage<SelfRegisterTeacherRequest> | undefined, b: SelfRegisterTeacherRequest | PlainMessage<SelfRegisterTeacherRequest> | undefined): boolean {
    return proto3.util.equals(SelfRegisterTeacherRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SelfRegisterTeacherResponse
 */
export class SelfRegisterTeacherResponse extends Message<SelfRegisterTeacherResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 teacher_profile_id = 4;
   */
  teacherProfileId = protoInt64.zero;

  constructor(data?: PartialMessage<SelfRegisterTeacherResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SelfRegisterTeacherResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfRegisterTeacherResponse {
    return new SelfRegisterTeacherResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfRegisterTeacherResponse {
    return new SelfRegisterTeacherResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfRegisterTeacherResponse {
    return new SelfRegisterTeacherResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SelfRegisterTeacherResponse | PlainMessage<SelfRegisterTeacherResponse> | undefined, b: SelfRegisterTeacherResponse | PlainMessage<SelfRegisterTeacherResponse> | undefined): boolean {
    return proto3.util.equals(SelfRegisterTeacherResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SchoolData
 */
export class SchoolData extends Message<SchoolData> {
  /**
   * @generated from field: string schoolCode = 1;
   */
  schoolCode = "";

  /**
   * @generated from field: string schoolName = 2;
   */
  schoolName = "";

  /**
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated geneo.ums.self_registration.apis.SchoolClassSection school_class_section_details = 4;
   */
  schoolClassSectionDetails: SchoolClassSection[] = [];

  constructor(data?: PartialMessage<SchoolData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SchoolData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schoolName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "school_class_section_details", kind: "message", T: SchoolClassSection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolData {
    return new SchoolData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolData {
    return new SchoolData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolData {
    return new SchoolData().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolData | PlainMessage<SchoolData> | undefined, b: SchoolData | PlainMessage<SchoolData> | undefined): boolean {
    return proto3.util.equals(SchoolData, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SchoolClassSection
 */
export class SchoolClassSection extends Message<SchoolClassSection> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class_name = 2;
   */
  className = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 4;
   */
  sectionName = "";

  /**
   * @generated from field: repeated geneo.ums.self_registration.apis.ClassSubjects subjects = 5;
   */
  subjects: ClassSubjects[] = [];

  constructor(data?: PartialMessage<SchoolClassSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SchoolClassSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subjects", kind: "message", T: ClassSubjects, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined, b: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined): boolean {
    return proto3.util.equals(SchoolClassSection, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.ClassSubjects
 */
export class ClassSubjects extends Message<ClassSubjects> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 2;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: string subjectName = 3;
   */
  subjectName = "";

  constructor(data?: PartialMessage<ClassSubjects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ClassSubjects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 3, name: "subjectName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSubjects {
    return new ClassSubjects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSubjects {
    return new ClassSubjects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSubjects {
    return new ClassSubjects().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSubjects | PlainMessage<ClassSubjects> | undefined, b: ClassSubjects | PlainMessage<ClassSubjects> | undefined): boolean {
    return proto3.util.equals(ClassSubjects, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.ValidateSchoolCodeRequest
 */
export class ValidateSchoolCodeRequest extends Message<ValidateSchoolCodeRequest> {
  /**
   * @generated from field: string school_code = 1;
   */
  schoolCode = "";

  constructor(data?: PartialMessage<ValidateSchoolCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ValidateSchoolCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateSchoolCodeRequest {
    return new ValidateSchoolCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateSchoolCodeRequest {
    return new ValidateSchoolCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateSchoolCodeRequest {
    return new ValidateSchoolCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateSchoolCodeRequest | PlainMessage<ValidateSchoolCodeRequest> | undefined, b: ValidateSchoolCodeRequest | PlainMessage<ValidateSchoolCodeRequest> | undefined): boolean {
    return proto3.util.equals(ValidateSchoolCodeRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.ValidateSchoolCodeResponse
 */
export class ValidateSchoolCodeResponse extends Message<ValidateSchoolCodeResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.self_registration.apis.SchoolData data = 4;
   */
  data?: SchoolData;

  constructor(data?: PartialMessage<ValidateSchoolCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.ValidateSchoolCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: SchoolData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateSchoolCodeResponse {
    return new ValidateSchoolCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateSchoolCodeResponse {
    return new ValidateSchoolCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateSchoolCodeResponse {
    return new ValidateSchoolCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateSchoolCodeResponse | PlainMessage<ValidateSchoolCodeResponse> | undefined, b: ValidateSchoolCodeResponse | PlainMessage<ValidateSchoolCodeResponse> | undefined): boolean {
    return proto3.util.equals(ValidateSchoolCodeResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SendReminderToAdminRequest
 */
export class SendReminderToAdminRequest extends Message<SendReminderToAdminRequest> {
  /**
   * @generated from field: int64 teacher_profile_id = 1;
   */
  teacherProfileId = protoInt64.zero;

  /**
   * @generated from field: optional int32 school_id = 2;
   */
  schoolId?: number;

  constructor(data?: PartialMessage<SendReminderToAdminRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SendReminderToAdminRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendReminderToAdminRequest {
    return new SendReminderToAdminRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendReminderToAdminRequest {
    return new SendReminderToAdminRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendReminderToAdminRequest {
    return new SendReminderToAdminRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendReminderToAdminRequest | PlainMessage<SendReminderToAdminRequest> | undefined, b: SendReminderToAdminRequest | PlainMessage<SendReminderToAdminRequest> | undefined): boolean {
    return proto3.util.equals(SendReminderToAdminRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.SendReminderToAdminResponse
 */
export class SendReminderToAdminResponse extends Message<SendReminderToAdminResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<SendReminderToAdminResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.SendReminderToAdminResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendReminderToAdminResponse {
    return new SendReminderToAdminResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendReminderToAdminResponse {
    return new SendReminderToAdminResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendReminderToAdminResponse {
    return new SendReminderToAdminResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendReminderToAdminResponse | PlainMessage<SendReminderToAdminResponse> | undefined, b: SendReminderToAdminResponse | PlainMessage<SendReminderToAdminResponse> | undefined): boolean {
    return proto3.util.equals(SendReminderToAdminResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.RegistrationRequestsData
 */
export class RegistrationRequestsData extends Message<RegistrationRequestsData> {
  /**
   * @generated from field: int32 self_registration_request_id = 1;
   */
  selfRegistrationRequestId = 0;

  /**
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: int32 school_id = 4;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 5;
   */
  schoolName = "";

  /**
   * @generated from field: google.protobuf.Timestamp date = 6;
   */
  date?: Timestamp;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 7;
   */
  profileVerificationStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: optional geneo.ums.db.Remark reject_info = 8;
   */
  rejectInfo?: Remark;

  constructor(data?: PartialMessage<RegistrationRequestsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.RegistrationRequestsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "self_registration_request_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "date", kind: "message", T: Timestamp },
    { no: 7, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 8, name: "reject_info", kind: "message", T: Remark, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegistrationRequestsData {
    return new RegistrationRequestsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegistrationRequestsData {
    return new RegistrationRequestsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegistrationRequestsData {
    return new RegistrationRequestsData().fromJsonString(jsonString, options);
  }

  static equals(a: RegistrationRequestsData | PlainMessage<RegistrationRequestsData> | undefined, b: RegistrationRequestsData | PlainMessage<RegistrationRequestsData> | undefined): boolean {
    return proto3.util.equals(RegistrationRequestsData, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.GetSelfRegistrationReq
 */
export class GetSelfRegistrationReq extends Message<GetSelfRegistrationReq> {
  /**
   * @generated from field: geneo.ums.self_registration.apis.ListType list_type = 1;
   */
  listType = ListType.APPROVAL_PENDING;

  /**
   * @generated from field: repeated string name = 2;
   */
  name: string[] = [];

  /**
   * @generated from field: repeated int32 school_ids = 3;
   */
  schoolIds: number[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp start_date = 4;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_date = 5;
   */
  endDate?: Timestamp;

  /**
   * @generated from field: optional geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 6;
   */
  profileVerificationStatus?: ProfileVerificationStatusEnum;

  /**
   * @generated from field: optional int32 page_number = 7;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 itemsPerPage = 8;
   */
  itemsPerPage?: number;

  constructor(data?: PartialMessage<GetSelfRegistrationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.GetSelfRegistrationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list_type", kind: "enum", T: proto3.getEnumType(ListType) },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "school_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "start_date", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "end_date", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum), opt: true },
    { no: 7, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "itemsPerPage", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSelfRegistrationReq {
    return new GetSelfRegistrationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSelfRegistrationReq {
    return new GetSelfRegistrationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSelfRegistrationReq {
    return new GetSelfRegistrationReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetSelfRegistrationReq | PlainMessage<GetSelfRegistrationReq> | undefined, b: GetSelfRegistrationReq | PlainMessage<GetSelfRegistrationReq> | undefined): boolean {
    return proto3.util.equals(GetSelfRegistrationReq, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.GetSelfRegistrationResponse
 */
export class GetSelfRegistrationResponse extends Message<GetSelfRegistrationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.self_registration.apis.RegistrationRequestsData data = 4;
   */
  data: RegistrationRequestsData[] = [];

  /**
   * @generated from field: int32 total_requests = 5;
   */
  totalRequests = 0;

  constructor(data?: PartialMessage<GetSelfRegistrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.GetSelfRegistrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: RegistrationRequestsData, repeated: true },
    { no: 5, name: "total_requests", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSelfRegistrationResponse {
    return new GetSelfRegistrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSelfRegistrationResponse {
    return new GetSelfRegistrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSelfRegistrationResponse {
    return new GetSelfRegistrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSelfRegistrationResponse | PlainMessage<GetSelfRegistrationResponse> | undefined, b: GetSelfRegistrationResponse | PlainMessage<GetSelfRegistrationResponse> | undefined): boolean {
    return proto3.util.equals(GetSelfRegistrationResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.EvaluateSelfRegistrationRequest
 */
export class EvaluateSelfRegistrationRequest extends Message<EvaluateSelfRegistrationRequest> {
  /**
   * @generated from field: int32 self_registration_request_id = 1;
   */
  selfRegistrationRequestId = 0;

  /**
   * @generated from field: int64 teacher_profile_id = 2;
   */
  teacherProfileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 3;
   */
  profileVerificationStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: optional geneo.ums.db.Remark reject_info = 4;
   */
  rejectInfo?: Remark;

  constructor(data?: PartialMessage<EvaluateSelfRegistrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.EvaluateSelfRegistrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "self_registration_request_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 4, name: "reject_info", kind: "message", T: Remark, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EvaluateSelfRegistrationRequest {
    return new EvaluateSelfRegistrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EvaluateSelfRegistrationRequest {
    return new EvaluateSelfRegistrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EvaluateSelfRegistrationRequest {
    return new EvaluateSelfRegistrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EvaluateSelfRegistrationRequest | PlainMessage<EvaluateSelfRegistrationRequest> | undefined, b: EvaluateSelfRegistrationRequest | PlainMessage<EvaluateSelfRegistrationRequest> | undefined): boolean {
    return proto3.util.equals(EvaluateSelfRegistrationRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.self_registration.apis.EvaluateSelfRegistrationResponse
 */
export class EvaluateSelfRegistrationResponse extends Message<EvaluateSelfRegistrationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<EvaluateSelfRegistrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.self_registration.apis.EvaluateSelfRegistrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EvaluateSelfRegistrationResponse {
    return new EvaluateSelfRegistrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EvaluateSelfRegistrationResponse {
    return new EvaluateSelfRegistrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EvaluateSelfRegistrationResponse {
    return new EvaluateSelfRegistrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EvaluateSelfRegistrationResponse | PlainMessage<EvaluateSelfRegistrationResponse> | undefined, b: EvaluateSelfRegistrationResponse | PlainMessage<EvaluateSelfRegistrationResponse> | undefined): boolean {
    return proto3.util.equals(EvaluateSelfRegistrationResponse, a, b);
  }
}

