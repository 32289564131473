import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { UmsSsoLoginApiServiceV1 } from '@protos/user_management/ums.login.sso.apis_connect';
import { ResponseStatusCodeFunction, UmsTransportWithLogoutHandler, umsTransport } from './transports';

export const UmsSsoAPIServiceV1Client = createPromiseClient(
  UmsSsoLoginApiServiceV1,
  umsTransport
);

export const useUmsSsoAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  UmsSsoLoginApiServiceV1,
  UmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
