import { Timestamp } from '@bufbuild/protobuf';
import { ResourceEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IResourceCloseArgs,
  IResourceEventArgs,
  IResourceOpenArgs,
} from './inputArgs';
import {
  ResourceCloseArgsValidator,
  ResourceEventArgsValidator,
  ResourceOpenArgsValidator,
} from './inputArgsValidator';
import { RESOURCE_KEY } from './storageKeys';
import {
  checkNgetResourceEventData,
  clearEventData,
  getResourceEventData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  timeDifference,
} from './utils';

export function resourceStorageKey(resourceId: string) {
  return `${RESOURCE_KEY}!${resourceId}`;
}

export function resourceOpen(args: IResourceOpenArgs): ResourceEventData {
  // const { error } = ResourceOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, resourceId, selectedFrom, recommendationId, rank } =
    args;
  const storageKey = resourceStorageKey(resourceId);
  const resourceData = getResourceEventData(storageKey);
  const resourceSessionId = uuidv4();
  if (resourceData !== null) {
    throw new Error(
      `Resource Data has to be null but ${JSON.stringify(
        resourceData
      )} is present"`
    );
  } else {
    const eventData = new ResourceEventData({
      resourceId,
      resourceSessionId: resourceSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: resourceSessionId,
      selectedFrom: selectedFrom,
      recommendationId: recommendationId,
      rank: rank,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getResourceOpenEvent(args: IResourceEventArgs): StudentEvent {
  const { error } = ResourceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { resourceId } = args;
  const storageKey = resourceStorageKey(resourceId);
  const eventData = checkNgetResourceEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const resourceSessionData = getResourceSessionData();
  // if (resourceSessionData.resource === undefined) {
  //   throw new Error(
  //     `resource Session id can not be undefined in ${JSON.stringify(
  //       resourceSessionData
  //     )}`
  //   );
  // }

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'resourceAction',
      value: {
        actionId: eventData.actionId,
        resourceId,
        selectedFrom: {
          type: eventData.selectedFrom,
          ...(eventData.recommendationId && eventData.rank
            ? {
                ResourceSelectionFromType: {
                  case: 'RESOURCESELECTION',
                  value: {
                    recommendationId: eventData.recommendationId,
                    rank: eventData.rank,
                  },
                },
              }
            : {}),
        },
        ResourceActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function resourceClose(args: IResourceCloseArgs): ResourceEventData {
  const { error } = ResourceCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, resourceId } = args;
  const storageKey = resourceStorageKey(resourceId);
  const resourceData = getResourceEventData(storageKey);

  if (resourceData === null) {
    throw new Error(`Resource data is null for resourceId: ${resourceId}`);
  }

  if (resourceData.resourceId !== resourceId) {
    throw new Error(
      `Resource Id in ${JSON.stringify(
        resourceData
      )} can't be different from provided "${resourceId}"`
    );
  } else {
    resourceData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, resourceData);
    return resourceData;
  }
}

export function getResourceCloseEvent(args: IResourceEventArgs): StudentEvent {
  const { error } = ResourceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { resourceId } = args;
  const storageKey = resourceStorageKey(resourceId);
  const eventData = checkNgetResourceEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const resourceSessionData = getResourceSessionData();
  // if (resourceSessionData.resource === undefined) {
  //   throw new Error(
  //     `resource Session id can not be undefined in ${JSON.stringify(
  //       resourceSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }

  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'resourceAction',
      value: {
        actionId: eventData.actionId,
        resourceId,
        selectedFrom: {
          type: eventData.selectedFrom,
          ...(eventData.recommendationId && eventData.rank
            ? {
                ResourceSelectionFromType: {
                  case: 'RESOURCESELECTION',
                  value: {
                    recommendationId: eventData.recommendationId,
                    rank: eventData.rank,
                  },
                },
              }
            : {}),
        },
        ResourceActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearResourceData(args: IResourceEventArgs) {
  const { error } = ResourceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { resourceId } = args;
  const storageKey = resourceStorageKey(resourceId);
  clearEventData(storageKey);
}

export function isResourceOpen(args: IResourceEventArgs): boolean {
  const { error } = ResourceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { resourceId } = args;
  const storageKey = resourceStorageKey(resourceId);
  return hasEventData(storageKey);
}
