import { Box, Typography } from '@mui/material';
import { QuestionContentModel } from '@protos/content_management/content.db_pb';
import { useState } from 'react';
import { pxToRem, pxTovW } from '../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../commonUtils/styleUtils';
import PrimaryButton from '../../../elements/PrimaryButton';
import { SelectedResourceInfo } from '../../VideoPlayerWrapper/VideoPlayerWrapper';
import Question from '../Question';
import { QuestionEventCollection } from '../QuestionContainerWithSolution';

export interface QuestionContainerProps {
  questionContent?: QuestionContentModel;
  questionNumber?: number | string;
  disableInput?: boolean;
  userAttemptedAnswer?: string[];
  handleAnswerChange?: (answer: string, index?: number) => void;
  onDisableButtonClick?: () => void;
  isCorrect?: boolean;
  showAnswer?: boolean;
  handleNext?: () => void;
  showNextButton?: boolean;
  showSubmitButton?: boolean;
  headerRightElement?: JSX.Element;
  isSubmitted?: boolean;
  hideMarks?: boolean;
  homeworkAttempt?: boolean;
  scanClickHandler?: () => void;
  selected_resource_info?: SelectedResourceInfo;
  onNextButtonClick?: () => void;
  eventCollection?: QuestionEventCollection;
}

const styles: IStyles = {
  questionDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: {
      xs: pxToRem(15),
      md: pxTovW(15),
    },
    '&>div:last-child': {
      marginLeft: 'auto',
    },
  },
  marksWrapper: {
    padding: {
      xs: `${pxToRem(5)} ${pxToRem(13)}`,
      md: `${pxTovW(7)} ${pxTovW(11)}`,
    },
    borderRadius: {
      xs: pxToRem(16),
      md: pxTovW(35),
    },
    border: '1px solid #CEEAFF',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  marks: {
    fontWeight: 700,
  },
};

export default function QuestionContainer(props: QuestionContainerProps) {
  const {
    questionContent,
    hideMarks,
    questionNumber,
    disableInput,
    handleAnswerChange,
    userAttemptedAnswer,
    handleNext,
    showNextButton,
    showSubmitButton,
    isSubmitted,
    homeworkAttempt,
    scanClickHandler,
    selected_resource_info,
    onNextButtonClick,
    eventCollection,
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  if (!questionContent) {
    return null;
  }
  const questionCase = questionContent.model.case;
  if (!questionCase) {
    return null;
  }
  const marks =
    questionContent.model.value.commonQuestionContent?.marks.reduce(
      (acc, curr) => acc + curr,
      0
    ) || 0;
  const handleClick = async () => {
    try {
      setLoading(true);
      setError(null);
      if (handleNext) {
        await handleNext();
      }
    } catch (error) {
      setError('An error occurred');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Box sx={styles.questionDetailsWrapper}>
        {!!questionNumber && (
          <Typography variant="elementH3">Q. {questionNumber}</Typography>
        )}
        {!!marks && !hideMarks && (
          <Box sx={styles.marksWrapper}>
            <Typography
              variant="elementSmallText"
              fontFamily="Poppins"
              sx={styles.marks}
            >
              {marks} marks
            </Typography>
          </Box>
        )}
        {props.headerRightElement}
      </Box>
      <Question
        questionContent={questionContent}
        disableInput={disableInput}
        userAttemptedAnswer={userAttemptedAnswer}
        handleAnswerChange={handleAnswerChange}
        homeworkAttempt={homeworkAttempt && homeworkAttempt}
        scanClickHandler={scanClickHandler}
        selected_resource_info={selected_resource_info}
        eventCollection={eventCollection}
      />
      {[
        'fibContentModel',
        'mcqMultipleContentModel',
        'veryShortQuesContentModel',
        'shortQuesContentModel',
        'longQuesContentModel',
      ].includes(questionCase) &&
        handleNext &&
        showNextButton && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: { xs: pxToRem(10), md: pxTovW(10) } }}
          >
            <PrimaryButton
              onClick={() => {
                handleClick();
                if (onNextButtonClick) onNextButtonClick();
              }}
              disabled={
                loading ||
                !userAttemptedAnswer ||
                userAttemptedAnswer.length === 0 ||
                //for subjective questions
                (userAttemptedAnswer.length === 1 &&
                  !userAttemptedAnswer[0].trim())
              }
            >
              Next
            </PrimaryButton>
          </Box>
        )}
      {showSubmitButton && handleNext && !isSubmitted && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: { xs: pxToRem(20), md: pxTovW(20) } }}
        >
          <PrimaryButton
            sx={{
              width: {
                xs: pxToRem(120),
                md: pxTovW(170),
              },
              padding: { md: pxToRem(15) },
              height: { xs: pxToRem(38), md: pxTovW(56) },
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: { xs: '16px', md: '18px', lg: '21px' },
              boxShadow:
                'inset 0 0 8px rgba(0, 0, 0, 0.5),0 7px 13px rgba(0, 0, 0, 0.5)',
              borderRadius: '10px',
            }}
            onClick={() => {
              handleClick();
              eventCollection?.submitAnswer && eventCollection?.submitAnswer();
            }}
            disabled={
              loading ||
              !userAttemptedAnswer ||
              userAttemptedAnswer.length === 0
            }
          >
            Submit
          </PrimaryButton>
        </Box>
      )}
    </Box>
  );
}
