// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.recommendation.apis.proto (package geneo.ai.student.recommendation.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ResourceInfo } from "@protos/content_management/content.common.apis_pb";

/**
 * @generated from enum geneo.ai.student.recommendation.apis.RecommendationReqSendFromTypeEnum
 */
export enum RecommendationReqSendFromTypeEnum {
  /**
   * @generated from enum value: SEND_FROM_UNDEFINED = 0;
   */
  SEND_FROM_UNDEFINED = 0,

  /**
   * @generated from enum value: PERFOMANCE_HOMEPAGE = 1;
   */
  PERFOMANCE_HOMEPAGE = 1,

  /**
   * @generated from enum value: HOMEWORK_ENDED_RECOMMENDATION = 2;
   */
  HOMEWORK_ENDED_RECOMMENDATION = 2,

  /**
   * @generated from enum value: HOMEWORK_SUBMITTED_RECOMMENDATION = 3;
   */
  HOMEWORK_SUBMITTED_RECOMMENDATION = 3,

  /**
   * @generated from enum value: APP_HOMEPAGE_RECOMMENDATION = 4;
   */
  APP_HOMEPAGE_RECOMMENDATION = 4,

  /**
   * @generated from enum value: APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION = 5;
   */
  APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(RecommendationReqSendFromTypeEnum)
proto3.util.setEnumType(RecommendationReqSendFromTypeEnum, "geneo.ai.student.recommendation.apis.RecommendationReqSendFromTypeEnum", [
  { no: 0, name: "SEND_FROM_UNDEFINED" },
  { no: 1, name: "PERFOMANCE_HOMEPAGE" },
  { no: 2, name: "HOMEWORK_ENDED_RECOMMENDATION" },
  { no: 3, name: "HOMEWORK_SUBMITTED_RECOMMENDATION" },
  { no: 4, name: "APP_HOMEPAGE_RECOMMENDATION" },
  { no: 5, name: "APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION" },
]);

/**
 * @generated from message geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendationRequest
 */
export class GetAIStudentResourceRecommendationRequest extends Message<GetAIStudentResourceRecommendationRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 3;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 4;
   */
  topicId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 homework_id = 6;
   */
  homeworkId?: number;

  /**
   * @generated from field: optional geneo.ai.student.recommendation.apis.RecommendationReqSendFromTypeEnum send_from = 7;
   */
  sendFrom?: RecommendationReqSendFromTypeEnum;

  constructor(data?: PartialMessage<GetAIStudentResourceRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "send_from", kind: "enum", T: proto3.getEnumType(RecommendationReqSendFromTypeEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAIStudentResourceRecommendationRequest {
    return new GetAIStudentResourceRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendationRequest {
    return new GetAIStudentResourceRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendationRequest {
    return new GetAIStudentResourceRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAIStudentResourceRecommendationRequest | PlainMessage<GetAIStudentResourceRecommendationRequest> | undefined, b: GetAIStudentResourceRecommendationRequest | PlainMessage<GetAIStudentResourceRecommendationRequest> | undefined): boolean {
    return proto3.util.equals(GetAIStudentResourceRecommendationRequest, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendationResponse
 */
export class GetAIStudentResourceRecommendationResponse extends Message<GetAIStudentResourceRecommendationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendation data = 4;
   */
  data?: GetAIStudentResourceRecommendation;

  constructor(data?: PartialMessage<GetAIStudentResourceRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: GetAIStudentResourceRecommendation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAIStudentResourceRecommendationResponse {
    return new GetAIStudentResourceRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendationResponse {
    return new GetAIStudentResourceRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendationResponse {
    return new GetAIStudentResourceRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAIStudentResourceRecommendationResponse | PlainMessage<GetAIStudentResourceRecommendationResponse> | undefined, b: GetAIStudentResourceRecommendationResponse | PlainMessage<GetAIStudentResourceRecommendationResponse> | undefined): boolean {
    return proto3.util.equals(GetAIStudentResourceRecommendationResponse, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendation
 */
export class GetAIStudentResourceRecommendation extends Message<GetAIStudentResourceRecommendation> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: repeated geneo.content.common.apis.ResourceInfo resources = 2;
   */
  resources: ResourceInfo[] = [];

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 3;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 4;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 5;
   */
  topicId?: number;

  /**
   * @generated from field: optional string recommendation_id = 6;
   */
  recommendationId?: string;

  constructor(data?: PartialMessage<GetAIStudentResourceRecommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.recommendation.apis.GetAIStudentResourceRecommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resources", kind: "message", T: ResourceInfo, repeated: true },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "recommendation_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAIStudentResourceRecommendation {
    return new GetAIStudentResourceRecommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendation {
    return new GetAIStudentResourceRecommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAIStudentResourceRecommendation {
    return new GetAIStudentResourceRecommendation().fromJsonString(jsonString, options);
  }

  static equals(a: GetAIStudentResourceRecommendation | PlainMessage<GetAIStudentResourceRecommendation> | undefined, b: GetAIStudentResourceRecommendation | PlainMessage<GetAIStudentResourceRecommendation> | undefined): boolean {
    return proto3.util.equals(GetAIStudentResourceRecommendation, a, b);
  }
}

