// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.student.apis.proto (package geneo.analysis.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * fetchOverAllAnalysisStats Request
 *
 * @generated from message geneo.analysis.student.apis.FetchOverAllAnalysisStatsRequest
 */
export class FetchOverAllAnalysisStatsRequest extends Message<FetchOverAllAnalysisStatsRequest> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchOverAllAnalysisStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.FetchOverAllAnalysisStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchOverAllAnalysisStatsRequest {
    return new FetchOverAllAnalysisStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStatsRequest {
    return new FetchOverAllAnalysisStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStatsRequest {
    return new FetchOverAllAnalysisStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchOverAllAnalysisStatsRequest | PlainMessage<FetchOverAllAnalysisStatsRequest> | undefined, b: FetchOverAllAnalysisStatsRequest | PlainMessage<FetchOverAllAnalysisStatsRequest> | undefined): boolean {
    return proto3.util.equals(FetchOverAllAnalysisStatsRequest, a, b);
  }
}

/**
 * fetchOverAllAnalysisStats Response
 *
 * @generated from message geneo.analysis.student.apis.FetchOverAllAnalysisStatsResponse
 */
export class FetchOverAllAnalysisStatsResponse extends Message<FetchOverAllAnalysisStatsResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Overall analysis stats data (required)
   *
   * @generated from field: geneo.analysis.student.apis.FetchOverAllAnalysisStats data = 4;
   */
  data?: FetchOverAllAnalysisStats;

  constructor(data?: PartialMessage<FetchOverAllAnalysisStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.FetchOverAllAnalysisStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: FetchOverAllAnalysisStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchOverAllAnalysisStatsResponse {
    return new FetchOverAllAnalysisStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStatsResponse {
    return new FetchOverAllAnalysisStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStatsResponse {
    return new FetchOverAllAnalysisStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchOverAllAnalysisStatsResponse | PlainMessage<FetchOverAllAnalysisStatsResponse> | undefined, b: FetchOverAllAnalysisStatsResponse | PlainMessage<FetchOverAllAnalysisStatsResponse> | undefined): boolean {
    return proto3.util.equals(FetchOverAllAnalysisStatsResponse, a, b);
  }
}

/**
 * Overall Analysis Stats
 *
 * @generated from message geneo.analysis.student.apis.FetchOverAllAnalysisStats
 */
export class FetchOverAllAnalysisStats extends Message<FetchOverAllAnalysisStats> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 2;
   */
  scorePercent = 0;

  /**
   * Number of submitted homework assignments (required)
   *
   * @generated from field: int32 submitted_hw_count = 3;
   */
  submittedHwCount = 0;

  /**
   * Time spent in minutes (required)
   *
   * @generated from field: float time_spent_in_min = 4;
   */
  timeSpentInMin = 0;

  /**
   * List of subject performance stats (required)
   *
   * @generated from field: repeated geneo.analysis.student.apis.SubjectPerformanceOverAllStats subjects_performance = 5;
   */
  subjectsPerformance: SubjectPerformanceOverAllStats[] = [];

  constructor(data?: PartialMessage<FetchOverAllAnalysisStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.FetchOverAllAnalysisStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "submitted_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "time_spent_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "subjects_performance", kind: "message", T: SubjectPerformanceOverAllStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchOverAllAnalysisStats {
    return new FetchOverAllAnalysisStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStats {
    return new FetchOverAllAnalysisStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchOverAllAnalysisStats {
    return new FetchOverAllAnalysisStats().fromJsonString(jsonString, options);
  }

  static equals(a: FetchOverAllAnalysisStats | PlainMessage<FetchOverAllAnalysisStats> | undefined, b: FetchOverAllAnalysisStats | PlainMessage<FetchOverAllAnalysisStats> | undefined): boolean {
    return proto3.util.equals(FetchOverAllAnalysisStats, a, b);
  }
}

/**
 * Subject Performance Stats
 *
 * @generated from message geneo.analysis.student.apis.SubjectPerformanceOverAllStats
 */
export class SubjectPerformanceOverAllStats extends Message<SubjectPerformanceOverAllStats> {
  /**
   * ID of the course id (required)
   *
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * Subject (required)
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 2;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * URL of the subject icon image (required)
   *
   * @generated from field: string subject_icon_image_url = 3;
   */
  subjectIconImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<SubjectPerformanceOverAllStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.SubjectPerformanceOverAllStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 3, name: "subject_icon_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectPerformanceOverAllStats {
    return new SubjectPerformanceOverAllStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectPerformanceOverAllStats {
    return new SubjectPerformanceOverAllStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectPerformanceOverAllStats {
    return new SubjectPerformanceOverAllStats().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectPerformanceOverAllStats | PlainMessage<SubjectPerformanceOverAllStats> | undefined, b: SubjectPerformanceOverAllStats | PlainMessage<SubjectPerformanceOverAllStats> | undefined): boolean {
    return proto3.util.equals(SubjectPerformanceOverAllStats, a, b);
  }
}

/**
 * fetchSubjectAnalysisStats Request
 *
 * @generated from message geneo.analysis.student.apis.FetchSubjectAnalysisStatsRequest
 */
export class FetchSubjectAnalysisStatsRequest extends Message<FetchSubjectAnalysisStatsRequest> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  constructor(data?: PartialMessage<FetchSubjectAnalysisStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.FetchSubjectAnalysisStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectAnalysisStatsRequest {
    return new FetchSubjectAnalysisStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectAnalysisStatsRequest {
    return new FetchSubjectAnalysisStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectAnalysisStatsRequest {
    return new FetchSubjectAnalysisStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectAnalysisStatsRequest | PlainMessage<FetchSubjectAnalysisStatsRequest> | undefined, b: FetchSubjectAnalysisStatsRequest | PlainMessage<FetchSubjectAnalysisStatsRequest> | undefined): boolean {
    return proto3.util.equals(FetchSubjectAnalysisStatsRequest, a, b);
  }
}

/**
 * fetchSubjectAnalysisStats Response
 *
 * @generated from message geneo.analysis.student.apis.FetchSubjectAnalysisStatsResponse
 */
export class FetchSubjectAnalysisStatsResponse extends Message<FetchSubjectAnalysisStatsResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Subject analysis stats data (required)
   *
   * @generated from field: geneo.analysis.student.apis.SubjectAnalysisStats data = 4;
   */
  data?: SubjectAnalysisStats;

  constructor(data?: PartialMessage<FetchSubjectAnalysisStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.FetchSubjectAnalysisStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: SubjectAnalysisStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectAnalysisStatsResponse {
    return new FetchSubjectAnalysisStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectAnalysisStatsResponse {
    return new FetchSubjectAnalysisStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectAnalysisStatsResponse {
    return new FetchSubjectAnalysisStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectAnalysisStatsResponse | PlainMessage<FetchSubjectAnalysisStatsResponse> | undefined, b: FetchSubjectAnalysisStatsResponse | PlainMessage<FetchSubjectAnalysisStatsResponse> | undefined): boolean {
    return proto3.util.equals(FetchSubjectAnalysisStatsResponse, a, b);
  }
}

/**
 * Subject Analysis Stats
 *
 * @generated from message geneo.analysis.student.apis.SubjectAnalysisStats
 */
export class SubjectAnalysisStats extends Message<SubjectAnalysisStats> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * Subject (required)
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * Number of submitted homework assignments (required)
   *
   * @generated from field: int32 submitted_hw_count = 5;
   */
  submittedHwCount = 0;

  /**
   * Time spent in minutes (required)
   *
   * @generated from field: float time_spent_in_min = 6;
   */
  timeSpentInMin = 0;

  /**
   * List of chapter analysis stats (required)
   *
   * @generated from field: repeated geneo.analysis.student.apis.ChapterAnalysisStats chapters_performance = 7;
   */
  chaptersPerformance: ChapterAnalysisStats[] = [];

  constructor(data?: PartialMessage<SubjectAnalysisStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.SubjectAnalysisStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "submitted_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "time_spent_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "chapters_performance", kind: "message", T: ChapterAnalysisStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectAnalysisStats {
    return new SubjectAnalysisStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectAnalysisStats {
    return new SubjectAnalysisStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectAnalysisStats {
    return new SubjectAnalysisStats().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectAnalysisStats | PlainMessage<SubjectAnalysisStats> | undefined, b: SubjectAnalysisStats | PlainMessage<SubjectAnalysisStats> | undefined): boolean {
    return proto3.util.equals(SubjectAnalysisStats, a, b);
  }
}

/**
 * Chapter Analysis Stats
 *
 * @generated from message geneo.analysis.student.apis.ChapterAnalysisStats
 */
export class ChapterAnalysisStats extends Message<ChapterAnalysisStats> {
  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * ID of the chapter (required)
   *
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * Title of the chapter (required)
   *
   * @generated from field: string chapter_title = 3;
   */
  chapterTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 4;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 5;
   */
  scorePercent = 0;

  /**
   * List of topic analysis stats
   *
   * @generated from field: repeated geneo.analysis.student.apis.TopicAnalysisStats topics_performance = 6;
   */
  topicsPerformance: TopicAnalysisStats[] = [];

  constructor(data?: PartialMessage<ChapterAnalysisStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.ChapterAnalysisStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "topics_performance", kind: "message", T: TopicAnalysisStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterAnalysisStats {
    return new ChapterAnalysisStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterAnalysisStats {
    return new ChapterAnalysisStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterAnalysisStats {
    return new ChapterAnalysisStats().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterAnalysisStats | PlainMessage<ChapterAnalysisStats> | undefined, b: ChapterAnalysisStats | PlainMessage<ChapterAnalysisStats> | undefined): boolean {
    return proto3.util.equals(ChapterAnalysisStats, a, b);
  }
}

/**
 * Topic Analysis Stats
 *
 * @generated from message geneo.analysis.student.apis.TopicAnalysisStats
 */
export class TopicAnalysisStats extends Message<TopicAnalysisStats> {
  /**
   * ID of the topic (required)
   *
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * Title of the topic (required)
   *
   * @generated from field: string topic_title = 2;
   */
  topicTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<TopicAnalysisStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.student.apis.TopicAnalysisStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicAnalysisStats {
    return new TopicAnalysisStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicAnalysisStats {
    return new TopicAnalysisStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicAnalysisStats {
    return new TopicAnalysisStats().fromJsonString(jsonString, options);
  }

  static equals(a: TopicAnalysisStats | PlainMessage<TopicAnalysisStats> | undefined, b: TopicAnalysisStats | PlainMessage<TopicAnalysisStats> | undefined): boolean {
    return proto3.util.equals(TopicAnalysisStats, a, b);
  }
}

