// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.admin.portal.apis.proto (package geneo.lms.admin.portal.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ModuleContentLock } from "@protos/learning_management/lms.common.apis_pb";
import { SchoolContentModuleNodeLock } from "@protos/school_management/school.db_pb";
import { Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { TeachClassSubjects } from "@protos/user_management/ums.login.apis_pb";

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest
 */
export class AdminContentLockUpdateRequest extends Message<AdminContentLockUpdateRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.lms.common.apis.ModuleContentLock moduleInfo = 5;
   */
  moduleInfo?: ModuleContentLock;

  /**
   * @generated from field: optional bool is_update_required_for_all_sections = 6;
   */
  isUpdateRequiredForAllSections?: boolean;

  constructor(data?: PartialMessage<AdminContentLockUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "moduleInfo", kind: "message", T: ModuleContentLock },
    { no: 6, name: "is_update_required_for_all_sections", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined, b: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse
 */
export class AdminContentLockUpdateResponse extends Message<AdminContentLockUpdateResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminContentLockUpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined, b: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentLockModuleData
 */
export class ContentLockModuleData extends Message<ContentLockModuleData> {
  /**
   * @generated from field: geneo.school.db.SchoolContentModuleNodeLock module_updated_lock_info = 1;
   */
  moduleUpdatedLockInfo?: SchoolContentModuleNodeLock;

  constructor(data?: PartialMessage<ContentLockModuleData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentLockModuleData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_updated_lock_info", kind: "message", T: SchoolContentModuleNodeLock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJsonString(jsonString, options);
  }

  static equals(a: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined, b: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined): boolean {
    return proto3.util.equals(ContentLockModuleData, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest
 */
export class AdminModuleLockInfoFetchRequest extends Message<AdminModuleLockInfoFetchRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum category = 6;
   */
  category?: Module_ModuleCategoryEnum;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined, b: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse
 */
export class AdminModuleLockInfoFetchResponse extends Message<AdminModuleLockInfoFetchResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined, b: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest
 */
export class FetchAdminSchoolsInfoRequest extends Message<FetchAdminSchoolsInfoRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined, b: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse
 */
export class FetchAdminSchoolsInfoResponse extends Message<FetchAdminSchoolsInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.SchoolDetails data = 4;
   */
  data: SchoolDetails[] = [];

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: SchoolDetails, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined, b: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.SchoolDetails
 */
export class SchoolDetails extends Message<SchoolDetails> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: optional string school_code = 3;
   */
  schoolCode?: string;

  /**
   * @generated from field: string school_logo_image_url = 4;
   */
  schoolLogoImageUrl = "";

  constructor(data?: PartialMessage<SchoolDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.SchoolDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolDetails {
    return new SchoolDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolDetails | PlainMessage<SchoolDetails> | undefined, b: SchoolDetails | PlainMessage<SchoolDetails> | undefined): boolean {
    return proto3.util.equals(SchoolDetails, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest
 */
export class FetchSchoolClassSectionCoursesInfoResquest extends Message<FetchSchoolClassSectionCoursesInfoResquest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResquest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined, b: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResquest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse
 */
export class FetchSchoolClassSectionCoursesInfoResponse extends Message<FetchSchoolClassSectionCoursesInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.TeachClassSubjects data = 4;
   */
  data: TeachClassSubjects[] = [];

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeachClassSubjects, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined, b: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResponse, a, b);
  }
}

