import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsTeacherAPIServiceV1 } from '@protos/learning_management/lms.teacher.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsTeacherAPIServiceV1Client = createPromiseClient(
  LmsTeacherAPIServiceV1,
  lmsTransport
);

export const useLmsTeacherAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsTeacherAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
