// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.teacher.events.apis.proto (package geneo.ai.teacher.events.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { TeacherEvent, TeacherEventResponse } from "@protos/geneo_ai/ai.teacher.events_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ai.teacher.events.apis.TeacherEventsAPIServiceV1
 */
export const TeacherEventsAPIServiceV1 = {
  typeName: "geneo.ai.teacher.events.apis.TeacherEventsAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.teacher.events.apis.TeacherEventsAPIServiceV1.teacherEventProcess
     */
    teacherEventProcess: {
      name: "teacherEventProcess",
      I: TeacherEvent,
      O: TeacherEventResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

