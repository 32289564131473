import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { SchoolAssessmentAPIServiceV1 } from '@protos/analysis_management/analysis.assessment.apis_connect';

import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

export const SchoolAssessmentAPIServiceV1Client = createPromiseClient(
  SchoolAssessmentAPIServiceV1,
  lmsTransport
);
//SchoolAssessmentAPIServiceV1
export const useSchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    SchoolAssessmentAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
