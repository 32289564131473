import {
  ActionPopUpSingleButton,
  ContentDetailCard,
  IStyles,
  LockToggleButton,
  SectionListWithTopCarousel,
  deserify,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { RecommendationReqSendFromTypeEnum } from '@protos/geneo_ai/ai.student.recommendation.apis_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../reduxStore/reduxHooks';
import { onResourceClick } from '../../../../../utils/learn';
import { interactionEvent } from '../../../../Auth/Login/login_logout.events';
import SectionListSckeleton, {
  ShimmerActiveHwCard,
} from '../../../../Home/shimmer';
import { setResourceRedirectionPath } from '../../../../Learn/reducer/learn.slice';
import { aiResourceOpenEvent } from '../../../../Learn/utils_learn/learn.events';
import { useGlobalContext } from 'apps/student/src/app/Context/GlobalContextProvider';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ContentLockStatusType, Feature, LessonNodeLock } from '@protos/school_management/school.db_pb';
import { ResourceLock } from '@protos/learning_management/lms.common.apis_pb';
interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}
const styles: IStyles = {};
export const SuggestedLearning = () => {
  const [resources, setResources] = useState<ResourceInfo[]>([]);
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const { isIpadOnly } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recommendationId, setRecommendationId] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathSegments = location.pathname.split('/');
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const [lockedData, setLockedData] = useState<ResourceLock[]>();
  const [actionPopupState, setActionPopupState] = useState(false);
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });

  const { AiStudentAPIServiceV1ClientWithStatusCodeHandler, LmsCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  useEffect(() => {
    fetchRecommendations();
  }, []);
  async function fetchRecommendations() {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      // const response =
      //   await LmsStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendation({
      //     studentId: studentId,
      //   });
      const response =
        await AiStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendationfromGeneoAi(
          {
            studentId: studentId,
            sendFrom: RecommendationReqSendFromTypeEnum.PERFOMANCE_HOMEPAGE,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          setResources(response.data?.resources || []);
          const resourceIds = response?.data?.resources?.map((resource) => resource.resourceId);
          if (isContentLockFeature && studentId) {
            await fetchLockContent(resourceIds);
          }
          setRecommendationId(response.data.recommendationId);
          return;
        }
      }
      setResources([]);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
      setResources([]);
      // setLoading('error');
    }
  }

  const fetchLockContent = async (resourceIds: string[]) => {
    try {
      const response = await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
        {
          personId: studentId,
          personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          subjectId: selectedSubjectId,
          schoolClassSectionId: user?.classSectionDetails?.sectionId,
          resourceIds: resourceIds,
        }
      );
      if (response) {
        setLockedData(response?.resourceInfo);
        console.log(response?.resourceInfo);
      }
    }
    catch (err) {
      throw err
    }
  }
  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  if (loading === true) {
    return (
      <Box
        sx={{
          width: { xs: '100%', lg: pxTovW(555) },
          '&  h2,h3': {
            fontWeight: 'bold',
            fontSize: { xs: pxToRem(18), md: pxTovW(30) },
          },
        }}
      >
        <SectionListSckeleton fullWidth children={ResourceShimmerArray} />
      </Box>
    );
  }
  if (resources.length === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        width: { xs: '100%', lg: pxTovW(555) },
        '&  h2,h3': {
          fontWeight: 'bold',
          fontSize: { xs: pxToRem(18), md: pxTovW(30) },
          marginBottom: pxToRem(5),
        },
      }}
    >
      <SectionListWithTopCarousel
        containerMdWidth="100%"
        title="Suggested Learning"
        itemsToShow={2}
        items={resources.map((val, index) => (
          <ContentDetailCard
            isLocked={
              isContentLockFeature ?
                getResourceContentLockInfo(val.resourceId, lockedData)?.lockStatus ===
                ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED : false
            }
            locked={
              isContentLockFeature && (
                <LockToggleButton
                  isLocked={
                    lockedData
                      ? getResourceContentLockInfo
                        (val.resourceId, lockedData
                        )?.lockStatus
                      : false
                  }
                />
              )
            }
            onClick={async () => {
              dispatch(setResourceRedirectionPath(location.pathname));
              await aiResourceOpenEvent(
                val.resourceId,
                ResourceSelectionFromTypeEnum.PERFOMANCE_HOMEPAGE,
                index + 1,
                recommendationId
              );
              isContentLockFeature &&
                getResourceContentLockInfo(val.resourceId, lockedData)?.lockStatus ===
                ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                ? showLockPopup()
                : onResourceClick(
                  navigate,
                  {
                    resourceId: val.resourceId,
                  },
                  SessionModeEnum.SESSION_MODE_RESOURCE
                );
              await interactionEvent({
                url: '',
                context: 'suggested_learnings',
                name: 'SUGGESTED_LEARNINGS_OPEN',
                pathSegments: pathSegments,
              });
            }}
            key={'res_' + index}
            variant={isIpadOnly ? 'medium' : 'small'}
            image={getMediaBasePath(val.posterImageUrl, 'processedMediaBucket')}
            heading={val.title}
            iconDetails={[
              { iconName: 'clock', text: `${val.estimatedTimeInMin} Min` },
              {
                iconName: resourceTypeName(val.resourceType)?.icon,
                text: resourceTypeName(val.resourceType)?.name,
              },
            ]}
            showVideoIcon={
              val.resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
            }
            rootStyle={{
              backgroundColor: 'common.white',
            }}
          />
        ))}
      />
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
};

const ResourceShimmerArray = [
  <ShimmerActiveHwCard variant="small" key={1} />,
  <ShimmerActiveHwCard variant="small" key={2} />,
];
const getResourceContentLockInfo = (
  resourceId: string,
  contentLockData?: ResourceLock[],
) => {
  return contentLockData?.find(
    (val) => val.resourceId === resourceId
  );
};
