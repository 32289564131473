// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.login.sso.apis.proto (package geneo.ums.sso.login.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateSsoOrganizationRequest, CreateSsoOrganizationResponse, OnboardMcbStudentsRequest, OnboardMcbStudentsResponse, ssoLoginSessionRequest, ssoLoginSessionResponse, ValidateUserSsoLoginRequest, ValidateUserSsoLoginResponse, ValidateUserSsoLoginV2Request } from "./ums.login.sso.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1
 */
export const UmsSsoLoginApiServiceV1 = {
  typeName: "geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1.validateUserSsoLogin
     */
    validateUserSsoLogin: {
      name: "validateUserSsoLogin",
      I: ValidateUserSsoLoginRequest,
      O: ValidateUserSsoLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1.validateUserSsoLoginV2
     */
    validateUserSsoLoginV2: {
      name: "validateUserSsoLoginV2",
      I: ValidateUserSsoLoginV2Request,
      O: ValidateUserSsoLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1.createSsoLoginSession
     */
    createSsoLoginSession: {
      name: "createSsoLoginSession",
      I: ssoLoginSessionRequest,
      O: ssoLoginSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1.createSsoOrganization
     */
    createSsoOrganization: {
      name: "createSsoOrganization",
      I: CreateSsoOrganizationRequest,
      O: CreateSsoOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.sso.login.apis.UmsSsoLoginApiServiceV1.onboardMcbStudents
     */
    onboardMcbStudents: {
      name: "onboardMcbStudents",
      I: OnboardMcbStudentsRequest,
      O: OnboardMcbStudentsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

