import { PlainMessage, Timestamp } from '@bufbuild/protobuf';
import {
  ChapterMetaInfo,
  DownloadedAsset,
  DownloadedAsset_CacheKeyType,
  DownloadedChapter,
  DownloadedLessonPlan,
  DownloadedResource,
  DownloadedResourceCacheInfo,
  DownloadedSubject,
  DownloadedTopic,
  FileEnum,
  ResourceContent,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  StudentLoginResponseType,
  TeacherLoginResponseType,
} from '@protos/user_management/ums.login.apis_pb';
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { isGifMp4 } from '../components/elements/ImageWrapper';
import { CommonServiceClientContextData } from './CommonServiceClientProvider';
import {
  appendAndSortQueryParams,
  cacheFetch,
  extractMediaObjects,
  generateCacheKey,
  getOfflineAccessKeyFromCache,
} from './cacheFunctions';
import { categorizeLockInfo } from './contentLockUtils';
import { getMediaBasePath, shouldIncludeCredentialsInRequest } from './images';
import { checkM3u8Type, findClosestStream } from './m3u8utils';
import { toPlainMessage } from './utilFunctions';

export const CURRENT_DOWNLOAD_SUBJECT_DB_VERSION = 4;

// Create a Dexie database instance
class DownloadedSubjectDatabase extends Dexie {
  downloadedSubjects: Dexie.Table<PlainMessage<DownloadedSubject>, string>;
  downloadedSubjectsV2: Dexie.Table<PlainMessage<DownloadedSubject>, string>;
  downloadedSubjectsV3: Dexie.Table<PlainMessage<DownloadedSubject>, string>;
  downloadedSubjectsV4: Dexie.Table<PlainMessage<DownloadedSubject>, string>;

  constructor() {
    super('DownloadedSubjectDatabase');

    // Define tables
    this.version(1).stores({
      downloadedSubjects: '&subjectId',
    });

    this.version(2)
      .stores({
        downloadedSubjects: '&subjectId',
        downloadedSubjectsV2: '[userId+subjectId], userId, subjectId',
      })
      .upgrade((trans) => {
        return trans.table('downloadedSubjects').clear();
      });

    this.version(3)
      .stores({
        downloadedSubjects: '&subjectId',
        downloadedSubjectsV2: '[userId+subjectId], userId, subjectId',
        downloadedSubjectsV3:
          '[offlineAccessKey+subjectId], offlineAccessKey, userId, subjectId',
      })
      .upgrade((trans) => {
        return trans.table('downloadedSubjectsV2').clear();
      });

    // Version 4: Updated downloadedSubjectsV3 with the new index including 'version'
    this.version(4)
      .stores({
        downloadedSubjects: '&subjectId',
        downloadedSubjectsV2: '[userId+subjectId], userId, subjectId',
        downloadedSubjectsV3:
          '[offlineAccessKey+subjectId], offlineAccessKey, userId, subjectId',
        downloadedSubjectsV4:
          '[offlineAccessKey+subjectId+version],[version+offlineAccessKey],[offlineAccessKey+subjectId],offlineAccessKey, userId, subjectId, version',
      })
      .upgrade(async (trans) => {
        const table = trans.table('downloadedSubjectsV3');
        const tableNew = trans.table('downloadedSubjectsV4');
        const allEntries = await table.toArray();
        for (const entry of allEntries) {
          // Assign a default version if needed
          await tableNew.put({ ...entry, version: 3 });
        }
        await table.clear();
      });

    // Access the downloadedSubjects table
    this.downloadedSubjects = this.table('downloadedSubjects');
    this.downloadedSubjectsV2 = this.table('downloadedSubjectsV2');
    this.downloadedSubjectsV3 = this.table('downloadedSubjectsV3');
    this.downloadedSubjectsV4 = this.table('downloadedSubjectsV4');
  }
}

// Instantiate the database
const downloadsDb = new DownloadedSubjectDatabase();

// Custom hook for fetching downloaded subject requests
function useDownloadedSubjectRequests(searchParams: SearchParam) {
  const [result, setResult] = useState<
    PlainMessage<DownloadedSubject> | undefined
  >(undefined);
  const [offlineAccessKey, setOfflineAccessKey] = useState<string | null>(null);
  // Fetch the offline access key once when the component mounts
  useEffect(() => {
    const fetchAccessKey = async () => {
      try {
        const accessKey = await getOfflineAccessKeyFromCache();
        if (accessKey) {
          setOfflineAccessKey(accessKey);
        } else {
          console.error('Missing access key');
        }
      } catch (error) {
        console.error('Error fetching access key:', error);
      }
    };
    fetchAccessKey();
  }, []); // Empty dependency array to run once
  // Fetch data from Dexie when the offlineAccessKey and searchParams are available
  useEffect(() => {
    if (!offlineAccessKey) return; // Wait until access key is available
    // Listen for changes in the table
    const table = downloadsDb.downloadedSubjectsV4;
    const fetchData = async () => {
      try {
        // Combine searchParams with the offlineAccessKey
        const combinedParams = { ...searchParams, offlineAccessKey };
        // Query the database using the combined parameters
        const data = await table.where(combinedParams).toArray();
        if (data.length > 0) {
          setResult(data[0]);
        } else {
          setResult(undefined);
        }
      } catch (error) {
        console.error('Error fetching downloaded subjects:', error);
        setResult(undefined);
      }
    };
    // Fetch data initially
    fetchData();
    const updateHandler = () => {
      fetchData(); // Fetch data again if the table is updated
    };
    table.hook('creating', updateHandler);
    table.hook('updating', updateHandler);
    table.hook('deleting', updateHandler);
    // Cleanup listeners on component unmount
    return () => {
      table.hook('creating').unsubscribe(updateHandler);
      table.hook('updating').unsubscribe(updateHandler);
      table.hook('deleting').unsubscribe(updateHandler);
    };
  }, [offlineAccessKey, JSON.stringify(searchParams)]); // Dependencies: offlineAccessKey and searchParams

  return result;
}

export {
  downloadsDb,
  resourceCacheInfoDb,
  useDownloadedResourceCacheInfoRequests,
  useDownloadedSubjectRequests,
};

// Function to add a CacheRequest entry
export async function addDownloadedSubjectRequest(
  request: PlainMessage<DownloadedSubject>
) {
  try {
    const accessKey = await getOfflineAccessKeyFromCache();
    if (!accessKey) {
      throw new Error('Missing access key');
      return false;
    }
    request.offlineAccessKey = accessKey;
    await downloadsDb.transaction(
      'rw',
      downloadsDb.downloadedSubjectsV4,
      async function () {
        await downloadsDb.downloadedSubjectsV4.put(request);
      }
    );

    console.log('Data added successfully:', request);
    return true;
  } catch (error) {
    console.error('Error adding data:', error);
    return false;
  }
  return false;
}
interface SearchParam {
  subjectId?: number;
  offlineAccessKey?: string;
  version?: number;
}
export async function findDownloadedSubjectByParams(searchParams: SearchParam) {
  try {
    const accessKey = await getOfflineAccessKeyFromCache();
    if (!accessKey) {
      throw new Error('Missing access key');
      return [];
    }
    searchParams.offlineAccessKey = accessKey;
    const result = await downloadsDb.downloadedSubjectsV4
      .where(searchParams)
      .toArray();
    if (result && result.length > 0) {
      return result; // Return the first matching entry
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

// Create a Dexie database instance
class DownloadedResourceCacheInfoDatabase extends Dexie {
  downloadedResourceCacheInfo: Dexie.Table<
    PlainMessage<DownloadedResourceCacheInfo>,
    string
  >;

  constructor() {
    super('DownloadedResourceCacheInfoDatabase');

    // Define initial version
    this.version(1).stores({
      downloadedResourceCacheInfo:
        '[subjectId+chapterId+topicId+lessonId+resourceId+url+cacheKey], url, cacheKey',
    });

    // Define new version with additional index and migration logic
    this.version(2)
      .stores({
        downloadedResourceCacheInfo:
          '[subjectId+chapterId+topicId+lessonId+resourceId+url+cacheKey], ' +
          '[subjectId+chapterId+topicId+lessonId+resourceId], ' +
          'url, cacheKey',
      })
      .upgrade(async (tx) => {
        const table = tx.table('downloadedResourceCacheInfo');

        // Fetch all existing data from v1
        const allEntries = await table.toArray();

        // Reinsert the data to ensure compatibility with the new schema
        for (const entry of allEntries) {
          await table.put(entry);
        }
      });

    // Access the downloadedResourceCacheInfo table
    this.downloadedResourceCacheInfo = this.table(
      'downloadedResourceCacheInfo'
    );
  }
}

// Instantiate the database
const resourceCacheInfoDb = new DownloadedResourceCacheInfoDatabase();

// Example of using dexie-react-hooks to query the downloadedResourceCacheInfo table
function useDownloadedResourceCacheInfoRequests() {
  return useLiveQuery(() => {
    return resourceCacheInfoDb.downloadedResourceCacheInfo.toArray();
  }, []);
}

// Function to add a CacheRequest entry
export async function addDownloadedResourceCacheInfoRequest(
  request: PlainMessage<DownloadedResourceCacheInfo>
) {
  try {
    await resourceCacheInfoDb.transaction(
      'rw',
      resourceCacheInfoDb.downloadedResourceCacheInfo,
      async function () {
        await resourceCacheInfoDb.downloadedResourceCacheInfo.put(request);
      }
    );

    console.log('Data added successfully:', request);
    return true;
  } catch (error) {
    console.error('Error adding data:', error);
    return false;
  }
  return false;
}

export async function findDownloadedResourceCacheInfoByParams(
  searchParams: Partial<PlainMessage<DownloadedResourceCacheInfo>>
) {
  try {
    const result = await resourceCacheInfoDb.downloadedResourceCacheInfo
      .where(searchParams)
      .toArray();
    if (result && result.length > 0) {
      return result; // Return the first matching entry
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export async function deleteDownloadedResourceContentFromIndexDB(
  searchParams: Partial<PlainMessage<DownloadedResourceCacheInfo>>
) {
  try {
    await resourceCacheInfoDb.transaction(
      'rw',
      resourceCacheInfoDb.downloadedResourceCacheInfo,
      async () => {
        // Delete entries based on the provided IDs
        await resourceCacheInfoDb.downloadedResourceCacheInfo
          .where(searchParams)
          .delete();
      }
    );

    console.log(
      'IndexDB: Entries with params successfully deleted.',
      searchParams
    );
  } catch (error) {
    console.error('IndexDB: Error deleting entries:', error);
    throw new Error('IndexDB: Error deleting entries: ' + error);
  }
}

export interface CurrentDownload {
  subjectId?: number;
  chapterId?: number;
  topicId?: number;
  lessonPlanId?: string;
  resourceId?: string;
}

export interface DownloadParams {
  subjectId: number;
  chapterId?: number;
  topicId?: number;
  lessonPlanId?: string;
  resourceId?: string;
  classId: number;
  sectionId: number;
  downloadedMetaData?: Record<number, ChapterMetaInfo | undefined>;
  lockedData?: ContentLockModuleData;
}

interface CommonRequestParams {
  user_info: TeacherLoginResponseType | StudentLoginResponseType;
  sectionId: number;
  classId?: number;
  subjectId: number;
  downloadedSubject?: PlainMessage<DownloadedSubject> | undefined;
  downloadedMetaData?: Record<number, ChapterMetaInfo | undefined>;
  setCurrentDownload: (val: CurrentDownload) => void;
  downloadResolution?: number;
  personType: ProfileRolesEnum;
  commonServiceClientContext: CommonServiceClientContextData;
  lockedData?: ContentLockModuleData;
}

interface ChapterContent extends CommonRequestParams {
  chapterId?: number;
  fetchChildrenData?: boolean;
}

const getSelectedSubject = (
  user_info: TeacherLoginResponseType | StudentLoginResponseType,
  subjectId: number,
  classId?: number,
  sectionId?: number
) => {
  if (user_info instanceof TeacherLoginResponseType) {
    const teachClassSubjects = user_info?.teachClassSubjects.find(
      (classData) =>
        classId === classData.classId && sectionId === classData.sectionId
    );
    const selectedSubject = teachClassSubjects
      ? teachClassSubjects.subjects.find(
          (subjectData) => subjectData.subjectId === Number(subjectId)
        )
      : undefined;
    return selectedSubject;
  } else if (user_info instanceof StudentLoginResponseType) {
    const subject = user_info.learnSubjects.find(
      (sub) => sub.subjectId == subjectId
    );
    return subject;
  }
};

export async function fetchChapterContentOffline(params: ChapterContent) {
  const {
    user_info,
    subjectId,
    classId,
    sectionId,
    chapterId,
    fetchChildrenData,
    downloadedMetaData,
    downloadResolution,
    personType,
    commonServiceClientContext,
    setCurrentDownload,
    lockedData,
  } = params;
  const lockInfo = categorizeLockInfo(lockedData);
  let { downloadedSubject } = params;
  if (!downloadedSubject) {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: Number(subjectId),
    });
    downloadedSubject = indexDbData.length > 0 ? indexDbData[0] : undefined;
  }
  setCurrentDownload({ subjectId });
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    commonServiceClientContext;
  const selectedSubject = getSelectedSubject(
    user_info,
    subjectId,
    classId,
    sectionId
  );
  let dataToStore = new DownloadedSubject(downloadedSubject);
  if (
    lockInfo.book[subjectId]?.lockStatus ===
    ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
  ) {
    // Skip Locked
    setCurrentDownload({});
    return dataToStore;
  }
  if (!selectedSubject) {
    console.log('Unable to find selected subject');
    return dataToStore;
  }
  console.log('Downloading Subject with subject id: ', subjectId);
  const subjectImage = appendAndSortQueryParams(
    getMediaBasePath(selectedSubject.iconUrl, 'schoolnetStaticAssetBucket'),
    { subject_id: selectedSubject?.subjectId }
  );
  dataToStore.subjectId = selectedSubject.subjectId;
  dataToStore.version = CURRENT_DOWNLOAD_SUBJECT_DB_VERSION;
  dataToStore.name = selectedSubject.subjectName;
  dataToStore.bookImageUrl = subjectImage;
  dataToStore.userId =
    user_info instanceof TeacherLoginResponseType
      ? user_info.teacherProfileId.toString()
      : user_info instanceof StudentLoginResponseType
      ? user_info.studentProfileId.toString()
      : undefined;

  const resSubImage = await cacheFetch(subjectImage);
  if (resSubImage && resSubImage.ok) {
    const blobRes = await resSubImage.blob();
    const size = blobRes.size;
    if (!dataToStore.assets) {
      dataToStore.assets = {};
    }
    const newSubImage = new DownloadedAsset({
      url: subjectImage,
      cacheKey: (await generateCacheKey(new Request(subjectImage))).url,
      type: DownloadedAsset_CacheKeyType.IMAGE,
      size: BigInt(size),
    });
    if (dataToStore.assets[subjectImage]) {
      await deleteCacheEntryFromCache(
        dataToStore.assets[subjectImage].cacheKey
      );
    }
    dataToStore.assets[subjectImage] = newSubImage;
  }
  dataToStore.isDownloaded = true;
  const plainMsgData = toPlainMessage(dataToStore);
  await addDownloadedSubjectRequest(plainMsgData);
  downloadedSubject = plainMsgData;
  console.log('Downloaded Subject base data with subject id: ', subjectId);

  if (!dataToStore.chapters) {
    dataToStore.chapters = {};
  }

  const response =
    await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchSubjectChapterInfo(
      {
        personId:
          user_info instanceof TeacherLoginResponseType
            ? user_info.teacherProfileId
            : user_info instanceof StudentLoginResponseType
            ? user_info.studentProfileId
            : undefined,
        personType: personType,
        subjectId: subjectId,
        bookId: selectedSubject?.bookId,
        sectionId: sectionId,
      }
    );
  const chapters = response.data?.response || [];
  const chapterIdsToFetch: number[] = chapterId
    ? [Number(chapterId)]
    : chapters
        .sort((a, b) => a.chapterNo - b.chapterNo)
        .map((val) => val.chapterId);

  for (let k = 0; k < chapterIdsToFetch.length; k++) {
    const chapter_id = chapterIdsToFetch[k];
    if (
      lockInfo.chapter[chapter_id]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      // Skip Locked
      continue;
    }
    const downloadedMetaInfo = downloadedMetaData
      ? downloadedMetaData[chapter_id]
      : undefined;
    setCurrentDownload({ subjectId, chapterId: chapter_id });
    const backendTime = downloadedMetaInfo?.updateTimestamp;
    const lastUpdatedAt = dataToStore.chapters[chapter_id]?.lastUpdatedAt;
    const progress = calculateDownloadProgress(
      user_info,
      dataToStore,
      downloadedMetaInfo,
      chapter_id,
      undefined,
      undefined,
      sectionId
    );
    const shouldSkip =
      progress === 100 &&
      lastUpdatedAt &&
      backendTime &&
      new Timestamp(lastUpdatedAt).toDate().getTime() >
        new Timestamp(backendTime).toDate().getTime();
    if (shouldSkip) {
      console.log(
        `Chapter with chapter id ${chapter_id} is already up to date. No need to download`
      );
      continue;
    }
    console.log('Downloading Chapter with chapter id: ', chapter_id);
    if (!dataToStore.chapters[chapter_id]) {
      dataToStore.chapters[chapter_id] = new DownloadedChapter();
    }

    const foundChapter = chapters.find((chap) => chap.chapterId == chapter_id);
    if (!foundChapter) {
      console.log(`Chapter with id ${chapter_id} not found in hierarchy`);
      continue;
    }
    const chapImage = appendAndSortQueryParams(
      getMediaBasePath(foundChapter.posterImagesUrl, 'processedMediaBucket'),
      {
        subject_id: selectedSubject?.subjectId,
        chapterId: foundChapter?.chapterId,
      }
    );
    dataToStore.chapters[foundChapter.chapterId] = new DownloadedChapter({
      chapterId: foundChapter.chapterId,
      chapterTitle: foundChapter.chapterTitle,
      posterImagesUrl: chapImage,
      order: foundChapter.chapterNo,
      lastUpdatedAt: Timestamp.fromDate(new Date()),
      downloadedTopics: {
        ...(dataToStore.chapters[foundChapter.chapterId].downloadedTopics ||
          {}),
      },
    });
    const resChapImage = await cacheFetch(chapImage);
    if (resChapImage && resChapImage.ok) {
      const blobRes = await resChapImage.blob();
      const size = blobRes.size;
      if (!dataToStore.chapters[foundChapter.chapterId].assets) {
        dataToStore.chapters[foundChapter.chapterId].assets = {};
      }
      dataToStore.chapters[foundChapter.chapterId].assets[chapImage] =
        new DownloadedAsset({
          url: chapImage,
          cacheKey: (await generateCacheKey(new Request(chapImage))).url,
          type: DownloadedAsset_CacheKeyType.IMAGE,
          size: BigInt(size),
        });
    }
    if (fetchChildrenData) {
      dataToStore = await fetchTopicContentOffline({
        user_info,
        subjectId,
        downloadedSubject: dataToStore,
        classId,
        sectionId,
        fetchChildrenData: true,
        chapterId: chapter_id,
        downloadedMetaData,
        downloadResolution,
        personType,
        commonServiceClientContext,
        setCurrentDownload,
        lockedData,
      });
    }
    dataToStore.chapters[foundChapter.chapterId].isDownloaded = true;
    const plainMsg = toPlainMessage(dataToStore);
    await addDownloadedSubjectRequest(plainMsg);
    console.log('Downloaded Chapter with chapter id: ', chapter_id);
  }
  const subData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  const subjData = subData[0];
  await addDownloadedSubjectRequest(subjData);
  return dataToStore;
}

interface TopicContent extends CommonRequestParams {
  chapterId: number;
  topicId?: number;
  fetchChildrenData?: boolean;
}

export async function fetchTopicContentOffline(params: TopicContent) {
  const {
    user_info,
    subjectId,
    classId,
    sectionId,
    chapterId,
    topicId,
    fetchChildrenData,
    downloadedMetaData,
    downloadResolution,
    personType,
    commonServiceClientContext,
    setCurrentDownload,
    lockedData,
  } = params;
  const lockInfo = categorizeLockInfo(lockedData);
  let { downloadedSubject } = params;
  if (!downloadedSubject) {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: Number(subjectId),
    });
    downloadedSubject = indexDbData.length > 0 ? indexDbData[0] : undefined;
  }
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    commonServiceClientContext;
  const selectedSubject = getSelectedSubject(
    user_info,
    subjectId,
    classId,
    sectionId
  );
  let dataToStore = new DownloadedSubject(downloadedSubject);
  if (!selectedSubject) {
    console.log('Unable to find selected subject');
    return dataToStore;
  }
  if (topicId) {
    setCurrentDownload({ subjectId, chapterId, topicId });
  }
  if (!downloadedSubject?.chapters[Number(chapterId)]) {
    // if chapter data is not present in index db download it
    dataToStore = await fetchChapterContentOffline({
      user_info,
      subjectId,
      downloadedSubject,
      classId,
      sectionId,
      chapterId,
      fetchChildrenData: false,
      downloadedMetaData,
      downloadResolution,
      personType,
      commonServiceClientContext,
      setCurrentDownload,
      lockedData,
    });
  }
  if (!dataToStore?.chapters[Number(chapterId)]) {
    // if chapter data is not present in index db even after downloading it once
    console.log('Unable to download chapter with chapter id ' + chapterId);
    return dataToStore;
  }
  if (!dataToStore.chapters[Number(chapterId)].downloadedTopics) {
    dataToStore.chapters[Number(chapterId)].downloadedTopics = {};
  }
  const chapTopicData =
    await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchChapterTopicInfo(
      {
        personId:
          user_info instanceof TeacherLoginResponseType
            ? user_info.teacherProfileId
            : user_info instanceof StudentLoginResponseType
            ? user_info.studentProfileId
            : undefined,
        personType: personType,
        subjectId: subjectId,
        chapterId: chapterId,
        sectionId: sectionId,
      }
    );
  const topics = chapTopicData.data?.chapterTopics || [];
  const topicIdsToFetch = topicId
    ? [topicId]
    : topics.sort((a, b) => a.topicNo - b.topicNo).map((val) => val.topicId);
  for (let k = 0; k < topicIdsToFetch.length; k++) {
    const topic_id = topicIdsToFetch[k];
    if (
      lockInfo.topic[topic_id]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      // Skip Locked
      continue;
    }
    setCurrentDownload({ subjectId, chapterId, topicId: topic_id });
    const downloadedMetaInfo = downloadedMetaData
      ? downloadedMetaData[chapterId]
      : undefined;
    const currentTopicMetaInfo = downloadedMetaInfo?.topics.find(
      (val) => val.id == topic_id
    );
    const backendTime = currentTopicMetaInfo?.updateTimestamp;
    const lastUpdatedAt =
      dataToStore?.chapters[chapterId]?.downloadedTopics[topic_id]
        ?.lastUpdatedAt;
    const progress = calculateDownloadProgress(
      user_info,
      dataToStore,
      downloadedMetaInfo,
      chapterId,
      topic_id,
      undefined,
      sectionId
    );
    const shouldSkip =
      progress === 100 &&
      lastUpdatedAt &&
      backendTime &&
      new Timestamp(lastUpdatedAt).toDate().getTime() >
        new Timestamp(backendTime).toDate().getTime();
    if (shouldSkip) {
      console.log(
        `Topic with topic id ${topic_id} is already up to date. No need to download`
      );
      continue;
    }
    console.log('Downloading topic with topicId: ', topic_id);
    const foundTopic = topics?.find((top) => top.topicId == Number(topic_id));
    if (!foundTopic) {
      console.log('Topic with id-' + topic_id + ' not found');
      return dataToStore;
    }
    const topicImage = appendAndSortQueryParams(
      getMediaBasePath(foundTopic.posterImagesUrl, 'processedMediaBucket'),
      {
        subject_id: selectedSubject?.subjectId,
        chapter_id: chapterId,
        topic_id: foundTopic.topicId,
      }
    );
    dataToStore.chapters[chapterId].downloadedTopics[foundTopic.topicId] =
      new DownloadedTopic({
        topicId: foundTopic.topicId,
        topicName: foundTopic.topicTitle,
        order: foundTopic.topicNo,
        lessonPlanCount: foundTopic.topicContentStats?.lessonCount,
        questionCount: foundTopic.topicContentStats?.questionCount,
        lastUpdatedAt: Timestamp.fromDate(new Date()),
        posterImageUrl: topicImage,
        downloadedLessonPlans: {
          ...(dataToStore.chapters[chapterId].downloadedTopics[
            foundTopic.topicId
          ]?.downloadedLessonPlans || {}),
        },
      });
    const resTopicImage = await cacheFetch(topicImage);
    if (topicImage && resTopicImage.ok) {
      const blobRes = await resTopicImage.blob();
      const size = blobRes.size;
      if (
        !dataToStore.chapters[chapterId].downloadedTopics[foundTopic.topicId]
          .assets
      ) {
        dataToStore.chapters[chapterId].downloadedTopics[
          foundTopic.topicId
        ].assets = {};
      }
      dataToStore.chapters[chapterId].downloadedTopics[
        foundTopic.topicId
      ].assets[topicImage] = new DownloadedAsset({
        url: topicImage,
        cacheKey: (await generateCacheKey(new Request(topicImage))).url,
        type: DownloadedAsset_CacheKeyType.IMAGE,
        size: BigInt(size),
      });
    }
    if (fetchChildrenData) {
      dataToStore = await fetchLessonContentOffline({
        user_info,
        subjectId,
        downloadedSubject: dataToStore,
        classId,
        sectionId,
        chapterId: chapterId,
        topicId: foundTopic.topicId,
        downloadedMetaData,
        downloadResolution,
        personType,
        commonServiceClientContext,
        setCurrentDownload,
        lockedData,
      });
    }
    dataToStore.chapters[chapterId].downloadedTopics[
      foundTopic.topicId
    ].isDownloaded = true;
    const plainMsg = toPlainMessage(dataToStore);
    await addDownloadedSubjectRequest(plainMsg);
    console.log('Downloaded topic with topic id: ', foundTopic.topicId);
  }
  const subData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  const subjData = subData[0];
  await addDownloadedSubjectRequest(subjData);
  return dataToStore;
}

interface LessonContent extends CommonRequestParams {
  chapterId: number;
  topicId: number;
  lessonId?: string;
}

export async function fetchLessonContentOffline(params: LessonContent) {
  const {
    user_info,
    subjectId,
    classId,
    sectionId,
    chapterId,
    topicId,
    lessonId,
    downloadedMetaData,
    downloadResolution,
    personType,
    commonServiceClientContext,
    setCurrentDownload,
    lockedData,
  } = params;
  const lockInfo = categorizeLockInfo(lockedData);
  let { downloadedSubject } = params;
  if (!downloadedSubject) {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: Number(subjectId),
    });
    downloadedSubject = indexDbData.length > 0 ? indexDbData[0] : undefined;
  }
  const selectedSubject = getSelectedSubject(
    user_info,
    subjectId,
    classId,
    sectionId
  );
  let dataToStore = new DownloadedSubject(downloadedSubject);
  if (!selectedSubject) {
    console.log('Unable to find selected subject');
    return dataToStore;
  }
  if (lessonId) {
    setCurrentDownload({
      subjectId,
      chapterId,
      topicId,
      lessonPlanId: lessonId,
    });
  }
  if (!dataToStore?.chapters[chapterId]?.downloadedTopics[topicId]) {
    // if topic data is not present in index db download it
    dataToStore = await fetchTopicContentOffline({
      user_info,
      subjectId,
      downloadedSubject,
      classId,
      sectionId,
      chapterId,
      topicId,
      fetchChildrenData: false,
      downloadedMetaData,
      downloadResolution,
      personType,
      commonServiceClientContext,
      setCurrentDownload,
      lockedData,
    });
  }
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  if (!dataToStore?.chapters[chapterId]?.downloadedTopics[topicId]) {
    // if topic data is not present in index db even after downloading it once
    console.log('Unable to download topic with topicId id ' + topicId);
    return dataToStore;
  }

  if (
    !dataToStore.chapters[chapterId].downloadedTopics[topicId]
      .downloadedLessonPlans
  ) {
    dataToStore.chapters[chapterId].downloadedTopics[
      topicId
    ].downloadedLessonPlans = {};
  }

  console.log('Fetching all lessons for topic id ' + topicId);
  const response =
    await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonsByModule(
      {
        personId:
          user_info instanceof TeacherLoginResponseType
            ? user_info.teacherProfileId
            : user_info instanceof StudentLoginResponseType
            ? user_info.studentProfileId
            : undefined,
        personType: personType,
        moduleId: topicId,
        sectionId: sectionId,
      }
    );

  const lessons = response.data?.lessons || [];

  const lessonsIdsToDownload = lessonId
    ? [lessonId]
    : lessons
        .sort((a, b) =>
          a.lastSessionTime && b.lastSessionTime
            ? Number(b.lastSessionTime?.seconds) -
              Number(a.lastSessionTime?.seconds)
            : Number(b.modifiedOn?.seconds) - Number(a.modifiedOn?.seconds)
        )
        .map((val) => val.lessonId);

  for (let k = 0; k < lessonsIdsToDownload.length; k++) {
    const lesson_id = lessonsIdsToDownload[k];
    const lessonLockInfo = lockInfo.lesson[lesson_id];
    if (
      lessonLockInfo?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      // Skip Locked
      continue;
    }
    const downloadedMetaInfo = downloadedMetaData
      ? downloadedMetaData[chapterId]
      : undefined;
    setCurrentDownload({
      subjectId,
      chapterId,
      topicId,
      lessonPlanId: lesson_id,
    });
    const currentTopicMetaInfo = downloadedMetaInfo?.topics.find(
      (val) => val.id == topicId
    );
    const currentLessonMetaInfo = currentTopicMetaInfo?.lessonPlans.find(
      (val) => val.id == lesson_id
    );
    const backendTime = currentLessonMetaInfo?.updateTimestamp;
    const lastUpdatedAt =
      dataToStore?.chapters[chapterId]?.downloadedTopics[topicId]
        ?.downloadedLessonPlans[lesson_id]?.lastUpdatedAt;
    const progress = calculateDownloadProgress(
      user_info,
      dataToStore,
      downloadedMetaInfo,
      chapterId,
      topicId,
      lesson_id,
      sectionId
    );
    const shouldSkip =
      progress === 100 &&
      lastUpdatedAt &&
      backendTime &&
      new Timestamp(lastUpdatedAt).toDate().getTime() >
        new Timestamp(backendTime).toDate().getTime();
    if (shouldSkip) {
      // current section is not present in downloaded ones
      if (
        !dataToStore.chapters[chapterId].downloadedTopics[topicId]
          .downloadedLessonPlans[lesson_id].schoolClassSectionId
      ) {
        dataToStore.chapters[chapterId].downloadedTopics[
          topicId
        ].downloadedLessonPlans[lesson_id].schoolClassSectionId = [];
      }
      if (
        !dataToStore.chapters[chapterId].downloadedTopics[
          topicId
        ].downloadedLessonPlans[lesson_id].schoolClassSectionId.includes(
          sectionId
        )
      ) {
        dataToStore.chapters[chapterId].downloadedTopics[
          topicId
        ].downloadedLessonPlans[lesson_id].schoolClassSectionId = [
          ...new Set([
            sectionId,
            ...(dataToStore.chapters[chapterId].downloadedTopics[topicId]
              .downloadedLessonPlans[lesson_id]?.schoolClassSectionId || []),
          ]),
        ];
        const plainMsg = toPlainMessage(dataToStore);
        await addDownloadedSubjectRequest(plainMsg);
        console.log(`lesson plan with id ${lesson_id} - section id updated`);
        continue;
      }
      console.log(
        `lesson plan with id ${lesson_id} is already up to date. No need to download`
      );
      continue;
    }

    const lessonIndex = lessons.findIndex((val) => val.lessonId == lesson_id);
    const lesson = lessons[lessonIndex];
    if (!lesson) {
      console.log('Unable to find lesson with id:' + lesson_id);
      continue;
    }
    if (
      !dataToStore.chapters[chapterId].downloadedTopics[topicId]
        .downloadedLessonPlans[lesson.lessonId]
    ) {
      dataToStore.chapters[chapterId].downloadedTopics[
        topicId
      ].downloadedLessonPlans[lesson.lessonId] = new DownloadedLessonPlan();
    }
    console.log('Downloading lesson with id:' + lesson_id);
    const lessonResponse =
      await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonContent(
        {
          personId:
            user_info instanceof TeacherLoginResponseType
              ? user_info.teacherProfileId
              : user_info instanceof StudentLoginResponseType
              ? user_info.studentProfileId
              : undefined,
          personType: personType,
          lessonId: lesson.lessonId,
        }
      );

    const downloadResources: { [key: string]: DownloadedResource } = {
      ...dataToStore.chapters[chapterId]?.downloadedTopics[topicId]
        ?.downloadedLessonPlans[lesson.lessonId]?.downloadedResources,
    };

    const lessonResources =
      (lessonResponse.data?.resources || []).sort((a, b) => a.rank - b.rank) ||
      [];

    const resourcesDownloaded = Object.keys(downloadResources);
    const resourcesToDownload = lessonResources.map((val) => val.resourceId);

    const resourcesToDelete = resourcesDownloaded.filter(
      (val) => !resourcesToDownload.includes(val)
    );

    for (let i = 0; i < lessonResources.length; i++) {
      const resource = lessonResources[i];
      if (lessonLockInfo?.lockedResourceIds.includes(resource.resourceId)) {
        // Skip Locked
        continue;
      }
      setCurrentDownload({
        subjectId,
        chapterId,
        topicId,
        lessonPlanId: lesson_id,
        resourceId: resource.resourceId,
      });
      console.log('Downloading resource with id:' + resource.resourceId);
      // Download gcp json for resource
      const resourceContent =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceContent(
          {
            personId:
              user_info instanceof TeacherLoginResponseType
                ? user_info.teacherProfileId
                : user_info instanceof StudentLoginResponseType
                ? user_info.studentProfileId
                : undefined,
            personType: personType,
            resourceId: resource.resourceId,
          }
        );
      const gcpJsonURL = resourceContent.data?.gcpJsonUrl;
      if (!gcpJsonURL) {
        console.log('Invalid Resource Data');
        continue;
      }
      const modifiedUrl = getMediaBasePath(gcpJsonURL, 'resourceContentBucket');
      console.log(
        'Downloading gcp data for resource with id:' + resource.resourceId
      );
      const reqOptions: RequestInit = {};
      if (shouldIncludeCredentialsInRequest(modifiedUrl)) {
        reqOptions.credentials = 'include';
      }
      const gcpResponse = await (await fetch(modifiedUrl, reqOptions)).json();
      const parsedM3U8GcpResponse = parseGCPResponseM3U8(gcpResponse);
      const resContent = new ResourceContent().fromJson(parsedM3U8GcpResponse, {
        ignoreUnknownFields: true,
      });
      const resourceImage = appendAndSortQueryParams(
        getMediaBasePath(resource.posterImageUrl, 'processedMediaBucket'),
        {
          subject_id: selectedSubject?.subjectId,
          chapter_id: chapterId,
          topic_id: topicId,
          lesson_id: lesson.lessonId,
          resource_id: resource.resourceId,
        }
      );
      downloadResources[resource.resourceId] = new DownloadedResource({
        resourceId: resource.resourceId,
        title: resource.title,
        posterImageUrl: resourceImage,
        estimatedTimeInMin: resource.estimatedTimeInMin,
        resourceType: resource.resourceType,
        resourceCategoryType: resource.resourceCategoryType,
        order: i,
        assets: {},
        resourceContent: resContent,
      });
      const resResourceImage = await cacheFetch(resourceImage);
      console.log(
        'Downloading resource image for resource with id:' + resource.resourceId
      );
      if (resResourceImage && resResourceImage.ok) {
        const blobRes = await resResourceImage.blob();
        const size = blobRes.size;
        downloadResources[resource.resourceId].assets[resourceImage] =
          new DownloadedAsset({
            url: resourceImage,
            cacheKey: (await generateCacheKey(new Request(resourceImage))).url,
            type: DownloadedAsset_CacheKeyType.IMAGE,
            size: BigInt(size),
          });
      }
      const mediaObjs = extractMediaObjects(resContent);
      const contentVideos = mediaObjs.contentVideos;
      // pending
      const resourceImages = mediaObjs.images;
      let resourceVideos = mediaObjs.videos;
      const resourceAudios = mediaObjs.audios;
      const externalResources = mediaObjs.externalResources;
      // pending

      for (let i = 0; i < resourceImages.length; i++) {
        const imgUrl = resourceImages[i].imageUrl;
        console.log('Downloading image:' + imgUrl);
        if (imgUrl) {
          if (isGifMp4(imgUrl)) {
            const gifImage = imgUrl.replace('mp4', 'jpg');
            const mediaImgUrl = getMediaBasePath(
              gifImage,
              'processedMediaBucket'
            );
            downloadResources[resource.resourceId].assets[mediaImgUrl] =
              await getDownloadedAssetAndStoreInIndexedDb({
                subjectId,
                chapterId,
                topicId,
                lessonId: lesson.lessonId,
                resourceId: resource.resourceId,
                url: mediaImgUrl,
                cacheKey: (
                  await generateCacheKey(new Request(mediaImgUrl))
                ).url,
                type: DownloadedAsset_CacheKeyType.IMAGE,
              });

            const vidImgUrl = getMediaBasePath(imgUrl, 'processedMediaBucket');
            downloadResources[resource.resourceId].assets[vidImgUrl] =
              await getDownloadedAssetAndStoreInIndexedDb({
                subjectId,
                chapterId,
                topicId,
                lessonId: lesson.lessonId,
                resourceId: resource.resourceId,
                url: vidImgUrl,
                cacheKey: (await generateCacheKey(new Request(vidImgUrl))).url,
                type: DownloadedAsset_CacheKeyType.VIDEO_MP4,
              });
          } else {
            const mediaImgUrl = getMediaBasePath(
              imgUrl,
              'processedMediaBucket'
            );
            downloadResources[resource.resourceId].assets[mediaImgUrl] =
              await getDownloadedAssetAndStoreInIndexedDb({
                subjectId,
                chapterId,
                topicId,
                lessonId: lesson.lessonId,
                resourceId: resource.resourceId,
                url: mediaImgUrl,
                cacheKey: (
                  await generateCacheKey(new Request(mediaImgUrl))
                ).url,
                type: DownloadedAsset_CacheKeyType.IMAGE,
              });
          }
        }
      }

      resourceVideos = [...new Set(resourceVideos)];
      for (let i = 0; i < resourceVideos.length; i++) {
        const imgUrl = resourceVideos[i].thumbnailImageUrl;
        console.log('Downloading image:' + imgUrl);
        if (imgUrl) {
          const mediaImgUrl = getMediaBasePath(imgUrl, 'processedMediaBucket');
          downloadResources[resource.resourceId].assets[mediaImgUrl] =
            await getDownloadedAssetAndStoreInIndexedDb({
              subjectId,
              chapterId,
              topicId,
              lessonId: lesson.lessonId,
              resourceId: resource.resourceId,
              url: mediaImgUrl,
              cacheKey: (await generateCacheKey(new Request(mediaImgUrl))).url,
              type: DownloadedAsset_CacheKeyType.IMAGE,
            });
        }

        const vidUrl = resourceVideos[i].videoUrl;
        if (vidUrl && vidUrl.toLowerCase().includes('.m3u8')) {
          console.log('Downloading video:' + vidUrl);
          const mediaVidUrl = getMediaBasePath(vidUrl, 'processedMediaBucket');
          downloadResources[resource.resourceId].assets[mediaVidUrl] =
            await getDownloadedAssetAndStoreInIndexedDb({
              subjectId,
              chapterId,
              topicId,
              lessonId: lesson.lessonId,
              resourceId: resource.resourceId,
              url: mediaVidUrl,
              cacheKey: (await generateCacheKey(new Request(mediaVidUrl))).url,
              type: DownloadedAsset_CacheKeyType.VIDEO_M3U8,
              resolution: downloadResolution || 720,
            });
          const reqOptions: RequestInit = {};
          if (shouldIncludeCredentialsInRequest(mediaVidUrl)) {
            reqOptions.credentials = 'include';
          }
          const resAssetVideo = await fetch(mediaVidUrl, reqOptions);
          if (resAssetVideo && resAssetVideo.ok) {
            const textM3U8 = await resAssetVideo.text();
            const m3u8Type = checkM3u8Type(textM3U8);
            console.log('m3u8Type', m3u8Type);
            if (m3u8Type == 'Direct') {
              const segmentUrls = textM3U8.match(/^.+\.ts$/gm);
              if (segmentUrls) {
                for (let i = 0; i < segmentUrls.length; i++) {
                  const regex = /\/[^/]+\.m3u8(\?.*)?$/;
                  const segment = mediaVidUrl.replace(
                    regex,
                    `/${segmentUrls[i]}`
                  );
                  downloadResources[resource.resourceId].assets[segment] =
                    await getDownloadedAssetAndStoreInIndexedDb({
                      subjectId,
                      chapterId,
                      topicId,
                      lessonId: lesson.lessonId,
                      resourceId: resource.resourceId,
                      url: segment,
                      cacheKey: (
                        await generateCacheKey(new Request(segment))
                      ).url,
                      type: DownloadedAsset_CacheKeyType.VIDEO_M3U8_SEGMENT,
                    });
                }
              }
            } else if (m3u8Type == 'Multiple') {
              const closestStream = findClosestStream(
                textM3U8,
                downloadResolution || 720
              );
              const regex = /\/[^/]+\.m3u8(\?.*)?$/;
              const audioFile = mediaVidUrl.replace(
                regex,
                `/${closestStream.audioFile}`
              );
              const videoFile = mediaVidUrl.replace(
                regex,
                `/${closestStream.videoFile}`
              );
              console.log(
                `audio, video fetched for ${downloadResolution || 720}p:`,
                { audioFile, videoFile }
              );
              // Store video file
              downloadResources[resource.resourceId].assets[videoFile] =
                await getDownloadedAssetAndStoreInIndexedDb({
                  subjectId,
                  chapterId,
                  topicId,
                  lessonId: lesson.lessonId,
                  resourceId: resource.resourceId,
                  url: videoFile,
                  cacheKey: (
                    await generateCacheKey(new Request(videoFile))
                  ).url,
                  type: DownloadedAsset_CacheKeyType.VIDEO_M3U8,
                });
              const reqOptions: RequestInit = {};
              if (shouldIncludeCredentialsInRequest(videoFile)) {
                reqOptions.credentials = 'include';
              }
              const videoFetched = await fetch(videoFile, reqOptions);
              if (videoFetched && videoFetched.ok) {
                const textM3U8 = await videoFetched.text();
                const segments = textM3U8.match(/^.+\.ts$/gm);
                const segmentUrls = [...new Set(segments)];
                if (segmentUrls) {
                  for (let i = 0; i < segmentUrls.length; i++) {
                    const regex = /\/[^/]+\.m3u8(\?.*)?$/;
                    const segment = mediaVidUrl.replace(
                      regex,
                      `/${segmentUrls[i]}`
                    );
                    downloadResources[resource.resourceId].assets[segment] =
                      await getDownloadedAssetAndStoreInIndexedDb({
                        subjectId,
                        chapterId,
                        topicId,
                        lessonId: lesson.lessonId,
                        resourceId: resource.resourceId,
                        url: segment,
                        cacheKey: (
                          await generateCacheKey(new Request(segment))
                        ).url,
                        type: DownloadedAsset_CacheKeyType.VIDEO_M3U8_SEGMENT,
                      });
                  }
                }
              }
              // Store audio file
              downloadResources[resource.resourceId].assets[audioFile] =
                await getDownloadedAssetAndStoreInIndexedDb({
                  subjectId,
                  chapterId,
                  topicId,
                  lessonId: lesson.lessonId,
                  resourceId: resource.resourceId,
                  url: audioFile,
                  cacheKey: (
                    await generateCacheKey(new Request(audioFile))
                  ).url,
                  type: DownloadedAsset_CacheKeyType.AUDIO_M3U8,
                });
              const reqOptionsAudio: RequestInit = {};
              if (shouldIncludeCredentialsInRequest(audioFile)) {
                reqOptions.credentials = 'include';
              }
              const audioFetched = await fetch(audioFile, reqOptionsAudio);
              if (audioFetched && audioFetched.ok) {
                const textM3U8 = await audioFetched.text();
                const segments = textM3U8.match(/^.+\.ts$/gm);
                const segmentUrls = [...new Set(segments)];

                if (segmentUrls) {
                  for (let i = 0; i < segmentUrls.length; i++) {
                    const regex = /\/[^/]+\.m3u8(\?.*)?$/;
                    const segment = mediaVidUrl.replace(
                      regex,
                      `/${segmentUrls[i]}`
                    );
                    downloadResources[resource.resourceId].assets[segment] =
                      await getDownloadedAssetAndStoreInIndexedDb({
                        subjectId,
                        chapterId,
                        topicId,
                        lessonId: lesson.lessonId,
                        resourceId: resource.resourceId,
                        url: segment,
                        cacheKey: (
                          await generateCacheKey(new Request(segment))
                        ).url,
                        type: DownloadedAsset_CacheKeyType.AUDIO_M3U8_SEGMENT,
                      });
                  }
                }
              }
            }
          }
        }
      }

      for (let i = 0; i < resourceAudios.length; i++) {
        const resourceAudio = resourceAudios[i];
        console.log(
          'Downloading audio for resource :' + resourceAudio.audioUrl
        );
        const mediaAudioUrl = getMediaBasePath(
          resourceAudio.audioUrl,
          'processedMediaBucket'
        );
        downloadResources[resource.resourceId].assets[mediaAudioUrl] =
          await getDownloadedAssetAndStoreInIndexedDb({
            subjectId,
            chapterId,
            topicId,
            lessonId: lesson.lessonId,
            resourceId: resource.resourceId,
            url: mediaAudioUrl,
            cacheKey: (await generateCacheKey(new Request(mediaAudioUrl))).url,
            type: DownloadedAsset_CacheKeyType.AUDIO_MP3,
          });
      }

      for (let i = 0; i < externalResources.length; i++) {
        const externalResource = externalResources[i];
        if (
          [
            FileEnum.FILE_TYPE_TEXT,
            FileEnum.FILE_TYPE_IMAGE,
            FileEnum.FILE_TYPE_AUDIO,
            FileEnum.FILE_TYPE_VIDEO,
            FileEnum.FILE_TYPE_DOCUMENT,
            FileEnum.FILE_TYPE_SPREADSHEET,
            FileEnum.FILE_TYPE_PRESENTATION,
            FileEnum.FILE_TYPE_ARCHIVE,
            FileEnum.FILE_TYPE_PDF,
          ].includes(externalResource.fileType)
        ) {
          const fileUrl = getMediaBasePath(
            externalResource.fileUrl,
            'processedMediaBucket'
          );
          downloadResources[resource.resourceId].assets[fileUrl] =
            await getDownloadedAssetAndStoreInIndexedDb({
              subjectId,
              chapterId,
              topicId,
              lessonId: lesson.lessonId,
              resourceId: resource.resourceId,
              url: fileUrl,
              cacheKey: (await generateCacheKey(new Request(fileUrl))).url,
              type: DownloadedAsset_CacheKeyType.EXTERNAL_RESOURCE,
            });
          const previewUrl = externalResource.previewUrl
            ? getMediaBasePath(
                externalResource.previewUrl,
                'processedMediaBucket'
              )
            : undefined;
          if (previewUrl && previewUrl !== fileUrl) {
            downloadResources[resource.resourceId].assets[previewUrl] =
              await getDownloadedAssetAndStoreInIndexedDb({
                subjectId,
                chapterId,
                topicId,
                lessonId: lesson.lessonId,
                resourceId: resource.resourceId,
                url: previewUrl,
                cacheKey: (await generateCacheKey(new Request(previewUrl))).url,
                type: DownloadedAsset_CacheKeyType.EXTERNAL_RESOURCE,
              });
          }
        }
      }

      downloadResources[resource.resourceId].isDownloaded = true;
    }

    if (resourcesToDelete.length) {
      for (let i = 0; i < resourcesToDelete.length; i++) {
        const resourceToDelete = resourcesToDelete[i];
        const resource = downloadResources[resourceToDelete];
        if (resource) {
          const resourceAssets = Object.values(resource.assets);
          for (let j = 0; j < resourceAssets.length; j++) {
            const asset = resourceAssets[j];
            await deleteCacheEntryFromCache(asset.cacheKey);
          }
          delete downloadResources[resourceToDelete];
        }
      }
    }

    const lessonImage = appendAndSortQueryParams(
      getMediaBasePath(lesson.posterImageUrl, 'processedMediaBucket'),
      {
        subject_id: selectedSubject?.subjectId,
        chapter_id: chapterId,
        topic_id: topicId,
        lesson_id: lesson.lessonId,
      }
    );
    dataToStore.chapters[chapterId].downloadedTopics[
      topicId
    ].downloadedLessonPlans[lesson.lessonId] = new DownloadedLessonPlan({
      lessonPlanTitle: lesson.title,
      lessonPlanId: lesson.lessonId,
      order: lessonIndex,
      image: lessonImage,
      lastUpdatedAt: Timestamp.fromDate(new Date()),
      estimatedTimeInMin: lesson.estimatedTimeInMin,
      resourceIds: lesson.resourceIds,
      learningOutcomes: lessonResponse.data?.learningOutcomes,
      downloadedResources: downloadResources,
      teacherId:
        user_info instanceof TeacherLoginResponseType
          ? lesson.teacherName === 'Geneo'
            ? undefined
            : user_info.teacherProfileId
          : undefined,
      // Need a better key for teacher id
      teacherName: lesson.teacherName,
      schoolClassSectionId: [
        ...new Set([
          sectionId,
          ...(dataToStore.chapters[chapterId].downloadedTopics[topicId]
            .downloadedLessonPlans[lesson.lessonId]?.schoolClassSectionId ||
            []),
        ]),
      ],
    });
    const resLessonImage = await cacheFetch(lessonImage);
    console.log(
      'Downloading lesson image for lesson with id:' + lesson.lessonId
    );
    if (resLessonImage && resLessonImage.ok) {
      const blobRes = await resLessonImage.blob();
      const size = blobRes.size;
      dataToStore.chapters[chapterId].downloadedTopics[
        topicId
      ].downloadedLessonPlans[lesson.lessonId].assets[lessonImage] =
        new DownloadedAsset({
          url: lessonImage,
          cacheKey: (await generateCacheKey(new Request(lessonImage))).url,
          type: DownloadedAsset_CacheKeyType.IMAGE,
          size: BigInt(size),
        });
    }

    dataToStore.chapters[chapterId].downloadedTopics[
      topicId
    ].downloadedLessonPlans[lesson.lessonId].isDownloaded = true;
    // Add here
    const plainMsg = toPlainMessage(dataToStore);
    const res = await addDownloadedSubjectRequest(plainMsg);
    setCurrentDownload({
      subjectId,
      chapterId,
      topicId,
      lessonPlanId: lesson_id,
    });
  }
  const subData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  const subjData = subData[0];
  await addDownloadedSubjectRequest(subjData);
  setCurrentDownload({ subjectId, chapterId, topicId });
  return dataToStore;
}

const getDownloadedAssetAndStoreInIndexedDb = async (
  params: Omit<PlainMessage<DownloadedResourceCacheInfo>, 'size'> & {
    resolution?: number;
  }
) => {
  const {
    subjectId,
    chapterId,
    topicId,
    lessonId,
    resourceId,
    url,
    cacheKey,
    type,
    resolution,
  } = params;
  const indexedDbData = await findDownloadedResourceCacheInfoByParams({
    cacheKey: url,
  });
  const downloadedAsset = new DownloadedAsset({
    url: url,
    cacheKey: cacheKey,
    type: type,
    isResourceAsset: true,
  });
  if (indexedDbData?.length) {
    downloadedAsset.size = indexedDbData[0].size;
  } else {
    const resAsset = await cacheFetch(url, undefined, resolution);
    if (resAsset.ok) {
      const blobRes =
        type == DownloadedAsset_CacheKeyType.AUDIO_M3U8
          ? await resAsset.text()
          : await resAsset.blob();
      const size = typeof blobRes == 'string' ? blobRes.length : blobRes.size;
      downloadedAsset.size = BigInt(size);
    }
  }
  await addDownloadedResourceCacheInfoRequest({
    subjectId,
    chapterId,
    topicId,
    lessonId,
    resourceId,
    url: downloadedAsset.url,
    cacheKey: downloadedAsset.cacheKey,
    size: downloadedAsset.size,
    type: downloadedAsset.type,
  });
  return downloadedAsset;
};

interface DeleteLessonPlanParams {
  subjectId: number;
  chapterId: number;
  topicId: number;
  lessonId: string;
  sectionId?: number;
  userId?: string;
}

export const deleteDownloadedLessonPlan = async (
  params: DeleteLessonPlanParams
) => {
  const { subjectId, chapterId, topicId, lessonId, sectionId, userId } = params;
  const indexDbData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  console.log({
    subjectId,
    chapterId,
    topicId,
    lessonId,
    sectionId,
    indexDbData,
  });
  if (indexDbData && indexDbData.length) {
    const subjectData = indexDbData[0];
    const lessonData =
      subjectData?.chapters[chapterId]?.downloadedTopics[topicId]
        ?.downloadedLessonPlans[lessonId];
    let sections = lessonData.schoolClassSectionId || [];
    if (sectionId && lessonData && sections.includes(sectionId)) {
      // Remove current section from downloaded data
      sections = sections.filter((val) => val !== sectionId);
      subjectData.chapters[chapterId].downloadedTopics[
        topicId
      ].downloadedLessonPlans[lessonId].schoolClassSectionId = sections;
    }
    if (lessonData && (sections.length === 0 || !sectionId)) {
      if (lessonData.image) {
        await deleteCacheEntryFromCache(lessonData.image);
      }
      const downloadedResources = Object.values(lessonData.downloadedResources);
      for (let i = 0; i < downloadedResources.length; i++) {
        const resource = downloadedResources[i];
        if (resource.posterImageUrl) {
          await deleteCacheEntryFromCache(lessonData.image);
        }
        const assets = Object.values(resource.assets);
        for (let j = 0; j < assets.length; j++) {
          const asset = assets[j];
          const downloadedResourceIndexDBData =
            await findDownloadedResourceCacheInfoByParams({
              cacheKey: asset.cacheKey,
            });
          if (downloadedResourceIndexDBData.length == 1) {
            // if only present in one place
            await deleteCacheEntryFromCache(asset.cacheKey);
          }
          await deleteDownloadedResourceContentFromIndexDB({
            subjectId,
            chapterId,
            topicId,
            lessonId,
            resourceId: resource.resourceId,
            url: asset.url,
            cacheKey: asset.cacheKey,
          });
        }
      }
      delete subjectData.chapters[chapterId].downloadedTopics[topicId]
        .downloadedLessonPlans[lessonId];
    }
    await addDownloadedSubjectRequest(subjectData);
    console.log('Deleted lesson plan with lesson id ' + lessonId);
    const subData = await findDownloadedSubjectByParams({
      subjectId: subjectId,
    });
    const subjData = subData[0];
    await addDownloadedSubjectRequest(subjData);
    console.log('Deleted lesson plan with lesson id ' + lessonId);
  }
};

interface DeleteTopicParams {
  subjectId: number;
  chapterId: number;
  topicId: number;
  sectionId?: number;
  userId?: string;
}

export const deleteDownloadedTopic = async (params: DeleteTopicParams) => {
  const { subjectId, chapterId, topicId, sectionId, userId } = params;
  const indexDbData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  if (indexDbData && indexDbData.length) {
    const subjectData = indexDbData[0];
    const topicData =
      subjectData?.chapters[chapterId]?.downloadedTopics[topicId];
    if (topicData) {
      const downloadedLessonPlans = Object.values(
        topicData.downloadedLessonPlans
      );
      for (let i = 0; i < downloadedLessonPlans.length; i++) {
        const lessonPlan = downloadedLessonPlans[i];
        if (lessonPlan) {
          await deleteDownloadedLessonPlan({
            subjectId,
            chapterId,
            topicId,
            lessonId: lessonPlan.lessonPlanId,
            sectionId,
            userId,
          });
        }
      }
      const subData = await findDownloadedSubjectByParams({
        subjectId: subjectId,
      });
      const subjData = subData[0];
      const topics = subjData?.chapters[chapterId]?.downloadedTopics[topicId];
      if (Object.values(topics.downloadedLessonPlans).length === 0) {
        if (topicData.posterImageUrl) {
          await deleteCacheEntryFromCache(topicData.posterImageUrl);
        }
        delete subjData.chapters[chapterId].downloadedTopics[topicId];
        await addDownloadedSubjectRequest(subjData);
        console.log('Deleted topic with topic id ' + topicId);
      }
    }
  }
};

interface DeleteChapterParams {
  subjectId: number;
  chapterId: number;
  sectionId?: number;
  userId?: string;
}

export const deleteDownloadedChapter = async (params: DeleteChapterParams) => {
  const { subjectId, chapterId, sectionId, userId } = params;
  const indexDbData = await findDownloadedSubjectByParams({
    subjectId: subjectId,
  });
  if (indexDbData && indexDbData.length) {
    const subjectData = indexDbData[0];
    const chapterData = subjectData?.chapters[chapterId];
    if (chapterData) {
      const downloadedTopics = Object.values(chapterData.downloadedTopics);
      for (let i = 0; i < downloadedTopics.length; i++) {
        const topic = downloadedTopics[i];
        if (topic) {
          await deleteDownloadedTopic({
            subjectId,
            chapterId,
            topicId: topic.topicId,
            sectionId,
            userId,
          });
        }
      }

      const subData = await findDownloadedSubjectByParams({
        subjectId: subjectId,
      });
      const subjData = subData[0];
      const chapters = subjData?.chapters[chapterId];
      if (Object.values(chapters.downloadedTopics).length === 0) {
        if (chapterData.posterImagesUrl) {
          await deleteCacheEntryFromCache(chapterData.posterImagesUrl);
        }
        delete subjData.chapters[chapterId];
        await addDownloadedSubjectRequest(subjData);
        console.log('Deleted chapter with chapter id ' + chapterId);
      }
    }
  }
};

export async function deleteCacheEntryFromCache(cacheKey: string) {
  try {
    const cacheName = 'post-request-cache';
    const cache = await caches.open(cacheName);

    // Retrieve the cached response by cacheKey
    const cachedResponse = await cache.match(cacheKey);

    if (cachedResponse) {
      // If the cached response exists, delete it from the cache
      await cache.delete(cacheKey);
      console.log(
        'Cache: Entry with cacheKey ' + cacheKey + ' successfully deleted.'
      );
    } else {
      console.log('Cache: No entry found with cacheKey ' + cacheKey);
    }
  } catch (error) {
    console.error('Cache: Error deleting entry:', error);
    throw new Error('Cache: Error deleting entry: ' + error);
  }
}

export function getSizeOfNode(
  downloadedSubject: PlainMessage<DownloadedSubject>,
  chapterId: number,
  topicId?: number,
  lessonPlanId?: string,
  teacherId?: bigint
): number {
  let totalSize = 0;

  const chapter = downloadedSubject.chapters[chapterId];
  if (!chapter) {
    console.log('Chapter not found with chapter id', chapterId);
    return totalSize;
  }

  const processAsset = (asset: PlainMessage<DownloadedAsset>) => {
    totalSize += Number(asset.size);
  };

  if (topicId !== undefined) {
    const topic = chapter.downloadedTopics[topicId];
    if (!topic) {
      console.error('Topic not found');
      return totalSize;
    }

    if (lessonPlanId !== undefined) {
      const lessonPlan = topic.downloadedLessonPlans[lessonPlanId];
      if (!lessonPlan) {
        console.error('Lesson Plan not found');
        return totalSize;
      }

      Object.values(lessonPlan.downloadedResources).forEach((resource) => {
        Object.values(resource.assets).forEach(processAsset);
      });
    } else {
      let lps = Object.values(topic.downloadedLessonPlans) || [];
      if (teacherId) {
        lps = lps.filter(
          (val) => !val.teacherId || val.teacherId === teacherId
        );
      }
      lps.forEach((lessonPlan) => {
        Object.values(lessonPlan.downloadedResources).forEach((resource) => {
          Object.values(resource.assets).forEach(processAsset);
        });
      });
    }
  } else {
    Object.values(chapter.downloadedTopics).forEach((topic) => {
      let lps = Object.values(topic.downloadedLessonPlans) || [];
      if (teacherId) {
        lps = lps.filter(
          (val) => !val.teacherId || val.teacherId === teacherId
        );
      }
      lps.forEach((lessonPlan) => {
        Object.values(lessonPlan.downloadedResources).forEach((resource) => {
          Object.values(resource.assets).forEach(processAsset);
        });
      });
    });
  }

  return totalSize;
}

export function convertBytesToHumanReadable(bytes: number): string {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;
  const gigabytes = megabytes / 1024;

  if (gigabytes >= 1) {
    return gigabytes.toFixed(2) + 'GB';
  } else if (megabytes >= 1) {
    return megabytes.toFixed(2) + 'MB';
  } else if (kilobytes >= 0.5) {
    return kilobytes.toFixed(2) + 'KB';
  } else {
    return bytes + 'B';
  }
}

export function calculateDownloadProgress(
  user_info: TeacherLoginResponseType | StudentLoginResponseType,
  subjectData?: PlainMessage<DownloadedSubject>,
  metadata?: ChapterMetaInfo,
  chapterId?: number,
  topicId?: number,
  lessonPlanId?: string,
  schoolClassSectionId?: number,
  lockedData?: ContentLockModuleData
) {
  const lockInfo = categorizeLockInfo(lockedData);
  // Find the chapter in both subjectData and metadata
  if (!chapterId || !subjectData) {
    return 0; // Chapter not downloaded or not found
  }
  const chapterData = subjectData?.chapters[chapterId];

  if (!chapterData) {
    return 0; // Chapter not downloaded or not found
  }

  let totalResources = 0;
  let totalLessonPlans = 0;
  let totalTopics = 0;

  let downloadedResources = 0;
  let downloadedLessonPlans = 0;
  let downloadedTopics = 0;

  function calculateResources(
    chapter_id: number,
    topic_id: number,
    lesson_id: string
  ) {
    const topic = metadata?.topics.find((val) => val.id == topic_id);
    const lessonPlan = topic?.lessonPlans.find((lp) => lp.id == lesson_id);
    const lessonLockedResourceIds =
      lockInfo.lesson[lesson_id]?.lockedResourceIds || [];
    const resourceIdsMeta =
      lessonPlan?.resources
        .map((val) => val.id)
        .filter((id) => !lessonLockedResourceIds.includes(id)) || [];
    // first we filter out particular section
    // as per backend logic for fetchLessonsByModule lesson should be self created (same teacher id) or teacher name should be geneo
    let downloadedLessons = Object.values(
      subjectData?.chapters[chapter_id]?.downloadedTopics[topic_id]
        ?.downloadedLessonPlans || {}
    ).filter(
      (val) =>
        !val.schoolClassSectionId ||
        (schoolClassSectionId &&
          val.schoolClassSectionId.includes(schoolClassSectionId))
    );
    if (user_info instanceof TeacherLoginResponseType) {
      downloadedLessons = downloadedLessons.filter(
        (val) =>
          val.teacherId === user_info.teacherProfileId ||
          val.teacherName === 'Geneo'
      );
    }
    const downloadedLessonPlanIds = downloadedLessons.map(
      (val) => val.lessonPlanId
    );
    const isDownloadedLessonPlan = downloadedLessonPlanIds.includes(lesson_id);
    const downloadedResourceIds = isDownloadedLessonPlan
      ? Object.values(
          subjectData?.chapters[chapter_id]?.downloadedTopics[topic_id]
            ?.downloadedLessonPlans[lesson_id]?.downloadedResources || {}
        )
          .filter((val) => val.isDownloaded)
          .map((val) => val.resourceId)
      : [];
    const notDownloadedRes = resourceIdsMeta.filter(
      (item) => !downloadedResourceIds.includes(item)
    );
    totalResources = totalResources + resourceIdsMeta.length;
    downloadedResources =
      downloadedResources + (resourceIdsMeta.length - notDownloadedRes.length);
  }

  function calculateLessonPlans(chapter_id: number, topic_id: number) {
    const topic = metadata?.topics.find((val) => val.id == topic_id);
    let lessonPlans = (topic?.lessonPlans || []).filter(
      (val) =>
        val.schoolClassSectionId == undefined ||
        val.schoolClassSectionId == schoolClassSectionId
    );
    if (user_info instanceof TeacherLoginResponseType) {
      lessonPlans = lessonPlans.filter(
        (val) => !val.teacherId || val.teacherId == user_info.teacherProfileId
      );
    }
    const lessonPlanIdsMeta =
      lessonPlans
        .map((val) => val.id)
        .filter(
          (id) =>
            lockInfo.lesson[id]?.lockStatus !==
            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
        ) || [];
    lessonPlanIdsMeta.forEach((lesson_id) =>
      calculateResources(chapter_id, topic_id, lesson_id)
    );
    // first we filter out particular section
    // as per backend logic for fetchLessonsByModule lesson should be self created (same teacher id) or teacher name should be geneo
    let downloadedLessons = Object.values(
      subjectData?.chapters[chapter_id]?.downloadedTopics[topic_id]
        ?.downloadedLessonPlans || {}
    ).filter(
      (val) =>
        !val.schoolClassSectionId ||
        (schoolClassSectionId &&
          val.schoolClassSectionId.includes(schoolClassSectionId))
    );
    if (user_info instanceof TeacherLoginResponseType) {
      downloadedLessons = downloadedLessons.filter(
        (val) =>
          val.teacherId == user_info.teacherProfileId ||
          val.teacherName == 'Geneo'
      );
    }
    const downloadedLessonPlanIds = downloadedLessons.map(
      (val) => val.lessonPlanId
    );
    const notDownloadedLessonPlans = lessonPlanIdsMeta.filter(
      (item) => !downloadedLessonPlanIds.includes(item)
    );
    totalLessonPlans = totalLessonPlans + lessonPlanIdsMeta.length;
    downloadedLessonPlans =
      downloadedLessonPlans +
      (lessonPlanIdsMeta.length - notDownloadedLessonPlans.length);
  }

  function calculateTopics(chapter_id: number) {
    const topics = metadata?.topics;
    const topicIdsMeta =
      topics
        ?.map((val) => val.id)
        .filter(
          (id) =>
            lockInfo.topic[id]?.lockStatus !==
            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
        ) || [];
    topicIdsMeta?.forEach((topic_id) =>
      calculateLessonPlans(chapter_id, topic_id)
    );
    const downloadedTopicIds = Object.values(
      subjectData?.chapters[chapter_id]?.downloadedTopics || {}
    ).map((val) => val.topicId);
    const notDownloadedTopics = topicIdsMeta?.filter(
      (item) => !downloadedTopicIds.includes(item)
    );
    totalTopics = totalTopics + topicIdsMeta.length;
    downloadedTopics =
      downloadedTopics + (topicIdsMeta.length - notDownloadedTopics.length);
  }

  if (chapterId && topicId && lessonPlanId) {
    calculateResources(chapterId, topicId, lessonPlanId);
  } else if (chapterId && topicId) {
    calculateLessonPlans(chapterId, topicId);
  } else if (chapterId) {
    calculateTopics(chapterId);
  }
  const totalItems = totalLessonPlans + totalResources + totalTopics;
  const downloadedItems =
    downloadedResources + downloadedLessonPlans + downloadedTopics;
  if (totalItems === 0) {
    return 100;
  }
  const percent = (downloadedItems / totalItems) * 100;
  const roundedPercent = Number(percent.toFixed(2));
  return roundedPercent;
}

function parseGCPResponseM3U8(gcpResponse: any): any {
  function appendOfflineToM3U8Urls(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        // Check if the string is a .m3u8 URL and append ?offline=true
        if (obj[key].includes('.m3u8')) {
          obj[key] = appendAndSortQueryParams(obj[key], { offline: 'true' });
        }
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((element: any) => {
          if (typeof element === 'object') {
            appendOfflineToM3U8Urls(element);
          } else if (typeof element === 'string' && element.includes('.m3u8')) {
            // Handle strings within arrays
            const index = obj[key].indexOf(element);
            obj[key][index] = appendAndSortQueryParams(obj[key][index], {
              offline: 'true',
            });
          }
        });
      } else if (typeof obj[key] === 'object') {
        // Recurse into objects
        appendOfflineToM3U8Urls(obj[key]);
      }
    }
  }

  appendOfflineToM3U8Urls(gcpResponse);
  return gcpResponse;
}
