// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.events.apis.proto (package geneo.ai.student.events.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { StudentEvent, StudentEventResponse } from "@protos/geneo_ai/ai.student.events_pb";
import { MethodKind } from "@bufbuild/protobuf";
import { GetSessionIdDataRequest, GetSessionIdDataResponse } from "./ai.student.events.apis_pb";

/**
 * @generated from service geneo.ai.student.events.apis.StudentEventsAPIServiceV1
 */
export const StudentEventsAPIServiceV1 = {
  typeName: "geneo.ai.student.events.apis.StudentEventsAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.student.events.apis.StudentEventsAPIServiceV1.studentEventProcess
     */
    studentEventProcess: {
      name: "studentEventProcess",
      I: StudentEvent,
      O: StudentEventResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.ai.student.events.apis.LastActivityTimestampAPIServiceV1
 */
export const LastActivityTimestampAPIServiceV1 = {
  typeName: "geneo.ai.student.events.apis.LastActivityTimestampAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.student.events.apis.LastActivityTimestampAPIServiceV1.fetchLastInteractionSessionEventTime
     */
    fetchLastInteractionSessionEventTime: {
      name: "fetchLastInteractionSessionEventTime",
      I: GetSessionIdDataRequest,
      O: GetSessionIdDataResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

