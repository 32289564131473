// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.content.apis.proto (package geneo.cms.content.parser.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BMRequest, BMResponse, BookRequest, BookResourceRequest, BookResourceResponse, BookResponse, BookUrlRequest, BookUrlResponse, ContentRequest, ContentResponse, conversionRequest, conversionResponse, estimatedTimeRequest, estimatedTimeResponse, getResourceDataRequest, getResourceDataResponse, HomeworkRequest, HomeworkResponse, InstructionRequest, InstructionResponse, LearningOutcomeRequest, LearningOutcomeResponse, LessonRequest, LessonResponse, ModuleBookContentRequest, ModuleBookContentResponse, ModuleBookResourceRequest, ModuleBookResourceResponse, ModuleLessonTagRequest, ModuleLessonTagResponse, ModuleRequest, ModuleResourceTagRequest, ModuleResourceTagResponse, ModuleResponse, populateOfflineMetaRequest, populateOfflineMetaResponse, populateVideoRequest, populateVideoResponse, QuestionBankRequest, QuestionBankResponse, QuestionRequest, QuestionResponse, ResourceContentRequest, ResourceContentResponse, ResourceRequest, ResourceResponse, TranscoderRequest, TranscoderRequestCommon, TranscoderResponseCommon, updatePdfRequest, updatePdfResponse, upsertVideoRequest } from "./cms.content.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.cms.content.parser.apis.ContentParserAPIServiceV1
 */
export const ContentParserAPIServiceV1 = {
  typeName: "geneo.cms.content.parser.apis.ContentParserAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertResource
     */
    upsertResource: {
      name: "upsertResource",
      I: ResourceRequest,
      O: ResourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertContents
     */
    upsertContents: {
      name: "upsertContents",
      I: ContentRequest,
      O: ContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertResourceContents
     */
    upsertResourceContents: {
      name: "upsertResourceContents",
      I: ResourceContentRequest,
      O: ResourceContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertQuestions
     */
    upsertQuestions: {
      name: "upsertQuestions",
      I: QuestionRequest,
      O: QuestionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertLearningOutcome
     */
    upsertLearningOutcome: {
      name: "upsertLearningOutcome",
      I: LearningOutcomeRequest,
      O: LearningOutcomeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertHomework
     */
    upsertHomework: {
      name: "upsertHomework",
      I: HomeworkRequest,
      O: HomeworkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertQuestionBank
     */
    upsertQuestionBank: {
      name: "upsertQuestionBank",
      I: QuestionBankRequest,
      O: QuestionBankResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertInstruction
     */
    upsertInstruction: {
      name: "upsertInstruction",
      I: InstructionRequest,
      O: InstructionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertBook
     */
    upsertBook: {
      name: "upsertBook",
      I: BookRequest,
      O: BookResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertBookModule
     */
    upsertBookModule: {
      name: "upsertBookModule",
      I: BookRequest,
      O: BookResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertModule
     */
    upsertModule: {
      name: "upsertModule",
      I: ModuleRequest,
      O: ModuleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.fetchBookAndModule
     */
    fetchBookAndModule: {
      name: "fetchBookAndModule",
      I: BMRequest,
      O: BMResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.fetchBook
     */
    fetchBook: {
      name: "fetchBook",
      I: BookUrlRequest,
      O: BookUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.saveModuleResourceTag
     */
    saveModuleResourceTag: {
      name: "saveModuleResourceTag",
      I: ModuleResourceTagRequest,
      O: ModuleResourceTagResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.saveModuleBookContent
     */
    saveModuleBookContent: {
      name: "saveModuleBookContent",
      I: ModuleBookContentRequest,
      O: ModuleBookContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.saveLesson
     */
    saveLesson: {
      name: "saveLesson",
      I: LessonRequest,
      O: LessonResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.saveModuleLessonTag
     */
    saveModuleLessonTag: {
      name: "saveModuleLessonTag",
      I: ModuleLessonTagRequest,
      O: ModuleLessonTagResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.runTranscoder
     */
    runTranscoder: {
      name: "runTranscoder",
      I: TranscoderRequest,
      O: TranscoderResponseCommon,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.runTranscoderForFail
     */
    runTranscoderForFail: {
      name: "runTranscoderForFail",
      I: TranscoderRequestCommon,
      O: TranscoderResponseCommon,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.transcoderStatus
     */
    transcoderStatus: {
      name: "transcoderStatus",
      I: TranscoderRequestCommon,
      O: TranscoderResponseCommon,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.fetchTranscoderTableData
     */
    fetchTranscoderTableData: {
      name: "fetchTranscoderTableData",
      I: TranscoderRequestCommon,
      O: TranscoderResponseCommon,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.taskEstimatedTimeFix
     */
    taskEstimatedTimeFix: {
      name: "taskEstimatedTimeFix",
      I: estimatedTimeRequest,
      O: estimatedTimeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.askFix
     */
    askFix: {
      name: "askFix",
      I: estimatedTimeRequest,
      O: estimatedTimeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.populateOfflineMeta
     */
    populateOfflineMeta: {
      name: "populateOfflineMeta",
      I: populateOfflineMetaRequest,
      O: populateOfflineMetaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.parentModuleIdFix
     */
    parentModuleIdFix: {
      name: "parentModuleIdFix",
      I: estimatedTimeRequest,
      O: estimatedTimeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.populateVideoDb
     */
    populateVideoDb: {
      name: "populateVideoDb",
      I: populateVideoRequest,
      O: populateVideoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertVideoDb
     */
    upsertVideoDb: {
      name: "upsertVideoDb",
      I: upsertVideoRequest,
      O: populateVideoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.convertToPdf
     */
    convertToPdf: {
      name: "convertToPdf",
      I: conversionRequest,
      O: conversionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.updatePdfRow
     */
    updatePdfRow: {
      name: "updatePdfRow",
      I: updatePdfRequest,
      O: updatePdfResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertBookResource
     */
    upsertBookResource: {
      name: "upsertBookResource",
      I: BookResourceRequest,
      O: BookResourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.getResourceData
     */
    getResourceData: {
      name: "getResourceData",
      I: getResourceDataRequest,
      O: getResourceDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.cms.content.parser.apis.ContentParserAPIServiceV1.upsertModuleBookResource
     */
    upsertModuleBookResource: {
      name: "upsertModuleBookResource",
      I: ModuleBookResourceRequest,
      O: ModuleBookResourceResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

