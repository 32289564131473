import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { pxToRem, pxTovW } from '../../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../../commonUtils/styleUtils';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getLocalStorage } from '../../../../../commonUtils/utilFunctions';
import ImageWrapper from '../../../../elements/ImageWrapper';
const styles: IStyles = {
  root: {
    // border: '1px solid red',
    ml: { xs: pxToRem(20), md: pxTovW(30) },
    mb: { xs: pxToRem(40), md: pxTovW(40) },
  },
  remarksHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  editButton: {
    width: { xs: pxToRem(30), md: pxTovW(30) },
    height: { xs: pxToRem(30), md: pxTovW(30) },
    cursor: 'pointer',
    borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    border: '1px solid #0AA34F73',
  },
  remark: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(15), md: pxTovW(15) },
  },
};

interface IProps {
  remarks: string[];
  editMarksModal: boolean;
  setEditMarksModal: (arg: boolean) => void;
  showRemarksEditIcon: boolean;
}
export const RemarksDisplay = (props: IProps) => {
  const { remarks, editMarksModal, setEditMarksModal, showRemarksEditIcon } =
    props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userRole = getLocalStorage('role');

  if (remarks.length > 0)
    return (
      <Box sx={styles.root}>
        <Box sx={styles.remarksHeading}>
          <Typography variant={isMobile ? 'h2' : 'h3'} fontWeight="bold">
            Remarks
          </Typography>

          {userRole === 'teacher' && showRemarksEditIcon && (
            <ImageWrapper
              name="edit2"
              type="png"
              parentFolder="icons"
              onClick={() => {
                setEditMarksModal(true);
              }}
              styles={styles.editButton}
            />
          )}
        </Box>

        {remarks.map((remark, index) => (
          <Box key={index} sx={styles.remark}>
            <ArrowRightIcon
              // height={pxTovW(15)}
              // width={pxTovW(20)}
              color="primary"
              fontSize="small"
            />
            <Typography variant="bodyText">{remark}</Typography>
          </Box>
        ))}
      </Box>
    );
  else return null;
};
