import {
  ContentDetailCard,
  getMediaBasePath,
  resourceTypeName,
} from '@geneo2-web/shared-ui';

import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';
import { useLocation } from 'react-router-dom';

interface IProps {}
export default function DemoResourceCard(props: IProps) {
  const location = useLocation();

  const posterImageUrl =
    new URLSearchParams(location.search).get('posterImageUrl') || undefined;
  const resource_type =
    new URLSearchParams(location.search).get('resourceType') || undefined;
  const resourceType = isNaN(Number(resource_type))
    ? undefined
    : (Number(resource_type) as Resource_ResourceEnum);
  const estimatedTimeInMin =
    new URLSearchParams(location.search).get('estimatedTimeInMin') || undefined;
  const title = new URLSearchParams(location.search).get('title') || undefined;

  return (
    <div id="question">
      <ContentDetailCard
        variant={'large'}
        image={getMediaBasePath(posterImageUrl, 'processedMediaBucket')}
        showVideoIcon={
          resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
        }
        heading={title || ''}
        iconDetails={[
          {
            iconName: 'clock',
            text: estimatedTimeInMin + ' Min',
          },
          {
            iconName: resourceTypeName(
              resourceType || Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
            )?.icon,
            text: resourceTypeName(
              resourceType || Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
            )?.name,
          },
        ]}
        rootStyle={{
          backgroundColor: 'common.white',
        }}
      />
    </div>
  );
}
