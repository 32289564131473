import { LinearProgress, Popover, Typography } from '@mui/material';
import { Box, keyframes } from '@mui/system';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import React, { useState } from 'react';
import ImageWrapper from '../../elements/ImageWrapper';
import { ActionsPopup } from '../ActionsPopup';
interface IProps {
  title?: string;
  status?: string;
  progress?: number;
  isSyncAvail?: boolean;
  onDownloadClick?: () => void;
  onDeleteClick?: () => void;
  isDownloading?: boolean;
  isDisabled?: boolean;
}
export const DownloadPopup = (props: IProps) => {
  const {
    progress,
    title,
    status,
    isSyncAvail,
    onDownloadClick,
    isDownloading,
    onDeleteClick,
    isDisabled,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const variant = isDownloading
    ? 'downloading'
    : isSyncAvail
    ? 'sync'
    : progress === 100
    ? 'downloaded'
    : 'download';
  // (progress === 0 || !progress) ? 'download' : (progress === 100 ? (isSyncAvail ? 'sync' : 'downloaded') : isDownloading ? 'downloading' : 'paused');
  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (variant === 'download' || variant === 'sync') {
      if (onDownloadClick && !isDownloading) {
        // setIsDownloading(true);
        await onDownloadClick();
        // setIsDownloading(false);
      }
      return;
    } else if (variant === 'downloading') {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const handleDeletePopupClose = () => {
    setDeletePopupOpen(false);
  };
  return (
    <Box
      sx={{
        cursor: isDisabled ? undefined : 'pointer',
      }}
      onClick={(ev: React.MouseEvent) => {
        ev.stopPropagation();
      }}
    >
      <DownloadButton
        isDisabled={isDisabled}
        variant={variant}
        clickHandler={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: {
            xs: `${pxToRem(30)} ${pxToRem(30)} 0 0`,
            md: pxToRem(35),
          },
          '& .MuiPopover-paper': {
            // backgroundColor: 'red',
            borderRadius: {
              xs: pxToRem(7),
              md: pxTovW(15),
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: {
              xs: pxToRem(150),
              md: pxTovW(279),
            },
            height: 'max-content',
            borderRadius: {
              xs: pxToRem(7),
              md: pxTovW(15),
            },
            margin: `${pxTovW(20)} ${pxTovW(20)} ${pxTovW(20)} ${pxTovW(20)}`,
          }}
        >
          {variant !== 'downloaded' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { md: pxTovW(10), xs: pxToRem(10) },
                alignItems: 'flex-start',
                borderBottom: '2px solid #00000029',
                paddingBottom: pxTovW(15),
                paddingTop: pxTovW(15),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: { md: pxTovW(10), xs: pxToRem(10) },
                  alignItems: 'center',
                }}
              >
                <ImageWrapper
                  styles={{ height: pxTovW(25), width: pxTovW(25) }}
                  name="download-blue"
                  parentFolder="icons"
                  type="png"
                />
                <Typography variant="h5" fontWeight="bold">
                  {title}
                </Typography>
              </Box>
              {variant == 'downloading' && (
                <DownloadStatus
                  variant={variant}
                  status={status || ''}
                  progress={progress || 0}
                />
              )}
            </Box>
          )}
          {/* {variant === 'paused' && (
            <Box
              sx={{
                display: 'flex',
                gap: {md:pxTovW(10), xs: pxToRem(10)},
                alignItems: 'center',
                borderBottom: '2px solid #00000029',
                paddingBottom: pxTovW(15),
                paddingTop: pxTovW(15),
              }}
            >
              <ImageWrapper
                styles={{ height: pxTovW(25), width: pxTovW(25) }}
                name="pause2"
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h5" fontWeight="bold">
                Resume
              </Typography>
            </Box>
          )} */}
          {variant === 'downloading' && (
            <Box
              sx={{
                display: 'flex',
                gap: { md: pxTovW(10), xs: pxToRem(10) },
                alignItems: 'center',
                borderBottom: '2px solid #00000029',
                paddingBottom: pxTovW(15),
                paddingTop: pxTovW(15),
              }}
            >
              <ImageWrapper
                styles={{ height: pxTovW(25), width: pxTovW(25) }}
                name="pause2"
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h5" fontWeight="bold">
                Pause
              </Typography>
            </Box>
          )}
          {variant === 'downloading' && (
            <Box
              sx={{
                display: 'flex',
                gap: { md: pxTovW(10), xs: pxToRem(10) },
                alignItems: 'center',
                paddingBottom: pxTovW(15),
                paddingTop: pxTovW(15),
              }}
            >
              <ImageWrapper
                styles={{
                  height: { md: pxTovW(25), xs: pxToRem(35) },
                  width: { md: pxTovW(25), xs: pxToRem(35) },
                }}
                name="cancel-download"
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h5" fontWeight="bold">
                Cancel Download
              </Typography>
            </Box>
          )}
          {variant === 'downloaded' && (
            <Box
              sx={{
                display: 'flex',
                gap: { md: pxTovW(10), xs: pxToRem(10) },
                alignItems: 'center',
                paddingBottom: pxTovW(15),
                paddingTop: pxTovW(15),
                cursor: 'pointer',
              }}
              onClick={async (ev: React.MouseEvent) => {
                ev.stopPropagation();
                handleClose();
                if (!isDeleting) {
                  setDeletePopupOpen(true);
                }
              }}
            >
              <ImageWrapper
                styles={{
                  height: { md: pxTovW(25), xs: pxToRem(35) },
                  width: { md: pxTovW(25), xs: pxToRem(35) },
                }}
                name="delete-with-circle"
                parentFolder="icons"
                type="png"
              />
              <Typography variant="h5" fontWeight="bold">
                Delete
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
      <ActionsPopup
        open={deletePopupOpen}
        handleClose={handleDeletePopupClose}
        fontSmall
        iconName="delete"
        popupText={`Are you sure you want to delete \n${title || ''} ?`}
        yesClickHandler={async (ev?: React.MouseEvent) => {
          handleDeletePopupClose();
          ev?.stopPropagation();
          if (onDeleteClick && !isDeleting) {
            setIsDeleting(true);
            await onDeleteClick();
            setIsDeleting(false);
          }
        }}
        noClickHandler={(ev?: React.MouseEvent) => {
          ev?.stopPropagation();
          handleDeletePopupClose();
        }}
      />
    </Box>
  );
};

interface IButtonProps {
  variant: Variant;
  clickHandler: (event: React.MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}

export const DownloadButton = (props: IButtonProps) => {
  const { variant, clickHandler, isDisabled } = props;
  const img = variantMapping[variant].Image;
  return (
    <Box
      sx={{
        filter: isDisabled ? 'grayscale(0.8)' : undefined,
        backgroundColor: 'transparent',
        background: '#C1F8DA',
        display: 'flex',
        borderRadius: {
          xs: pxToRem(7),
          md: pxToRem(5),
          lg: pxTovW(7),
        },
        height: {
          xs: pxToRem(30),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        width: {
          xs: pxToRem(28),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: isDisabled ? undefined : 'pointer',
        border: '1px solid #0AA34F',
      }}
      onClick={(e) => {
        if (isDisabled) {
          return;
        }
        clickHandler(e);
      }}
    >
      <ImageWrapper
        styles={{
          height: {
            xs: pxToRem(15),
            md: pxToRem(13),
            lg: pxTovW(17),
          },
          width: {
            xs: img === 'sync' ? pxToRem(13) : pxToRem(16),
            md: img === 'sync' ? pxToRem(10) : pxToRem(13),
            lg: img === 'sync' ? pxTovW(15) : pxTovW(18),
          },
          ...(variant === 'downloading' && {
            animation: `${spin} 1s infinite linear`,
          }),
        }}
        name={img}
        parentFolder="icons"
        type="png"
      />
    </Box>
  );
};

interface IStatusProps {
  variant: Variant;
  status: string;
  progress: number;
}
export const DownloadStatus = (props: IStatusProps) => {
  const { progress, status, variant } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { md: pxTovW(10), xs: pxToRem(10) },
        width: '100%',
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Typography variant="subText" sx={{ color: '#929292' }}>
          {status}
        </Typography>
        {variant === 'paused' ? (
          <ImageWrapper
            styles={{ height: pxTovW(14), width: pxTovW(11) }}
            name="pause-blue-small"
            parentFolder="icons"
            type="png"
          ></ImageWrapper>
        ) : (
          <Typography
            variant="subText"
            sx={{ color: '#007CDC' }}
          >{`${progress}%`}</Typography>
        )}
      </Box>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

const variantMapping: {
  [key: string]: {
    Image: string;
  };
} = {
  download: {
    Image: 'download',
  },
  downloaded: {
    Image: 'downloaded',
  },
  paused: {
    Image: 'pause',
  },
  sync: {
    Image: 'sync',
  },
  downloading: {
    Image: 'sync',
  },
};

type Variant = 'download' | 'downloaded' | 'paused' | 'sync' | 'downloading';

export const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;
