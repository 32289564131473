import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Box, Button, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../IconWrapper/Index';

interface Iprops {
  variant: 'wifi' | 'globe' | 'class' | 'connected' | 'connectedmobile';
  fullwidth?: boolean;
  isOnline?: boolean;
  setInstructionModal?: Dispatch<SetStateAction<boolean>>;
  instructionEvent?: () => void;
  totalJoined?: number;
  totalStudents?: number;
  totalAnswered?: number;
  correct?: number;
  averageTime?: number;
  averagemarks?: number | string;
}
export const ConnectedClassIndicator = (props: Iprops) => {
  const {
    variant,
    fullwidth,
    isOnline,
    setInstructionModal,
    instructionEvent,
    totalJoined,
    totalStudents,
    totalAnswered,
    correct,
    averageTime,
    averagemarks,
  } = props;
  const styles: IStyles = {
    default: {
      width: fullwidth
        ? { xs: '100vw', md: '100%' }
        : { xs: pxToRem(472), md: pxTovW(472) },
      height: { xs: pxToRem(29), md: pxTovW(74) },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // borderRadius: { xs: pxToRem(18), md: pxTovW(18) },
      gap: { xs: pxToRem(25), md: pxTovW(25) },
      background:
        'transparent linear-gradient(270deg, #F1F0F6 0%, #FAEDEE 69%, #FCECED 100%) 0% 0% no-repeat padding-box;',
      border: '1px solid #007CDC',
      // opacity: 1
      boxSizing: 'border-box',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: pxTovW(5),
      '&>div': {
        display: 'flex',
        gap: pxTovW(5),
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  };

  if (!isOnline)
    return (
      <OfflineVariant
        largeHeightVariant={variant === 'connected' ? true : false}
        fullwidth={fullwidth}
      />
    );

  return (
    <Box>
      {variant === 'connected' ? (
        <Box
          sx={{
            ...styles.default,
            height: { md: pxTovW(118) },
            width: { md: '95%', lg: pxTovW(1441) },
            marginX: 'auto',
            background: '#F3FAFF',
            border: '1px solid #007CDC96',
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: { md: pxTovW(480) },
              height: '100%',
              background: '#007CDC',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: pxToRem(5), md: pxTovW(25) },
            }}
          >
            <IconWrapper
              name="Wifi@2x"
              parentFolder="icons"
              size="md"
              customSx={{
                width: { xs: pxToRem(15), md: pxTovW(48) },
                height: { xs: pxToRem(15), md: pxTovW(40) },
              }}
              type="png"
            />
            <Typography
              fontFamily={'poppins'}
              fontSize={{ xs: pxToRem(18), md: pxTovW(32) }}
              fontWeight={600}
              color={'#FFFFFF'}
            >
              Connected Class
            </Typography>
          </Box>
          <Box
            sx={{
              width: { md: pxTovW(818) },
              display: 'flex',
              gap: { md: pxTovW(30) },
              marginRight: { md: pxTovW(30) },
            }}
          >
            <Box
              sx={{
                width: { md: pxTovW(431) },
                height: { md: pxTovW(91) },
                display: 'flex',
                justifyContent: 'space-around',
                background: '#FFFFFF',
                border: '1px solid #70C0FE',
                borderRadius: { md: pxTovW(11) },
              }}
            >
              <Box sx={styles.item}>
                <Box>
                  <IconWrapper
                    name="joined"
                    size="large"
                    type="png"
                    parentFolder="icons"
                  />
                  <Typography variant="h3" fontWeight={700}>
                    {totalJoined}/{totalStudents}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="smallText">Joined</Typography>
                </Box>
              </Box>
              <Box sx={styles.item}>
                <Box>
                  <IconWrapper
                    name="whatsapp_pink"
                    size="large"
                    type="png"
                    parentFolder="icons"
                  />
                  <Typography variant="h3" fontWeight={700}>
                    {totalAnswered}/{totalStudents}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="smallText">Answered</Typography>
                </Box>
              </Box>
              <Box sx={styles.item}>
                <Box>
                  <IconWrapper
                    name="corrected"
                    size="large"
                    type="png"
                    parentFolder="icons"
                  />
                  <Typography variant="h3" fontWeight={700}>
                    {correct}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="smallText">Correct</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: { md: pxTovW(357) },
                height: { md: pxTovW(91) },
                display: 'flex',
                justifyContent: 'space-around',
                background: '#FFFFFF',
                border: '1px solid #70C0FE',
                borderRadius: { md: pxTovW(11) },
              }}
            >
              <Box sx={styles.item}>
                <Box>
                  <IconWrapper
                    name="average-time"
                    size="large"
                    type="png"
                    parentFolder="icons"
                  />
                  <Typography variant="h3" fontWeight={700}>
                    {averageTime ? averageTime : 0} secs
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="smallText">Average Time</Typography>
                </Box>
              </Box>
              <Box sx={styles.item}>
                <Box>
                  <IconWrapper
                    name="average-score"
                    size="large"
                    type="png"
                    parentFolder="icons"
                  />
                  <Typography variant="h3" fontWeight={700}>
                    {averagemarks}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="smallText">Average Score</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : variant === 'connectedmobile' ? (
        <Box
          sx={{
            ...styles.default,
            background: '#F3FAFF',
            border: '1px solid #007CDC96',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(5), md: pxTovW(25) },
            }}
          >
            <IconWrapper
              name="wifi"
              parentFolder="icons"
              size="md"
              customSx={{
                width: { xs: pxToRem(15), md: pxTovW(44) },
                height: { xs: pxToRem(15), md: pxTovW(44) },
              }}
              type="png"
            />
            <Typography
              fontFamily={'poppins'}
              fontSize={{ xs: pxToRem(18), md: pxTovW(24) }}
              fontWeight={600}
            >
              Connected Class
            </Typography>
          </Box>
        </Box>
      ) : variant === 'wifi' ? (
        <Box
          sx={{
            ...styles.default,
            background: '#F3FAFF',
            border: '1px solid #007CDC96',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(5), md: pxTovW(25) },
            }}
          >
            <IconWrapper
              name="wifi"
              parentFolder="icons"
              size="md"
              customSx={{
                width: { xs: pxToRem(15), md: pxTovW(44) },
                height: { xs: pxToRem(15), md: pxTovW(44) },
              }}
              type="png"
            />
            <Typography
              fontFamily={'poppins'}
              fontSize={{ xs: pxToRem(18), md: pxTovW(24) }}
              fontWeight={600}
            >
              Connected Class
            </Typography>
          </Box>

          <Box>
            <Button
              sx={{
                backgroundColor: 'transparent',
                '& :hover': {
                  backgroundColor: 'transparent',
                },
              }}
              variant="text"
              onClick={() => {
                setInstructionModal && setInstructionModal(true);
                instructionEvent && instructionEvent();
              }}
            >
              <InfoTwoToneIcon />
              <Typography variant="linkText" color="primary" fontWeight="bold">
                Instructions
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : variant === 'globe' ? (
        <Box
          sx={{
            ...styles.default,
            background: '#D9FCE9',
            border: '1px solid #0AA34F',
          }}
        >
          <IconWrapper
            name="globe"
            parentFolder="icons"
            size="md"
            customSx={{
              width: { xs: pxToRem(62), md: pxTovW(62) },
              height: { xs: pxToRem(58), md: pxTovW(58) },
            }}
            type="png"
          />
          <Typography
            fontFamily={'poppins'}
            fontSize={{ xs: pxToRem(24), md: pxTovW(24) }}
            fontWeight={600}
          >
            Connected Class
          </Typography>
        </Box>
      ) : variant === 'class' ? (
        <Box sx={styles.default}>
          <IconWrapper
            name="connectedclassicon"
            parentFolder="icons"
            size="md"
            customSx={{
              width: { xs: pxToRem(52), md: pxTovW(52) },
              height: { xs: pxToRem(52), md: pxTovW(52) },
            }}
            type="png"
          />
          <Typography
            fontFamily={'poppins'}
            fontSize={{ xs: pxToRem(14), md: pxTovW(24) }}
            fontWeight={600}
          >
            Connected Class
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

interface OfflineVariantProps {
  fullwidth?: boolean;
  largeHeightVariant?: boolean;
}
const OfflineVariant = ({
  fullwidth,
  largeHeightVariant,
}: OfflineVariantProps) => {
  return (
    <Box
      sx={{
        width: fullwidth
          ? { xs: '100vw', md: '100%' }
          : { xs: pxToRem(472), md: pxTovW(472) },
        height: {
          xs: pxToRem(29),
          md: largeHeightVariant ? pxTovW(118) : pxTovW(74),
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: { xs: pxToRem(10), md: pxTovW(25) },
        background: '#F9F9F9',
        border: '1px solid #C6C5C5',
      }}
    >
      <IconWrapper
        name="no_connection"
        parentFolder="icons"
        size="md"
        customSx={{
          width: { xs: pxToRem(20), md: pxTovW(44) },
          height: { xs: pxToRem(20), md: pxTovW(44) },
        }}
        type="png"
      />
      <Typography variant="h2">Disconnected from the Base station</Typography>
    </Box>
  );
};
