import {
  OutlinedTextFieldProps,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { theme } from '../../../theme/themeProvider';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';

interface IProps extends OutlinedTextFieldProps {
  boldtext?: boolean;
  id?: string;
  nonCircular?: boolean;
  borderWidth?: number;
  fontSize?: number;
  fontColor?: string;
  borderColor?: string;
}
const InputField = forwardRef((props: IProps, ref) => {
  const { boldtext, borderWidth, fontSize, fontColor, borderColor } = props;
  const [isFocused, setIsFocused] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <TextField
      inputProps={{ ref: ref }}
      id={props.id}
      InputProps={
        boldtext === true
          ? {
              style: {
                fontWeight: '700',
                color: 'black',
                fontSize: !isMobile && isIpad ? pxToRem(14) : 'inherit',
              },
            }
          : {
              style: {
                color: 'black',
                fontSize: !isMobile && isIpad ? pxToRem(14) : 'inherit',
              },
            }
      }
      label={null}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...props}
      sx={{
        borderRadius: props.nonCircular
          ? { xs: pxToRem(5), md: pxTovW(15) }
          : { xs: pxToRem(30), md: pxTovW(30) },
        '&.MuiFormControl-root': {
          border: 'none',
        },
        '& .MuiOutlinedInput-root': {
          fontSize: fontSize
            ? { xs: pxToRem(fontSize), md: pxTovW(fontSize) }
            : { xs: pxToRem(16), md: pxTovW(21) },
          fontWeight: boldtext === true ? '700' : '500',
          color: fontColor ? fontColor : 'primary.main',
          padding: { xs: pxToRem(15), md: `${pxTovW(20)}` },
          border: props.disabled
            ? 'none'
            : `${borderWidth ? pxTovW(borderWidth) : pxTovW(1)} solid red`,
          // borderColor: isFocused ? 'neutral.cornflowerBlue' : '#CCE6FE',
          borderColor: borderColor ? borderColor : 'neutral.cornflowerBlue',
          borderRadius: props.nonCircular
            ? { xs: pxToRem(5), md: pxTovW(15) }
            : { xs: pxToRem(30), md: pxTovW(30) },
          boxShadow: isFocused
            ? `inset 0 0 1px 0 #61BAFF, inset 0 0 2px 1px rgba(97, 186, 255, 0.6), inset 0 0 3px 2px rgba(97, 186, 255, 0.4), inset 0 0 4px 3px rgba(97, 186, 255, 0.2), inset 0 0 5px 4px rgba(97, 186, 255, 0.1)`
            : 'none',
        },
        ...props.sx,
      }}
    />
  );
});

export default InputField;
