import {
  CurrentDownload,
  DownloadParams,
  deserify,
  fetchChapterContentOffline,
  fetchLessonContentOffline,
  fetchTopicContentOffline,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setToastInfo } from '../../pages/Auth/reducer/auth.slice';
import { useAppSelector } from '../../reduxStore/reduxHooks';

// Define the shape of your context data
interface IContextData {
  currentDownload: CurrentDownload | null;
  setCurrentDownload: (func: CurrentDownload | null) => void;
  isOffline: boolean;
  setIsOffline: (val: boolean) => void;
  addDownload: (func: DownloadParams) => void;
  isDownloading: (func: DownloadParams) => boolean;
  isMigratingData: boolean;
  setIsMigratingData: (val: boolean) => void;
}

// Create the context
const downloadContext = createContext<IContextData | undefined>(undefined);

const { Provider } = downloadContext;

// Create a provider component
interface IProviderProps {
  children: ReactNode;
}

export const DownloadContextProviderV2: React.FC<IProviderProps> = ({
  children,
}) => {
  const user_info = deserify(useAppSelector((state) => state.auth.userInfo));
  const [isMigratingData, setIsMigratingData] = useState(false);
  const [currentDownload, setCurrentDownload] =
    useState<CurrentDownload | null>(null);
  const [currentDownloads, setCurrentDownloads] = useState<DownloadParams[]>(
    []
  );
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [processingQueue, setProcessingQueue] = useState<DownloadParams[]>([]);

  const commonServiceClientContext = useCommonServiceClientContext();
  const { downloadResolution } = deserify(
    useAppSelector((state) => state.offline)
  );
  const dispatch = useDispatch();

  const isDownloading = (downloadObj: DownloadParams) => {
    const {
      subjectId,
      chapterId,
      topicId,
      lessonPlanId,
      resourceId,
      classId,
      sectionId,
    } = downloadObj;
    return currentDownloads.some((val) => {
      return (
        val.subjectId === subjectId &&
        (val.chapterId === undefined || val.chapterId === chapterId) &&
        (val.topicId === undefined || val.topicId === topicId) &&
        (val.lessonPlanId === undefined || val.lessonPlanId === lessonPlanId) &&
        (val.resourceId === undefined || val.resourceId === resourceId) &&
        (val.classId === undefined || val.classId === classId) &&
        (val.sectionId === undefined || val.sectionId === sectionId)
      );
    });
  };

  const addDownload = (downloadObj: DownloadParams) => {
    if (isMigratingData) {
      dispatch(
        setToastInfo({
          label: 'Migrating data. Please try after few minutes',
          variant: 'info',
          open: true,
        })
      );
      return;
    }
    if (!isDownloading(downloadObj)) {
      setCurrentDownloads((prevDownloads) => [...prevDownloads, downloadObj]);
    }
  };

  useEffect(() => {
    if (currentDownloads.length && !isProcessingQueue) {
      setCurrentDownload(null);
      setProcessingQueue([...currentDownloads]);
      setIsProcessingQueue(true);
    }
  }, [currentDownloads, isProcessingQueue]);

  useEffect(() => {
    const processQueue = async () => {
      const processedIndices = new Set<number>();

      for (let i = 0; i < processingQueue.length; i++) {
        const download = processingQueue[i];
        // Process the download here
        console.log('Processing download:', download);
        if (!user_info) {
          continue;
        }
        if (
          download.resourceId &&
          download.lessonPlanId &&
          download.topicId &&
          download.chapterId
        ) {
          // Processing Resource Download
        } else if (
          download.lessonPlanId &&
          download.topicId &&
          download.chapterId
        ) {
          await fetchLessonContentOffline({
            user_info: new StudentLoginResponseType(user_info),
            subjectId: download.subjectId,
            classId: download.classId,
            sectionId: download.sectionId,
            chapterId: download.chapterId,
            topicId: download.topicId,
            lessonId: download.lessonPlanId,
            downloadResolution: downloadResolution,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            commonServiceClientContext,
            downloadedMetaData: download.downloadedMetaData,
            setCurrentDownload,
            lockedData: download.lockedData,
          });
        } else if (download.topicId && download.chapterId) {
          await fetchTopicContentOffline({
            user_info: new StudentLoginResponseType(user_info),
            subjectId: download.subjectId,
            classId: download.classId,
            sectionId: download.sectionId,
            chapterId: download.chapterId,
            topicId: download.topicId,
            downloadResolution: downloadResolution,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            commonServiceClientContext,
            downloadedMetaData: download.downloadedMetaData,
            setCurrentDownload,
            fetchChildrenData: true,
            lockedData: download.lockedData,
          });
        } else if (download.chapterId) {
          await fetchChapterContentOffline({
            user_info: new StudentLoginResponseType(user_info),
            subjectId: download.subjectId,
            classId: download.classId,
            sectionId: download.sectionId,
            chapterId: download.chapterId,
            downloadResolution: downloadResolution,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            commonServiceClientContext,
            downloadedMetaData: download.downloadedMetaData,
            setCurrentDownload,
            fetchChildrenData: true,
            lockedData: download.lockedData,
          });
        } else {
          await fetchChapterContentOffline({
            user_info: new StudentLoginResponseType(user_info),
            subjectId: download.subjectId,
            classId: download.classId,
            sectionId: download.sectionId,
            downloadResolution: downloadResolution,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            commonServiceClientContext,
            downloadedMetaData: download.downloadedMetaData,
            setCurrentDownload,
            fetchChildrenData: true,
            lockedData: download.lockedData,
          });
        }

        // Mark the download as processed
        processedIndices.add(i);
      }

      // Remove processed downloads from currentDownloads
      setCurrentDownloads((prevDownloads) =>
        prevDownloads.filter((_, index) => !processedIndices.has(index))
      );
      setProcessingQueue([]);
      setIsProcessingQueue(false);
    };

    if (isProcessingQueue) {
      processQueue();
    }
  }, [isProcessingQueue, processingQueue]);

  const [isOffline, setIsOffline] = useState<boolean>(false);

  const contextValue: IContextData = {
    currentDownload,
    setCurrentDownload,
    isOffline,
    setIsOffline,
    addDownload,
    isDownloading,
    isMigratingData,
    setIsMigratingData,
  };
  return <Provider value={contextValue}>{children}</Provider>;
};

export const useDownloadContext = () => {
  const context = useContext(downloadContext);
  if (!context) {
    throw new Error(
      'useDownloadContext must be used within a MyContextProvider'
    );
  }
  return context;
};
