// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.teacher.events.proto (package geneo.ai.teacher.events, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DifficultyLevelEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from message geneo.ai.teacher.events.InteractionButton
 */
export class InteractionButton extends Message<InteractionButton> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string context = 2;
   */
  context = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<InteractionButton>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.InteractionButton";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InteractionButton {
    return new InteractionButton().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InteractionButton {
    return new InteractionButton().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InteractionButton {
    return new InteractionButton().fromJsonString(jsonString, options);
  }

  static equals(a: InteractionButton | PlainMessage<InteractionButton> | undefined, b: InteractionButton | PlainMessage<InteractionButton> | undefined): boolean {
    return proto3.util.equals(InteractionButton, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.BookAction
 */
export class BookAction extends Message<BookAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.BookAction.BookActionType
   */
  BookActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.BookAction.BookSummary close = 2;
     */
    value: BookAction_BookSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 book_id = 3;
   */
  bookId = 0;

  /**
   * book session id
   *
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<BookAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.BookAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "BookActionType" },
    { no: 2, name: "close", kind: "message", T: BookAction_BookSummary, oneof: "BookActionType" },
    { no: 3, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookAction {
    return new BookAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookAction {
    return new BookAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookAction {
    return new BookAction().fromJsonString(jsonString, options);
  }

  static equals(a: BookAction | PlainMessage<BookAction> | undefined, b: BookAction | PlainMessage<BookAction> | undefined): boolean {
    return proto3.util.equals(BookAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.BookAction.BookSummary
 */
export class BookAction_BookSummary extends Message<BookAction_BookSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<BookAction_BookSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.BookAction.BookSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookAction_BookSummary {
    return new BookAction_BookSummary().fromJsonString(jsonString, options);
  }

  static equals(a: BookAction_BookSummary | PlainMessage<BookAction_BookSummary> | undefined, b: BookAction_BookSummary | PlainMessage<BookAction_BookSummary> | undefined): boolean {
    return proto3.util.equals(BookAction_BookSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ChapterAction
 */
export class ChapterAction extends Message<ChapterAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.ChapterAction.ChapterActionType
   */
  ChapterActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.ChapterAction.ChapterSummary close = 2;
     */
    value: ChapterAction_ChapterSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 chapter_id = 3;
   */
  chapterId = 0;

  /**
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<ChapterAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ChapterAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ChapterActionType" },
    { no: 2, name: "close", kind: "message", T: ChapterAction_ChapterSummary, oneof: "ChapterActionType" },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterAction {
    return new ChapterAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterAction {
    return new ChapterAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterAction {
    return new ChapterAction().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterAction | PlainMessage<ChapterAction> | undefined, b: ChapterAction | PlainMessage<ChapterAction> | undefined): boolean {
    return proto3.util.equals(ChapterAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ChapterAction.ChapterSummary
 */
export class ChapterAction_ChapterSummary extends Message<ChapterAction_ChapterSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ChapterAction_ChapterSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ChapterAction.ChapterSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterAction_ChapterSummary {
    return new ChapterAction_ChapterSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterAction_ChapterSummary | PlainMessage<ChapterAction_ChapterSummary> | undefined, b: ChapterAction_ChapterSummary | PlainMessage<ChapterAction_ChapterSummary> | undefined): boolean {
    return proto3.util.equals(ChapterAction_ChapterSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.TopicAction
 */
export class TopicAction extends Message<TopicAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.TopicAction.TopicActionType
   */
  TopicActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.TopicAction.TopicSummary close = 2;
     */
    value: TopicAction_TopicSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 topic_id = 3;
   */
  topicId = 0;

  /**
   * @generated from field: string action_id = 4;
   */
  actionId = "";

  constructor(data?: PartialMessage<TopicAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.TopicAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "TopicActionType" },
    { no: 2, name: "close", kind: "message", T: TopicAction_TopicSummary, oneof: "TopicActionType" },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicAction {
    return new TopicAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicAction {
    return new TopicAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicAction {
    return new TopicAction().fromJsonString(jsonString, options);
  }

  static equals(a: TopicAction | PlainMessage<TopicAction> | undefined, b: TopicAction | PlainMessage<TopicAction> | undefined): boolean {
    return proto3.util.equals(TopicAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.TopicAction.TopicSummary
 */
export class TopicAction_TopicSummary extends Message<TopicAction_TopicSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<TopicAction_TopicSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.TopicAction.TopicSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicAction_TopicSummary {
    return new TopicAction_TopicSummary().fromJsonString(jsonString, options);
  }

  static equals(a: TopicAction_TopicSummary | PlainMessage<TopicAction_TopicSummary> | undefined, b: TopicAction_TopicSummary | PlainMessage<TopicAction_TopicSummary> | undefined): boolean {
    return proto3.util.equals(TopicAction_TopicSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.LessonAction
 */
export class LessonAction extends Message<LessonAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.LessonAction.LessonPlanActionType
   */
  LessonPlanActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.LessonAction.LessonPlanSummary close = 2;
     */
    value: LessonAction_LessonPlanSummary;
    case: "close";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.LessonAction.LessonCreate create = 3;
     */
    value: LessonAction_LessonCreate;
    case: "create";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.LessonAction.ResourceUpdate update = 4;
     */
    value: LessonAction_ResourceUpdate;
    case: "update";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string lesson_id = 7;
   */
  lessonId = "";

  /**
   * @generated from field: string action_id = 8;
   */
  actionId = "";

  constructor(data?: PartialMessage<LessonAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.LessonAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "LessonPlanActionType" },
    { no: 2, name: "close", kind: "message", T: LessonAction_LessonPlanSummary, oneof: "LessonPlanActionType" },
    { no: 3, name: "create", kind: "message", T: LessonAction_LessonCreate, oneof: "LessonPlanActionType" },
    { no: 4, name: "update", kind: "message", T: LessonAction_ResourceUpdate, oneof: "LessonPlanActionType" },
    { no: 7, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction {
    return new LessonAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction {
    return new LessonAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction {
    return new LessonAction().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction | PlainMessage<LessonAction> | undefined, b: LessonAction | PlainMessage<LessonAction> | undefined): boolean {
    return proto3.util.equals(LessonAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.LessonAction.LessonPlanSummary
 */
export class LessonAction_LessonPlanSummary extends Message<LessonAction_LessonPlanSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<LessonAction_LessonPlanSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.LessonAction.LessonPlanSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction_LessonPlanSummary {
    return new LessonAction_LessonPlanSummary().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction_LessonPlanSummary | PlainMessage<LessonAction_LessonPlanSummary> | undefined, b: LessonAction_LessonPlanSummary | PlainMessage<LessonAction_LessonPlanSummary> | undefined): boolean {
    return proto3.util.equals(LessonAction_LessonPlanSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.LessonAction.LessonCreate
 */
export class LessonAction_LessonCreate extends Message<LessonAction_LessonCreate> {
  /**
   * @generated from field: repeated string resource_ids = 1;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<LessonAction_LessonCreate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.LessonAction.LessonCreate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction_LessonCreate {
    return new LessonAction_LessonCreate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction_LessonCreate {
    return new LessonAction_LessonCreate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction_LessonCreate {
    return new LessonAction_LessonCreate().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction_LessonCreate | PlainMessage<LessonAction_LessonCreate> | undefined, b: LessonAction_LessonCreate | PlainMessage<LessonAction_LessonCreate> | undefined): boolean {
    return proto3.util.equals(LessonAction_LessonCreate, a, b);
  }
}

/**
 * when add resorce and updating resource
 *
 * @generated from message geneo.ai.teacher.events.LessonAction.ResourceUpdate
 */
export class LessonAction_ResourceUpdate extends Message<LessonAction_ResourceUpdate> {
  /**
   * all ids and their postion is index
   *
   * @generated from field: repeated string resource_ids = 1;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: repeated string new_resource_ids = 2;
   */
  newResourceIds: string[] = [];

  constructor(data?: PartialMessage<LessonAction_ResourceUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.LessonAction.ResourceUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "new_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonAction_ResourceUpdate {
    return new LessonAction_ResourceUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonAction_ResourceUpdate {
    return new LessonAction_ResourceUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonAction_ResourceUpdate {
    return new LessonAction_ResourceUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: LessonAction_ResourceUpdate | PlainMessage<LessonAction_ResourceUpdate> | undefined, b: LessonAction_ResourceUpdate | PlainMessage<LessonAction_ResourceUpdate> | undefined): boolean {
    return proto3.util.equals(LessonAction_ResourceUpdate, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ResourcePageContentAction
 */
export class ResourcePageContentAction extends Message<ResourcePageContentAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.ResourcePageContentAction.ResourcePageContentActionType
   */
  ResourcePageContentActionType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.ResourcePageContentAction.ResourcePageContentSummary close = 2;
     */
    value: ResourcePageContentAction_ResourcePageContentSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 resource_page_id = 4;
   */
  resourcePageId = protoInt64.zero;

  /**
   * ContentEnum content_type = 6; // Type of content
   *
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<ResourcePageContentAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ResourcePageContentAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResourcePageContentActionType" },
    { no: 2, name: "close", kind: "message", T: ResourcePageContentAction_ResourcePageContentSummary, oneof: "ResourcePageContentActionType" },
    { no: 4, name: "resource_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction {
    return new ResourcePageContentAction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction | PlainMessage<ResourcePageContentAction> | undefined, b: ResourcePageContentAction | PlainMessage<ResourcePageContentAction> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ResourcePageContentAction.ResourcePageContentSummary
 */
export class ResourcePageContentAction_ResourcePageContentSummary extends Message<ResourcePageContentAction_ResourcePageContentSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ResourcePageContentAction_ResourcePageContentSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ResourcePageContentAction.ResourcePageContentSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentAction_ResourcePageContentSummary {
    return new ResourcePageContentAction_ResourcePageContentSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentAction_ResourcePageContentSummary | PlainMessage<ResourcePageContentAction_ResourcePageContentSummary> | undefined, b: ResourcePageContentAction_ResourcePageContentSummary | PlainMessage<ResourcePageContentAction_ResourcePageContentSummary> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentAction_ResourcePageContentSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ResourceContentAction
 */
export class ResourceContentAction extends Message<ResourceContentAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.ResourceContentAction.ResourceContentType
   */
  ResourceContentType: {
    /**
     * @generated from field: bool open = 1;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.ResourceContentAction.ResourceContentSummary close = 2;
     */
    value: ResourceContentAction_ResourceContentSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 resource_content_id = 4;
   */
  resourceContentId = protoInt64.zero;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<ResourceContentAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ResourceContentAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResourceContentType" },
    { no: 2, name: "close", kind: "message", T: ResourceContentAction_ResourceContentSummary, oneof: "ResourceContentType" },
    { no: 4, name: "resource_content_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentAction {
    return new ResourceContentAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentAction {
    return new ResourceContentAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentAction {
    return new ResourceContentAction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentAction | PlainMessage<ResourceContentAction> | undefined, b: ResourceContentAction | PlainMessage<ResourceContentAction> | undefined): boolean {
    return proto3.util.equals(ResourceContentAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.ResourceContentAction.ResourceContentSummary
 */
export class ResourceContentAction_ResourceContentSummary extends Message<ResourceContentAction_ResourceContentSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<ResourceContentAction_ResourceContentSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.ResourceContentAction.ResourceContentSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentAction_ResourceContentSummary {
    return new ResourceContentAction_ResourceContentSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentAction_ResourceContentSummary {
    return new ResourceContentAction_ResourceContentSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentAction_ResourceContentSummary {
    return new ResourceContentAction_ResourceContentSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentAction_ResourceContentSummary | PlainMessage<ResourceContentAction_ResourceContentSummary> | undefined, b: ResourceContentAction_ResourceContentSummary | PlainMessage<ResourceContentAction_ResourceContentSummary> | undefined): boolean {
    return proto3.util.equals(ResourceContentAction_ResourceContentSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.HomeworkAction
 */
export class HomeworkAction extends Message<HomeworkAction> {
  /**
   * @generated from oneof geneo.ai.teacher.events.HomeworkAction.HomeworkActionType
   */
  HomeworkActionType: {
    /**
     * @generated from field: geneo.ai.teacher.events.HomeworkAction.HomeworkCreate create = 1;
     */
    value: HomeworkAction_HomeworkCreate;
    case: "create";
  } | {
    /**
     * @generated from field: bool open = 2;
     */
    value: boolean;
    case: "open";
  } | {
    /**
     * @generated from field: bool view_homework = 3;
     */
    value: boolean;
    case: "viewHomework";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.HomeworkAction.HomeworkSummary close = 4;
     */
    value: HomeworkAction_HomeworkSummary;
    case: "close";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 homework_id = 10;
   */
  homeworkId = 0;

  /**
   * @generated from field: string action_id = 11;
   */
  actionId = "";

  constructor(data?: PartialMessage<HomeworkAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.HomeworkAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "create", kind: "message", T: HomeworkAction_HomeworkCreate, oneof: "HomeworkActionType" },
    { no: 2, name: "open", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "HomeworkActionType" },
    { no: 3, name: "view_homework", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "HomeworkActionType" },
    { no: 4, name: "close", kind: "message", T: HomeworkAction_HomeworkSummary, oneof: "HomeworkActionType" },
    { no: 10, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction {
    return new HomeworkAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction {
    return new HomeworkAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction {
    return new HomeworkAction().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction | PlainMessage<HomeworkAction> | undefined, b: HomeworkAction | PlainMessage<HomeworkAction> | undefined): boolean {
    return proto3.util.equals(HomeworkAction, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.HomeworkAction.QuestionInfo
 */
export class HomeworkAction_QuestionInfo extends Message<HomeworkAction_QuestionInfo> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * easy
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 2;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  constructor(data?: PartialMessage<HomeworkAction_QuestionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.HomeworkAction.QuestionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_QuestionInfo {
    return new HomeworkAction_QuestionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionInfo {
    return new HomeworkAction_QuestionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_QuestionInfo {
    return new HomeworkAction_QuestionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_QuestionInfo | PlainMessage<HomeworkAction_QuestionInfo> | undefined, b: HomeworkAction_QuestionInfo | PlainMessage<HomeworkAction_QuestionInfo> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_QuestionInfo, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.HomeworkAction.HomeworkCreate
 */
export class HomeworkAction_HomeworkCreate extends Message<HomeworkAction_HomeworkCreate> {
  /**
   * task id
   *
   * @generated from field: repeated geneo.ai.teacher.events.HomeworkAction.QuestionInfo question_info = 1;
   */
  questionInfo: HomeworkAction_QuestionInfo[] = [];

  /**
   * @generated from field: int32 estimate_time = 2;
   */
  estimateTime = 0;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: int32 no_of_questions = 4;
   */
  noOfQuestions = 0;

  /**
   * @generated from field: int32 marks = 5;
   */
  marks = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start_timestamp = 6;
   */
  startTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_timestamp = 7;
   */
  endTimestamp?: Timestamp;

  /**
   * @generated from field: bool allow_late_submission = 8;
   */
  allowLateSubmission = false;

  /**
   * @generated from field: optional google.protobuf.Timestamp daily_reminder_time = 17;
   */
  dailyReminderTime?: Timestamp;

  constructor(data?: PartialMessage<HomeworkAction_HomeworkCreate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.HomeworkAction.HomeworkCreate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_info", kind: "message", T: HomeworkAction_QuestionInfo, repeated: true },
    { no: 2, name: "estimate_time", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "start_timestamp", kind: "message", T: Timestamp },
    { no: 7, name: "end_timestamp", kind: "message", T: Timestamp },
    { no: 8, name: "allow_late_submission", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "daily_reminder_time", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_HomeworkCreate {
    return new HomeworkAction_HomeworkCreate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkCreate {
    return new HomeworkAction_HomeworkCreate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkCreate {
    return new HomeworkAction_HomeworkCreate().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_HomeworkCreate | PlainMessage<HomeworkAction_HomeworkCreate> | undefined, b: HomeworkAction_HomeworkCreate | PlainMessage<HomeworkAction_HomeworkCreate> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_HomeworkCreate, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.HomeworkAction.HomeworkSummary
 */
export class HomeworkAction_HomeworkSummary extends Message<HomeworkAction_HomeworkSummary> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<HomeworkAction_HomeworkSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.HomeworkAction.HomeworkSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAction_HomeworkSummary {
    return new HomeworkAction_HomeworkSummary().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAction_HomeworkSummary | PlainMessage<HomeworkAction_HomeworkSummary> | undefined, b: HomeworkAction_HomeworkSummary | PlainMessage<HomeworkAction_HomeworkSummary> | undefined): boolean {
    return proto3.util.equals(HomeworkAction_HomeworkSummary, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.Logout
 */
export class Logout extends Message<Logout> {
  /**
   * @generated from field: int32 timespent = 1;
   */
  timespent = 0;

  constructor(data?: PartialMessage<Logout>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.Logout";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timespent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Logout {
    return new Logout().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Logout {
    return new Logout().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Logout {
    return new Logout().fromJsonString(jsonString, options);
  }

  static equals(a: Logout | PlainMessage<Logout> | undefined, b: Logout | PlainMessage<Logout> | undefined): boolean {
    return proto3.util.equals(Logout, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.TeacherEvent
 */
export class TeacherEvent extends Message<TeacherEvent> {
  /**
   * @generated from oneof geneo.ai.teacher.events.TeacherEvent.EventType
   */
  EventType: {
    /**
     * @generated from field: bool login = 1;
     */
    value: boolean;
    case: "login";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.Logout logout = 2;
     */
    value: Logout;
    case: "logout";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.TopicAction topic_action = 4;
     */
    value: TopicAction;
    case: "topicAction";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.LessonAction lesson_action = 5;
     */
    value: LessonAction;
    case: "lessonAction";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.HomeworkAction homework_action = 6;
     */
    value: HomeworkAction;
    case: "homeworkAction";
  } | {
    /**
     * ResourceAction resource_action = 7;
     *
     * @generated from field: geneo.ai.teacher.events.ResourceContentAction content_action = 8;
     */
    value: ResourceContentAction;
    case: "contentAction";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.ChapterAction chapter_action = 10;
     */
    value: ChapterAction;
    case: "chapterAction";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.BookAction book_action = 11;
     */
    value: BookAction;
    case: "bookAction";
  } | {
    /**
     * @generated from field: geneo.ai.teacher.events.InteractionButton interaction_button = 35;
     */
    value: InteractionButton;
    case: "interactionButton";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 teacher_id = 102;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string session_id = 103;
   */
  sessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 104;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: string book_session_id = 105;
   */
  bookSessionId = "";

  /**
   * @generated from field: string chapter_session_id = 106;
   */
  chapterSessionId = "";

  /**
   * @generated from field: string active_session_id = 107;
   */
  activeSessionId = "";

  /**
   * @generated from field: string login_session_id = 108;
   */
  loginSessionId = "";

  /**
   * @generated from field: string lesson_session_id = 109;
   */
  lessonSessionId = "";

  /**
   * @generated from field: string resource_session_id = 110;
   */
  resourceSessionId = "";

  /**
   * event number starts from 0 for each session
   *
   * @generated from field: int32 event_index = 113;
   */
  eventIndex = 0;

  /**
   * will be stored from action id
   *
   * @generated from field: string action_id = 114;
   */
  actionId = "";

  /**
   * @generated from field: string event_type = 115;
   */
  eventType = "";

  /**
   * @generated from field: string action_type = 116;
   */
  actionType = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 117;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 118;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 119;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 120;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional bool is_offline = 121;
   */
  isOffline?: boolean;

  /**
   * @generated from field: optional google.protobuf.Timestamp server_timestamp = 122;
   */
  serverTimestamp?: Timestamp;

  constructor(data?: PartialMessage<TeacherEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.TeacherEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "login", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "EventType" },
    { no: 2, name: "logout", kind: "message", T: Logout, oneof: "EventType" },
    { no: 4, name: "topic_action", kind: "message", T: TopicAction, oneof: "EventType" },
    { no: 5, name: "lesson_action", kind: "message", T: LessonAction, oneof: "EventType" },
    { no: 6, name: "homework_action", kind: "message", T: HomeworkAction, oneof: "EventType" },
    { no: 8, name: "content_action", kind: "message", T: ResourceContentAction, oneof: "EventType" },
    { no: 10, name: "chapter_action", kind: "message", T: ChapterAction, oneof: "EventType" },
    { no: 11, name: "book_action", kind: "message", T: BookAction, oneof: "EventType" },
    { no: 35, name: "interaction_button", kind: "message", T: InteractionButton, oneof: "EventType" },
    { no: 102, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 103, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 104, name: "timestamp", kind: "message", T: Timestamp },
    { no: 105, name: "book_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 106, name: "chapter_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 107, name: "active_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 108, name: "login_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 109, name: "lesson_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 110, name: "resource_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 113, name: "event_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 114, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 115, name: "event_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 116, name: "action_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 117, name: "created_on", kind: "message", T: Timestamp },
    { no: 118, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 119, name: "modified_on", kind: "message", T: Timestamp },
    { no: 120, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 121, name: "is_offline", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 122, name: "server_timestamp", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherEvent {
    return new TeacherEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherEvent {
    return new TeacherEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherEvent {
    return new TeacherEvent().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherEvent | PlainMessage<TeacherEvent> | undefined, b: TeacherEvent | PlainMessage<TeacherEvent> | undefined): boolean {
    return proto3.util.equals(TeacherEvent, a, b);
  }
}

/**
 * @generated from message geneo.ai.teacher.events.TeacherEventResponse
 */
export class TeacherEventResponse extends Message<TeacherEventResponse> {
  /**
   * @generated from field: bool is_successful = 1;
   */
  isSuccessful = false;

  /**
   * @generated from field: repeated string error_messages = 2;
   */
  errorMessages: string[] = [];

  /**
   * @generated from field: string stack_trace = 3;
   */
  stackTrace = "";

  /**
   * @generated from field: string response_type = 5;
   */
  responseType = "";

  /**
   * @generated from field: int64 student_id = 6;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string session_id = 7;
   */
  sessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 8;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: bool is_active = 9;
   */
  isActive = false;

  constructor(data?: PartialMessage<TeacherEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.teacher.events.TeacherEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_successful", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "error_messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "stack_trace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "response_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "timestamp", kind: "message", T: Timestamp },
    { no: 9, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherEventResponse {
    return new TeacherEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherEventResponse {
    return new TeacherEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherEventResponse {
    return new TeacherEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherEventResponse | PlainMessage<TeacherEventResponse> | undefined, b: TeacherEventResponse | PlainMessage<TeacherEventResponse> | undefined): boolean {
    return proto3.util.equals(TeacherEventResponse, a, b);
  }
}

