import { Box } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import { IStyles } from 'libs/shared-ui/src/commonUtils/styleUtils';
interface Iprops {
  status: 'on' | 'off';
}
export const StatusRadioMark = (props: Iprops) => {
  const { status } = props;
  const styles: IStyles = {
    connected: {
      width: { xs: pxToRem(20), md: pxTovW(20) },
      height: { xs: pxToRem(20), md: pxTovW(20) },
      borderRadius: '50%',
      border: '2px solid #84C7FF',
      background: '#007CDC'
    },
    disconnected: {
      width: { xs: pxToRem(20), md: pxTovW(20) },
      height: { xs: pxToRem(20), md: pxTovW(20) },
      borderRadius: '50%',
      border: '2px solid #949292',
      background: '#D9D9D9'
    },
  }
  return (
    <>{status === 'on' ?
      <Box sx={styles.connected}>
      </Box> :
      <Box sx={styles.disconnected}>
      </Box >
    }
    </>
  )
}

