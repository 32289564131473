import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  LessonNodeLock,
  SchoolContentModuleNodeLock,
} from '@protos/school_management/school.db_pb';

export interface LockStatusInfo {
  book: Record<
    number,
    Pick<SchoolContentModuleNodeLock, 'lockStatus' | 'title'>
  >;
  chapter: Record<
    number,
    Pick<
      SchoolContentModuleNodeLock,
      'title' | 'lockStatus' | 'lockedResourceIds'
    >
  >;
  topic: Record<
    number,
    Pick<
      SchoolContentModuleNodeLock,
      'title' | 'lockStatus' | 'lockedResourceIds'
    >
  >;
  lesson: Record<
    string,
    Pick<
      LessonNodeLock,
      'lockStatus' | 'lockedResourceIds' | 'lockedResourceIds'
    >
  >;
}

export const categorizeLockInfo = (lockData?: ContentLockModuleData) => {
  const moduleUpdatedLockInfo = lockData?.moduleUpdatedLockInfo;
  const lockInfo: LockStatusInfo = {
    book: {},
    chapter: {},
    topic: {},
    lesson: {},
  };
  if (moduleUpdatedLockInfo) {
    recursiveUpdateLockInfo(lockInfo, [moduleUpdatedLockInfo]);
  }
  return lockInfo;
};

const recursiveUpdateLockInfo = (
  lockInfo: LockStatusInfo,
  moduleUpdatedLockInfoArray?: SchoolContentModuleNodeLock[]
) => {
  if (!moduleUpdatedLockInfoArray) {
    return;
  }
  for (let i = 0; i < moduleUpdatedLockInfoArray.length; i++) {
    const moduleUpdatedLockInfo = moduleUpdatedLockInfoArray[i];
    if (
      moduleUpdatedLockInfo?.category ===
      Module_ModuleCategoryEnum.MODULE_CATEGORY_BOOK
    ) {
      lockInfo.book[moduleUpdatedLockInfo?.moduleId] = {
        title: moduleUpdatedLockInfo?.title,
        lockStatus: moduleUpdatedLockInfo?.lockStatus,
      };
      recursiveUpdateLockInfo(lockInfo, moduleUpdatedLockInfo?.children);
    } else if (
      moduleUpdatedLockInfo?.category ===
      Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER
    ) {
      lockInfo.chapter[moduleUpdatedLockInfo?.moduleId] = {
        title: moduleUpdatedLockInfo?.title,
        lockStatus: moduleUpdatedLockInfo?.lockStatus,
        lockedResourceIds: moduleUpdatedLockInfo?.lockedResourceIds,
      };
      recursiveUpdateLockInfo(lockInfo, moduleUpdatedLockInfo?.children);
    } else if (
      moduleUpdatedLockInfo?.category ===
      Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC
    ) {
      lockInfo.topic[moduleUpdatedLockInfo?.moduleId] = {
        title: moduleUpdatedLockInfo?.title,
        lockStatus: moduleUpdatedLockInfo?.lockStatus,
        lockedResourceIds: moduleUpdatedLockInfo?.lockedResourceIds,
      };
      moduleUpdatedLockInfo?.lessonsLockInfo?.forEach(
        (val) =>
          (lockInfo.lesson[val?.lessonId] = {
            lockStatus: val?.lockStatus,
            lockedResourceIds: val?.lockedResourceIds,
          })
      );
    }
  }
};
