import { Box, SxProps, Typography, useMediaQuery } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ReactNode } from 'react';
import QuestionIcon from '../../../assets/icons/Questions.png';
import lessonIcon from '../../../assets/icons/lesson.png';
import rightArrow from '../../../assets/icons/rightArrow.png';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { theme } from '../../../theme/themeProvider';
import TertiaryButton from '../../elements/TertiaryButton';
const styles: IStyles = {
  root: {
    height: { xs: pxToRem(72), md: 'max-content' },
    // padding: { xs: '1.9rem 1.4rem 2.2rem 1rem' },
    padding: {
      xs: `${pxToRem(14)} ${pxToRem(32)}`,
      md: `${pxTovW(14)} ${pxTovW(32)}`,
    },
    boxSizing: 'border-box',
    width: { xs: '100%', md: '100%' },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    // gap: { xs: pxToRem(11), md: pxTovW(20) },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(11) },
    width: { md: '86%' },
  },
};
interface IProps {
  viewAllClickHandler: () => void;
  title: string;
  lessonPlanCount?: number | undefined;
  homeWorkCount?: number | undefined;
  questionsCount: number | undefined;
  profileType?: ProfileRolesEnum;
  rootStyle?: SxProps;
  offlineSubText?: string;
  children?: ReactNode;
  showViewAllOnMobile?: boolean;
  isLocked?: boolean;
}
export const TopicCard = (props: IProps) => {
  const {
    title,
    viewAllClickHandler,
    lessonPlanCount,
    homeWorkCount,
    questionsCount,
    profileType,
    rootStyle,
    offlineSubText,
    children,
    showViewAllOnMobile,
    isLocked,
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  return (
    <Box
      sx={{
        ...rootSx,
        opacity: isLocked ? 0.5 : 1,
      }}
      onClick={viewAllClickHandler}
    >
      <Box sx={styles.content}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontFamily="Lato"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {title}
        </Typography>

        {offlineSubText ? (
          <Typography variant="h5" fontWeight="regular" color="#333333">
            {offlineSubText}
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: pxToRem(9) },
              // backgroundColor: 'red',
            }}
          >
            {!!lessonPlanCount && (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: pxToRem(5) },
                }}
              >
                <img
                  alt="topic"
                  src={lessonIcon}
                  style={{ height: pxToRem(17), width: pxToRem(14) }}
                />
                <Typography
                  fontSize={{
                    xs: pxToRem(12),
                    md: pxToRem(12),
                    lg: pxTovW(16),
                  }}
                  fontFamily="Lato"
                >
                  {lessonPlanCount}{' '}
                  {profileType === ProfileRolesEnum.PROFILE_ROLE_STUDENT
                    ? 'Lesson'
                    : 'Lesson Plan'}
                  {lessonPlanCount > 1 ? 's' : ''}
                </Typography>
              </Box>
            )}

            {!!homeWorkCount && (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: pxToRem(5) },
                }}
              >
                <img
                  alt="topic"
                  src={lessonIcon}
                  style={{ height: pxToRem(17), width: pxToRem(14) }}
                />
                <Typography
                  fontSize={{
                    xs: pxToRem(12),
                    md: pxToRem(12),
                    lg: pxTovW(16),
                  }}
                  fontFamily="Lato"
                >
                  {homeWorkCount}{' '}
                  {profileType === ProfileRolesEnum.PROFILE_ROLE_TEACHER
                    ? 'Homework'
                    : 'Homework'}
                  {homeWorkCount > 1 ? 's' : ''}
                </Typography>
              </Box>
            )}

            {!!questionsCount && (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: pxToRem(5) },
                  // backgroundColor: 'blue',
                }}
              >
                <img
                  alt="topic"
                  src={QuestionIcon}
                  style={{
                    height: pxToRem(17),
                    width: pxToRem(14),
                  }}
                />
                <Typography
                  fontSize={{
                    xs: pxToRem(12),
                    md: pxToRem(12),
                    lg: pxTovW(16),
                  }}
                  fontFamily="Lato"
                >
                  {questionsCount} Questions
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {isMobile && !showViewAllOnMobile ? (
        !children && (
          <img
            alt="chapter"
            src={rightArrow}
            onClick={viewAllClickHandler}
            style={{
              height: pxToRem(12.5),
              width: pxToRem(7),
              marginLeft: 'auto',
              marginRight: pxToRem(10),
            }}
          ></img>
        )
      ) : (
        <Typography
          variant="smallText"
          fontWeight="bold"
          sx={{ marginLeft: 'auto' }}
        >
          {/* <TertiaryButton onClick={viewAllClickHandler}>View</TertiaryButton> */}
          <TertiaryButton sx={{ height: { xs: pxToRem(27), lg: pxTovW(35) } }}>
            View
          </TertiaryButton>
        </Typography>
      )}
      {children}
    </Box>
  );
};
