import { Timestamp } from '@bufbuild/protobuf';
import { PerformanceEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IPerformanceCloseArgs,
  IPerformanceEventArgs,
  IPerformanceOpenArgs,
} from './inputArgs';
import {
  PerformanceCloseArgsValidator,
  PerformanceEventArgsValidator,
  PerformanceOpenArgsValidator,
} from './inputArgsValidator';
import { PERFORMANCE_KEY } from './storageKeys';
import {
  checkNgetPerformanceEventData,
  clearEventData,
  getPerformanceEventData,
  getPerformanceSessionData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  timeDifference,
} from './utils';

export function performanceStorageKey(performanceId: string) {
  return `${PERFORMANCE_KEY}!${performanceId}`;
}

export function performanceOpen(
  args: IPerformanceOpenArgs
): PerformanceEventData {
  const { error } = PerformanceOpenArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { openTimestamp, performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  const performanceData = getPerformanceEventData(storageKey);
  if (performanceData !== null) {
    throw new Error(
      `Performance Data has to be null but ${JSON.stringify(
        performanceData
      )} is present"`
    );
  } else {
    const eventData = new PerformanceEventData({
      performanceId,
      actionId: uuidv4(),
      openTimestamp: Timestamp.fromDate(openTimestamp),
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getPerformanceOpenEvent(
  args: IPerformanceEventArgs
): StudentEvent {
  const { error } = PerformanceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  const eventData = checkNgetPerformanceEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  const performanceSessionData = getPerformanceSessionData();
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'performanceAction',
      value: {
        actionId: eventData.actionId,
        performanceId,
        PerformanceActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function performanceClose(
  args: IPerformanceCloseArgs
): PerformanceEventData {
  const { error } = PerformanceCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  const performanceData = getPerformanceEventData(storageKey);
  if (!performanceData) {
    throw new Error('performanceData can not be undefined');
  }

  if (performanceData.performanceId !== performanceId) {
    throw new Error(
      `Performance Id in ${JSON.stringify(
        performanceData
      )} can't be different from provided "${performanceId}"`
    );
  } else {
    performanceData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, performanceData);
    return performanceData;
  }
}

export function getPerformanceCloseEvent(
  args: IPerformanceEventArgs
): StudentEvent {
  const { error } = PerformanceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  const eventData = checkNgetPerformanceEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const performanceSessionData = getPerformanceSessionData();

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'performanceAction',
      value: {
        actionId: eventData.actionId,
        performanceId,
        PerformanceActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearPerformanceData(args: IPerformanceEventArgs) {
  const { error } = PerformanceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  clearEventData(storageKey);
}

export function isPerformanceOpen(args: IPerformanceEventArgs): boolean {
  const { error } = PerformanceEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { performanceId } = args;
  const storageKey = performanceStorageKey(performanceId);
  return hasEventData(storageKey);
}
