import { ImageWrapper, theme } from '@geneo2-web/shared-ui';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Button,
  Checkbox,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SubmissionType } from '@protos/learning_management/lms.db_pb';
import { StudentPerformanceInfo } from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { ScoreProgressBar } from '../ScoreProgressBar';

const styles: IStyles = {
  root: {
    width: { xs: '90vw', md: pxTovW(510), lg: pxTovW(360) },
    // backgroundColor: 'red',
    height: { xs: pxToRem(56), md: pxToRem(45), lg: pxTovW(80) },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headImage: {
    width: { xs: pxToRem(60), md: pxTovW(80) },
    height: { xs: pxToRem(56), md: pxTovW(80) },
    borderRadius: pxToRem(15),
  },
  marksText: {
    fontSize: { xs: pxToRem(16), md: pxTovW(18) },
    '& span': {
      fontWeight: '600',
      fontSize: { xs: pxToRem(18), md: pxTovW(21) },
    },
  },
  marksButton: {
    p: 0,
    m: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'max-content',
  },
};
interface ISubmissionProps {
  studentData?: StudentPerformanceInfo;
  marks?: number;
  checkedValue?: boolean;
}
interface IProps {
  imageUrl?: string;
  path?: string;
  parentFolder?: 'icons' | 'illustrations' | 'images' | 'tempAssets';
  studentName: string;
  score: number;
  submissionType?: SubmissionType;
  submission?: boolean;
  marks?: number;
  maxMarks?: number;
  checkBoxClickHandler?: (props: ISubmissionProps) => Promise<void>;
  textClickHandler?: (studentInfo: StudentPerformanceInfo) => void;
  withoutArrow?: boolean;
  studentPerformanceInfo?: StudentPerformanceInfo;
}

export const StudentScoreCard = ({
  imageUrl,
  studentName,
  score,
  submissionType,
  submission,
  marks,
  maxMarks,
  checkBoxClickHandler,
  textClickHandler,
  withoutArrow,
  studentPerformanceInfo,
}: IProps) => {
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const renderText = () => {
    if (submissionType === SubmissionType.SUBMISSION_OFFLINE) {
      if (maxMarks) {
        return (
          <Button
            sx={styles.marksButton}
            onClick={() => {
              if (studentPerformanceInfo && textClickHandler) {
                textClickHandler(studentPerformanceInfo);
              }
            }}
          >
            <Typography color="primary" sx={styles.marksText}>
              <span>{marks ?? 0}</span> / {maxMarks} marks
            </Typography>
          </Button>
        );
      }
      return null;
    } else if (submissionType === SubmissionType.SUBMISSION_ONLINE) {
      if (maxMarks === undefined) {
        return (
          <Typography
            variant="h4"
            color={submission ? 'primary' : '#828282'}
            sx={styles.marksText}
          >
            {submission ? 'Submitted' : 'No submission'}
          </Typography>
        );
      } else {
        if (!submission) {
          return (
            <Typography variant="h4" color="#828282" sx={styles.marksText}>
              No submission
            </Typography>
          );
        } else {
          return studentPerformanceInfo?.isEvaluated === true ? (
            <Typography variant="h4" color="primary" sx={styles.marksText}>
              <span>{marks || 0}</span> / {maxMarks} marks
            </Typography>
          ) : (
            <Typography variant="h4" color="primary" sx={styles.marksText}>
              Assign Marks
            </Typography>
          );
        }
      }
    } else {
      return <ScoreProgressBar variant={isIpad ? 'lg' : 'md'} score={score} />;
    }
  };

  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(20), md: pxTovW(19) },
          width: '100%',
        }}
      >
        <ImageWrapper
          name="student1"
          type="png"
          parentFolder="tempAssets"
          path={imageUrl}
          styles={styles.headImage}
        />

        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: { xs: pxToRem(10), md: pxTovW(16) },
            width: { xs: '50vw', md: '95%', lg: '80%' },
            marginY: 'auto',
          }}
        >
          <Box
            sx={{ display: 'flex', gap: { xs: pxToRem(6), md: pxTovW(10) } }}
          >
            <Tooltip
              title={studentName}
              placement="bottom-start"
              disableHoverListener={!toolTipDisplay(studentName)}
              disableFocusListener={!toolTipDisplay(studentName)}
            >
              <Typography
                variant="h3"
                fontWeight="medium"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  width: { lg: pxTovW(150) },
                }}
              >
                {studentName}
              </Typography>
            </Tooltip>
            {submissionType === SubmissionType.SUBMISSION_ONLINE && submission && (
              <CheckCircleIcon
                sx={{
                  width: { xs: pxToRem(16), md: pxTovW(20) },
                  height: { xs: pxToRem(16), md: pxTovW(20) },
                  alignSelf: 'center',
                }}
                color="success"
              />
            )}
          </Box>
          {renderText()}
        </Box>
      </Box>
      <Box sx={{ marginLeft: { md: pxTovW(26) } }}>
        {submissionType === SubmissionType.SUBMISSION_OFFLINE ? (
          <Box
          // onClick={() =>
          //   checkBoxClickHandler &&
          //   checkBoxClickHandler({
          //     studentData: maxMarks ? undefined : studentPerformanceInfo,
          //   })
          // }
          >
            <Checkbox
              checked={!!submission}
              onChange={(event) => {
                console.log(
                  'check1 ',
                  event.target.checked,
                  studentPerformanceInfo,
                  maxMarks
                );
                checkBoxClickHandler &&
                  checkBoxClickHandler({
                    checkedValue: event.target.checked,
                    studentData: studentPerformanceInfo,
                  });
              }}
              icon={<RadioButtonUncheckedIcon sx={{ color: '#02FC73' }} />}
              checkedIcon={<CheckCircleIcon color="success" />}
            />
          </Box>
        ) : !withoutArrow ? (
          <ArrowForwardIosIcon
            sx={{
              fontSize: pxToRem(12),
              fontWeight: 'bold',
              // marginBottom: pxToRem(10),
              color: '#828282',
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const toolTipDisplay = (name: string) => {
  if (name.includes(' ')) {
    if (name.length > 20) return true;
    else return false;
  } else {
    if (name.length > 10) return true;
    else return false;
  }
};
