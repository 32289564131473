import { getLocalStorage } from '@geneo2-web/shared-ui';
import { useMemo } from 'react';

export const useAuthentication = () => {
  const isAuthenticated = useMemo(() => {
    //check token in localStorage
    let auth = getLocalStorage('auth');
    if (auth === 'true' || auth === 'false') {
      auth = JSON.parse(auth);
    }
    return auth;
  }, []);

  return isAuthenticated;
};
