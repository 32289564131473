import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsCommonAPIServiceV2 } from '@protos/learning_management/lms.common.apis_connect';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

export const LmsCommonAPIServiceV2Client = createPromiseClient(
  LmsCommonAPIServiceV2,
  lmsTransport
);

export const useLmsCommonAPIServiceV2ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsCommonAPIServiceV2,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
