import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { UmsSelfRegistrationAPIServiceV1 } from '@protos/user_management/ums.self_registration.apis_connect';
import {
  ResponseStatusCodeFunction,
  UmsTransportWithLogoutHandler,
  umsTransport,
} from './transports';

export const UmsSelfRegistrationAPIServiceV1Client = createPromiseClient(
  UmsSelfRegistrationAPIServiceV1,
  umsTransport
);

export const useUmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    UmsSelfRegistrationAPIServiceV1,
    UmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
