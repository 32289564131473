import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { UMSLoginAPIServiceV1 } from '@protos/user_management/ums.login.apis_connect';
import { ResponseStatusCodeFunction, UmsTransportWithLogoutHandler, umsTransport } from './transports';

export const UMSLoginAPIServiceV1Client = createPromiseClient(
  UMSLoginAPIServiceV1,
  umsTransport
);

export const useUMSLoginAPIServiceV1ClientWithLStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  UMSLoginAPIServiceV1,
  UmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
