import { StudentLoginResponseType, TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { Buffer } from 'buffer';
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { getKeyFromRawKey } from './cacheFunctions';
import { createHash } from 'crypto';

export interface OfflineUser {
  username: string,
  email?: string,
  phone?: string,
  passwordHash: string,
  userId: string
}

// Create a Dexie database instance
class OfflineUserDatabse extends Dexie {
  offlineUsers: Dexie.Table<OfflineUser, string>;
  offlineUsersV2: Dexie.Table<OfflineUser, string>;

  constructor() {
    super('OfflineUserDatabse');

    // Define tables
    this.version(1).stores({
      offlineUsers: '&[username+email+phone],username,userId',
    });

    this.version(2).stores({
      offlineUsersV2: '&username,userId,[username+passwordHash],[email+passwordHash],[phone+passwordHash]',
    }).upgrade(async (transaction) => {
      console.log('Running migration from version 1 to version 2');
      const oldTable = transaction.table('offlineUsers');
      const newTable = transaction.table('offlineUsersV2');

      const oldUsers = await oldTable.toArray();
      console.log(`Found ${oldUsers.length} users in version 1`);

      for (const user of oldUsers) {
        const existingUser = await newTable.get(user.username);
        if (existingUser) {
          await newTable.update(user.username, user);
          console.log(`Updated existing user: ${user.username}`);
        } else {
          await newTable.add(user);
          console.log(`Added new user: ${user.username}`);
        }
      }

      // Clear old version data
      await oldTable.clear();
      console.log('Cleared old version 1 data');
    });

    // Access the offlineUsers table
    this.offlineUsers = this.table('offlineUsers');
    this.offlineUsersV2 = this.table('offlineUsersV2');
  }
}

// Instantiate the database
const db = new OfflineUserDatabse();

// Example of using dexie-react-hooks to query the offlineUsers table
function useOfflineUsers() {
  return useLiveQuery(() => {
    return db.offlineUsersV2.toArray();
  }, []);
}

export async function addOfflineUserRequest(userInfo: TeacherLoginResponseType | StudentLoginResponseType, passwordHash: string) {
  try {
    const userId = userInfo instanceof TeacherLoginResponseType ? userInfo.teacherProfileId.toString() : userInfo instanceof StudentLoginResponseType ? userInfo.studentProfileId.toString() : "";
    await storeUserInfoInCache(userInfo, passwordHash);
    await db.transaction('rw', db.offlineUsersV2, async function () {
      const request = {
        username: userInfo.userName,
        email: userInfo.emailVerify === 1 ? userInfo.email : '',
        phone: userInfo.phoneVerify === 1 ? userInfo.phoneNumber : '',
        passwordHash: passwordHash,
        userId: userId
      }
      await db.transaction('rw', db.offlineUsersV2, async function () {
        await db.offlineUsersV2.put(request);
      })
    });

    // console.log('Data added successfully:', request);
  } catch (error) {
    console.error('Error adding data:', error);
  }
}
export async function findOfflineUserRequestByParamsDexie(
  searchParams: Partial<OfflineUser>
) {
  try {
    const result = await db.offlineUsersV2.where(searchParams).toArray();
    if (result && result.length > 0) {
      const user = result[0];
      return user;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Error finding data: ' + error);
  }
}

export async function findOfflineUserRequestByParamsFromCache(
  searchParams: Partial<OfflineUser>
) {
  try {
    const result = await db.offlineUsersV2.where(searchParams).toArray();
    if (result && result.length > 0) {
      const user = result[0];
      const cachedUser = await getUserInfoFromCache(user.userId, user.passwordHash)
      return cachedUser; // Return the first matching entry
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error finding data: ' + error);
    return null;
  }
}

export async function storeUserInfoInCache(userInfo: TeacherLoginResponseType | StudentLoginResponseType, passwordHash: string) {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);
  const userId = userInfo instanceof TeacherLoginResponseType ? userInfo.teacherProfileId.toString() : userInfo instanceof StudentLoginResponseType ? userInfo.studentProfileId.toString() : undefined;

  // Create a synthetic request and response pair
  const request = new Request(`/user-info/${userId}`);
  const response = new Response(userInfo.toJsonString(), {
    headers: {
      'Content-Type': 'text/plain'
    }
  });
  const arrayBuffer = await response.arrayBuffer();
  const { encryptedData, iv } = await encryptOfflineUserData(passwordHash, arrayBuffer);

  const encryptedResponse = new Response(encryptedData, {
    headers: {
      'X-Cache-IV': iv,
      'X-Content-Encoding': 'base64',
      'Content-Type': 'application/octet-stream',
    },
  });

  await cache.put(request, encryptedResponse);
  console.log('User info stored in cache:', userId);
}

export async function getUserInfoFromCache(userId: string, passwordHash: string) {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);
  const cachedResponse = await cache.match(`/user-info/${userId}`);

  if (cachedResponse) {
    const base64Iv = cachedResponse.headers.get('X-Cache-IV') || '';
    const encryptedText = await cachedResponse.text();
    const decryptedData = await decryptOfflineUserData(encryptedText, base64Iv, passwordHash);
    const userInfo = Buffer.from(decryptedData).toString();
    console.log('userInfo retrieved from cache:', userInfo);
    return userInfo;
  } else {
    console.log('No User Info found in cache.');
    return null;
  }
}

export async function deleteUserInfoFromCache(userId: string) {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);
  const success = await cache.delete(`/user-info/${userId}`);

  if (success) {
    console.log('User Info deleted from cache.');
  } else {
    console.log('User Info not found in cache.');
  }
}


async function encryptOfflineUserData(password: string, data: ArrayBuffer) {
  const keyBuffer = await getKeyFromRawKey(password);
  // Import the key to be used with AES-CBC
  const key = await window.crypto.subtle.importKey(
    'raw', // raw format of the key - as a sequence of bytes
    keyBuffer,
    { name: 'AES-CBC' }, // Algorithm details
    false, // whether the key is extractable (i.e., can be exported)
    ['encrypt', 'decrypt'] // can be used to encrypt and decrypt
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(16));
  const algorithm = { name: 'AES-CBC', iv };
  const encryptedData = await window.crypto.subtle.encrypt(algorithm, key, data);

  // Using Buffer to convert encryptedData and iv to base64 strings
  const encryptedDataBase64 = Buffer.from(encryptedData).toString('base64');
  const ivBase64 = Buffer.from(iv).toString('base64');

  return {
    encryptedData: encryptedDataBase64,
    iv: ivBase64
  };
}

async function decryptOfflineUserData(encryptedDataBase64: string, base64Iv: string, passwordHash: string) {
  const keyBuffer = await getKeyFromRawKey(passwordHash);

  // Import the key to be used with AES-CBC
  const key = await window.crypto.subtle.importKey(
    'raw', // raw format of the key - as a sequence of bytes
    keyBuffer,
    { name: 'AES-CBC' }, // Algorithm details
    false, // whether the key is extractable (i.e., can be exported)
    ['encrypt', 'decrypt'] // can be used to encrypt and decrypt
  );
  const iv = Buffer.from(base64Iv, 'base64');
  const encryptedData = Buffer.from(encryptedDataBase64, 'base64');

  const algorithm = { name: 'AES-CBC', iv };
  const decryptedData = await window.crypto.subtle.decrypt(
    algorithm,
    key,
    encryptedData.buffer.slice(
      encryptedData.byteOffset,
      encryptedData.byteOffset + encryptedData.byteLength
    )
  );
  return decryptedData;
}


export const updateUserDetailsByUsername = async (user?: TeacherLoginResponseType | StudentLoginResponseType) => {
  if (!user) {
    return;
  }
  const username = user.userName;
  const userFound = await findOfflineUserRequestByParamsDexie({
    username,
  });
  if (userFound) {
    await addOfflineUserRequest(
      user,
      userFound.passwordHash
    );
  }
}

export const generatePasswordHash = (password: string) => {
  const passwordString = `${password}|${password
    .split('')
    .reverse()
    .join('')}`;
  const passwordHash = createHash('md5').update(passwordString).digest('hex');
  return passwordHash;
}
