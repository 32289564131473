import {
  IStyles,
  deserify,
  getLocalStorage,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  theme,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import { Box, Grid, Slide, Typography, useMediaQuery } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  LOGIN,
  RESET_PASSWORD,
} from '../../../routeHandling/RoutesNomenclature';
import { resetAuthState, setUserInfo } from '../reducer/auth.slice';

const styles: IStyles = {
  root: {
    // height: '100vh',
    // display: 'flex',
    // width: '100vw',
    // boxSizing: 'border-box',
    // flexDirection: 'column',
    // justifyContent: { xs: 'start' },
    // alignItems: 'center',
    // gap: { xs: pxToRem(20), md: pxTovW(58) },
    // // backgroundColor: 'red',
    // p: {
    //   xs: pxToRem(10),
    //   md: `${pxTovW(128)}  ${pxTovW(0)} `,
    // },
    padding: { xs: pxToRem(20), md: `${pxTovW(128)} ${pxTovW(485)}` },
    boxSizing: 'border-box',
  },
  heading: {
    textAlign: 'center',
  },

  profilesContainer: {
    boxSizing: 'border-box',
    mt: { xs: pxToRem(38), md: pxTovW(38) },
  },
  profileBox: {
    mb: { xs: pxToRem(20), md: pxTovW(30) },
  },
  profileCircle: {
    width: { xs: pxToRem(200), md: pxTovW(200) },
    height: { xs: pxToRem(200), md: pxTovW(200) },
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: {
      xs: `rgba(0, 0, 0, 0.35) 0px ${pxToRem(5)} ${pxToRem(15)}`,
      md: `rgba(0, 0, 0, 0.35) 0px ${pxTovW(5)} ${pxTovW(15)}`,
    },
    cursor: 'pointer',
    mb: { xs: pxToRem(15), md: pxTovW(21) },
  },
  profileColorCircle: {
    width: { xs: pxToRem(180), md: pxTovW(180) },
    height: { xs: pxToRem(180), md: pxTovW(180) },
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: pxToRem(50), md: pxTovW(90) },
    color: 'common.white',
    textShadow: {
      xs: `${pxToRem(2)} ${pxToRem(2)} #333333`,
      md: `${pxTovW(2)} ${pxTovW(2)} #333333`,
    },
  },
};
export const ChooseProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { selectedFunction, setSelectedFunction } = useGlobalContext();
  const userId = deserify(getLocalStorage('userId'));
  const { multiple_profiles } = deserify(useAppSelector((state) => state.auth));
  const { password_verfication } = deserify(
    useAppSelector((state) => state.auth)
  );
  const { permissionGranted, requestPermission } =
    useNotificationPermission('student');
  const backClick = () => {
    if (!getLocalStorage('auth')) {
      dispatch(resetAuthState());
      navigate(LOGIN);
    }
  };
  useEffect(() => {
    setSelectedFunction(() => backClick);

    return () => {
      setSelectedFunction(null);
    };
  }, []);
  const handleProfileSelection = async (elem: StudentLoginResponseType) => {
    dispatch(setUserInfo(elem));
    if (password_verfication) {
      navigate(RESET_PASSWORD);
    } else {
      const accessKey = elem.studentProfileId
        ? 'student-' + elem.studentProfileId.toString()
        : 'geneo';
      await storeOfflineAccessKeyInCache(accessKey);
      setLocalStorage('userId', elem.studentProfileId.toString());
      setLocalStorage('role', 'student');
      setLocalStorage('auth', 'true');
      navigate(HOME);
      await requestPermission();
    }
  };

  //  ---------------------------------------------------------------------------------

  function getRandomColor() {
    // Define the range of RGB values for non-white colors
    const minColor = 50; // Minimum value for R, G, B to avoid white variations
    const maxColor = 255; // Maximum value for R, G, B (full brightness)
    // Generate random RGB values
    const r = Math.floor(Math.random() * (maxColor - minColor + 1)) + minColor;
    const g = Math.floor(Math.random() * (maxColor - minColor + 1)) + minColor;
    const b = Math.floor(Math.random() * (maxColor - minColor + 1)) + minColor;
    // Construct the RGB string
    const color = `rgb(${r}, ${g}, ${b})`;
    return color;
  }

  const [checkedStates, setCheckedStates] = useState(
    Array(multiple_profiles?.length).fill(true)
  );
  const [colorCollection, setColorCollection] = useState(() => {
    const colors = Array(multiple_profiles?.length || 0).fill(null); // Pre-allocate empty array
    return colors.map(() => getRandomColor());
  });

  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const handleChange = (index: number) => {
    setCheckedStates((prevState) => {
      return prevState.map((checked, idx) =>
        idx === index ? checked : !checked
      );
    });

    setColorCollection((prevState) => {
      return prevState.map((color, idx) =>
        idx === index ? 'secondary.main' : 'neutral.grey'
      );
    });
  };

  const getFirstAndLastInitials = (fullName: string) => {
    if (!fullName) return '';
    const words = fullName.trim().split(/\s+/); // Split by one or more spaces

    if (words.length === 1) {
      return (
        words[0].charAt(0).toUpperCase() + words[0].charAt(1).toUpperCase()
      );
    }
    const firstInitial = words[0].charAt(0).toUpperCase();
    const lastInitial = words[words.length - 1].charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  };

  const switchUserProfile = async ({
    profileData,
    profileIndex,
  }: {
    profileData: StudentLoginResponseType;
    profileIndex: number;
  }) => {
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.switchUserProfile(
          {
            profileId: profileData.studentProfileId,
            role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          }
        );

      if (response.data) {
        const incomingProfileData = response.data.studentProfile;

        dispatch(setUserInfo(incomingProfileData));
        if (!userId && password_verfication) {
          navigate(RESET_PASSWORD);
        } else {
          const accessKey = incomingProfileData?.studentProfileId
            ? 'student-' + incomingProfileData.studentProfileId.toString()
            : 'geneo';
          await storeOfflineAccessKeyInCache(accessKey);
          setLocalStorage('userId', profileData.studentProfileId.toString());
          setLocalStorage('role', 'student');
          setLocalStorage('auth', 'true');
          setLocalStorage('activeSession', response.activeSessionId);
          setLocalStorage('lastUpdatedTime', String(new Date().getTime()));

          handleChange(profileIndex);
          setTimeout(async () => {
            navigate(HOME);
            !userId && (await requestPermission());
          }, 1000);
        }
      }
    } catch (error) {
      console.error('error:', error);
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant={isMobile ? 'h1' : 'g1'}>
          Who's Learning?
        </Typography>
      </Box>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        gap={isMobile ? pxToRem(30) : pxTovW(48.5)}
        sx={styles.profilesContainer}
      >
        {multiple_profiles?.map((profile, index) => (
          <Slide key={index} in={checkedStates[index]} timeout={500}>
            <Grid
              item
              md={2.54}
              onClick={async () => {
                switchUserProfile({
                  profileData: profile,
                  profileIndex: index,
                });

                // handleChange(index);
              }}
              sx={styles.profileBox}
            >
              <Box
                sx={{
                  ...styles.profileCircle,
                  '&:hover': {
                    boxShadow: `${colorCollection[index]} 0px ${pxTovW(
                      15
                    )}  ${pxTovW(45)} ${pxTovW(4)}`,
                  },
                }}
              >
                <Box
                  sx={{
                    ...styles.profileColorCircle,
                    backgroundColor: colorCollection[index],
                  }}
                >
                  {getFirstAndLastInitials(
                    profile.firstName + profile.lastName
                  )}
                </Box>
              </Box>

              <Typography variant="h3" textAlign="center">
                {profile.firstName + profile.lastName}
              </Typography>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Box>
  );
};
