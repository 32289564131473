import {
  clearPerformanceData,
  getPerformanceCloseEvent,
  getPerformanceOpenEvent,
  isPerformanceOpen,
  performanceClose,
  performanceOpen,
} from '@events/student/eventsCreator/performance';
import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

//* --------------- Performance Events ---------------
export const aiPerformanceOpenEvent = async (
  performanceId: string | undefined
) => {
  try {
    if (performanceId && !isPerformanceOpen({ performanceId: performanceId })) {
      performanceOpen({
        openTimestamp: new Date(),
        performanceId: performanceId,
      });

      const getEventData = getPerformanceOpenEvent({
        performanceId: performanceId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }

      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiPerformanceCloseEvent = async (
  performanceId: string | undefined
) => {
  try {
    if (performanceId && isPerformanceOpen({ performanceId: performanceId })) {
      performanceClose({
        closeTimestamp: new Date(),
        performanceId: performanceId,
      });

      const getEventData = getPerformanceCloseEvent({
        performanceId: performanceId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      clearPerformanceData({ performanceId: performanceId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
