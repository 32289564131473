import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { TeacherAnalysisAPIServiceV1 } from '@protos/analysis_management/analysis.teacher.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const TeacherAnalysisAPIServiceV1Client = createPromiseClient(
  TeacherAnalysisAPIServiceV1,
  lmsTransport
);

export const useTeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  TeacherAnalysisAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);

//TeacherAnalysisAPIServiceV1
