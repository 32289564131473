import { Box, SxProps, Typography, useMediaQuery } from '@mui/material';

import { SubmissionType } from '@protos/learning_management/lms.db_pb';
import { ReactNode } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import ImageWrapper from '../../elements/ImageWrapper';
import { theme } from '../../../../src/theme/themeProvider';

const styles: IStyles = {
  root: {
    // border:"1px solid red",
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    boxSizing: 'border-box',
    boxShadow: `0 0 ${pxTovW(39)} #E7E7E7D9`,
    '&:hover': {
      boxShadow: `0 0 ${pxTovW(20)} grey`,
    },
    padding: {
      xs: `${pxToRem(13)} ${pxToRem(12)} ${pxToRem(14)} ${pxToRem(12)}`,
      md: pxTovW(14),
    },

    // height: 'max-content',
    // minWidth: { xs: pxToRem(199), md: pxTovW(253) },
    minWidth: { xs: pxToRem(199), md: 'unset' },
    // maxWidth: { xs: pxToRem(199), md: pxTovW(310) },

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: 'white',
    cursor: 'pointer',
    position: 'relative',
  },

  avatarBox: {
    display: 'flex',
    gap: pxToRem(5),
    alignItems: 'center',
    justifyContent: 'center',
  },

  mainHeadingBox: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textAlign: 'center',
  },
  mainHeading: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },

  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: { xs: 'center', md: 'space-between', lg: 'center' },
    gap: { xs: pxToRem(11), md: 0, lg: pxTovW(16) },
    p: { md: pxTovW(2) },
    // mt: { xs: pxToRem(30), md: pxTovW(30) },
  },
  lowerBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // mt: { xs: pxToRem(7), md: pxTovW(10) },
    borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
    pt: { xs: pxToRem(5), md: pxTovW(5) },
  },
  ribbon: {
    position: 'absolute',
    top: { xs: pxToRem(15), md: pxTovW(15) },
    right: { xs: pxToRem(-6), md: pxTovW(-8) },
    paddingX: { xs: pxToRem(5), md: pxTovW(10) },
    paddingTop: { xs: pxToRem(8), md: pxTovW(20), lg: pxTovW(15) },
    paddingBottom: { xs: pxToRem(2), md: pxTovW(4) },
    width: { xs: pxToRem(80), lg: pxTovW(105) },
    height: { xs: pxToRem(20), lg: pxTovW(25) },
    textAlign: 'center',
    background: '#F8C807',
    clipPath: 'polygon(93% 28%, 93% 0, 100% 27%, 100% 100%, 0 99%, 0 28%)',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '28px',
  },
  iconBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    // wordWrap: 'break-word',
    alignItems: 'center',
    width: 'max-content',
  },
};

const variantWidth = {
  xs: { small: pxToRem(199), medium: pxToRem(199), large: pxToRem(273) },
  md: { small: pxTovW(253), medium: pxTovW(277), large: pxTovW(310) },
};
const variantHeight = {
  xs: { small: pxToRem(222), medium: pxToRem(241), large: pxToRem(282) },
  md: { small: pxTovW(266), medium: pxTovW(314), large: pxTovW(357) },
};

// const variantHeadingWidth = {
//   xs: { small: pxToRem(199), medium: pxToRem(199), large: pxToRem(199) },
//   md: { small: pxTovW(225), medium: pxTovW(253), large: pxTovW(286) },
// };

interface IconDetails {
  iconName: string;
  text: string;
}
interface IVariants {
  small: string;
  medium: string;
  large: string;
}
interface IProps {
  variant: keyof IVariants;
  blueSubText?: string;
  mainHeading: string;
  iconDetails: IconDetails[];
  status?: ReactNode;
  rootStyle?: SxProps;
  image?: string;
  lastSessionTime?: string;
  cardClickHandler?: () => void;
  userImage?: string;
  userName?: string;
  defaultImage: string;
  children?: ReactNode;
  submissionType?: SubmissionType;
  isLocked?: boolean;
}
export const InfoDisplayPanel = ({
  variant,
  blueSubText,
  mainHeading,
  iconDetails,
  status,
  rootStyle,
  image,
  lastSessionTime,
  cardClickHandler,
  userImage,
  userName,
  defaultImage,
  children,
  submissionType,
  isLocked,
}: IProps) => {
  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  return (
    <Box
      onClick={cardClickHandler}
      sx={{
        width: {
          xs: variantWidth.xs[variant],
          md: variantWidth.md[variant],
        },
        height: {
          xs: variantHeight.xs[variant],
          md: variantHeight.md[variant],
        },

        ...rootSx,
        opacity: isLocked ? 0.5 : 1,
      }}
    >
      <ImageWrapper
        name={defaultImage}
        type="png"
        parentFolder="icons"
        styles={{
          width: {
            xs: `calc(${variantWidth.xs[variant]} - ${pxToRem(24)})`, md: '100%'
          },
          objectFit: 'cover',
          borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
          aspectRatio: 272 / 144,
          alignItems: 'center'
        }}
        path={image}
      />
      {submissionType === SubmissionType.SUBMISSION_ONLINE ||
        submissionType === SubmissionType.SUBMISSION_OFFLINE ? (
        <Typography variant="h4" fontWeight={900} sx={styles.ribbon}>
          Custom HW
        </Typography>
      ) : (
        <Typography></Typography>
      )}
      {blueSubText && (
        <Typography
          variant="subText"
          fontWeight="bold"
          color="primary"
          textAlign="center"
        >
          {blueSubText}
        </Typography>
      )}

      <Box sx={{ ...styles.mainHeadingBox, width: { xs: `calc(${variantWidth.xs[variant]} - ${pxToRem(24)})`, md: '100%' }, }}>
        <Typography variant="cardText" sx={styles.mainHeading}>
          {mainHeading}
        </Typography>
      </Box>

      {/* <Box>
        <Typography variant="cardText" fontWeight="bold" textAlign="center">
          {mainHeading}
        </Typography>
      </Box> */}

      {submissionType === SubmissionType.SUBMISSION_ONLINE ||
        submissionType === SubmissionType.SUBMISSION_OFFLINE ? (
        <Box sx={styles.iconBox}>
          <IconWrapper
            name={
              submissionType === SubmissionType.SUBMISSION_ONLINE
                ? 'digital-submission'
                : 'physical-submission'
            }
            size={isIpadOnly ? 'md' : 'small'}
            parentFolder="icons"
            type="png"
          />
          <Typography variant="smallText" fontWeight="bold">
            {submissionType === SubmissionType.SUBMISSION_ONLINE
              ? 'Digital Submission'
              : 'Physical Submission'}
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.iconTextBoxContainer}>
          {iconDetails.map((elem, index) => (
            <IconTextBox
              key={index}
              iconName={elem.iconName}
              text={elem.text}
            />
          ))}
        </Box>
      )}

      {status ? (
        status
      ) : (
        <Box sx={styles.lowerBox}>
          <Box sx={styles.avatarBox}>
            <ImageWrapper
              name="user"
              type="png"
              parentFolder="tempAssets"
              styles={{ height: { xs: pxToRem(18), md: pxTovW(25) } }}
              path={userImage}
            />

            <Typography variant="smallText" fontWeight="bold">
              {userName || 'Geneo'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: { xs: pxToRem(5) },
              alignItems: 'center',
            }}
          >
            {typeof lastSessionTime == 'string' ? (
              lastSessionTime ? (
                <>
                  <IconWrapper
                    name="calender"
                    size="small"
                    parentFolder="icons"
                    type="png"
                  />
                  <Typography fontSize={pxToRem(12)} color="#007CDC">
                    {lastSessionTime}
                  </Typography>
                </>
              ) : null
            ) : (
              <>
                <IconWrapper
                  name="clock"
                  size="small"
                  parentFolder="icons"
                  type="png"
                />
                <Typography fontSize={pxToRem(12)} color="#007CDC">
                  0 Mins
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};

interface IBox {
  iconName: string;
  text: string;
}
const IconTextBox = ({ iconName, text }: IBox) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(4), md: pxTovW(4) },
        alignItems: 'center',
      }}
    >
      <IconWrapper
        name={iconName}
        size={isIpadOnly ? 'md' : 'small'}
        parentFolder="icons"
        type="png"
      />
      <Typography variant="smallText">{text}</Typography>
    </Box>
  );
};
