import {
  FilePreviewCard,
  IStyles,
  InfoBar,
  deserify,
  formatDateAsDayMonth,
  getMediaBasePath,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(18)} `,
      md: pxToRem(20),
      lg: `${pxTovW(37)} ${pxTovW(240)}`,
    },

    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(20) },
  },
  leftPanel: {
    width: { xs: '100%', md: '45%', lg: pxTovW(520) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  rightPanel: {
    width: { xs: '100%', md: '50%', lg: pxTovW(787) },
    display: 'flex',
    flexDirection: 'column',
    alignSelf: { md: 'end' },
    gap: { xs: pxToRem(10), md: pxTovW(19) },
  },
};
export const CustomHomeworkDisplay = () => {
  const location = useLocation();
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const { active_homework_student_response, active_homework_content } =
    deserify(useAppSelector((state) => state.homework));
  const activeHomeworkStudentResponse =
    active_homework_student_response && homework_id
      ? active_homework_student_response[homework_id]
      : undefined;
  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;
  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <Box sx={styles.heading}>
            <Typography variant="h1" fontWeight={'medium'}>
              Your Homework
            </Typography>
          </Box>
          <InfoBar
            contentList={[
              {
                iconName: 'calender',
                heading: formatDateAsDayMonth(
                  activeHomeworkContent?.homework?.homeworkTargetDate
                ),
                subHeading: 'Deadline',
              },

              ...(activeHomeworkStudentResponse?.metrics?.maxScore
                ? [
                    {
                      iconName: 'marks',
                      heading: ` ${activeHomeworkStudentResponse?.metrics?.responseScore}/${activeHomeworkStudentResponse?.metrics?.maxScore}`,
                      subHeading: 'Marks',
                    },
                  ]
                : []),
            ]}
          />
        </Box>
        <Box sx={styles.rightPanel}>
          <Typography variant="h2" fontWeight={'medium'}>
            Student's Submission
          </Typography>
          <Box
            sx={{
              width: { xs: '100%', md: pxTovW(787) },
              // height: { xs: pxToRem(420), md: pxTovW(787) },
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: 'space-around', md: 'space-between' },
              rowGap: { xs: pxToRem(20), md: pxTovW(20) },
              marginBottom: { xs: pxToRem(20), md: pxTovW(30) },
            }}
          >
            {activeHomeworkStudentResponse?.userUploads.length === 0 ? (
              <Typography variant="elementBodyText">
                No Uploads by Student
              </Typography>
            ) : (
              <Grid
                container
                columns={{ xs: 1, md: 1, lg: 2 }}
                rowGap={2}
                sx={{
                  height: 'fit-content',
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: { xs: pxToRem(5), md: pxTovW(10) },
                }}
              >
                {activeHomeworkStudentResponse?.userUploads
                  .filter((e) => e.isDeleted === false)
                  .map((elem) => (
                    <Grid item xs={1}>
                      <Box>
                        <FilePreviewCard
                          element={{
                            fileExtensionType: elem.fileExtensionType,
                            downloadUrl: getMediaBasePath(
                              elem.downloadUrl,
                              'schoolnetCdnImagesBucket'
                            ),
                            fileSizeInMb: elem.fileSizeInMb,
                            fileName: elem.fileName,
                            fileType: elem.fileType,
                            filePreviewUrl: getMediaBasePath(
                              elem.previewUrl,
                              'schoolnetCdnImagesBucket'
                            ),
                            gcpFileUrl: getMediaBasePath(
                              elem.gcpFileUrl,
                              'schoolnetCdnImagesBucket'
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
