// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/content_management/content.common.apis.proto (package geneo.content.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DownloadInfoResponse, FetchChapterResourcesRequest, FetchChapterResourcesRequestV2, FetchChapterResourcesResponse, FetchChapterResourcesResponseV2, FetchChapterTopicInfoRequest, FetchChapterTopicInfoResponse, FetchQuestionsRequest, FetchQuestionsResponse, FetchResourceContentRequest, FetchResourceContentResponse, FetchSubjectChapterInfoRequest, FetchSubjectChapterInfoResponse, FetchTopicResourceInfoRequest, FetchTopicResourceInfoRequestV2, FetchTopicResourceInfoResponse, FetchTopicResourceInfoResponseV2, GetDownloadInfoRequest } from "./content.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.content.common.apis.ContentCommonAPIServiceV1
 */
export const ContentCommonAPIServiceV1 = {
  typeName: "geneo.content.common.apis.ContentCommonAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchSubjectChapterInfo
     */
    fetchSubjectChapterInfo: {
      name: "fetchSubjectChapterInfo",
      I: FetchSubjectChapterInfoRequest,
      O: FetchSubjectChapterInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchChapterTopicInfo
     */
    fetchChapterTopicInfo: {
      name: "fetchChapterTopicInfo",
      I: FetchChapterTopicInfoRequest,
      O: FetchChapterTopicInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchChapterResources
     */
    fetchChapterResources: {
      name: "fetchChapterResources",
      I: FetchChapterResourcesRequest,
      O: FetchChapterResourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchTopicResources
     */
    fetchTopicResources: {
      name: "fetchTopicResources",
      I: FetchTopicResourceInfoRequest,
      O: FetchTopicResourceInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FetchResourceContent fetches a specific resource by its ID.
     *
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchResourceContent
     */
    fetchResourceContent: {
      name: "fetchResourceContent",
      I: FetchResourceContentRequest,
      O: FetchResourceContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchResourceContentV2
     */
    fetchResourceContentV2: {
      name: "fetchResourceContentV2",
      I: FetchResourceContentRequest,
      O: FetchResourceContentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.fetchQuestions
     */
    fetchQuestions: {
      name: "fetchQuestions",
      I: FetchQuestionsRequest,
      O: FetchQuestionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV1.getOfflineMetaInfo
     */
    getOfflineMetaInfo: {
      name: "getOfflineMetaInfo",
      I: GetDownloadInfoRequest,
      O: DownloadInfoResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.content.common.apis.ContentCommonAPIServiceV2
 */
export const ContentCommonAPIServiceV2 = {
  typeName: "geneo.content.common.apis.ContentCommonAPIServiceV2",
  methods: {
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV2.fetchChapterResources
     */
    fetchChapterResources: {
      name: "fetchChapterResources",
      I: FetchChapterResourcesRequestV2,
      O: FetchChapterResourcesResponseV2,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.content.common.apis.ContentCommonAPIServiceV2.fetchTopicResources
     */
    fetchTopicResources: {
      name: "fetchTopicResources",
      I: FetchTopicResourceInfoRequestV2,
      O: FetchTopicResourceInfoResponseV2,
      kind: MethodKind.Unary,
    },
  }
} as const;

