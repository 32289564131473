import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
interface Iprops {
  number: number;
  backgroundColor?: string;
  bordercolor?: string;
}
export const StepsBadge = (props: Iprops) => {
  const { number, backgroundColor, bordercolor } = props;
  return (
    <Box
      sx={{
        width: { xs: pxToRem(90), md: pxTovW(141) },
        height: { xs: pxToRem(33), md: pxTovW(51) },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor || '#FFF4CA',
        borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
        border: bordercolor ? `1px solid ${bordercolor}` : ' 1px solid #F8C807',
      }}
    >
      <Typography variant="h3" fontWeight={'bold'}>
        STEP {number}
      </Typography>
    </Box>
  );
};
