import { useEffect, useState } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import { IUpdateHWTeacherReviewRequestParams } from '..';
import { pxToRem, pxTovW } from '../../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../../commonUtils/styleUtils';
import { getLocalStorage } from '../../../../../commonUtils/utilFunctions';
import ImageWrapper from '../../../../elements/ImageWrapper';
import { AllottedMarksModal } from './AllottedMarksModal';

const styles: IStyles = {
  root: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
  },

  marksBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(8), md: pxTovW(8) },
  },
};

interface IProps {
  receivedMarks: number;
  totalMarks: number;
  editClickHandler?: () => void;
  responseRemarks: string[];
  // setRemarks: React.Dispatch<React.SetStateAction<string[]>>;
  editMarksModal: boolean;
  setEditMarksModal: (arg: boolean) => void;
  rootStyle?: SxProps;
  sessionId?: number;
  questionId?: string;
  saveClickHandlerInsideModal?: (
    reqObj: IUpdateHWTeacherReviewRequestParams
  ) => void;
}
export const AnswerStatusMarksEdit = (props: IProps) => {
  const {
    receivedMarks,
    totalMarks,
    editClickHandler,
    responseRemarks,
    // setRemarks,
    editMarksModal,
    setEditMarksModal,
    rootStyle,
    sessionId,
    questionId,
    saveClickHandlerInsideModal,
  } = props;

  const userRole = getLocalStorage('role');

  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  const [marksState, setMarksState] = useState(0);
  const [remarks, setRemarks] = useState<string[]>([]);

  useEffect(() => {
    setMarksState(receivedMarks);
    if (responseRemarks) {
      setRemarks(responseRemarks.length === 0 ? [''] : responseRemarks);
    }
  }, [receivedMarks, responseRemarks]);

  const saveClickHandler = () => {
    const newRemarks = remarks.filter((remark) => remark.trim() !== '');
    setRemarks(newRemarks);
    if (saveClickHandlerInsideModal && sessionId && questionId) {
      saveClickHandlerInsideModal({
        sessionId: sessionId,
        questionId: questionId,
        obtainedMarks: marksState === 0 ? 0 : marksState,
        remarks: newRemarks,
      });
    }
    setEditMarksModal(false);
  };
  return (
    <Box sx={rootSx}>
      <Box sx={styles.marksBox}>
        <Typography variant={'elementH1'} color="secondary" fontWeight="bold">
          {receivedMarks}
        </Typography>
        <Typography variant="elementH3" fontWeight="bold">
          /{totalMarks} Marks
        </Typography>
      </Box>

      {userRole === 'teacher' && (
        <ImageWrapper
          name="edit2"
          type="png"
          parentFolder="icons"
          onClick={() => {
            setEditMarksModal(true);
            if (editClickHandler) editClickHandler();
          }}
          styles={{
            width: { xs: pxToRem(28), md: pxTovW(50) },
            height: { xs: pxToRem(28), md: pxTovW(50) },
            cursor: 'pointer',
            borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
          }}
        />
      )}

      <AllottedMarksModal
        editMarksModal={editMarksModal}
        setEditMarksModal={setEditMarksModal}
        receivedMarks={marksState}
        totalMarks={totalMarks}
        setMarksState={setMarksState}
        saveClickHandler={saveClickHandler}
        remarks={remarks}
        setRemarks={setRemarks}
      />
    </Box>
  );
};
