import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { StudentAnalysisAPIServiceV1 } from '@protos/analysis_management/analysis.student.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const StudentAnalysisAPIServiceV1Client = createPromiseClient(
  StudentAnalysisAPIServiceV1,
  lmsTransport
);
//StudentAnalysisAPIServiceV1
export const useStudentAnalysisAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  StudentAnalysisAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
