import { PlainMessage } from '@bufbuild/protobuf';
import {
  DownloadedChapter,
  DownloadedLessonPlan,
  DownloadedResource,
  DownloadedTopic,
} from '@protos/content_management/content.db_pb';
import {
  generateCacheKey,
  getOfflineAccessKeyFromCache,
} from './cacheFunctions';
import {
  CURRENT_DOWNLOAD_SUBJECT_DB_VERSION,
  downloadsDb,
  findDownloadedResourceCacheInfoByParams,
  findDownloadedSubjectByParams,
  resourceCacheInfoDb,
} from './downloadedSubjectDexie';

export const migrateIndexedDbData = async () => {
  const gcpPrefixUrl = 'https://storage.googleapis.com';
  const newStoragePrefixUrl = process.env.NX_STORAGE_PREFIX_URL;
  const accessKey = await getOfflineAccessKeyFromCache();
  if (!newStoragePrefixUrl || !accessKey) return;

  async function updateCacheUrls(
    cacheName: string,
    newStoragePrefixUrl: string
  ) {
    const cache = await caches.open(cacheName);
    const requests = await cache.keys();
    for (const request of requests) {
      const url = request.url;
      // Check if the URL starts with gcpPrefixUrl
      if (url.startsWith(gcpPrefixUrl)) {
        const newUrl = url.replace(gcpPrefixUrl, newStoragePrefixUrl);
        const response = await cache.match(request);
        if (response) {
          // Put the new request and response in the cache
          await cache.put(new Request(newUrl), response.clone());
          // Delete the old entry
          await cache.delete(request);
        }
      }
    }
    console.log(
      `Updated URLs in cache '${cacheName}' from '${gcpPrefixUrl}' to '${newStoragePrefixUrl}'`
    );
  }

  // Usage
  updateCacheUrls('subjects-images', newStoragePrefixUrl);

  const cacheName = 'post-request-cache';
  const cache = await caches.open(cacheName);
  const data = await findDownloadedSubjectByParams({ version: 3 });

  for (let subjectIndex = 0; subjectIndex < data.length; subjectIndex++) {
    const downloadedSub = data[subjectIndex];
    const bookImageUrl = downloadedSub.bookImageUrl;
    const subjectLevelAssets = downloadedSub.assets || {};
    console.log('Before migrating: ', downloadedSub);
    if (bookImageUrl && bookImageUrl.startsWith(gcpPrefixUrl)) {
      const url = bookImageUrl;
      const oldCacheKey = subjectLevelAssets[url]?.cacheKey;
      const cachedResponse = await cache.match(oldCacheKey);
      const newUrl = url.replace(gcpPrefixUrl, newStoragePrefixUrl);
      const request = new Request(newUrl);
      const newCacheKey = await generateCacheKey(request);
      // const newCachedResponse = await cache.match(newCacheKey);
      if (cachedResponse) {
        await cache.put(newCacheKey, cachedResponse);
        await cache.delete(oldCacheKey);
      }
      subjectLevelAssets[newUrl] = {
        ...subjectLevelAssets[url],
        cacheKey: newCacheKey.url,
        url: newUrl,
      };
      delete subjectLevelAssets[url];
      downloadedSub.bookImageUrl = newUrl;
      downloadedSub.assets = { ...subjectLevelAssets };
    }

    const chapters: Record<
      number | string,
      PlainMessage<DownloadedChapter>
    > = downloadedSub.chapters || {};
    const chapterKeys = Object.keys(chapters);

    for (
      let chapterIndex = 0;
      chapterIndex < chapterKeys.length;
      chapterIndex++
    ) {
      const chapterId = chapterKeys[chapterIndex];
      const chapter = chapters[chapterId];
      const chapterImageUrl = chapter.posterImagesUrl;
      const chapterLevelAssets = chapter.assets || {};

      if (chapterImageUrl && chapterImageUrl.startsWith(gcpPrefixUrl)) {
        const oldCacheKey = chapterLevelAssets[chapterImageUrl]?.cacheKey;
        const cachedResponse = await cache.match(oldCacheKey);
        const newUrl = chapterImageUrl.replace(
          gcpPrefixUrl,
          newStoragePrefixUrl
        );

        const request = new Request(newUrl);
        const newCacheKey = await generateCacheKey(request);
        const newCachedResponse = await cache.match(newCacheKey);
        let assetFound = false;
        if (cachedResponse) {
          await cache.put(newCacheKey, cachedResponse);
          await cache.delete(oldCacheKey);
          assetFound = true;
        } else if (newCachedResponse) {
          assetFound = true;
        }
        if (assetFound) {
          chapterLevelAssets[newUrl] = {
            ...chapterLevelAssets[chapterImageUrl],
            cacheKey: newCacheKey.url,
            url: newUrl,
          };
          delete chapterLevelAssets[chapterImageUrl];
          downloadedSub.chapters[chapterId].assets = { ...chapterLevelAssets };
          downloadedSub.chapters[chapterId].posterImagesUrl = newUrl;
        }
      }

      const topics: Record<
        number | string,
        PlainMessage<DownloadedTopic>
      > = chapter.downloadedTopics || {};
      const topicKeys = Object.keys(topics);

      for (let topicIndex = 0; topicIndex < topicKeys.length; topicIndex++) {
        const topicId = topicKeys[topicIndex];
        const topic = topics[topicId];
        const topicImageUrl = topic.posterImageUrl;
        const topicLevelAssets = topic.assets || {};

        if (topicImageUrl && topicImageUrl.startsWith(gcpPrefixUrl)) {
          const oldCacheKey = topicLevelAssets[topicImageUrl]?.cacheKey;
          const cachedResponse = await cache.match(oldCacheKey);
          const newUrl = topicImageUrl.replace(
            gcpPrefixUrl,
            newStoragePrefixUrl
          );
          const request = new Request(newUrl);
          const newCacheKey = await generateCacheKey(request);
          const newCachedResponse = await cache.match(newCacheKey);
          let assetFound = false;
          if (cachedResponse) {
            await cache.put(newCacheKey, cachedResponse);
            await cache.delete(oldCacheKey);
            assetFound = true;
          } else if (newCachedResponse) {
            assetFound = true;
          }
          if (assetFound) {
            topicLevelAssets[newUrl] = {
              ...topicLevelAssets[topicImageUrl],
              cacheKey: newCacheKey.url,
              url: newUrl,
            };
            delete topicLevelAssets[topicImageUrl];
            downloadedSub.chapters[chapterId].downloadedTopics[topicId].assets =
              { ...topicLevelAssets };
            downloadedSub.chapters[chapterId].downloadedTopics[
              topicId
            ].posterImageUrl = newUrl;
          }
        }

        const lessons: Record<
          number | string,
          PlainMessage<DownloadedLessonPlan>
        > = topic.downloadedLessonPlans || {};
        const lessonKeys = Object.keys(lessons);

        for (
          let lessonIndex = 0;
          lessonIndex < lessonKeys.length;
          lessonIndex++
        ) {
          const lessonId = lessonKeys[lessonIndex];
          const lesson = lessons[lessonId];
          const lessonImageUrl = lesson.image;
          const lessonLevelAssets = lesson.assets || {};

          if (lessonImageUrl && lessonImageUrl.startsWith(gcpPrefixUrl)) {
            const oldCacheKey = lessonLevelAssets[lessonImageUrl]?.cacheKey;
            const cachedResponse = await cache.match(oldCacheKey);
            const newUrl = lessonImageUrl.replace(
              gcpPrefixUrl,
              newStoragePrefixUrl
            );
            const request = new Request(newUrl);
            const newCacheKey = await generateCacheKey(request);
            const newCachedResponse = await cache.match(newCacheKey);
            let assetFound = false;
            if (cachedResponse) {
              await cache.put(newCacheKey, cachedResponse);
              await cache.delete(oldCacheKey);
              assetFound = true;
            } else if (newCachedResponse) {
              assetFound = true;
            }
            if (assetFound) {
              lessonLevelAssets[newUrl] = {
                ...lessonLevelAssets[lessonImageUrl],
                cacheKey: newCacheKey.url,
                url: newUrl,
              };
              delete lessonLevelAssets[lessonImageUrl];
              downloadedSub.chapters[chapterId].downloadedTopics[
                topicId
              ].downloadedLessonPlans[lessonId].assets = {
                ...lessonLevelAssets,
              };
              downloadedSub.chapters[chapterId].downloadedTopics[
                topicId
              ].downloadedLessonPlans[lessonId].image = newUrl;
            }
          }

          const resources: Record<
            number | string,
            PlainMessage<DownloadedResource>
          > = lesson.downloadedResources || {};
          const resourceKeys = Object.keys(resources);
          for (
            let resourceIndex = 0;
            resourceIndex < resourceKeys.length;
            resourceIndex++
          ) {
            const resourceId = resourceKeys[resourceIndex];
            const resource = resources[resourceId];
            const resourceImageUrl = resource.posterImageUrl;
            const resourceLevelAssets = resource.assets || {};

            if (resourceImageUrl && resourceImageUrl.startsWith(gcpPrefixUrl)) {
              const oldCacheKey =
                resourceLevelAssets[resourceImageUrl]?.cacheKey;
              const cachedResponse = await cache.match(oldCacheKey);
              const newUrl = resourceImageUrl.replace(
                gcpPrefixUrl,
                newStoragePrefixUrl
              );
              const request = new Request(newUrl);
              const newCacheKey = await generateCacheKey(request);
              const newCachedResponse = await cache.match(newCacheKey);
              let assetFound = false;
              if (cachedResponse) {
                await cache.put(newCacheKey, cachedResponse);
                await cache.delete(oldCacheKey);
                assetFound = true;
              } else if (newCachedResponse) {
                assetFound = true;
              }
              if (assetFound) {
                resourceLevelAssets[newUrl] = {
                  ...resourceLevelAssets[resourceImageUrl],
                  cacheKey: newCacheKey.url,
                  url: newUrl,
                };
                delete resourceLevelAssets[resourceImageUrl];
                downloadedSub.chapters[chapterId].downloadedTopics[
                  topicId
                ].downloadedLessonPlans[lessonId].downloadedResources[
                  resourceId
                ].assets = { ...resourceLevelAssets };
                downloadedSub.chapters[chapterId].downloadedTopics[
                  topicId
                ].downloadedLessonPlans[lessonId].downloadedResources[
                  resourceId
                ].posterImageUrl = newUrl;
              }
            }
            const downloadedResourceCacheInfo =
              await findDownloadedResourceCacheInfoByParams({
                subjectId: downloadedSub.subjectId,
                chapterId: Number(chapterId),
                topicId: Number(topicId),
                lessonId: lessonId,
                resourceId: resource.resourceId,
              });
            for (
              let assetIndex = 0;
              assetIndex < downloadedResourceCacheInfo.length;
              assetIndex++
            ) {
              const resourceAsset = downloadedResourceCacheInfo[assetIndex];
              const resourceAssetUrl = resourceAsset.url;
              if (
                resourceAssetUrl &&
                resourceAssetUrl.startsWith(gcpPrefixUrl)
              ) {
                const oldCacheKey = resourceAsset.cacheKey;
                const cachedResponse = oldCacheKey
                  ? await cache.match(oldCacheKey)
                  : undefined;
                const newUrl = resourceAssetUrl.replace(
                  gcpPrefixUrl,
                  newStoragePrefixUrl
                );
                const request = new Request(newUrl);
                const newCacheKey = await generateCacheKey(request);
                const newCachedResponse = await cache.match(newCacheKey);
                let assetFound = false;
                if (cachedResponse) {
                  await cache.put(newCacheKey, cachedResponse);
                  await cache.delete(oldCacheKey);
                  assetFound = true;
                  const newResourceAsset = {
                    ...resourceAsset,
                    cacheKey: newCacheKey.url,
                    url: newUrl,
                  };
                  await resourceCacheInfoDb.downloadedResourceCacheInfo.put(
                    newResourceAsset
                  );
                  await resourceCacheInfoDb.downloadedResourceCacheInfo
                    .where(
                      '[subjectId+chapterId+topicId+lessonId+resourceId+url+cacheKey]'
                    )
                    .equals([
                      resourceAsset.subjectId,
                      resourceAsset.chapterId,
                      resourceAsset.topicId,
                      resourceAsset.lessonId,
                      resourceAsset.resourceId,
                      resourceAsset.url,
                      resourceAsset.cacheKey,
                    ])
                    .delete();
                } else if (newCachedResponse) {
                  assetFound = true;
                }
                if (assetFound) {
                  resourceLevelAssets[newUrl] = {
                    ...resourceLevelAssets[resourceAssetUrl],
                    cacheKey: newCacheKey.url,
                    url: newUrl,
                  };
                  delete resourceLevelAssets[resourceAssetUrl];
                  downloadedSub.chapters[chapterId].downloadedTopics[
                    topicId
                  ].downloadedLessonPlans[lessonId].downloadedResources[
                    resourceId
                  ].assets = { ...resourceLevelAssets };
                }
              }
            }
            // Fallback if some of the resources are left
            for (
              let resourceAssetIndex = 0;
              resourceAssetIndex < Object.values(resourceLevelAssets).length;
              resourceAssetIndex++
            ) {
              const resourceAsset =
                Object.values(resourceLevelAssets)[resourceAssetIndex];
              const resourceAssetUrl = resourceAsset?.url;
              if (
                resourceAssetUrl &&
                resourceAssetUrl.startsWith(gcpPrefixUrl)
              ) {
                const oldCacheKey = resourceAsset.cacheKey;
                const cachedResponse = oldCacheKey
                  ? await cache.match(oldCacheKey)
                  : undefined;
                const newUrl = resourceAssetUrl.replace(
                  gcpPrefixUrl,
                  newStoragePrefixUrl
                );
                const request = new Request(newUrl);
                const newCacheKey = await generateCacheKey(request);
                const newCachedResponse = await cache.match(newCacheKey);
                if (resourceLevelAssets[newUrl]) {
                  // Already migrated
                  delete resourceLevelAssets[resourceAssetUrl];
                  continue;
                }
                let assetFound = false;
                if (cachedResponse) {
                  await cache.put(newCacheKey, cachedResponse);
                  await cache.delete(oldCacheKey);
                  assetFound = true;
                } else if (newCachedResponse) {
                  assetFound = true;
                }
                if (assetFound) {
                  resourceLevelAssets[newUrl] = {
                    ...resourceLevelAssets[resourceAssetUrl],
                    cacheKey: newCacheKey.url,
                    url: newUrl,
                  };
                  delete resourceLevelAssets[resourceAssetUrl];
                  downloadedSub.chapters[chapterId].downloadedTopics[
                    topicId
                  ].downloadedLessonPlans[lessonId].downloadedResources[
                    resourceId
                  ].assets = { ...resourceLevelAssets };
                }
              }
            }
          }
        }
      }
    }
    downloadedSub.version = CURRENT_DOWNLOAD_SUBJECT_DB_VERSION;
    console.log('After migrating: ', downloadedSub);
    await downloadsDb.downloadedSubjectsV4.put(downloadedSub);
    await downloadsDb.downloadedSubjectsV4
      .where('[offlineAccessKey+subjectId+version]')
      .equals([accessKey, downloadedSub.subjectId, 3])
      .delete();
  }
};
