import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import { IStyles } from 'libs/shared-ui/src/commonUtils/styleUtils';
import ImageWrapper from '../ImageWrapper';
interface Iprops {
  connectedStudents: number;
  totalStudents: number;
}
export const ConnectedStudentsInfoBadge = (props: Iprops) => {
  const { connectedStudents, totalStudents } = props
  const styles: IStyles = {
    root: {
      width: { xs: pxToRem(283), md: pxTovW(606) },
      height: { xs: pxToRem(104), md: pxTovW(231) },
      background: '#E4FEF0',
      border: '1px solid #0AA34F',
      borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    middlebox: {
      display: 'flex',
      gap: { xs: pxToRem(28), md: pxTovW(28) }
    },
    imagebox: {
      width: { xs: pxToRem(84), md: pxTovW(178) },
      height: { xs: pxToRem(84), md: pxTovW(178) },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
  return (
    <Box sx={styles.root}>
      <Box sx={styles.middlebox}>
        <ImageWrapper
          name="connectedclassprofileicon"
          type="png"
          parentFolder="images"
          styles={styles.imagebox} />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}>
          <Typography fontSize={{ xs: pxToRem(40), md: pxTovW(75) }} fontWeight={'bold'} fontFamily={'poppins'}>{connectedStudents}</Typography>
          <Typography fontSize={{ xs: pxToRem(18), md: pxTovW(30) }} fontWeight={'regular'} fontFamily={'poppins'}>out of {totalStudents} Joined</Typography>
        </Box>
      </Box>
    </Box >
  )
}

