import { ReactNode } from 'react';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  SxProps,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import ImageWrapper from '../../elements/ImageWrapper';
import { theme } from '../../../theme/themeProvider';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const styles: IStyles = {
  root: {
    border: '1px solid red',
    borderColor: 'neutral.electricGreen',
    bgcolor: 'common.white',
    boxSizing: 'border-box',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    p: { xs: pxToRem(10), md: pxTovW(10), lg: pxTovW(19) },
    cursor: 'pointer',
  },

  mainGrid: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: 'red',
    height: '100%',
  },

  leftGrid: {
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    // backgroundColor: 'red',

    p: { xs: pxToRem(10), md: pxTovW(19) },
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    maxWidth: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  headingChipBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: { xs: pxToRem(1), lg: pxTovW(13) },
    alignItems: 'flex-start',
    height: '100%',
    maxHeight: '100%',
  },
  chip: {
    height: { xs: pxToRem(22), lg: pxTovW(38) },
    width: 'max-content',
    backgroundColor: 'warning.main',
    fontSize: { xs: pxToRem(16), md: pxToRem(12), lg: pxTovW(16) },
  },

  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    alignItems: 'center',
  },
};
const variantWidth = {
  xs: { small: pxToRem(320), medium: pxToRem(320), large: pxToRem(320) },
  md: { small: pxToRem(320), medium: pxToRem(320), large: pxToRem(320) },
  lg: { small: pxTovW(692), medium: pxTovW(692), large: '100%' },
};
const variantHeight = {
  xs: { small: pxToRem(110), medium: pxToRem(95), large: pxToRem(95) },
  md: { small: pxToRem(78), medium: pxToRem(78), large: pxToRem(95) },
  lg: { small: pxTovW(165), medium: pxTovW(181), large: pxTovW(159) },
};

interface IconDetails {
  iconName: string;
  text: string;
}
interface IVariants {
  small: string;
  medium: string;
  large: string;
}
interface IProps {
  variant: keyof IVariants;
  image?: string;
  tagName?: string;
  mainHeading: string;
  blueSubText?: string;
  iconDetails?: IconDetails[];
  withArrow?: boolean;
  status?: ReactNode;
  rootStyle?: SxProps;
  cardClickHandler?: () => void;
  isLocked?: boolean | ContentLockStatusType;
  handleOpenPopup?: (ev?: React.MouseEvent) => void;
  isContentLockFeature?: boolean;
}
export const ChapterInfoCard = ({
  variant,
  image,
  tagName,
  blueSubText,
  mainHeading,
  iconDetails,
  withArrow,
  status,
  rootStyle,
  cardClickHandler,
  isLocked,
  handleOpenPopup,
  isContentLockFeature,
}: IProps) => {
  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  const getImageName = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'lock' : 'unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'partial_lock';
      default:
        return ''; // or a default image name
    }
  };
  console.log(isLocked, 'fsdfs');
  const getTooltipTitle = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'Lock' : 'Unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'Unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'Lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'Partially Locked';
      default:
        return 'Status Unknown'; // or another default tooltip title
    }
  };
  return (
    <Box
      onClick={cardClickHandler}
      sx={{
        width: {
          xs: variantWidth.xs[variant],
          md: variantWidth.md[variant],
          lg: variantWidth.lg[variant],
        },
        height: {
          xs: variantHeight.xs[variant],
          md: variantHeight.md[variant],
          lg: variantHeight.lg[variant],
        },
        ...rootSx,
        opacity:
          isLocked === ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            ? 0.5
            : 1,
      }}
    >
      <Grid container sx={styles.mainGrid}>
        <Grid item xs={2.8} md={2.6} sx={styles.leftGrid}>
          <ImageWrapper
            name="chapterImage"
            type="png"
            parentFolder="tempAssets"
            styles={styles.image}
            path={image}
          />
        </Grid>

        <Grid item xs={8} md={8.2} sx={{ height: '100%' }}>
          <Box sx={styles.headingChipBox}>
            {tagName && <Chip label={tagName} sx={styles.chip} />}

            <Typography
              variant="bodyText"
              fontWeight="bold"
              sx={{
                width: '100%',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                wordWrap: 'break-word',
              }}
            >
              {mainHeading}
            </Typography>

            {blueSubText && (
              <Typography variant="cardText" color="primary">
                {blueSubText}
              </Typography>
            )}

            {status ? (
              status
            ) : (
              <Box>
                {iconDetails && (
                  <Box sx={styles.iconTextBoxContainer}>
                    {iconDetails.map((elem, index) => (
                      <IconTextBox
                        key={index}
                        iconName={elem.iconName}
                        text={elem.text}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={0.4}
          md={0.4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginRight: isIpadOnly ? pxToRem(5) : 'none',
          }}
        >
          {isContentLockFeature && (
            <BootstrapTooltip title={getTooltipTitle()}>
              <IconButton
                size="small"
                sx={{ position: 'relative', top: '-6px' }}
              >
                <ImageWrapper
                  onClick={handleOpenPopup}
                  name={getImageName()}
                  type="png"
                  parentFolder="icons"
                  styles={{
                    height: {
                      xs: pxToRem(30),
                      md: pxToRem(22),
                      lg: pxTovW(35),
                    },
                    width: {
                      xs: pxToRem(30),
                      md: pxToRem(22),
                      lg: pxTovW(35),
                    },
                  }}
                />
              </IconButton>
            </BootstrapTooltip>
          )}
          {/* {withArrow && <IconTextBox iconName="rightArrow" />} */}
          {withArrow && (
            <ArrowForwardIosRoundedIcon
              fontSize={isIpad ? 'small' : 'medium'}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface IBox {
  iconName: string;
  text?: string;
}
const IconTextBox = ({ iconName, text }: IBox) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(4), md: pxTovW(4) },
        alignItems: 'center',
      }}
    >
      <IconWrapper
        name={iconName}
        size={isIpadOnly ? 'md' : 'small'}
        parentFolder="icons"
        type="png"
      />
      {text && (
        <Typography variant="subText" fontWeight={'bold'}>
          {text}
        </Typography>
      )}
    </Box>
  );
};
