import { Box, Typography } from '@mui/material';
import React from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { default as PrimaryButton } from '../../elements/PrimaryButton';
const styles: IStyles = {
  root: {
    display: 'flex',
    // width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: { xs: 'inherit', md: 'common.white' },
    padding: {
      xs: pxToRem(0),
      md: `${pxToRem(20)} 0`,
      lg: `${pxTovW(20)} ${pxTovW(240)}`,
    },
  },
};
interface IProps {
  title: string | React.ReactNode;
  buttonClickHandler?: () => void;
  buttonText?: string;
}
export const InPageHeader = (props: IProps) => {
  const { title, buttonText, buttonClickHandler } = props;

  return (
    <Box sx={styles.root}>
      {title}
      {buttonText && (
        <Box
          sx={{
            // backgroundColor: 'red',
            width: { md: pxTovW(444) },
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
          }}
        >
          <PrimaryButton
            fullWidth
            sx={{
              boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                7
              )} #0AA34F63`,
              height: { xs: pxToRem(52), lg: pxTovW(70) },
            }}
            onClick={buttonClickHandler}
          >
            <Typography
              variant="bodyText"
              fontWeight="bold"
              color={'white'}
              sx={{ letterSpacing: '1px' }}
            >
              {buttonText}
            </Typography>
          </PrimaryButton>
        </Box>
      )}
    </Box>
  );
};
