// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.teacher.apis.proto (package geneo.lms.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchTeacherBannersRequest, FetchTeacherBannersResponse } from "./lms.teacher.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.teacher.apis.LmsTeacherAPIServiceV1
 */
export const LmsTeacherAPIServiceV1 = {
  typeName: "geneo.lms.teacher.apis.LmsTeacherAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.teacher.apis.LmsTeacherAPIServiceV1.fetchTeacherBanners
     */
    fetchTeacherBanners: {
      name: "fetchTeacherBanners",
      I: FetchTeacherBannersRequest,
      O: FetchTeacherBannersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

