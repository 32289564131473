

import { ActionsPopup, IStyles, ImageWrapper, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { SxProps } from '@mui/system';
import { useState } from 'react';

const styles: IStyles = {
};

interface IProps {
  title?: string;
  onClick: () => void;
  rootStyle?: SxProps;
}
export const DeleteButton = ({
  title,
  onClick,
  rootStyle
}: IProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false)
  return <>
    <ImageWrapper
      onClick={async (ev?: React.MouseEvent) => {
        ev?.stopPropagation();
        setOpen(true);
      }}
      name={"delete2"}
      type="png"
      parentFolder="icons"
      styles={{
        cursor: 'pointer',
        height: {
          xs: pxToRem(25),
          md: pxTovW(35),
        },
        width: {
          xs: pxToRem(25),
          md: pxTovW(35),
        },
        ...rootStyle
      }}
    />
    <ActionsPopup
      open={open}
      handleClose={handleClose}
      fontSmall
      iconName="delete"
      popupText={`Are you sure you want to delete \n${title || ''} ?`}
      yesClickHandler={(ev?: React.MouseEvent) => {
        handleClose()
        ev?.stopPropagation();
        onClick();
      }}
      noClickHandler={(ev?: React.MouseEvent) => {
        ev?.stopPropagation();
        handleClose()
      }}
    />
  </>
};
