import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsStudentAPIServiceV1 } from '@protos/learning_management/lms.student.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsStudentAPIServiceV1Client = createPromiseClient(
  LmsStudentAPIServiceV1,
  lmsTransport
);

export const useLmsStudentAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsStudentAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
