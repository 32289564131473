import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';

interface Iprops {
  number: number;
  size: 'small' | 'large';
}
export const RemoteNumber = (props: Iprops) => {
  const { number, size } = props;

  return (
    <Box
      sx={{
        borderRadius: '50%',
        border: '1px solid #F8C807',
        backgroundColor: '#FFF4CA',
        width: {
          xs: size === 'small' ? pxToRem(30) : pxToRem(40),
          md: size === 'small' ? pxTovW(50) : pxTovW(80),
        },
        height: {
          xs: size === 'small' ? pxToRem(30) : pxToRem(40),
          md: size === 'small' ? pxTovW(50) : pxTovW(80),
        },
        display: 'flex',
      }}
    >
      <Typography
        fontSize={
          size === 'small'
            ? { xs: pxToRem(25), md: pxTovW(25) }
            : { xs: pxToRem(21), md: pxTovW(45) }
        }
        fontFamily={'poppins'}
        fontWeight={'600'}
        margin="auto"
      >
        {number}
      </Typography>
    </Box>
  );
};
