import { Avatar, Badge, Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
interface IProps {
  size: 'small' | 'large';
  ImageSource?: string;
  name?: string;
  online?: boolean;
}
export const SquareImageAvatar = (props: IProps) => {
  const { size, ImageSource, name, online } = props;
  return (
    <Box sx={{ width: 'max-content', zIndex: 0 }}>
      <Badge
        // color="secondary"
        // badgeContent=" "
        // variant="dot"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Box
            sx={{
              width: size === 'small' ? pxToRem(12) : pxToRem(15),
              height: size === 'small' ? pxToRem(12) : pxToRem(15),
              background: online === true ? 'green' : 'red',
              borderRadius: '50%',
              border: `${
                size === 'small' ? pxToRem(3) : pxToRem(4)
              } solid white`,
            }}
          ></Box>
        }
      >
        <Box
          sx={{
            borderRadius: '10%',
            backgroundColor: 'secondary.main',
            width: {
              xs: size === 'small' ? pxToRem(28) : pxToRem(46),
              md: size === 'small' ? pxTovW(50) : pxTovW(95),
            },
            height: {
              xs: size === 'small' ? pxToRem(28) : pxToRem(46),
              md: size === 'small' ? pxTovW(50) : pxTovW(95),
            },
          }}
        >
          {ImageSource ? (
            <img
              src={ImageSource}
              alt="subject"
              style={{ height: '100%', width: '100%' }}
            />
          ) : (
            <Avatar
              sx={{
                height: '100%',
                width: '100%',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                variant={size === 'small' ? 'h4' : 'g1'}
                color={'common.white'}
              >
                {name?.substring(0, 1).toUpperCase()}
              </Typography>
            </Avatar>
          )}
        </Box>
      </Badge>
    </Box>
  );
};
