// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.common.apis.proto (package geneo.lms.lesson.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchLessonContentRequest, FetchLessonContentResponse, FetchLessonsByModuleRequest, FetchLessonsByModuleResponse } from "./lms.lesson.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.lesson.common.apis.LessonCommonAPIServiceV1
 */
export const LessonCommonAPIServiceV1 = {
  typeName: "geneo.lms.lesson.common.apis.LessonCommonAPIServiceV1",
  methods: {
    /**
     * GetLessonList returns a list of Lesson Plan.
     *
     * @generated from rpc geneo.lms.lesson.common.apis.LessonCommonAPIServiceV1.fetchLessonsByModule
     */
    fetchLessonsByModule: {
      name: "fetchLessonsByModule",
      I: FetchLessonsByModuleRequest,
      O: FetchLessonsByModuleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FetchLessonContent returns a specific Lesson Plan.
     *
     * @generated from rpc geneo.lms.lesson.common.apis.LessonCommonAPIServiceV1.fetchLessonContent
     */
    fetchLessonContent: {
      name: "fetchLessonContent",
      I: FetchLessonContentRequest,
      O: FetchLessonContentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

