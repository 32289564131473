import 'react-image-crop/src/ReactCrop.scss';
import {
  ImageWrapper,
  PrimaryButton,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { CropStep, IStatus } from './ImageUploadPopup';

const styles = {
  processingRoot: {
    backgroundColor: 'common.white',
    overflowY: 'auto',
    margin: 'auto',
    width: { xs: '90vw', md: pxTovW(1440) },
    height: { xs: 'unset', md: 'max-content' },
    minHeight: { xs: '80vh', md: '80vh' },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    paddingY: { xs: pxToRem(22), md: pxTovW(50) },
    // boxSizing: 'border-box',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  processingHeadingBox: {
    textAlign: 'center',
  },
  fileUploadReport: {
    mt: { xs: pxToRem(30), md: pxTovW(40) },
    paddingY: { xs: pxToRem(10), md: pxTovW(40) },
    boxShadow: {
      xs: `0px 0px ${pxToRem(10)} #00000029`,
      md: `0px 0px ${pxTovW(10)} #00000029`,
    },
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    display: 'flex',
    flexWrap: 'wrap',
  },
  instructions: {
    width: { xs: '80%', md: pxTovW(800), lg: pxTovW(586) },
    backgroundColor: 'neutral.honeydew',
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    paddingY: { xs: pxToRem(11), md: pxTovW(11) },
    paddingX: { xs: pxToRem(14), md: pxTovW(14) },
    mt: { xs: pxToRem(30), md: pxTovW(40) },
    border: '1px solid #0AA34F',
  },
  buttonBox: {
    mt: { xs: pxToRem(30), md: pxTovW(40) },
    display: 'flex',
    gap: { xs: pxToRem(20), md: pxTovW(25), lg: pxTovW(11) },
    width: { xs: '60%', md: pxTovW(700), lg: pxTovW(580) },
    flexDirection: { xs: 'column', md: 'row' },
  },

  imgSelectCover: {
    cursor: 'pointer',
    width: { xs: pxToRem(75), md: pxToRem(100) },
    height: { xs: pxToRem(75), md: pxToRem(100) },
    background: 'rgba(0,0,0,0.5)',
  },

  // - - - - - UploadReport - - - - -
  uploadReportRoot: {
    width: { xs: pxToRem(120), md: pxTovW(180) },
    flexGrow: '1',
    paddingY: { xs: pxToRem(10), md: pxTovW(5) },
    paddingX: { xs: pxToRem(10), md: pxTovW(25), lg: pxTovW(40) },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  uploadReportCount: {
    display: 'flex',
    // alignItems: 'center',
    gap: { xs: pxToRem(6), md: pxTovW(6) },
  },
  uploadReportImage: {
    width: { xs: pxToRem(30), md: pxTovW(45) },
    height: { xs: pxToRem(30), md: pxTovW(45) },
  },
};

interface imgDetails {
  src: string;
  name: string;
  type: string;
}

interface IProps {
  imageStatuses: IStatus[];
  saveHandler: () => Promise<void>;
  setStep: Dispatch<SetStateAction<CropStep>>;
  originalImageSrc: imgDetails[];
  loading: boolean;
}

export const FileUploadReport = (props: IProps) => {
  const { imageStatuses, saveHandler, setStep, originalImageSrc, loading } =
    props;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getTotalNumberFileStatus = (statusToFetch?: IStatus) => {
    const statusCounts: Record<IStatus, number> = {
      failure: 0,
      'failure-upload': 0,
      'failure-conversion': 0,
      'not-started': 0,
      success: 0,
    };

    imageStatuses.forEach((status) => {
      statusCounts[status]++;
    });

    if (statusToFetch) {
      return statusCounts[statusToFetch];
    }

    return (
      imageStatuses.length -
      statusCounts['not-started'] -
      statusCounts['success'] -
      statusCounts['failure'] -
      statusCounts['failure-upload'] -
      statusCounts['failure-conversion']
    );
  };

  const getImageName = (indexOfStatus: number) => {
    if (imageStatuses[indexOfStatus] === 'failure') return 'error_yellow';
    else if (imageStatuses[indexOfStatus] === 'failure-conversion')
      return 'text_upload_failed';
    else if (imageStatuses[indexOfStatus] === 'failure-upload')
      return 'image_upload_failed';
    else if (imageStatuses[indexOfStatus] === 'success') return 'correct';
    else return 'image_uploaded';
  };

  return (
    <Box
      sx={{
        ...styles.processingRoot,
        justifyContent: loading ? 'end' : 'center',
        gap: loading ? { xs: pxToRem(150), md: pxTovW(120) } : 'unset',
      }}
    >
      {loading ? (
        <CircularProgress size={100} color="primary" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={styles.processingHeadingBox}>
            <Typography variant="h1">
              Failed To Convert{' '}
              {imageStatuses.length - getTotalNumberFileStatus('success')}
            </Typography>
            <Typography variant="h1">Image To Text</Typography>
          </Box>

          <Box sx={styles.fileUploadReport}>
            {/* <UploadReport
              count={getTotalNumberFileStatus()}
              imageName="image_uploaded"
              name="Image Upload Success"
              rightDashBorder
            /> */}

            <UploadReport
              count={getTotalNumberFileStatus('success')}
              imageName="correct"
              name="Successful"
              rightDashBorder
            />

            <UploadReport
              count={getTotalNumberFileStatus('failure-upload')}
              imageName="image_upload_failed"
              name="Image Upload Failed"
              rightDashBorder={isMobile ? false : true}
            />

            <UploadReport
              count={getTotalNumberFileStatus('failure-conversion')}
              imageName="text_upload_failed"
              name="Text Conversion Failed"
              rightDashBorder
            />

            <UploadReport
              count={getTotalNumberFileStatus('failure')}
              imageName="error_yellow"
              name="Error Occurred"
            />
          </Box>

          <Box sx={styles.instructions}>
            {instructions.map((ins, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: { xs: pxToRem(15), md: pxTovW(15) },
                  paddingTop:
                    index === 0 ? '0px' : { xs: pxToRem(17), md: pxTovW(17) },
                  paddingBottom:
                    index === instructions.length - 1
                      ? '0px'
                      : { xs: pxToRem(17), md: pxTovW(17) },
                  borderBottom:
                    index === instructions.length - 1
                      ? 'none'
                      : '1px solid #0AA34F',
                }}
              >
                <ImageWrapper
                  name="green_tick"
                  type="png"
                  parentFolder="icons"
                  styles={{
                    width: { xs: pxToRem(21), md: pxTovW(21) },
                    height: { xs: pxToRem(16), md: pxTovW(16) },
                  }}
                />

                <Typography
                  variant="h3"
                  color="secondary.main"
                  fontWeight="medium"
                >
                  {ins}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={styles.buttonBox}>
            <PrimaryButton
              onClick={() => {
                // const arr = Array(originalImageSrc.length).fill(
                //   'not-started'
                // );
                // setImageStatuses(arr);
                saveHandler();
              }}
            >
              RETRY
            </PrimaryButton>
            <PrimaryButton
              color="primary"
              onClick={() => {
                // const arr = Array(originalImageSrc.length).fill(
                //   'not-started'
                // );
                // setImageStatuses(arr);
                setStep(CropStep.OriginalImage);
              }}
            >
              UPDATE IMAGES
            </PrimaryButton>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: pxToRem(10),
          alignItems: 'center',
          px: pxToRem(20),
          // pt: { xs: pxToRem(50), md: pxToRem(45) },
          mt: { xs: pxToRem(50), md: pxToRem(45) },
          overflowX: { xs: 'visible', md: 'auto' },
          height: { xs: pxToRem(150), md: pxToRem(120) },
          flexWrap: 'wrap',

          // display: 'flex',
          // flexDirection: 'row',
          // gap: pxToRem(10),
          // justifyContent: 'flex-start',
          // alignItems: 'center',
          // overflowX: { xs: 'scroll', md: 'auto' },
          // overflowY: { xs: 'scroll', md: 'auto' },
          // overflow: 'scroll',
          // flexWrap: 'wrap',
        }}
      >
        {originalImageSrc.map((e, i) => (
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              // onClick={() => handleClickImageCover(e.src, i)}
              src={e.src}
              sx={styles.imgSelectCover}
            />
            <Box
              sx={{
                position: 'absolute',
                top: ' 50%',
                left: ' 50%',
                transform: ' translate(-50%, -50%)',
              }}
            >
              {/* <StatusIcon
                status={imageStatuses[i]}
                sx={{ fontSize: pxToRem(40) }}
              /> */}
              <ImageWrapper
                name={getImageName(i)}
                type="png"
                parentFolder="icons"
                styles={{
                  width: { xs: pxToRem(30), md: pxTovW(30) },
                  height: { xs: pxToRem(30), md: pxTovW(30) },
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

interface IUploadReport {
  count: number | string;
  imageName: string;
  name: string;
  rightDashBorder?: boolean;
}
const UploadReport = (props: IUploadReport) => {
  const { count, imageName, name, rightDashBorder } = props;

  return (
    <Box
      sx={{
        ...styles.uploadReportRoot,
        borderRight: rightDashBorder ? '1px dashed grey' : 'none',
      }}
    >
      <Box sx={styles.uploadReportCount}>
        <Typography variant="h1">{count}</Typography>
        <ImageWrapper
          name={imageName}
          type="png"
          parentFolder="icons"
          styles={styles.uploadReportImage}
        />
      </Box>

      <Typography variant="h3" fontWeight="bold">
        {name}
      </Typography>
    </Box>
  );
};

const instructions = [
  'Update images with errors.',
  'Ensure that text is present in the image and you have cropped it correctly.',
];
