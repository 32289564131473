import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { StudentEventsAPIServiceV1 } from '@protos/geneo_ai/ai.student.events.apis_connect';
import { TeacherEventsAPIServiceV1 } from '@protos/geneo_ai/ai.teacher.events.apis_connect';
import { ResponseStatusCodeFunction, studentEventsTransport, studentEventsTransportWithLogoutHandler, teacherEventsTransport, teacherEventsTransportWithLogoutHandler } from './transports';

export const StudentEventsAPIServiceV1Client = createPromiseClient(
  StudentEventsAPIServiceV1,
  studentEventsTransport
);

export const TeacherEventsAPIServiceV1Client = createPromiseClient(
  TeacherEventsAPIServiceV1,
  teacherEventsTransport
);

export const useStudentEventsAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  StudentEventsAPIServiceV1,
  studentEventsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);

export const useTeacherEventsAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  TeacherEventsAPIServiceV1,
  teacherEventsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
