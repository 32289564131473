import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../commonUtils/constants';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';

export const HelpAndSupportText = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: pxToRem(20), md: pxTovW(31) },
        paddingTop: { xs: pxToRem(21), md: pxTovW(47) },
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: '1px solid ',
          borderColor: '#D4D4D4',
          paddingBottom: { xs: pxToRem(20), md: pxTovW(31) },
          gap: { xs: pxToRem(9.5), md: pxTovW(7) },
        }}
      >
        {' '}
        <IconWrapper
          name="call2"
          type="png"
          parentFolder="icons"
          customSx={{
            height: { xs: pxToRem(16.5), md: pxTovW(26.12) },
            width: { xs: pxToRem(16.5), md: pxTovW(26.12) },
          }}
        />
        <Typography variant="h2" fontFamily="Lato" fontWeight="regular">
          Call: {SUPPORT_PHONE}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',

          alignItems: 'center',
          justifyContent: 'center',
          gap: { xs: pxToRem(9.5), md: pxTovW(7) },
        }}
      >
        <IconWrapper
          name="email"
          type="png"
          parentFolder="icons"
          customSx={{
            height: { xs: pxToRem(13.75), md: pxTovW(21) },
            width: { xs: pxToRem(19.63), md: pxTovW(31) },
          }}
        />
        <Typography variant="h2" fontFamily="Lato" fontWeight="regular">
          Email:
        </Typography>
      </Box>

      <Typography variant="h2" fontFamily="Lato" fontWeight="regular">
        {SUPPORT_EMAIL}
      </Typography>
    </Box>
  );
};
