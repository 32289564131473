// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.common.apis.proto (package geneo.lms.hw.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchHomeworksByModuleRequest, FetchHomeworksByModuleResponse, HomeworkContentFetchRequest, HomeworkContentFetchResponse } from "./lms.hw.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.hw.common.apis.LmsHomeworkCommonAPIServiceV1
 */
export const LmsHomeworkCommonAPIServiceV1 = {
  typeName: "geneo.lms.hw.common.apis.LmsHomeworkCommonAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.hw.common.apis.LmsHomeworkCommonAPIServiceV1.fetchHomeworksByModule
     */
    fetchHomeworksByModule: {
      name: "fetchHomeworksByModule",
      I: FetchHomeworksByModuleRequest,
      O: FetchHomeworksByModuleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.common.apis.LmsHomeworkCommonAPIServiceV1.fetchHomeworkContent
     */
    fetchHomeworkContent: {
      name: "fetchHomeworkContent",
      I: HomeworkContentFetchRequest,
      O: HomeworkContentFetchResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

