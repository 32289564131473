import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import { Book } from '@protos/learning_management/lms.book.apis_pb';
import { StudentHomeworkTask } from '@protos/learning_management/lms.hw.student.apis_pb';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import { createSlice } from '@reduxjs/toolkit';
// import {} from '@protos/common/'

interface InfoToast {
  label: string | React.ReactElement;
  variant: 'success' | 'error' | 'info';
  open: boolean;
}
export interface IHomeDashboardState {
  student_profile_id: string;
  student_profile?: unknown;
  school_course_id?: string;
  ongoing_lesson_list?: LessonInfo[];
  home_homework_list?: StudentHomeworkTask[];
  recommended_resources_list?: ResourceInfo[];
  text_book_list?: Book[];
  selected_subject_id?: number;
  selected_book_id?: number;
  toast_info: InfoToast;
}

const initialState: IHomeDashboardState = {
  student_profile_id: 'abcd',
  home_homework_list: [],
  ongoing_lesson_list: [],
  recommended_resources_list: [],
  text_book_list: [],
  selected_subject_id: undefined,
  selected_book_id: undefined,
  toast_info: { label: '', variant: 'success', open: false },
};

export const homeDashboardSlice = createSlice({
  name: 'homeDashboard',
  initialState,
  reducers: {
    setHomeHomeworkList: (state, action) => {
      state.home_homework_list = action.payload;
    },
    setHomeOngoingLessonList: (state, action) => {
      state.ongoing_lesson_list = action.payload;
    },
    setHomeRecommendedResourceList: (state, action) => {
      state.recommended_resources_list = action.payload;
    },
    setHomeTextBookList: (state, action) => {
      state.text_book_list = action.payload;
    },
    setHomeSelectedSubjectId: (state, action) => {
      state.selected_subject_id = action.payload;
    },
    setHomeSelectedBookId: (state, action) => {
      state.selected_book_id = action.payload;
    },
    setToastInfo: (state, action) => {
      state.toast_info = action.payload;
    },
    toggleToastOpen: (state, action) => {
      state.toast_info.open = action.payload;
    },
    resetHomeState: (state) => {
      return initialState;
    },
  },
});

export const {
  setToastInfo,
  toggleToastOpen,
  setHomeHomeworkList,
  setHomeOngoingLessonList,
  setHomeRecommendedResourceList,
  setHomeTextBookList,
  setHomeSelectedSubjectId,
  setHomeSelectedBookId,
  resetHomeState,
} = homeDashboardSlice.actions;
export default homeDashboardSlice.reducer;
