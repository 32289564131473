import {
  Content_ContentEnum,
  Question_QuestionEnum,
} from '@protos/content_management/content.db_pb';
import {
  QAttemptResultEnum,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';

import * as Joi from 'joi';

export const BookOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  bookId: Joi.number().required(),
});

export const BookCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  bookId: Joi.number().required(),
});

export const BookEventArgsValidator = Joi.object({
  bookId: Joi.number().required(),
});

// Chapter Events
export const ChapterOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  chapterId: Joi.number().required(),
});

export const ChapterCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  chapterId: Joi.number().required(),
});

export const ChapterEventArgsValidator = Joi.object({
  chapterId: Joi.number().required(),
});

// Resource Events
export const ResourceOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  resourceId: Joi.string().required(),
});

export const ResourceCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  resourceId: Joi.string().required(),
});

export const ResourceEventArgsValidator = Joi.object({
  resourceId: Joi.string().required(),
});

// Resource page content Events
export const ResourcePageOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  resourceId: Joi.string().required(),
  pageNumber: Joi.number().required(),
});

export const ResourcePageCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  resourceId: Joi.string().required(),
  pageNumber: Joi.number().required(),
});

export const ResourcePageEventArgsValidator = Joi.object({
  resourceId: Joi.string().required(),
  pageNumber: Joi.number().required(),
});

// Lesson Plan Events
export const LessonPlanOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  lessonId: Joi.string().required(),
});

export const LessonPlanCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  lessonId: Joi.string().required(),
});

export const LessonPlanEventArgsValidator = Joi.object({
  lessonId: Joi.string().required(),
});

// Contents  Events
export const ContentOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  contentId: Joi.string().required(),
  contentType: Joi.number().valid(
    Content_ContentEnum.CONTENT_TYPE_UNDEFINED,
    Content_ContentEnum.CONTENT_TYPE_PASSAGE,
    Content_ContentEnum.CONTENT_TYPE_EXPERIMENT,
    Content_ContentEnum.CONTENT_TYPE_CONTENT_VIDEO,
    Content_ContentEnum.CONTENT_TYPE_FLASHCARD,
    Content_ContentEnum.CONTENT_TYPE_MINDMAP
  ),
  resourceId: Joi.string(),
  pageNumber: Joi.number(),
});

export const RPQuestionOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  questionId: Joi.string().required(),
  type: Joi.number().valid(
    Question_QuestionEnum.QUESTION_TYPE_UNDEFINED,
    Question_QuestionEnum.QUESTION_TYPE_FIB,
    Question_QuestionEnum.QUESTION_TYPE_MTF,
    Question_QuestionEnum.QUESTION_TYPE_MCQS,
    Question_QuestionEnum.QUESTION_TYPE_MCQM,
    Question_QuestionEnum.QUESTION_TYPE_TF,
    Question_QuestionEnum.QUESTION_TYPE_SHORT,
    Question_QuestionEnum.QUESTION_TYPE_LONG,
    Question_QuestionEnum.QUESTION_TYPE_ARRANGE,
    // Question_QuestionEnum.QUESTION_TYPE_SUBJECTIVE,
    Question_QuestionEnum.QUESTION_TYPE_LABEL
  ),
  resourceId: Joi.string(),
  pageNumber: Joi.number(),
});
export const RPQuestionCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  questionId: Joi.string().required(),
});

export const ContentCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  contentId: Joi.string().required(),
});

export const ContentEventArgsValidator = Joi.object({
  contentId: Joi.string().required(),
  // contentType: Content_ContentEnum,
  // resourceId: Joi.string().required(),
  // pageNumber: Joi.number().required(),
});

export const InstructionEventArgsValidator = Joi.object({
  instructionId: Joi.string().required(),
});

export const InstructionOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  instructionId: Joi.string().required(),
  resourceId: Joi.string(),
  pageNumber: Joi.number(),
});

export const InstructionCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  instructionId: Joi.string().required(),
});

export const RPQuestionSubmitEventArgsValidator = Joi.object({
  // contentId: Joi.string().required(),
  // resourceId: Joi.string().required(),
  // pageNumber: Joi.number().required(),
  status: Joi.number().valid(
    QAttemptResultEnum.RESPONSE_UNDEFINED,
    QAttemptResultEnum.RESPONSE_CORRECT,
    QAttemptResultEnum.RESPONSE_INCORRECT,
    QAttemptResultEnum.RESPONSE_PARTIALLY_CORRECT
  ),
  questionId: Joi.string().required(),
  answer: Joi.array().items(Joi.string()).required(),
});

// Contents  Events
export const HomeworkOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  homeworkId: Joi.number().required(),
  questionIds: Joi.array().items(Joi.string()).required(),
  homeworkType: Joi.number().valid(
    TaskEnum.TASK_HOMEWORK_AUTOMATED,
    TaskEnum.TASK_ASSESSMENT_CUSTOM,
    TaskEnum.TASK_ASSESSMENT_AUTOMATED,
    TaskEnum.TASK_HOMEWORK_CUSTOM,
    TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM
  ),
});

export const HomeworkCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  homeworkId: Joi.number().required(),
});

export const HomeworkEventArgsValidator = Joi.object({
  homeworkId: Joi.number().required(),
  isResume: Joi.bool(),
  isReattempted: Joi.bool(),
});

export const QuestionOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  questionId: Joi.string().required(),
  homeworkId: Joi.number().required(),
});

export const QuestionCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  questionId: Joi.string().required(),
  homeworkId: Joi.number().required(),
  status: Joi.number().valid(
    QAttemptResultEnum.RESPONSE_UNDEFINED,
    QAttemptResultEnum.RESPONSE_CORRECT,
    QAttemptResultEnum.RESPONSE_INCORRECT,
    QAttemptResultEnum.RESPONSE_PARTIALLY_CORRECT
  ),
  answer: Joi.array().items(Joi.string()).required(),
});

export const QuestionEventArgsValidator = Joi.object({
  questionId: Joi.string().required(),
});

// Topic  Events
export const TopicOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  topicId: Joi.number().required(),
});

export const TopicCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  topicId: Joi.number().required(),
});

export const TopicEventArgsValidator = Joi.object({
  topicId: Joi.number().required(),
});

// Recommendation  Events
export const RecommendationOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  recommendationId: Joi.string().required(),
});

export const RecommendationCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  recommendationId: Joi.string().required(),
});

export const RecommendationEventArgsValidator = Joi.object({
  recommendationId: Joi.string().required(),
});

export const InteractionButtonActionArgsValidator = Joi.object({
  url: Joi.string().required(),
  name: Joi.string().required(),
  context: Joi.string().required(),
  timestamp: Joi.date().required(),
});

// Performance  Events
export const PerformanceOpenArgsValidator = Joi.object({
  openTimestamp: Joi.date().required(),
  performanceId: Joi.string().required(),
});

export const PerformanceCloseArgsValidator = Joi.object({
  closeTimestamp: Joi.date().required(),
  performanceId: Joi.string().required(),
});

export const PerformanceEventArgsValidator = Joi.object({
  performanceId: Joi.string().required(),
});

export const VideoPlayArgsValidator = Joi.object({
  contentId: Joi.string().required(),
  playPosition: Joi.number().required(),
  speed: Joi.number().required(),
});

export const VideoUpdatePositionArgsValidator = Joi.object({
  contentId: Joi.string().required(),
  videoPosition: Joi.number().required(),
});

export const VideoStopArgsValidator = Joi.object({
  contentId: Joi.string().required(),
  stopPosition: Joi.number().required(),
});
