// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.student.apis.proto (package geneo.analysis.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchOverAllAnalysisStatsRequest, FetchOverAllAnalysisStatsResponse, FetchSubjectAnalysisStatsRequest, FetchSubjectAnalysisStatsResponse } from "./analysis.student.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.analysis.student.apis.StudentAnalysisAPIServiceV1
 */
export const StudentAnalysisAPIServiceV1 = {
  typeName: "geneo.analysis.student.apis.StudentAnalysisAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.analysis.student.apis.StudentAnalysisAPIServiceV1.fetchOverAllAnalysisStats
     */
    fetchOverAllAnalysisStats: {
      name: "fetchOverAllAnalysisStats",
      I: FetchOverAllAnalysisStatsRequest,
      O: FetchOverAllAnalysisStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.student.apis.StudentAnalysisAPIServiceV1.fetchSubjectAnalysisStats
     */
    fetchSubjectAnalysisStats: {
      name: "fetchSubjectAnalysisStats",
      I: FetchSubjectAnalysisStatsRequest,
      O: FetchSubjectAnalysisStatsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

