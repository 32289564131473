// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.learn.apis.proto (package geneo.lms.lesson.learn.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateStudentLessonSessionRequest, GetStudentOngoingLessonListRequest, GetStudentOngoingLessonListResponse, GetStudentPreviousLessonSessionRequest, StudentLessonSessionResponse, StudentPreviousLessonSessionResponse, UpdateStudentLessonSessionRequest } from "./lms.lesson.learn.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * lessonService manages all operations related to Lesson Plan.
 *
 * @generated from service geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1
 */
export const LessonLearnAPIServiceV1 = {
  typeName: "geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1.createStudentLessonSession
     */
    createStudentLessonSession: {
      name: "createStudentLessonSession",
      I: CreateStudentLessonSessionRequest,
      O: StudentLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1.updateStudentLessonSession
     */
    updateStudentLessonSession: {
      name: "updateStudentLessonSession",
      I: UpdateStudentLessonSessionRequest,
      O: StudentLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1.getPreviousLessonSessionInfo
     */
    getPreviousLessonSessionInfo: {
      name: "getPreviousLessonSessionInfo",
      I: GetStudentPreviousLessonSessionRequest,
      O: StudentPreviousLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.learn.apis.LessonLearnAPIServiceV1.getStudentOngoingLessonList
     */
    getStudentOngoingLessonList: {
      name: "getStudentOngoingLessonList",
      I: GetStudentOngoingLessonListRequest,
      O: GetStudentOngoingLessonListResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

