// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.teach.apis.proto (package geneo.lms.lesson.teach.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateTeacherLessonSessionRequest, GetTeacherLessonListRequest, GetTeacherLessonListResponse, GetTeacherPreviousLessonSessionRequest, TeacherDeleteLessonRequest, TeacherDeleteLessonResponse, TeacherLessonSessionResponse, TeacherPreviousLessonSessionResponse, TeacherUpsertLessonRequest, TeacherUpsertLessonResponse, UpdateTeacherLessonSessionRequest } from "./lms.lesson.teach.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * lessonService manages all operations related to Lesson Plan.
 *
 * @generated from service geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1
 */
export const LessonTeachAPIServiceV1 = {
  typeName: "geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.getTeacherLessonList
     */
    getTeacherLessonList: {
      name: "getTeacherLessonList",
      I: GetTeacherLessonListRequest,
      O: GetTeacherLessonListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * upsertLesson
     * EditResourceBylesson updates an existing Lesson Plan or creates a new one if it doesn't exist.
     *
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.upsertLesson
     */
    upsertLesson: {
      name: "upsertLesson",
      I: TeacherUpsertLessonRequest,
      O: TeacherUpsertLessonResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.createTeacherLessonSession
     */
    createTeacherLessonSession: {
      name: "createTeacherLessonSession",
      I: CreateTeacherLessonSessionRequest,
      O: TeacherLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.updateTeacherLessonSession
     */
    updateTeacherLessonSession: {
      name: "updateTeacherLessonSession",
      I: UpdateTeacherLessonSessionRequest,
      O: TeacherLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.getPreviousLessonSessionInfo
     */
    getPreviousLessonSessionInfo: {
      name: "getPreviousLessonSessionInfo",
      I: GetTeacherPreviousLessonSessionRequest,
      O: TeacherPreviousLessonSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.lesson.teach.apis.LessonTeachAPIServiceV1.deleteTeacherLessonById
     */
    deleteTeacherLessonById: {
      name: "deleteTeacherLessonById",
      I: TeacherDeleteLessonRequest,
      O: TeacherDeleteLessonResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

