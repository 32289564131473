// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.common.apis.proto (package geneo.lms.hw.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { APIResponse_CodeEnum, APIResponse_StatusEnum } from "@protos/common/common.db_pb";
import { AssessmentEnum, CloudUpload, SubmissionType, TaskActiveStatusEnum, TaskCreationStatusEnum, TaskEnum, TaskInfo } from "@protos/learning_management/lms.db_pb";
import { DifficultyLevelEnum, Module_ModuleCategoryEnum, Question } from "@protos/content_management/content.db_pb";

/**
 * @generated from message geneo.lms.hw.common.apis.HomeworkContentFetchRequest
 */
export class HomeworkContentFetchRequest extends Message<HomeworkContentFetchRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 homework_id = 3;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<HomeworkContentFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworkContentFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkContentFetchRequest {
    return new HomeworkContentFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkContentFetchRequest {
    return new HomeworkContentFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkContentFetchRequest {
    return new HomeworkContentFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkContentFetchRequest | PlainMessage<HomeworkContentFetchRequest> | undefined, b: HomeworkContentFetchRequest | PlainMessage<HomeworkContentFetchRequest> | undefined): boolean {
    return proto3.util.equals(HomeworkContentFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.common.apis.HomeworkContentFetchResponse
 */
export class HomeworkContentFetchResponse extends Message<HomeworkContentFetchResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworkContent data = 4;
   */
  data?: HomeworkContent;

  constructor(data?: PartialMessage<HomeworkContentFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworkContentFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: HomeworkContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkContentFetchResponse {
    return new HomeworkContentFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkContentFetchResponse {
    return new HomeworkContentFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkContentFetchResponse {
    return new HomeworkContentFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkContentFetchResponse | PlainMessage<HomeworkContentFetchResponse> | undefined, b: HomeworkContentFetchResponse | PlainMessage<HomeworkContentFetchResponse> | undefined): boolean {
    return proto3.util.equals(HomeworkContentFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.common.apis.HomeworkContent
 */
export class HomeworkContent extends Message<HomeworkContent> {
  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworkTask homework = 1;
   */
  homework?: HomeworkTask;

  /**
   * @generated from field: geneo.lms.db.TaskInfo questions_sequence_info = 2;
   */
  questionsSequenceInfo?: TaskInfo;

  /**
   * @generated from field: repeated geneo.content.db.Question homework_content = 3;
   */
  homeworkContent: Question[] = [];

  constructor(data?: PartialMessage<HomeworkContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworkContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework", kind: "message", T: HomeworkTask },
    { no: 2, name: "questions_sequence_info", kind: "message", T: TaskInfo },
    { no: 3, name: "homework_content", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkContent {
    return new HomeworkContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkContent {
    return new HomeworkContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkContent {
    return new HomeworkContent().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkContent | PlainMessage<HomeworkContent> | undefined, b: HomeworkContent | PlainMessage<HomeworkContent> | undefined): boolean {
    return proto3.util.equals(HomeworkContent, a, b);
  }
}

/**
 * Request model for /teacher/homework/existing/by-module
 *
 * @generated from message geneo.lms.hw.common.apis.FetchHomeworksByModuleRequest
 */
export class FetchHomeworksByModuleRequest extends Message<FetchHomeworksByModuleRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 module_id = 3;
   */
  moduleId = 0;

  /**
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<FetchHomeworksByModuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.FetchHomeworksByModuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchHomeworksByModuleRequest {
    return new FetchHomeworksByModuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchHomeworksByModuleRequest {
    return new FetchHomeworksByModuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchHomeworksByModuleRequest {
    return new FetchHomeworksByModuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchHomeworksByModuleRequest | PlainMessage<FetchHomeworksByModuleRequest> | undefined, b: FetchHomeworksByModuleRequest | PlainMessage<FetchHomeworksByModuleRequest> | undefined): boolean {
    return proto3.util.equals(FetchHomeworksByModuleRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/existing/by-module
 *
 * @generated from message geneo.lms.hw.common.apis.FetchHomeworksByModuleResponse
 */
export class FetchHomeworksByModuleResponse extends Message<FetchHomeworksByModuleResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworksByModule data = 4;
   */
  data?: HomeworksByModule;

  constructor(data?: PartialMessage<FetchHomeworksByModuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.FetchHomeworksByModuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: HomeworksByModule },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchHomeworksByModuleResponse {
    return new FetchHomeworksByModuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchHomeworksByModuleResponse {
    return new FetchHomeworksByModuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchHomeworksByModuleResponse {
    return new FetchHomeworksByModuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchHomeworksByModuleResponse | PlainMessage<FetchHomeworksByModuleResponse> | undefined, b: FetchHomeworksByModuleResponse | PlainMessage<FetchHomeworksByModuleResponse> | undefined): boolean {
    return proto3.util.equals(FetchHomeworksByModuleResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.common.apis.HomeworksByModule
 */
export class HomeworksByModule extends Message<HomeworksByModule> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask existing_homeworks = 1;
   */
  existingHomeworks: HomeworkTask[] = [];

  /**
   * @generated from field: optional int32 module_id = 2;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.lms.hw.common.apis.ModuleHWListCountInfo hwListInfo = 3;
   */
  hwListInfo?: ModuleHWListCountInfo;

  constructor(data?: PartialMessage<HomeworksByModule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworksByModule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "existing_homeworks", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "hwListInfo", kind: "message", T: ModuleHWListCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworksByModule {
    return new HomeworksByModule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworksByModule {
    return new HomeworksByModule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworksByModule {
    return new HomeworksByModule().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworksByModule | PlainMessage<HomeworksByModule> | undefined, b: HomeworksByModule | PlainMessage<HomeworksByModule> | undefined): boolean {
    return proto3.util.equals(HomeworksByModule, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.common.apis.ModuleHWListCountInfo
 */
export class ModuleHWListCountInfo extends Message<ModuleHWListCountInfo> {
  /**
   * @generated from field: int32 total_hw_count = 1;
   */
  totalHwCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 2;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<ModuleHWListCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.ModuleHWListCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleHWListCountInfo {
    return new ModuleHWListCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleHWListCountInfo {
    return new ModuleHWListCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleHWListCountInfo {
    return new ModuleHWListCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleHWListCountInfo | PlainMessage<ModuleHWListCountInfo> | undefined, b: ModuleHWListCountInfo | PlainMessage<ModuleHWListCountInfo> | undefined): boolean {
    return proto3.util.equals(ModuleHWListCountInfo, a, b);
  }
}

/**
 * Message for homework task
 * Message for homework task
 *
 * @generated from message geneo.lms.hw.common.apis.HomeworkTask
 */
export class HomeworkTask extends Message<HomeworkTask> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * Title of the homework
   *
   * @generated from field: string homework_title = 2;
   */
  homeworkTitle = "";

  /**
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * Class to which the homework is assigned
   *
   * @generated from field: string class = 4;
   */
  class = "";

  /**
   * @generated from field: optional int32 sectionId = 5;
   */
  sectionId?: number;

  /**
   * Section to which the homework is assigned
   *
   * @generated from field: optional string section = 6;
   */
  section?: string;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 7;
   */
  subjectId = 0;

  /**
   * Subject of the homework
   *
   * @generated from field: string subject = 8;
   */
  subject = "";

  /**
   * ID of the module
   *
   * @generated from field: int32 module_id = 9;
   */
  moduleId = 0;

  /**
   * @generated from field: string module_name = 10;
   */
  moduleName = "";

  /**
   * @generated from field: optional int32 chapter_id = 31;
   */
  chapterId?: number;

  /**
   * ID of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 11;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * ID of the teacher
   *
   * @generated from field: optional int64 teacher_id = 12;
   */
  teacherId?: bigint;

  /**
   * Name of the teacher
   *
   * @generated from field: optional string teacher_name = 13;
   */
  teacherName?: string;

  /**
   * URL of the teacher's profile image
   *
   * @generated from field: optional string teacher_profile_image_url = 14;
   */
  teacherProfileImageUrl?: string;

  /**
   * Description of the homework
   *
   * @generated from field: string description = 15;
   */
  description = "";

  /**
   * URL of the homework poster image
   *
   * @generated from field: string homework_poster_img_url = 16;
   */
  homeworkPosterImgUrl = "";

  /**
   * Difficulty level of the homework
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 17;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Estimated time for which the homework is assigned
   *
   * @generated from field: float estimated_time_in_min = 18;
   */
  estimatedTimeInMin = 0;

  /**
   * Creation status of the task
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum task_creation_status = 19;
   */
  taskCreationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * Active status of the task
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum task_active_status = 20;
   */
  taskActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Instructions for the homework
   *
   * @generated from field: repeated string homework_instructions = 21;
   */
  homeworkInstructions: string[] = [];

  /**
   * Content info of the homework
   *
   * @generated from field: geneo.lms.hw.common.apis.HomeworkContentInfo homework_content_info = 22;
   */
  homeworkContentInfo?: HomeworkContentInfo;

  /**
   * Date when the task was assigned
   *
   * @generated from field: optional google.protobuf.Timestamp homework_assigned_date = 23;
   */
  homeworkAssignedDate?: Timestamp;

  /**
   * Date when the task commenced
   *
   * @generated from field: optional google.protobuf.Timestamp homework_commence_date = 24;
   */
  homeworkCommenceDate?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: optional google.protobuf.Timestamp homework_target_date = 25;
   */
  homeworkTargetDate?: Timestamp;

  /**
   * Last Modified date for task
   *
   * @generated from field: optional google.protobuf.Timestamp last_modified_time = 26;
   */
  lastModifiedTime?: Timestamp;

  /**
   * Date when the task was assigned
   *
   * @generated from field: optional google.protobuf.Timestamp daily_reminder_time = 27;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: optional int32 assigned_students_count = 28;
   */
  assignedStudentsCount?: number;

  /**
   * @generated from field: optional int32 students_submission_count = 29;
   */
  studentsSubmissionCount?: number;

  /**
   * @generated from field: optional float class_score = 30;
   */
  classScore?: number;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 35;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 32;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 33;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 34;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 36;
   */
  assessmentType?: AssessmentEnum;

  /**
   * ID of the module;
   *
   * @generated from field: optional string chapter_name = 37;
   */
  chapterName?: string;

  /**
   * ID of the module;
   *
   * @generated from field: optional int32 topic_id = 38;
   */
  topicId?: number;

  /**
   * ID of the module;
   *
   * @generated from field: optional string topic_name = 39;
   */
  topicName?: string;

  constructor(data?: PartialMessage<HomeworkTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworkTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "module_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 12, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "homework_poster_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 18, name: "estimated_time_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "task_creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 20, name: "task_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 21, name: "homework_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "homework_content_info", kind: "message", T: HomeworkContentInfo },
    { no: 23, name: "homework_assigned_date", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "homework_commence_date", kind: "message", T: Timestamp, opt: true },
    { no: 25, name: "homework_target_date", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "last_modified_time", kind: "message", T: Timestamp, opt: true },
    { no: 27, name: "daily_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 28, name: "assigned_students_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 29, name: "students_submission_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 30, name: "class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 35, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 32, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 33, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 34, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 36, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 37, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 39, name: "topic_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkTask {
    return new HomeworkTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkTask {
    return new HomeworkTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkTask {
    return new HomeworkTask().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkTask | PlainMessage<HomeworkTask> | undefined, b: HomeworkTask | PlainMessage<HomeworkTask> | undefined): boolean {
    return proto3.util.equals(HomeworkTask, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.common.apis.HomeworkContentInfo
 */
export class HomeworkContentInfo extends Message<HomeworkContentInfo> {
  /**
   * @generated from field: int32 time_duration_in_min = 1;
   */
  timeDurationInMin = 0;

  /**
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  constructor(data?: PartialMessage<HomeworkContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.common.apis.HomeworkContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_duration_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkContentInfo | PlainMessage<HomeworkContentInfo> | undefined, b: HomeworkContentInfo | PlainMessage<HomeworkContentInfo> | undefined): boolean {
    return proto3.util.equals(HomeworkContentInfo, a, b);
  }
}

