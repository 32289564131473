export function checkM3u8Type(content: string): "Multiple" | "Direct" | "Unknown" {
  const multiplePattern = /#EXT-X-STREAM-INF:/;
  const directPattern = /#EXTINF:[0-9.]+,/;

  if (multiplePattern.test(content)) {
    return "Multiple";
  } else if (directPattern.test(content)) {
    return "Direct";
  } else {
    return "Unknown";
  }
}


interface M3u8Result {
  videoFile: string;
  audioFile: string;
}

interface StreamInfo {
  resolution: number;
  videoFile: string;
}

export function findClosestStream(content: string, desiredResolution: number): M3u8Result {
  const streamPattern = /#EXT-X-STREAM-INF:.*RESOLUTION=\d+x(\d+).*\n(.+\.m3u8)/g;
  const audioPattern = /#EXT-X-MEDIA:TYPE=AUDIO.*URI="(.+\.m3u8)"/;

  let closestStream: StreamInfo | null = null;
  let match: RegExpExecArray | null;

  // Find all video streams
  while ((match = streamPattern.exec(content)) !== null) {
    const resolution = parseInt(match[1], 10);
    const videoFile = match[2];
    if (!closestStream || Math.abs(desiredResolution - resolution) < Math.abs(desiredResolution - closestStream.resolution)) {
      closestStream = { resolution, videoFile };
    }
  }

  // Find the audio file
  const audioMatch = audioPattern.exec(content);
  const audioFile = audioMatch ? audioMatch[1] : '';

  return {
    videoFile: closestStream ? closestStream.videoFile : '',
    audioFile: audioFile
  };
}
