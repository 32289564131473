import React, { RefObject, useEffect } from 'react';

import { Button, Box, MenuItem, Select, Typography } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import 'react-quill/dist/quill.snow.css';
import ImageIcon from '@mui/icons-material/Image';
import {
  Directions,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlinedRounded,
} from '@mui/icons-material';
import ReactDOMServer from 'react-dom/server';
import FormatSizeIcon from '@mui/icons-material/FormatSize';

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);
export const modules = {
  toolbar: {
    container: '#toolbar',

    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },

  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};
function undoChange(this: { quill: any }) {
  console.log(this.quill);
  this.quill.history.undo();
}
function redoChange(this: { quill: any }) {
  this.quill.history.redo();
}
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
  'align',
  'direction',
  'size-custom',
];

export const CustomToolbar = () => {
  return (
    <Box
      id="toolbar"
      sx={{
        display: 'flex !important',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#6586FE',
        borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
        boxSizing: 'border-box !important',
        height: { xs: pxToRem(40), md: pxTovW(60) },
        maxheight: '10%',

        width: '100%',
      }}
    >
      <button className="ql-bold">
        <FormatBoldRounded />
      </button>
      <button className="ql-italic">
        <FormatItalicRounded />
      </button>
      <button className="ql-underline">
        <FormatUnderlinedRounded />
      </button>

      <select className="ql-background" />
      <select className="ql-color"></select>

      <select
        className="ql-size"
        id="custom"
        defaultValue="small"
        style={{ backgroundColor: 'red !important' }}
      ></select>

      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <select className="ql-align" />

      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
      <button className="ql-code-block" />
    </Box>
  );
};

export default CustomToolbar;
