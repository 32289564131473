// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.book.apis.proto (package geneo.lms.book.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { ModuleBookContent, PDFResource } from "@protos/content_management/content.db_pb";
import { Board_BoardEnum } from "@protos/common/common.db_pb";

/**
 * @generated from message geneo.lms.book.apis.FetchClassBooksRequest
 */
export class FetchClassBooksRequest extends Message<FetchClassBooksRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: optional int32 class_id = 3;
   */
  classId?: number;

  constructor(data?: PartialMessage<FetchClassBooksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.FetchClassBooksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClassBooksRequest {
    return new FetchClassBooksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClassBooksRequest {
    return new FetchClassBooksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClassBooksRequest {
    return new FetchClassBooksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClassBooksRequest | PlainMessage<FetchClassBooksRequest> | undefined, b: FetchClassBooksRequest | PlainMessage<FetchClassBooksRequest> | undefined): boolean {
    return proto3.util.equals(FetchClassBooksRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.FetchClassBooksResponse
 */
export class FetchClassBooksResponse extends Message<FetchClassBooksResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.book.apis.BooksInfo data = 4;
   */
  data?: BooksInfo;

  constructor(data?: PartialMessage<FetchClassBooksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.FetchClassBooksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: BooksInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClassBooksResponse {
    return new FetchClassBooksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClassBooksResponse {
    return new FetchClassBooksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClassBooksResponse {
    return new FetchClassBooksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClassBooksResponse | PlainMessage<FetchClassBooksResponse> | undefined, b: FetchClassBooksResponse | PlainMessage<FetchClassBooksResponse> | undefined): boolean {
    return proto3.util.equals(FetchClassBooksResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.BooksInfo
 */
export class BooksInfo extends Message<BooksInfo> {
  /**
   * @generated from field: repeated geneo.lms.book.apis.Book books = 1;
   */
  books: Book[] = [];

  constructor(data?: PartialMessage<BooksInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.BooksInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "books", kind: "message", T: Book, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BooksInfo {
    return new BooksInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BooksInfo {
    return new BooksInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BooksInfo {
    return new BooksInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BooksInfo | PlainMessage<BooksInfo> | undefined, b: BooksInfo | PlainMessage<BooksInfo> | undefined): boolean {
    return proto3.util.equals(BooksInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.FetchBookContentRequest
 */
export class FetchBookContentRequest extends Message<FetchBookContentRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 book_id = 3;
   */
  bookId = 0;

  constructor(data?: PartialMessage<FetchBookContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.FetchBookContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBookContentRequest {
    return new FetchBookContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBookContentRequest {
    return new FetchBookContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBookContentRequest {
    return new FetchBookContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBookContentRequest | PlainMessage<FetchBookContentRequest> | undefined, b: FetchBookContentRequest | PlainMessage<FetchBookContentRequest> | undefined): boolean {
    return proto3.util.equals(FetchBookContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.FetchBookContentResponse
 */
export class FetchBookContentResponse extends Message<FetchBookContentResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.book.apis.BookContentInfo data = 4;
   */
  data?: BookContentInfo;

  constructor(data?: PartialMessage<FetchBookContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.FetchBookContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: BookContentInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBookContentResponse {
    return new FetchBookContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBookContentResponse {
    return new FetchBookContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBookContentResponse {
    return new FetchBookContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBookContentResponse | PlainMessage<FetchBookContentResponse> | undefined, b: FetchBookContentResponse | PlainMessage<FetchBookContentResponse> | undefined): boolean {
    return proto3.util.equals(FetchBookContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.BookContentInfo
 */
export class BookContentInfo extends Message<BookContentInfo> {
  /**
   * @generated from field: repeated geneo.lms.book.apis.BookChaptersInfo book_chapters_info = 1;
   */
  bookChaptersInfo: BookChaptersInfo[] = [];

  /**
   * @generated from field: repeated geneo.lms.book.apis.BookTopicsInfo book_topics_info = 2;
   */
  bookTopicsInfo: BookTopicsInfo[] = [];

  constructor(data?: PartialMessage<BookContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.BookContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_chapters_info", kind: "message", T: BookChaptersInfo, repeated: true },
    { no: 2, name: "book_topics_info", kind: "message", T: BookTopicsInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookContentInfo {
    return new BookContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookContentInfo {
    return new BookContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookContentInfo {
    return new BookContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BookContentInfo | PlainMessage<BookContentInfo> | undefined, b: BookContentInfo | PlainMessage<BookContentInfo> | undefined): boolean {
    return proto3.util.equals(BookContentInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.BookChaptersInfo
 */
export class BookChaptersInfo extends Message<BookChaptersInfo> {
  /**
   * @generated from field: geneo.content.db.ModuleBookContent book_chapter_content_info = 1;
   */
  bookChapterContentInfo?: ModuleBookContent;

  /**
   * @generated from field: int32 book_id = 2;
   */
  bookId = 0;

  /**
   * @generated from field: int32 chapter_id = 3;
   */
  chapterId = 0;

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: int32 rank = 5;
   */
  rank = 0;

  constructor(data?: PartialMessage<BookChaptersInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.BookChaptersInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_chapter_content_info", kind: "message", T: ModuleBookContent },
    { no: 2, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookChaptersInfo {
    return new BookChaptersInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookChaptersInfo {
    return new BookChaptersInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookChaptersInfo {
    return new BookChaptersInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BookChaptersInfo | PlainMessage<BookChaptersInfo> | undefined, b: BookChaptersInfo | PlainMessage<BookChaptersInfo> | undefined): boolean {
    return proto3.util.equals(BookChaptersInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.BookTopicsInfo
 */
export class BookTopicsInfo extends Message<BookTopicsInfo> {
  /**
   * @generated from field: geneo.content.db.ModuleBookContent book_topic_content_info = 1;
   */
  bookTopicContentInfo?: ModuleBookContent;

  /**
   * @generated from field: int32 book_id = 2;
   */
  bookId = 0;

  /**
   * @generated from field: int32 chapter_id = 3;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 topic_id = 4;
   */
  topicId = 0;

  /**
   * @generated from field: string title = 5;
   */
  title = "";

  /**
   * @generated from field: int32 rank = 6;
   */
  rank = 0;

  constructor(data?: PartialMessage<BookTopicsInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.BookTopicsInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_topic_content_info", kind: "message", T: ModuleBookContent },
    { no: 2, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookTopicsInfo {
    return new BookTopicsInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookTopicsInfo {
    return new BookTopicsInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookTopicsInfo {
    return new BookTopicsInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BookTopicsInfo | PlainMessage<BookTopicsInfo> | undefined, b: BookTopicsInfo | PlainMessage<BookTopicsInfo> | undefined): boolean {
    return proto3.util.equals(BookTopicsInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.Book
 */
export class Book extends Message<Book> {
  /**
   * @generated from field: int32 book_id = 1;
   */
  bookId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 version = 3;
   */
  version = 0;

  /**
   * @generated from field: string publisher = 4;
   */
  publisher = "";

  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 7;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: int32 classId = 6;
   */
  classId = 0;

  /**
   * @generated from field: int32 subjectId = 5;
   */
  subjectId = 0;

  /**
   * @generated from field: string teach_medium = 8;
   */
  teachMedium = "";

  /**
   * @generated from field: string cover_image_url = 9;
   */
  coverImageUrl = "";

  /**
   * @generated from field: geneo.content.db.PDFResource pdf = 10;
   */
  pdf?: PDFResource;

  constructor(data?: PartialMessage<Book>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.Book";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "publisher", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 6, name: "classId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "subjectId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "teach_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "cover_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "pdf", kind: "message", T: PDFResource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Book {
    return new Book().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJsonString(jsonString, options);
  }

  static equals(a: Book | PlainMessage<Book> | undefined, b: Book | PlainMessage<Book> | undefined): boolean {
    return proto3.util.equals(Book, a, b);
  }
}

/**
 * @generated from message geneo.lms.book.apis.BookInfo
 */
export class BookInfo extends Message<BookInfo> {
  constructor(data?: PartialMessage<BookInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.book.apis.BookInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookInfo {
    return new BookInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookInfo {
    return new BookInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookInfo {
    return new BookInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BookInfo | PlainMessage<BookInfo> | undefined, b: BookInfo | PlainMessage<BookInfo> | undefined): boolean {
    return proto3.util.equals(BookInfo, a, b);
  }
}

