import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LessonLearnAPIServiceV1 } from '@protos/learning_management/lms.lesson.learn.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LessonLearnAPIServiceV1Client = createPromiseClient(
  LessonLearnAPIServiceV1,
  lmsTransport
);

export const useLessonLearnAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LessonLearnAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
