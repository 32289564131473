import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { UmsUtilityApiServiceV1 } from '@protos/user_management/ums.common.apis_connect';
import { ResponseStatusCodeFunction, UmsTransportWithLogoutHandler, umsTransport } from './transports';

export const UmsUtilityAPIServiceV1Client = createPromiseClient(
  UmsUtilityApiServiceV1,
  umsTransport
);

export const useUmsUtilityAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  UmsUtilityApiServiceV1,
  UmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
