// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.book.apis.proto (package geneo.lms.book.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchBookContentRequest, FetchBookContentResponse, FetchClassBooksRequest, FetchClassBooksResponse } from "./lms.book.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.book.apis.LmsBookAPIServiceV1
 */
export const LmsBookAPIServiceV1 = {
  typeName: "geneo.lms.book.apis.LmsBookAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.book.apis.LmsBookAPIServiceV1.fetchClassBooks
     */
    fetchClassBooks: {
      name: "fetchClassBooks",
      I: FetchClassBooksRequest,
      O: FetchClassBooksResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.book.apis.LmsBookAPIServiceV1.fetchBookContent
     */
    fetchBookContent: {
      name: "fetchBookContent",
      I: FetchBookContentRequest,
      O: FetchBookContentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

