import {
  IStyles,
  InputField,
  Loader,
  PrimaryButton,
  addOfflineUserRequest,
  callStudentEventsApi,
  clearLocalStorageKeys,
  deserify,
  generatePasswordHash,
  getEnvConfig,
  getLocalStorage,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  PasswordTypeEnum,
  StudentLoginResponseType,
} from '@protos/user_management/ums.login.apis_pb';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import { LOGIN } from '../../../routeHandling/RoutesNomenclature';
import {
  aiLogoutEvent,
  interactionEvent,
} from '../../Auth/Login/login_logout.events';
import { resetAuthState, setToastInfo as setAuthToastInfo } from '../../Auth/reducer/auth.slice';
import { resetHomeState, setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { resetHomeworkState } from '../../Homework/reducer/homework.slice';
import {
  aiHomeworkCloseEvent,
  aiHomeworkReviewCloseEvent,
} from '../../Homework/utils_homework/homework.events';
import { resetLearnState } from '../../Learn/reducer/learn.slice';
import {
  aiBookCloseEvent,
  aiChapterCloseEvent,
  aiContentCloseEvent,
  aiLessonCloseEvent,
  aiResourceCloseEvent,
  aiResourcePageCloseEvent,
  aiTopicCloseEvent,
} from '../../Learn/utils_learn/learn.events';
import { resetPerformanceState } from '../../Performance/reducer/performance.slice';
import { aiPerformanceCloseEvent } from '../../Performance/utils_performance/performance.events';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
const styles: IStyles = {
  root: {
    // height: '95vh',
    display: 'flex',
    paddingX: { xs: pxToRem(20), md: 0 },
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(40) },
    justifyContent: { xs: 'start', md: 'center' },
    alignSelf: 'center',
  },
  heading: {
    paddingTop: { xs: pxToRem(32), md: pxTovW(37) },
    textAlign: { xs: 'start', md: 'center' },
  },
};
export const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const commonServiceClientContext = useCommonServiceClientContext();
  const { userInfo, ssoRedirectionUrl } = deserify(
    useAppSelector((state) => state.auth)
  );
  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const { isOffline } = useDownloadContext();
  const {
    selected_chapter_resources,
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
  } = deserify(useAppSelector((state) => state.learn));
  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );

  const { selected_homework_data } = deserify(
    useAppSelector((state) => state.homework)
  );

  const config = getEnvConfig();
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [currentPassword, setCurrentPassword] = useState('');
  const [latestPassword, setLatestPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const { isIpadOnly } = useGlobalContext();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowcurrentPassword = () =>
    setCurrentShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const commonAiCloseEvents = async () => {
    await aiContentCloseEvent({
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent || [],
    });
    await aiResourcePageCloseEvent(
      selected_resource_content?.parsedData.resourceId,
      selected_resource_info?.pageNumber
    );
    await aiResourceCloseEvent(
      selected_resource_content?.parsedData.resourceId
    );
    await aiLessonCloseEvent(selected_lesson_content?.lessonId);
    await aiTopicCloseEvent(selected_module_lessons?.moduleId);
    await aiChapterCloseEvent(selected_chapter_resources?.chapterId);

    await aiPerformanceCloseEvent(performance_id);

    await aiHomeworkCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
    });
    await aiHomeworkReviewCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
    });
    await aiBookCloseEvent(selected_book_id);
    await aiLogoutEvent();
    !isOffline && (await callStudentEventsApi(commonServiceClientContext));
  };

  const confirmClickHandler = async () => {
    if (!currentPassword) {
      dispatch(
        setToastInfo({
          label: 'Current password not entered',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (!latestPassword) {
      dispatch(
        setToastInfo({
          label: 'New password not entered ',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (currentPassword === latestPassword) {
      dispatch(
        setToastInfo({
          label: 'Current password and new password should not be same',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (confirmPassword !== latestPassword) {
      dispatch(
        setToastInfo({
          label: 'Please confirm the right password ',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const updatePassword =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updatePassword({
          profileId: userInfo?.studentProfileId,
          oldPassword: currentPassword,
          newPassword: latestPassword,
          role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          passwordType: PasswordTypeEnum.PASSWORD_TYPE_UPDATE,
        });
      if (updatePassword.status === 200) {
        if (userInfo) {
          const passwordHash = generatePasswordHash(latestPassword);
          await addOfflineUserRequest(
            new StudentLoginResponseType(userInfo),
            passwordHash
          );
        }
        commonAiCloseEvents();
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
            profileId: userInfo?.studentProfileId,
            role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          });
        let redirectUrl = undefined;
        if (isMCBUser && ssoRedirectionUrl) {
          redirectUrl = ssoRedirectionUrl;
        }
        dispatch(resetAuthState());
        dispatch(resetHomeState());
        dispatch(resetLearnState());
        dispatch(resetPerformanceState());
        dispatch(resetHomeworkState());
        clearLocalStorageKeys();
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate(LOGIN);
        }
        setLoading('completed');
        dispatch(
          setAuthToastInfo({
            label: 'Password Updated Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'password',
          name: 'CONFIRM_PASSWORD',
          pathSegments: pathSegments,
        });
      } else {
        dispatch(
          setToastInfo({
            label: 'Password update failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (err) {
      dispatch(
        setToastInfo({
          label: 'Password update failed',
          variant: 'error',
          open: true,
        })
      );
      setLoading('error');
      console.log(err);
    }
  };

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1" paddingTop={1}>
          Change Your
        </Typography>
        <Typography
          variant="h1"
          fontWeight={'bold'}
          color={'primary.main'}
          paddingTop={2}
        >
          Password
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '50%',
            lg: pxTovW(774),
          },
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: pxToRem(20), md: pxTovW(30) },
          alignSelf: 'center',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            Current Password
          </Typography>
          <InputField
            value={currentPassword}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setCurrentPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type={currentShowPassword ? 'text' : 'password'}
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowcurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {currentShowPassword ? (
                      <Visibility
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            New Password
          </Typography>
          <InputField
            value={latestPassword}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setLatestPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type={showPassword ? 'text' : 'password'}
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            Confirm Password
          </Typography>
          <InputField
            value={confirmPassword}
            // onKeyDown={confirmClickHandler}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setConfirmPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type="password"
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '40%',
            lg: pxTovW(520),
          },
          display: 'flex',
          alignSelf: 'center',
          marginTop: { xs: pxToRem(10), md: pxTovW(13) },
        }}
      >
        <PrimaryButton
          // ref={passwordRef}
          fullWidth
          onClick={confirmClickHandler}
        // disabled={latestPassword === '' || confirmPassword === ''}
        >
          <Typography
            variant={isIpadOnly ? 'h1' : 'h2'}
            sx={{ color: 'common.white' }}
          >
            Confirm Password
          </Typography>
        </PrimaryButton>
      </Box>
    </Box>
  );
};
