// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.recommendation.apis.proto (package geneo.ai.student.recommendation.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetAIStudentResourceRecommendationRequest, GetAIStudentResourceRecommendationResponse } from "./ai.student.recommendation.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ai.student.recommendation.apis.AiStudentAPIServiceV1
 */
export const AiStudentAPIServiceV1 = {
  typeName: "geneo.ai.student.recommendation.apis.AiStudentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ai.student.recommendation.apis.AiStudentAPIServiceV1.getStudentResourceRecommendationfromGeneoAi
     */
    getStudentResourceRecommendationfromGeneoAi: {
      name: "getStudentResourceRecommendationfromGeneoAi",
      I: GetAIStudentResourceRecommendationRequest,
      O: GetAIStudentResourceRecommendationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

