// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/common/common.db.apis.proto (package geneo.common.db.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddAcademicYearRequest, FetchAcademicYearsRequest, FetchAcademicYearsResponse, FetchBoardsRequest, FetchBoardsResponse, FetchClassesRequest, FetchClassesResponse, FetchSubjectsRequest, FetchSubjectsResponse, FetchTeachMediumsRequest, FetchTeachMediumsResponse } from "./common.db.apis_pb";
import { AcademicYear } from "@protos/common/common.db_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.common.db.apis.CommonDbAPIServiceV1
 */
export const CommonDbAPIServiceV1 = {
  typeName: "geneo.common.db.apis.CommonDbAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.addAcademicYear
     */
    addAcademicYear: {
      name: "addAcademicYear",
      I: AddAcademicYearRequest,
      O: AcademicYear,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.fetchBoards
     */
    fetchBoards: {
      name: "fetchBoards",
      I: FetchBoardsRequest,
      O: FetchBoardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.fetchAcademicYears
     */
    fetchAcademicYears: {
      name: "fetchAcademicYears",
      I: FetchAcademicYearsRequest,
      O: FetchAcademicYearsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.fetchClasses
     */
    fetchClasses: {
      name: "fetchClasses",
      I: FetchClassesRequest,
      O: FetchClassesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.fetchSubjects
     */
    fetchSubjects: {
      name: "fetchSubjects",
      I: FetchSubjectsRequest,
      O: FetchSubjectsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.common.db.apis.CommonDbAPIServiceV1.fetchTeachMediums
     */
    fetchTeachMediums: {
      name: "fetchTeachMediums",
      I: FetchTeachMediumsRequest,
      O: FetchTeachMediumsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

