import {
  ChipBadge,
  IStyles,
  Loader,
  LockToggleButton,
  NoContentCard,
  TopicCard,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  ChapterMetaInfo,
  DownloadedSubject,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadButtonWrapper } from '../../../../../components/DownloadButtonWrapper';
import { useAppSelector } from '../../../../../reduxStore/reduxHooks';
import { TOPIC_LESSON } from '../../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../../Auth/Login/login_logout.events';

const styles: IStyles = {
  topicBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '& > div': {
      borderBottom: '1px solid #C6C6C6',
      width: {
        xs: '100%',
        md: `calc(100% - ${pxToRem(24)})`,
      },
      paddingLeft: { xs: '20px' },
      paddingY: {
        xs: 0,
        md: pxToRem(19),
      },
    },
  },
};

interface IChooseTopic {
  loading: boolean;
  error: boolean;
  lockedData?: ContentLockModuleData;
  showLockPopup: () => void;
}

export default function ChooseTopic(props: IChooseTopic) {
  const { loading, error, lockedData, showLockPopup } = props;
  const { subject_id, chapter_id } = useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const navigate = useNavigate();
  const chapterTopic = deserify(
    useAppSelector((state) => state.learn.selected_chapter_topic_info)
  );
  const topics = chapterTopic?.chapterTopics || [];
  const dispatch = useDispatch();
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const topicNavigation = async (topicId: number) => {
    await interactionEvent({
      url: 'Student_Learn_topic',
      context: 'Choose_a_Topic',
      name: 'VIEW',
    });
    const isLocked = lockedData?.moduleUpdatedLockInfo?.children.find(
      (val) => Number(val.moduleId) === topicId
    );
    if (
      isContentLockFeature &&
      isLocked?.lockStatus ===
        ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      showLockPopup();
      return;
    } else {
      navigate(`${TOPIC_LESSON}/${subjectId}/${chapterId}/${topicId}`);
    }
  };

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  useEffect(() => {
    if (
      subject_id &&
      chapter_id &&
      !isNaN(Number(subject_id)) &&
      !isNaN(Number(chapter_id))
    ) {
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        const meta = deepClone(downloadedMetaData);
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const offlineData =
            await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
              {
                chapterId: Number(chapter_id),
              }
            );
          meta[Number(chapter_id)] = offlineData.data;
        }
        setDownloadedMetaData(meta);
        setFetchingState(false);
      })();
    }
  }, [subject_id, chapter_id]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(7), md: pxTovW(10) },
          // backgroundColor: 'red',
          paddingLeft: { xs: pxToRem(20), md: 0 },
          paddingBottom: { xs: pxToRem(0), md: pxTovW(18) },
        }}
      >
        <Typography variant="h2">Choose a Topic</Typography>
        <Typography variant="h3">
          <ChipBadge label={topics.length} color="primary" size="small" />
        </Typography>
      </Box>
      {loading ? (
        <Loader />
      ) : error ? (
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      ) : topics.length === 0 ? (
        <NoContentCard
          variant="white"
          icon="coming-soon-yellow"
          text="No topics to show"
          rootStyle={{
            flexDirection: 'column',
            gap: { xs: pxToRem(20), md: pxTovW(20) },
          }}
        />
      ) : (
        <Box sx={styles.topicBox}>
          {topics.map((topic, index) => {
            return (
              <TopicCard
                key={'topic_' + index}
                // title={topic.topicNo + '. ' + topic.topicTitle}
                title={topic.topicTitle}
                lessonPlanCount={topic.topicContentStats?.lessonCount || 0}
                questionsCount={topic.topicContentStats?.questionCount || 0}
                viewAllClickHandler={() => topicNavigation(topic.topicId)}
                profileType={ProfileRolesEnum.PROFILE_ROLE_STUDENT}
                rootStyle={{
                  padding: {
                    xs: `${pxToRem(14)} ${pxToRem(5)} ${pxToRem(14)} ${pxToRem(
                      20
                    )}`,
                    md: `${pxTovW(14)} ${pxTovW(32)}`,
                  },
                }}
                isLocked={
                  isContentLockFeature &&
                  lockedData &&
                  lockedData?.moduleUpdatedLockInfo?.children.find(
                    (val) => val.moduleId === topic.topicId
                  )?.lockStatus ===
                    ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                    ? true
                    : false
                }
              >
                {!fetchingState && (
                  <Box
                    sx={{
                      marginLeft: '5px',
                      display: 'flex',
                      gap: { xs: pxToRem(10), md: pxTovW(7) },
                    }}
                  >
                    {isContentLockFeature &&
                      lockedData?.moduleUpdatedLockInfo?.children
                        ?.filter(
                          (val) =>
                            Number(val.moduleId) === Number(topic.topicId)
                        )
                        .map((vals, childIndex) => (
                          <LockToggleButton
                            key={childIndex}
                            isLocked={vals.lockStatus}
                          />
                        ))}
                    <DownloadButtonWrapper
                      user={new StudentLoginResponseType(user)}
                      downloadedSubject={
                        downloadedSubject
                          ? new DownloadedSubject(downloadedSubject)
                          : undefined
                      }
                      downloadedMetaData={downloadedMetaData}
                      setDownloadedMetaData={setDownloadedMetaData}
                      subjectId={subjectId}
                      classId={user?.classSectionDetails?.classId}
                      sectionId={user?.classSectionDetails?.sectionId}
                      chapterId={Number(chapter_id)}
                      topicId={topic.topicId}
                      title={`Topic - ${topic.topicTitle}`}
                      interactionEventUrl={'Student_Learn_topic'}
                      interactionEventContext={'topic'}
                      lockedData={lockedData}
                    />
                  </Box>
                )}
                {/* )} */}
              </TopicCard>
            );
          })}
        </Box>
      )}
    </>
  );
}
