// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.common.apis.proto (package geneo.lms.lesson.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { ChapterTopicMiniInfo, ResourceInfo } from "@protos/content_management/content.common.apis_pb";
import { CreationStatusEnum, Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * @generated from message geneo.lms.lesson.common.apis.FetchLessonContentRequest
 */
export class FetchLessonContentRequest extends Message<FetchLessonContentRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string lesson_id = 3;
   */
  lessonId = "";

  constructor(data?: PartialMessage<FetchLessonContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.FetchLessonContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchLessonContentRequest {
    return new FetchLessonContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchLessonContentRequest {
    return new FetchLessonContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchLessonContentRequest {
    return new FetchLessonContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchLessonContentRequest | PlainMessage<FetchLessonContentRequest> | undefined, b: FetchLessonContentRequest | PlainMessage<FetchLessonContentRequest> | undefined): boolean {
    return proto3.util.equals(FetchLessonContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.FetchLessonsByModuleRequest
 */
export class FetchLessonsByModuleRequest extends Message<FetchLessonsByModuleRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 module_id = 3;
   */
  moduleId = 0;

  /**
   * @generated from field: int32 section_id = 4;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 page_number = 5;
   */
  pageNumber = 0;

  /**
   * @generated from field: int32 items_per_page = 6;
   */
  itemsPerPage = 0;

  constructor(data?: PartialMessage<FetchLessonsByModuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.FetchLessonsByModuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchLessonsByModuleRequest {
    return new FetchLessonsByModuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchLessonsByModuleRequest {
    return new FetchLessonsByModuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchLessonsByModuleRequest {
    return new FetchLessonsByModuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchLessonsByModuleRequest | PlainMessage<FetchLessonsByModuleRequest> | undefined, b: FetchLessonsByModuleRequest | PlainMessage<FetchLessonsByModuleRequest> | undefined): boolean {
    return proto3.util.equals(FetchLessonsByModuleRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.FetchLessonsByModuleResponse
 */
export class FetchLessonsByModuleResponse extends Message<FetchLessonsByModuleResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.lesson.common.apis.LessonsByModule data = 4;
   */
  data?: LessonsByModule;

  constructor(data?: PartialMessage<FetchLessonsByModuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.FetchLessonsByModuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: LessonsByModule },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchLessonsByModuleResponse {
    return new FetchLessonsByModuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchLessonsByModuleResponse {
    return new FetchLessonsByModuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchLessonsByModuleResponse {
    return new FetchLessonsByModuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchLessonsByModuleResponse | PlainMessage<FetchLessonsByModuleResponse> | undefined, b: FetchLessonsByModuleResponse | PlainMessage<FetchLessonsByModuleResponse> | undefined): boolean {
    return proto3.util.equals(FetchLessonsByModuleResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.LessonsByModule
 */
export class LessonsByModule extends Message<LessonsByModule> {
  /**
   * @generated from field: int32 module_id = 1;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.common.apis.ChapterTopicMiniInfo topicInfo = 5;
   */
  topicInfo?: ChapterTopicMiniInfo;

  /**
   * @generated from field: repeated geneo.lms.lesson.common.apis.LessonInfo lessons = 2;
   */
  lessons: LessonInfo[] = [];

  /**
   * @generated from field: geneo.lms.lesson.common.apis.PaginationResult pagination_data = 6;
   */
  paginationData?: PaginationResult;

  constructor(data?: PartialMessage<LessonsByModule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.LessonsByModule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "topicInfo", kind: "message", T: ChapterTopicMiniInfo },
    { no: 2, name: "lessons", kind: "message", T: LessonInfo, repeated: true },
    { no: 6, name: "pagination_data", kind: "message", T: PaginationResult },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonsByModule {
    return new LessonsByModule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonsByModule {
    return new LessonsByModule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonsByModule {
    return new LessonsByModule().fromJsonString(jsonString, options);
  }

  static equals(a: LessonsByModule | PlainMessage<LessonsByModule> | undefined, b: LessonsByModule | PlainMessage<LessonsByModule> | undefined): boolean {
    return proto3.util.equals(LessonsByModule, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.PaginationResult
 */
export class PaginationResult extends Message<PaginationResult> {
  /**
   * @generated from field: int32 results = 1;
   */
  results = 0;

  /**
   * @generated from field: int32 page = 2;
   */
  page = 0;

  /**
   * @generated from field: int32 limit = 3;
   */
  limit = 0;

  /**
   * @generated from field: int32 total_pages = 4;
   */
  totalPages = 0;

  /**
   * @generated from field: int32 total_lessons = 5;
   */
  totalLessons = 0;

  constructor(data?: PartialMessage<PaginationResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.PaginationResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_lessons", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationResult {
    return new PaginationResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationResult {
    return new PaginationResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationResult {
    return new PaginationResult().fromJsonString(jsonString, options);
  }

  static equals(a: PaginationResult | PlainMessage<PaginationResult> | undefined, b: PaginationResult | PlainMessage<PaginationResult> | undefined): boolean {
    return proto3.util.equals(PaginationResult, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.LessonInfo
 */
export class LessonInfo extends Message<LessonInfo> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 5;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.CreationStatusEnum creation_status = 7;
   */
  creationStatus = CreationStatusEnum.CREATION_STATUS_UNDEFINED;

  /**
   * @generated from field: string poster_image_url = 8;
   */
  posterImageUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: int64 created_by = 10;
   */
  createdBy = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: int64 modified_by = 12;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: repeated string resource_ids = 13;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp last_session_time = 14;
   */
  lastSessionTime?: Timestamp;

  /**
   * @generated from field: string teacher_name = 15;
   */
  teacherName = "";

  /**
   * @generated from field: string teacher_profile_image_url = 16;
   */
  teacherProfileImageUrl = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 17;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: string subjectName = 18;
   */
  subjectName = "";

  /**
   * @generated from field: optional int32 parent_module_id = 19;
   */
  parentModuleId?: number;

  constructor(data?: PartialMessage<LessonInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.LessonInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "creation_status", kind: "enum", T: proto3.getEnumType(CreationStatusEnum) },
    { no: 8, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "last_session_time", kind: "message", T: Timestamp },
    { no: 15, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 18, name: "subjectName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "parent_module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonInfo {
    return new LessonInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonInfo {
    return new LessonInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonInfo {
    return new LessonInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LessonInfo | PlainMessage<LessonInfo> | undefined, b: LessonInfo | PlainMessage<LessonInfo> | undefined): boolean {
    return proto3.util.equals(LessonInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.TeachClassSubjects
 */
export class TeachClassSubjects extends Message<TeachClassSubjects> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class = 2;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: int32 subject_id = 5;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject = 6;
   */
  subject = "";

  constructor(data?: PartialMessage<TeachClassSubjects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.TeachClassSubjects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromJsonString(jsonString, options);
  }

  static equals(a: TeachClassSubjects | PlainMessage<TeachClassSubjects> | undefined, b: TeachClassSubjects | PlainMessage<TeachClassSubjects> | undefined): boolean {
    return proto3.util.equals(TeachClassSubjects, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.TeacherLessonInfo
 */
export class TeacherLessonInfo extends Message<TeacherLessonInfo> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 5;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.CreationStatusEnum creation_status = 7;
   */
  creationStatus = CreationStatusEnum.CREATION_STATUS_UNDEFINED;

  /**
   * @generated from field: string poster_image_url = 8;
   */
  posterImageUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: int64 created_by = 10;
   */
  createdBy = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: int64 modified_by = 12;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: repeated string resource_ids = 13;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp last_session_time = 14;
   */
  lastSessionTime?: Timestamp;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 15;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string teacher_name = 17;
   */
  teacherName = "";

  /**
   * @generated from field: string teacher_profile_image_url = 18;
   */
  teacherProfileImageUrl = "";

  /**
   * @generated from field: geneo.lms.lesson.common.apis.TeachClassSubjects teach_class_subjects = 19;
   */
  teachClassSubjects?: TeachClassSubjects;

  /**
   * @generated from field: optional int32 parent_module_id = 20;
   */
  parentModuleId?: number;

  constructor(data?: PartialMessage<TeacherLessonInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.TeacherLessonInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "creation_status", kind: "enum", T: proto3.getEnumType(CreationStatusEnum) },
    { no: 8, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "last_session_time", kind: "message", T: Timestamp },
    { no: 15, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 17, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "teach_class_subjects", kind: "message", T: TeachClassSubjects },
    { no: 20, name: "parent_module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLessonInfo {
    return new TeacherLessonInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLessonInfo {
    return new TeacherLessonInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLessonInfo {
    return new TeacherLessonInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLessonInfo | PlainMessage<TeacherLessonInfo> | undefined, b: TeacherLessonInfo | PlainMessage<TeacherLessonInfo> | undefined): boolean {
    return proto3.util.equals(TeacherLessonInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.FetchLessonContentResponse
 */
export class FetchLessonContentResponse extends Message<FetchLessonContentResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.lesson.common.apis.LessonContent data = 4;
   */
  data?: LessonContent;

  constructor(data?: PartialMessage<FetchLessonContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.FetchLessonContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: LessonContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchLessonContentResponse {
    return new FetchLessonContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchLessonContentResponse {
    return new FetchLessonContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchLessonContentResponse {
    return new FetchLessonContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchLessonContentResponse | PlainMessage<FetchLessonContentResponse> | undefined, b: FetchLessonContentResponse | PlainMessage<FetchLessonContentResponse> | undefined): boolean {
    return proto3.util.equals(FetchLessonContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.LessonContent
 */
export class LessonContent extends Message<LessonContent> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: string lesson_title = 2;
   */
  lessonTitle = "";

  /**
   * @generated from field: string lesson_poster_image_url = 3;
   */
  lessonPosterImageUrl = "";

  /**
   * @generated from field: string lesson_created_by = 4;
   */
  lessonCreatedBy = "";

  /**
   * @generated from field: repeated geneo.content.common.apis.ResourceInfo resources = 5;
   */
  resources: ResourceInfo[] = [];

  /**
   * @generated from field: repeated string learning_outcomes = 6;
   */
  learningOutcomes: string[] = [];

  constructor(data?: PartialMessage<LessonContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.LessonContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lesson_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lesson_poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lesson_created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "resources", kind: "message", T: ResourceInfo, repeated: true },
    { no: 6, name: "learning_outcomes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonContent {
    return new LessonContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonContent {
    return new LessonContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonContent {
    return new LessonContent().fromJsonString(jsonString, options);
  }

  static equals(a: LessonContent | PlainMessage<LessonContent> | undefined, b: LessonContent | PlainMessage<LessonContent> | undefined): boolean {
    return proto3.util.equals(LessonContent, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.common.apis.LearningOutcomeInfo
 */
export class LearningOutcomeInfo extends Message<LearningOutcomeInfo> {
  /**
   * @generated from field: int32 learning_outcome_id = 1;
   */
  learningOutcomeId = 0;

  /**
   * @generated from field: string learning_outcome = 2;
   */
  learningOutcome = "";

  constructor(data?: PartialMessage<LearningOutcomeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.common.apis.LearningOutcomeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "learning_outcome_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "learning_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomeInfo {
    return new LearningOutcomeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomeInfo {
    return new LearningOutcomeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomeInfo {
    return new LearningOutcomeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomeInfo | PlainMessage<LearningOutcomeInfo> | undefined, b: LearningOutcomeInfo | PlainMessage<LearningOutcomeInfo> | undefined): boolean {
    return proto3.util.equals(LearningOutcomeInfo, a, b);
  }
}

