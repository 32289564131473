import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
interface Iprops {
  checked: boolean;
  handleChange: () => void;
}
export const ToggleSwitch = (props: Iprops) => {
  const { checked, handleChange } = props;
  const transitionDuration = '0.3s'; // Set your desired transition duration

  const styles: IStyles = {
    box: {
      display: 'flex',
      flexDirection: 'row',
      border: '2px solid #0AA34F',
      width: { xs: pxToRem(121), md: pxTovW(140), lg: pxTovW(121) },
      height: { xs: pxToRem(46), md: pxTovW(55), lg: pxTovW(46) },
      justifyContent: 'space-around',
      background: '#E4FCEE',
      borderRadius: {
        xs: `${pxToRem(23)} !important`,
        md: `${pxToRem(23)} !important`,
      },
      cursor: 'pointer',
    },
  };
  return (
    <Box sx={styles.box} onClick={handleChange}>
      <Box
        sx={{
          width: { xs: pxToRem(19), md: pxTovW(19) },
          background: '#FFFFFF',
          height: { xs: pxToRem(19), md: pxTovW(19) },
          borderRadius: {
            xs: `${pxToRem(15)} !important`,
            md: `${pxToRem(15)} !important`,
          },
          marginY: 'auto',
          border: '3px solid #0AA34F',
          order: checked ? 2 : 1,
          // transition: `all ${transitionDuration} ease-in`,
        }}
      ></Box>
      <Box
        sx={{
          marginY: 'auto',
          order: checked ? 1 : 2,
          // transition: `all ${transitionDuration} ease-in`,
        }}
      >
        <Typography variant="h3" fontWeight={600} textAlign="left">
          {checked ? 'ON' : 'OFF'}
        </Typography>
      </Box>
    </Box>
  );
};
