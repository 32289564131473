import { ConnectTransportOptions } from '@bufbuild/connect-web';
import {
  lmsTransportWithLogoutHandler,
  ResponseStatusCodeFunction,
} from './transports';
import { createPromiseClient } from '@bufbuild/connect';
import { LmsAdminPortalAPIServiceV1 } from '@protos/learning_management/lms.admin.portal.apis_connect';
export const useLmsAdminAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsAdminPortalAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
