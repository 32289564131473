// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.student.apis.proto (package geneo.lms.hw.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateStudentHomeworkSessionRequest, GetStudentHWListRequest, SignedUrlReponse, SignedUrlRequest, StudentGetOCRTextRequest, StudentGetOCRTextResponse, StudentHomeworkListResponse, StudentHomeworkSessionResponse, StudentHWResponseFetchRequest, StudentHWResponseFetchResponse, StudentHWResponseSubmitRequest, StudentHWResponseSubmitResponse } from "./lms.hw.student.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1
 */
export const LmsHomewokStudentAPIServiceV1 = {
  typeName: "geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.getStudentHomeworkList
     */
    getStudentHomeworkList: {
      name: "getStudentHomeworkList",
      I: GetStudentHWListRequest,
      O: StudentHomeworkListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.fetchStudentHWResponse
     */
    fetchStudentHWResponse: {
      name: "fetchStudentHWResponse",
      I: StudentHWResponseFetchRequest,
      O: StudentHWResponseFetchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.createStudentHomeworkSession
     */
    createStudentHomeworkSession: {
      name: "createStudentHomeworkSession",
      I: CreateStudentHomeworkSessionRequest,
      O: StudentHomeworkSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.submitStudentHWResponse
     */
    submitStudentHWResponse: {
      name: "submitStudentHWResponse",
      I: StudentHWResponseSubmitRequest,
      O: StudentHWResponseSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.getOCRTextFromImage
     */
    getOCRTextFromImage: {
      name: "getOCRTextFromImage",
      I: StudentGetOCRTextRequest,
      O: StudentGetOCRTextResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV1.getSignedUrlForImageUpload
     */
    getSignedUrlForImageUpload: {
      name: "getSignedUrlForImageUpload",
      I: SignedUrlRequest,
      O: SignedUrlReponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV2
 */
export const LmsHomewokStudentAPIServiceV2 = {
  typeName: "geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV2",
  methods: {
    /**
     * @generated from rpc geneo.lms.hw.student.apis.LmsHomewokStudentAPIServiceV2.submitStudentHWResponse
     */
    submitStudentHWResponse: {
      name: "submitStudentHWResponse",
      I: StudentHWResponseSubmitRequest,
      O: StudentHWResponseSubmitResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

