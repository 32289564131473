import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { ContentCommonAPIServiceV2 } from '@protos/content_management/content.common.apis_connect';
import {
  ResponseStatusCodeFunction,
  cmsTransport,
  cmsTransportWithLogoutHandler,
} from './transports';

export const ContentCommonAPIServiceV2Client = createPromiseClient(
  ContentCommonAPIServiceV2,
  cmsTransport
);

export const useContentCommonAPIServiceV2ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    ContentCommonAPIServiceV2,
    cmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
