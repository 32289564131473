import {
  ChapterInfoCard,
  IStyles,
  getMediaBasePath,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SubjectChapterMiniInfo } from '@protos/content_management/content.common.apis_pb';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { useGlobalContext } from 'apps/student/src/app/Context/GlobalContextProvider';

const styles: IStyles = {
  heading: {
    marginTop: { xs: pxToRem(15), md: pxTovW(0) },
    marginBottom: { xs: pxToRem(15), md: pxTovW(15) },
    display: { xs: 'block', md: 'none' },
  },
};

interface IProps {
  chapter?: SubjectChapterMiniInfo;
  onChapterCardClick?: (chapterId: number) => void;
  isContentLockFeature?: boolean;
  lockStatus?: ContentLockStatusType;
}

const OngoingChapterCard = (props: IProps) => {
  const { chapter, onChapterCardClick, isContentLockFeature, lockStatus } =
    props;
  const theme = useTheme();
  const { isIpadOnly } = useGlobalContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!chapter) {
    return null;
  }
  return (
    <Box>
      <Typography variant="h2" sx={styles.heading}>
        Ongoing Chapter
      </Typography>

      <ChapterInfoCard
        variant={isIpadOnly ? 'large' : 'medium'}
        tagName={isMobile ? undefined : 'Ongoing'}
        mainHeading={chapter.chapterNo + '. ' + chapter.chapterTitle}
        blueSubText={(chapter.noOfTopics || 0) + ' Topics'}
        image={getMediaBasePath(
          chapter.posterImagesUrl,
          'processedMediaBucket'
        )}
        withArrow
        rootStyle={{
          // backgroundColor: 'red',
          width: { xs: '100%', md: pxToRem(360), lg: pxTovW(692) },
          cursor: 'pointer',
        }}
        cardClickHandler={() => {
          if (onChapterCardClick) {
            onChapterCardClick(chapter.chapterId);
          }
        }}
        isContentLockFeature={isContentLockFeature}
        isLocked={lockStatus}
      />
    </Box>
  );
};

export default OngoingChapterCard;
