import {
  ConnectTransportOptions,
  createConnectTransport,
} from '@bufbuild/connect-web';

const backend_url = process.env.NX_GENEO_BACKEND_URL;

export const BASE_URL = backend_url ? backend_url : 'https://dev2-api.geneo.in';

export interface ResponseStatusCodeFunction {
  [code: string]: () => void;
}

export const checkAuthAndFetch =
  (responseStatusCodeFunction?: ResponseStatusCodeFunction) =>
  async (input: RequestInfo | URL, init?: RequestInit) => {
    try {
      const res = await fetch(input, init);
      if (res.status === 401) {
        if (responseStatusCodeFunction && responseStatusCodeFunction['401']) {
          responseStatusCodeFunction['401']();
        } else {
          // console.log('Login Failed');
          localStorage.setItem('auth', 'false');
          window.location.reload();
        }
      }
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

export const checkAuthAndFetchDebounce =
  (responseStatusCodeFunction?: ResponseStatusCodeFunction) =>
  async (input: RequestInfo | URL, init?: RequestInit) => {
    // Create an AbortController instance
    const controller = new AbortController();
    const signal = controller.signal;
    const RESPONSE_WAIT_TIME = 5000;

    // Set a timeout to abort the fetch after 5 seconds
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, RESPONSE_WAIT_TIME);

    try {
      // Include the abort signal in the fetch request
      const response = await fetch(input, { ...init, signal });

      clearTimeout(timeoutId); // Clear the timeout if the fetch completes in time

      if (response.status === 401) {
        if (responseStatusCodeFunction && responseStatusCodeFunction['401']) {
          responseStatusCodeFunction['401']();
        } else {
          // console.log('Login Failed');
          localStorage.setItem('auth', 'false');
          window.location.reload();
        }
      }

      return response;
    } catch (error: any) {
      clearTimeout(timeoutId); // Ensure to clear the timeout in case of an error as well

      // Handle the AbortError specifically, indicating a timeout
      if (error?.name && error.name === 'AbortError') {
        console.error('Fetch request aborted due to timeout');
        throw new Error('Request timed out');
      } else {
        console.error('Fetch error:', error);
        throw error;
      }
    }
  };

// export const AI_URL = `http://0.0.0.0:3034`;
export const AI_URL = `${BASE_URL}/ai`;
export const studentEventsTransport = createConnectTransport({
  baseUrl: AI_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetchDebounce(),
});
export const teacherEventsTransport = createConnectTransport({
  baseUrl: AI_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetchDebounce(),
});
export const studentRecommendationTransport = createConnectTransport({
  baseUrl: AI_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetch(),
});
export const CMS_URL = `${BASE_URL}/cms`;
export const cmsTransport = createConnectTransport({
  baseUrl: CMS_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetch(),
});

export const LMS_URL = `${BASE_URL}/lms`;
export const lmsTransport = createConnectTransport({
  baseUrl: LMS_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetch(),
});

export const UMS_URL = `${BASE_URL}/ums`;
export const umsTransport = createConnectTransport({
  baseUrl: UMS_URL,
  credentials: 'include',
  useHttpGet: true,
  fetch: checkAuthAndFetch(),
});

export const studentEventsTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: AI_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetchDebounce(responseStatusCodeFunction),
    ...options,
  });
export const teacherEventsTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: AI_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetchDebounce(responseStatusCodeFunction),
    ...options,
  });
export const studentRecommendationTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: AI_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetch(responseStatusCodeFunction),
    ...options,
  });
export const cmsTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: CMS_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetch(responseStatusCodeFunction),
    ...options,
  });

export const lmsTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: LMS_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetch(responseStatusCodeFunction),
    ...options,
  });

export const UmsTransportWithLogoutHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction?: ResponseStatusCodeFunction
) =>
  createConnectTransport({
    baseUrl: UMS_URL,
    credentials: 'include',
    useHttpGet: true,
    fetch: checkAuthAndFetch(responseStatusCodeFunction),
    ...options,
  });
