import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { ContentCommonAPIServiceV1 } from '@protos/content_management/content.common.apis_connect';
import { ResponseStatusCodeFunction, cmsTransport, cmsTransportWithLogoutHandler } from './transports';

export const ContentCommonAPIServiceV1Client = createPromiseClient(
  ContentCommonAPIServiceV1,
  cmsTransport
);

//ContentCommonAPIServiceV1
export const useContentCommonAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  ContentCommonAPIServiceV1,
  cmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
