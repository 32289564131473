import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsHomeworkCommonAPIServiceV1 } from '@protos/learning_management/lms.hw.common.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsHomeworkCommonAPIServiceV1Client = createPromiseClient(
  LmsHomeworkCommonAPIServiceV1,
  lmsTransport
);
//LmsHomeworkCommonAPIServiceV1

export const useLmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsHomeworkCommonAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
