import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { ContentParserAPIServiceV1 } from '@protos/content_management/cms.content.apis_connect';
import { ResponseStatusCodeFunction, cmsTransport, cmsTransportWithLogoutHandler } from './transports';

export const ContentParserAPIServiceV1Client = createPromiseClient(
  ContentParserAPIServiceV1,
  cmsTransport
);

export const useContentParserAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  ContentParserAPIServiceV1,
  cmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
