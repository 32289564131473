import { Box, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import InputField from '../../elements/InputField';
const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(10), md: pxTovW(20) },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  helperText: {
    bgcolor: '#FFDFDF',
    color: 'error.main',
    padding: { xs: pxToRem(10), md: `${pxTovW(12)} ${pxTovW(30)}` },
  },
  helperText2: {
    height: { xs: pxToRem(20), md: pxTovW(40) },
  },
};
interface Iprops {
  topLabel: string;
  type?: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  helperTextvariant?: 'success' | 'error';
  onEnter?: () => void;
}
export const PasswordInputField = forwardRef((props: Iprops, ref) => {
  const {
    topLabel,
    type,
    value,
    onChange,
    helperTextvariant,
    helperText,
    onEnter,
  } = props;
  return (
    <Box sx={styles.root}>
      <Typography variant="cardText" fontWeight="bold">
        {topLabel}
      </Typography>
      <Box sx={styles.inputContainer}>
        <InputField
          variant="outlined"
          fullWidth
          boldtext
          nonCircular
          type={type ? type : 'text'}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => e.code === 'Enter' && onEnter && onEnter()}
          sx={{ bgcolor: 'neutral.lightBlue' }}
          inputProps={{ ref: ref }}
        />
        {helperText ? (
          <Typography
            variant="h4"
            sx={{
              bgcolor: variantMapping[helperTextvariant || 'error'].bgColor,
              color: variantMapping[helperTextvariant || 'error'].textColor,
              padding: {
                xs: pxToRem(10),
                md: `${pxTovW(12)} ${pxTovW(30)}`,
              },
              mb: { xs: pxToRem(30), md: pxTovW(30) },
            }}
          >
            {helperText}*
          </Typography>
        ) : (
          <Typography sx={styles.helperText2}></Typography>
        )}
      </Box>
    </Box>
  );
});

const variantMapping = {
  success: {
    bgColor: '#F1FFF7',
    textColor: '#0AA34F',
    border: '2px solid #0AA34F',
  },
  error: {
    bgColor: '#FFEAEA',
    textColor: '#F54040',
    border: '2px solid #F54040',
  },
};
