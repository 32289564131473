import { Dispatch, SetStateAction, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  InputAdornment,
  Modal,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material/styles';

import { pxToRem, pxTovW } from '../../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../../commonUtils/styleUtils';
import ImageWrapper from '../../../../../components/elements/ImageWrapper';
import InputField from '../../../../elements/InputField';
import PrimaryButton from '../../../../elements/PrimaryButton';

import AddCircleIcon from '@mui/icons-material/AddCircle';

const modalStyles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: pxToRem(20), md: pxToRem(20) },
    width: { xs: 'unset', md: 'max-content' },
    alignItems: { xs: 'center', md: 'unset' },
    height: 'max-content',
    margin: 'auto',
  },
  root: {
    // boxSizing: 'border-box',
    margin: 'auto',
    width: { xs: '80%', md: pxTovW(625) },
    // height: { xs: pxToRem(500), md: pxTovW(630) },
    minHeight: { xs: pxToRem(400), md: pxTovW(670) },
    backgroundColor: 'common.white',
    borderRadius: { xs: pxToRem(30), md: pxToRem(15) },
    paddingX: { xs: pxToRem(20), md: pxToRem(20) },
    paddingY: { xs: pxToRem(20), md: pxToRem(20) },
    display: 'flex',
    flexDirection: 'column',
  },
  marksEditBox: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxToRem(10) },
    width: 'max-content',
    margin: 'auto',
    mt: { xs: pxToRem(16), md: pxToRem(16) },
  },
  marksInput: {
    borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    // width: { xs: pxToRem(245), md: pxTovW(245) },
    width: { xs: pxToRem(245), md: pxTovW(220) },
    '& input': { padding: 0, textAlign: 'center' },
  },
  remarksBox: {
    // boxSizing: 'border-box',
    border: '1px solid #CCE6FE',
    borderRadius: { xs: pxToRem(10), md: pxToRem(10) },
    marginY: { xs: pxToRem(30), md: pxTovW(30) },
    bgcolor: '#F6FBFF',
    paddingX: { xs: pxToRem(20), md: pxToRem(20) },
    paddingY: { xs: pxToRem(15), md: pxToRem(15) },
    // flexGrow: '1',
  },
  remarksFieldBox: {
    // border: '1px solid red',
    // boxSizing: 'border-box',
    bgcolor: 'white',
    display: 'flex',
    flexDirection: 'column',
    mt: { xs: pxToRem(20), md: pxToRem(20) },
    overflowY: 'auto',
    // height: '24vh',
    // height: { xs: pxToRem(160), md: pxToRem(160) },
    maxHeight: { xs: pxToRem(160), md: pxToRem(160) },
    border: `${pxToRem(1)} solid #DFDFDF`,
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    minHeight: { xs: 'unset', md: pxTovW(280) },
  },
  addRemarks: {
    // boxSizing: 'border-box',
    boxSizing: { xs: 'border-box', md: 'unset' },
    borderBottom: '1px solid #D9D9D9',
    // height: { xs: pxToRem(60), md: pxTovW(70) },
    maxHeight: { xs: pxToRem(60), md: pxTovW(70) },
    flexGrow: 'unset',
    paddingX: { xs: pxToRem(30), md: pxTovW(16) },
    // paddingY: { xs: pxToRem(15), md: pxTovW(20) },
    paddingY: { xs: pxToRem(15), md: pxTovW(16) },
  },
  closeBox: {
    height: { xs: pxToRem(50), md: pxToRem(50) },
    width: { xs: pxToRem(50), md: pxToRem(50) },
    cursor: 'pointer',
  },
};
const customTheme = (outerTheme: Theme) =>
  createTheme({
    breakpoints: {
      values: { xs: 0, sm: 300, md: 680, lg: 1200, xl: 1920 },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&::before, &::after': {
              borderBottom: `${pxTovW(0.6)} solid #D9D9D9`,
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: `${pxTovW(0.6)} solid #D9D9D9`,
            },
            '&.Mui-focused:after': {
              borderBottom: `${pxTovW(2)} solid #007CDC`,
            },
          },
        },
      },
    },
  });

interface ModalProps {
  editMarksModal: boolean;
  setEditMarksModal: (arg: boolean) => void;
  receivedMarks: number;
  setMarksState: Dispatch<SetStateAction<number>>;
  totalMarks: number;
  saveClickHandler: () => void;
  remarks: string[];
  setRemarks: Dispatch<SetStateAction<string[]>>;
}
export const AllottedMarksModal = (props: ModalProps) => {
  const {
    editMarksModal,
    setEditMarksModal,
    receivedMarks,
    setMarksState,
    totalMarks,
    saveClickHandler,
    remarks,
    setRemarks,
  } = props;

  const outerTheme = useTheme();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [marksFocus, setMarksFocus] = useState(false);
  const [inputFocus, setInputFocus] = useState<number | null>(null);

  const modifyMarks = (operation: 'add' | 'reduce') => {
    let marks = parseFloat(receivedMarks.toString());
    const total = parseFloat(totalMarks.toString());
    if (operation === 'add' && marks < total) {
      marks = Math.min(total, marks + 0.5);
    } else if (operation === 'reduce' && marks > 0) {
      marks = Math.max(0, marks - 0.5);
    }
    // 0 and total will be displayed without decimal point
    // setMarksState(
    //   marks === 0.0 ? 0 : marks === total ? total : parseFloat(marks.toFixed(1))
    // );
    setMarksState(marks);
  };
  const saveMarksAndRemarks = () => {
    if (saveClickHandler) {
      saveClickHandler();
    }
  };
  const remarkChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    remIndex: number
  ) => {
    const value = event.target.value;
    setRemarks((prevRemarks) => {
      const newRemarks = [...prevRemarks];
      newRemarks[remIndex] = value;
      return newRemarks;
    });
  };

  const remarkDeleteHandler = (remIndex: number) => {
    if (remIndex === 0) {
      return;
    }
    setRemarks((prevRemarks) => {
      const newRemarks = [...prevRemarks];
      newRemarks.splice(remIndex, 1);
      return newRemarks;
    });
  };
  return (
    <Modal
      open={editMarksModal}
      onClose={() => setEditMarksModal(false)}
      sx={modalStyles.modal}
    >
      <>
        <Slide
          direction="up"
          in={editMarksModal}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Box sx={modalStyles.root}>
            <Typography variant="h2" fontWeight="medium" textAlign="center">
              Allotted Marks
            </Typography>

            <Box sx={modalStyles.marksEditBox}>
              <InputField
                variant="outlined"
                nonCircular
                value={receivedMarks}
                // onChange={(e) => {
                //   setMarksState(parseFloat(e.target.value));
                // }}
                boldtext
                borderWidth={4}
                fontSize={41}
                borderColor="#EEEEEE"
                fontColor={'secondary.main'}
                sx={modalStyles.marksInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={() => modifyMarks('reduce')}
                      sx={{ cursor: 'pointer' }}
                    >
                      <RemoveIcon
                        fontSize={isMobile ? 'large' : 'medium'}
                        color={receivedMarks === 0 ? 'disabled' : 'secondary'}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => modifyMarks('add')}
                      sx={{ cursor: 'pointer' }}
                    >
                      <AddIcon
                        fontSize={isMobile ? 'large' : 'medium'}
                        color={
                          receivedMarks === totalMarks
                            ? 'disabled'
                            : 'secondary'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography variant="h4" fontWeight="bold">
                out of {totalMarks} Marks
              </Typography>
            </Box>

            <Box
              onFocus={() => setMarksFocus(true)}
              onBlur={() => setMarksFocus(false)}
              sx={{
                ...modalStyles.remarksBox,
                borderColor: marksFocus ? 'neutral.cornflowerBlue' : '#EEEEEE',
              }}
            >
              <Typography variant="h2" fontWeight="bold">
                Remarks
              </Typography>

              <Box sx={modalStyles.remarksFieldBox}>
                {remarks.map((rem, remIndex) => (
                  <ThemeProvider key={remIndex} theme={customTheme(outerTheme)}>
                    <TextField
                      label={null}
                      variant="filled"
                      value={rem}
                      onFocus={() => setInputFocus(remIndex)}
                      onBlur={() => {
                        setInputFocus(null);
                        if (remIndex !== 0 && !rem) {
                          remarkDeleteHandler(remIndex);
                        }
                      }}
                      placeholder="Add your Remark"
                      onChange={(e) => remarkChangeHandler(e, remIndex)}
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: 'white',
                          paddingX: { xs: pxToRem(15), md: pxTovW(16) },
                          paddingY: { xs: pxToRem(20), md: pxTovW(20) },
                          fontSize: { xs: pxToRem(16), md: pxTovW(21) },
                          fontWeight: inputFocus === remIndex ? 'bold' : '500',
                        },
                        '& input': { padding: 0 },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => remarkDeleteHandler(remIndex)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ClearIcon fontSize="medium" color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                ))}

                {/* <Box
                onClick={() => {
                  setRemarks((prevRemarks) => {
                    const newRemarks = [...prevRemarks, ''];
                    setInputFocus(newRemarks.length - 1);
                    return newRemarks;
                  });
                }}
                sx={modalStyles.addRemarks}
              >
                <Typography variant="bodyText" color="#D9D9D9">
                  Add your Remarks
                </Typography>
              </Box> */}

                {/* <PrimaryButton
                  sx={{
                    mt: { xs: pxToRem(10), md: pxToRem(10) },
                    mb: { xs: pxToRem(10), md: pxToRem(10) },
                    margin: 'auto',
                  }}
                  disabled={
                    remarks.length > 0 &&
                    remarks[remarks.length - 1].trim() === ''
                  }
                  onClick={() => {
                    setRemarks((prevRemarks) => {
                      const newRemarks = [...prevRemarks, ''];
                      setInputFocus(newRemarks.length - 1);
                      return newRemarks;
                    });
                  }}
                >
                  <Typography
                    variant="bodyText"
                    color="success.light"
                    fontWeight="bold"
                  >
                    Add +
                  </Typography>
                </PrimaryButton> */}

                <AddCircleIcon
                  fontSize="large"
                  color={
                    remarks.length > 0 &&
                    remarks[remarks.length - 1].trim() === ''
                      ? 'disabled'
                      : 'primary'
                  }
                  onClick={() => {
                    if (remarks[remarks.length - 1].trim() !== '') {
                      setRemarks((prevRemarks) => {
                        const newRemarks = [...prevRemarks, ''];
                        setInputFocus(newRemarks.length - 1);
                        return newRemarks;
                      });
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                    mt: { xs: pxToRem(10), md: pxToRem(10) },
                    mb: { xs: pxToRem(10), md: pxToRem(10) },
                    margin: 'auto',
                  }}
                />
              </Box>
            </Box>

            <PrimaryButton
              sx={{
                margin: 'auto',
                width: { xs: pxToRem(267), md: pxTovW(267) },
              }}
              onClick={saveMarksAndRemarks}
            >
              <Typography
                variant="bodyText"
                color="success.light"
                fontWeight="bold"
              >
                Save
              </Typography>
            </PrimaryButton>
          </Box>
        </Slide>

        {/* <Box sx={modalStyles.closeBox} onClick={() => setEditMarksModal(false)}>
          <CloseIcon fontSize="large" color="inherit" />
        </Box> */}

        <ImageWrapper
          name="close-yellow"
          type="png"
          parentFolder="icons"
          onClick={() => setEditMarksModal(false)}
          styles={modalStyles.closeBox}
        />
      </>
    </Modal>
  );
};

/*
// <TextField
                //   label={null}
                //   variant="filled"
                //   value={rem}
                //   onFocus={() => setInputFocus(remIndex)}
                //   onBlur={() => setInputFocus(null)}
                //   onChange={(e) => {
                //     setRemarks((prevRemarks) => {
                //       const newRemarks = [...prevRemarks];
                //       newRemarks[remIndex] = e.target.value;
                //       return newRemarks;
                //     });
                //   }}
                //   sx={{
                //     bgcolor: 'white',
                //     padding: '0',
                //     borderColor: 'green',
                //     '& .MuiInputBase-root': {
                //       backgroundColor: 'white',
                //       paddingX: { xs: pxToRem(15), md: pxTovW(16) },
                //       paddingY: { xs: pxToRem(20), md: pxTovW(20) },
                //       fontSize: { xs: pxToRem(16), md: pxTovW(21) },
                //       fontWeight: inputFocus === remIndex ? 'bold' : '500',
                //     },
                //     '& .MuiOutlinedInput-root': {
                //       borderRadius: { xs: pxToRem(5), md: pxTovW(15) },
                //       padding: '0',
                //     },
                //     '& input': { padding: 0 },

                // '&::before, &::after': {
                //   borderBottom: `${pxTovW(0.6)} solid #D9D9D9`,
                // },
                // '&:hover:not(.Mui-disabled, .Mui-error):before': {
                //   borderBottom: `${pxTovW(0.6)} solid #D9D9D9`,
                // },
                // '&.Mui-focused:after': {
                //   borderBottom: `${pxTovW(2)} solid #007CDC`,
                // },
                //   }}
                //   InputProps={{
                //     endAdornment: (
                //       <InputAdornment
                //         position="end"
                //         onClick={() => {
                //           setRemarks((prevRemarks) => {
                //             const newRemarks = [...prevRemarks];
                //             newRemarks.splice(remIndex, 1);
                //             return newRemarks;
                //           });
                //         }}
                //         sx={{ cursor: 'pointer' }}
                //       >
                //         <ClearIcon fontSize="medium" color="primary" />
                //       </InputAdornment>
                //     ),
                //   }}
                // />
*/
