import { SxProps } from '@mui/system';
import { useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { ActionsPopup } from '../../composites/ActionsPopup';
import ImageWrapper from '../ImageWrapper';

const styles: IStyles = {};

interface IProps {
  title?: string;
  onClick: () => void;
  rootStyle?: SxProps;
}
export const DeleteButtonCommon = ({ title, onClick, rootStyle }: IProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <ImageWrapper
        onClick={async (ev?: React.MouseEvent) => {
          ev?.stopPropagation();
          setOpen(true);
        }}
        name={'delete2'}
        type="png"
        parentFolder="icons"
        styles={{
          cursor: 'pointer',
          height: { xs: pxToRem(40), md: pxTovW(40) },
          width: { xs: pxToRem(40), md: pxTovW(40) },
          ...rootStyle,
        }}
      />
      <ActionsPopup
        open={open}
        handleClose={handleClose}
        fontSmall
        iconName="delete"
        popupText={`Are you sure you want to delete \n${title || ''} ?`}
        yesClickHandler={(ev?: React.MouseEvent) => {
          handleClose();
          ev?.stopPropagation();
          onClick();
        }}
        noClickHandler={(ev?: React.MouseEvent) => {
          ev?.stopPropagation();
          handleClose();
        }}
      />
    </>
  );
};
