// import {
//   DeviceEnum,
//   ProfileRolesEnum,
// } from '@protos/user_management/ums.db_pb';
// import { FirebaseApp, initializeApp } from 'firebase/app';
// import {
//   MessagePayload,
//   Messaging,
//   getMessaging,
//   getToken,
//   onMessage,
// } from 'firebase/messaging';
// import React, { useEffect, useState } from 'react';
// import { useCommonServiceClientContext } from './CommonServiceClientProvider';
// import { getEnvConfig } from './config';
// import getDeviceType from './getDeviceType';
// import { getLocalStorage, setLocalStorage } from './utilFunctions';

// interface NotificationPayload {
//   title: string | undefined;
//   body: string | undefined;
// }

// const Notification: React.FC = () => {
//   const [notification, setNotification] = useState<NotificationPayload>({
//     title: '',
//     body: '',
//   });
//   const { UmsUtilityAPIServiceV1ClientWithStatusCodeHandler } =
//     useCommonServiceClientContext();

//   // Get environment configuration and initialize Firebase
//   const config = getEnvConfig();
//   const deviceType = getDeviceType();
//   const app: FirebaseApp = initializeApp(config.firebaseConfig);
//   const messaging: Messaging = getMessaging(app);

//   const topic = getLocalStorage('userId');
//   const role = getLocalStorage('role');
//   const roles =
//     role === 'teacher'
//       ? ProfileRolesEnum.PROFILE_ROLE_TEACHER
//       : ProfileRolesEnum.PROFILE_ROLE_STUDENT;
//   const deviceId = getLocalStorage('deviceID');
//   const subscribeTopic = true;
//   console.log(deviceId, 'deviceId');
//   const requestPermission = async () => {
//     console.log('Requesting permission...');
//     try {
//       const permission = await window.Notification.requestPermission();

//       if (permission === 'granted') {
//         const currentToken = await getToken(messaging, {
//           vapidKey: config.firebaseVapidKey,
//         });
//         const storedToken = sessionStorage.getItem('fcmToken');
//         console.log('Stored Token:', storedToken);
//         console.log('Current Token:', currentToken);
//         if (currentToken !== storedToken) {
//           sessionStorage.setItem('fcmToken', currentToken);
//           if (currentToken) {
//             console.log('Token:', currentToken);
//             const userId = getLocalStorage('userId');
//             if (userId) {
//               await fetchFirebaseAPI(userId, currentToken);
//               await subscribeFirebaseTopicAPI(
//                 userId,
//                 currentToken,
//                 subscribeTopic
//               );
//             }
//           } else {
//             console.log('No registration token available');
//           }
//         } else {
//           console.log('FCM token has not changed');
//         }
//       } else {
//         console.log('Unable to get permission to notify');
//       }
//     } catch (error) {
//       console.error('Error requesting permission:', error);
//     }
//   };

//   const fetchFirebaseAPI = async (userId: string, fcmtoken: string) => {
//     if (!userId) return;

//     try {
//       const deviceInfo = {
//         profileId: BigInt(userId),
//         role: roles,
//         deviceType: DeviceEnum.DEVICE_TYPE_WEB,
//         deviceToken: fcmtoken,
//         isActive: true,
//         deviceId: deviceId ? BigInt(deviceId) : undefined,
//       };

//       const response =
//         await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo(
//           deviceInfo
//         );
//       console.log('Firebase Token Response:', response);

//       if (response && response.data && response.data.deviceId) {
//         setLocalStorage('deviceID', Number(response.data.deviceId));
//       }
//     } catch (err) {
//       console.error('Error:', err);
//     }
//   };

//   const subscribeFirebaseTopicAPI = async (
//     userId: string,
//     fcmtoken: string,
//     subscribeTopic: boolean
//   ) => {
//     if (!userId) return;
//     console.log('subscribeTopic:', subscribeTopic);
//     console.log('userId:', typeof userId);

//     try {
//       const response =
//         await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
//           deviceToken: fcmtoken,
//           subscribeTopic: subscribeTopic,
//           topic: `${userId}`,
//         });
//       console.log('Firebase Token Response:', response);
//     } catch (err) {
//       console.error('Error:', err);
//     }
//   };

//   useEffect(() => {
//     const initializeFirebase = async () => {
//       if (deviceType === 'web' && topic && getLocalStorage('auth')) {
//         if (deviceType === 'web' && topic && getLocalStorage('auth')) {
//           await new Promise((resolve) => setTimeout(resolve, 2000)); // 2000ms delay
//           await requestPermission();
//         }
//       }

//       onMessage(messaging, (payload: MessagePayload) => {
//         if (payload.notification) {
//           setNotification({
//             title: payload.notification.title,
//             body: payload.notification.body,
//           });
//         }
//       });
//     };

//     initializeFirebase();
//   }, [deviceType, topic]);

//   return null;
//   // return (
//   //   <div>
//   //     {notification.title && (
//   //       <div>
//   //         <h1>{notification.title}</h1>
//   //         <p>{notification.body}</p>
//   //       </div>
//   //     )}
//   //   </div>
//   // );
// };

// export default Notification;

import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken } from 'firebase/messaging';
import { useState } from 'react';
import { useCommonServiceClientContext } from './CommonServiceClientProvider';
import { getEnvConfig } from './config';
import { getLocalStorage, setLocalStorage } from './utilFunctions';
export const useNotificationPermission = (profileType: string) => {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const { UmsUtilityAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const config = getEnvConfig();
  const app: FirebaseApp = initializeApp(config.firebaseConfig);

  const topic = getLocalStorage('userId');
  const role = getLocalStorage('role');
  const roles =
    profileType === 'teacher'
      ? ProfileRolesEnum.PROFILE_ROLE_TEACHER
      : ProfileRolesEnum.PROFILE_ROLE_STUDENT;
  const deviceId = getLocalStorage('deviceID');
  const subscribeTopic = true;

  const requestPermission = async (): Promise<void> => {
    try {
      const messaging: Messaging = getMessaging(app);
      const permission = await window.Notification.requestPermission();
      if (permission === 'granted') {
        setPermissionGranted(true);
        const currentToken = await getToken(messaging, {
          vapidKey: config.firebaseVapidKey,
        });
        const token = getLocalStorage('fcmToken');
        console.log('Stored Token:', token);
        if (currentToken !== String(token)) {
          console.log('Token:', currentToken);
          setLocalStorage('fcmToken', currentToken);
          if (currentToken) {
            const userId = getLocalStorage('userId');
            console.log('userId:', userId);
            if (userId) {
              await fetchFirebaseAPI(userId, currentToken);
              await subscribeFirebaseTopicAPI(
                userId,
                currentToken,
                subscribeTopic
              );
            } else {
              console.log('User ID not found');
            }
          } else {
            console.log('No registration token available');
          }
        } else {
          console.log('FCM token has not changed');
        }
      } else {
        console.log('Unable to get permission to notify');
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
    }
  };

  const fetchFirebaseAPI = async (
    userId: string,
    fcmtoken: string
  ): Promise<void> => {
    if (!userId) return;

    try {
      const deviceInfo = {
        profileId: BigInt(userId),
        role: roles,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmtoken,
        isActive: true,
        deviceId: deviceId ? BigInt(deviceId) : undefined,
      };

      const response =
        await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo(
          deviceInfo
        );
      console.log('Firebase Token Response:', response);
      if (response && response.data && response.data.deviceId) {
        setLocalStorage('deviceID', Number(response.data.deviceId));
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const subscribeFirebaseTopicAPI = async (
    userId: string,
    fcmtoken: string,
    subscribeTopic: boolean
  ): Promise<void> => {
    if (!userId) return;

    try {
      const response =
        await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
          deviceToken: fcmtoken,
          subscribeTopic: subscribeTopic,
          topic: `${userId}_${roles}`,
        });
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return { permissionGranted, requestPermission };
};
