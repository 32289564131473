import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsHomewokStudentAPIServiceV2 } from '@protos/learning_management/lms.hw.student.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsHomewokStudentAPIServiceV2Client = createPromiseClient(
  LmsHomewokStudentAPIServiceV2,
  lmsTransport
);

//LmsHomewokStudentAPIServiceV1
export const useLmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsHomewokStudentAPIServiceV2,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
