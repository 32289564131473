// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.student.apis.proto (package geneo.lms.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ResourceInfo } from "@protos/content_management/content.common.apis_pb";
import { LessonInfo } from "@protos/learning_management/lms.lesson.common.apis_pb";

/**
 * @generated from message geneo.lms.student.apis.FetchBannerRequest
 */
export class FetchBannerRequest extends Message<FetchBannerRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchBannerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.FetchBannerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBannerRequest {
    return new FetchBannerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBannerRequest {
    return new FetchBannerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBannerRequest {
    return new FetchBannerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBannerRequest | PlainMessage<FetchBannerRequest> | undefined, b: FetchBannerRequest | PlainMessage<FetchBannerRequest> | undefined): boolean {
    return proto3.util.equals(FetchBannerRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.FetchBannerResponse
 */
export class FetchBannerResponse extends Message<FetchBannerResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.student.apis.Banner banners = 4;
   */
  banners: Banner[] = [];

  constructor(data?: PartialMessage<FetchBannerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.FetchBannerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "banners", kind: "message", T: Banner, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBannerResponse {
    return new FetchBannerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBannerResponse {
    return new FetchBannerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBannerResponse {
    return new FetchBannerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBannerResponse | PlainMessage<FetchBannerResponse> | undefined, b: FetchBannerResponse | PlainMessage<FetchBannerResponse> | undefined): boolean {
    return proto3.util.equals(FetchBannerResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.Banner
 */
export class Banner extends Message<Banner> {
  /**
   * Add banner fields here.
   *
   * @generated from field: int32 banner_id = 1;
   */
  bannerId = 0;

  /**
   * @generated from field: optional string imageUrl = 2;
   */
  imageUrl?: string;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: optional string deeplink_url = 4;
   */
  deeplinkUrl?: string;

  /**
   * @generated from field: bool isActive = 5;
   */
  isActive = false;

  /**
   * @generated from field: int32 class_id = 6;
   */
  classId = 0;

  /**
   * @generated from field: string class = 7;
   */
  class = "";

  /**
   * @generated from field: optional int32 section_id = 8;
   */
  sectionId?: number;

  /**
   * @generated from field: optional string section = 9;
   */
  section?: string;

  /**
   * @generated from field: float score_percent = 10;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<Banner>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.Banner";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "banner_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "imageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deeplink_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Banner {
    return new Banner().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Banner {
    return new Banner().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Banner {
    return new Banner().fromJsonString(jsonString, options);
  }

  static equals(a: Banner | PlainMessage<Banner> | undefined, b: Banner | PlainMessage<Banner> | undefined): boolean {
    return proto3.util.equals(Banner, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentResourceRecommendationRequest
 */
export class GetStudentResourceRecommendationRequest extends Message<GetStudentResourceRecommendationRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 3;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 4;
   */
  topicId?: number;

  constructor(data?: PartialMessage<GetStudentResourceRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentResourceRecommendationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentResourceRecommendationRequest {
    return new GetStudentResourceRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendationRequest {
    return new GetStudentResourceRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendationRequest {
    return new GetStudentResourceRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentResourceRecommendationRequest | PlainMessage<GetStudentResourceRecommendationRequest> | undefined, b: GetStudentResourceRecommendationRequest | PlainMessage<GetStudentResourceRecommendationRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentResourceRecommendationRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentResourceRecommendationResponse
 */
export class GetStudentResourceRecommendationResponse extends Message<GetStudentResourceRecommendationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.student.apis.GetStudentResourceRecommendation data = 4;
   */
  data?: GetStudentResourceRecommendation;

  constructor(data?: PartialMessage<GetStudentResourceRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentResourceRecommendationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: GetStudentResourceRecommendation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentResourceRecommendationResponse {
    return new GetStudentResourceRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendationResponse {
    return new GetStudentResourceRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendationResponse {
    return new GetStudentResourceRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentResourceRecommendationResponse | PlainMessage<GetStudentResourceRecommendationResponse> | undefined, b: GetStudentResourceRecommendationResponse | PlainMessage<GetStudentResourceRecommendationResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentResourceRecommendationResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentResourceRecommendation
 */
export class GetStudentResourceRecommendation extends Message<GetStudentResourceRecommendation> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: repeated geneo.content.common.apis.ResourceInfo resources = 2;
   */
  resources: ResourceInfo[] = [];

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 3;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 4;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 5;
   */
  topicId?: number;

  constructor(data?: PartialMessage<GetStudentResourceRecommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentResourceRecommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resources", kind: "message", T: ResourceInfo, repeated: true },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentResourceRecommendation {
    return new GetStudentResourceRecommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendation {
    return new GetStudentResourceRecommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentResourceRecommendation {
    return new GetStudentResourceRecommendation().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentResourceRecommendation | PlainMessage<GetStudentResourceRecommendation> | undefined, b: GetStudentResourceRecommendation | PlainMessage<GetStudentResourceRecommendation> | undefined): boolean {
    return proto3.util.equals(GetStudentResourceRecommendation, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentLessonRecommendationRequest
 */
export class GetStudentLessonRecommendationRequest extends Message<GetStudentLessonRecommendationRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 3;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 4;
   */
  topicId?: number;

  constructor(data?: PartialMessage<GetStudentLessonRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentLessonRecommendationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentLessonRecommendationRequest {
    return new GetStudentLessonRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendationRequest {
    return new GetStudentLessonRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendationRequest {
    return new GetStudentLessonRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentLessonRecommendationRequest | PlainMessage<GetStudentLessonRecommendationRequest> | undefined, b: GetStudentLessonRecommendationRequest | PlainMessage<GetStudentLessonRecommendationRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentLessonRecommendationRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentLessonRecommendationResponse
 */
export class GetStudentLessonRecommendationResponse extends Message<GetStudentLessonRecommendationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.student.apis.GetStudentLessonRecommendation data = 4;
   */
  data?: GetStudentLessonRecommendation;

  constructor(data?: PartialMessage<GetStudentLessonRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentLessonRecommendationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: GetStudentLessonRecommendation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentLessonRecommendationResponse {
    return new GetStudentLessonRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendationResponse {
    return new GetStudentLessonRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendationResponse {
    return new GetStudentLessonRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentLessonRecommendationResponse | PlainMessage<GetStudentLessonRecommendationResponse> | undefined, b: GetStudentLessonRecommendationResponse | PlainMessage<GetStudentLessonRecommendationResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentLessonRecommendationResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.student.apis.GetStudentLessonRecommendation
 */
export class GetStudentLessonRecommendation extends Message<GetStudentLessonRecommendation> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: repeated geneo.lms.lesson.common.apis.LessonInfo lessons = 2;
   */
  lessons: LessonInfo[] = [];

  /**
   * optional
   *
   * @generated from field: optional int32 subject_id = 3;
   */
  subjectId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 chapter_id = 4;
   */
  chapterId?: number;

  /**
   * optional
   *
   * @generated from field: optional int32 topic_id = 5;
   */
  topicId?: number;

  constructor(data?: PartialMessage<GetStudentLessonRecommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.student.apis.GetStudentLessonRecommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lessons", kind: "message", T: LessonInfo, repeated: true },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentLessonRecommendation {
    return new GetStudentLessonRecommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendation {
    return new GetStudentLessonRecommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentLessonRecommendation {
    return new GetStudentLessonRecommendation().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentLessonRecommendation | PlainMessage<GetStudentLessonRecommendation> | undefined, b: GetStudentLessonRecommendation | PlainMessage<GetStudentLessonRecommendation> | undefined): boolean {
    return proto3.util.equals(GetStudentLessonRecommendation, a, b);
  }
}

