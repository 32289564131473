import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsBookAPIServiceV1 } from '@protos/learning_management/lms.book.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsBookAPIServiceV1Client = createPromiseClient(
  LmsBookAPIServiceV1,
  lmsTransport
);

export const useLmsBookAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsBookAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
