// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.lesson.learn.apis.proto (package geneo.lms.lesson.learn.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { SessionModeEnum, SessionStatusEnum, StudentLessonSessionMetrics } from "@protos/learning_management/lms.db_pb";
import { LessonInfo, PaginationResult } from "@protos/learning_management/lms.lesson.common.apis_pb";

/**
 * @generated from message geneo.lms.lesson.learn.apis.CreateStudentLessonSessionRequest
 */
export class CreateStudentLessonSessionRequest extends Message<CreateStudentLessonSessionRequest> {
  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 4;
   */
  academicYear = 0;

  /**
   * Grade of the student.
   *
   * @generated from field: int32 class_id = 5;
   */
  classId = 0;

  /**
   * Section of the student.
   *
   * @generated from field: string section = 6;
   */
  section = "";

  /**
   * Subject of the lesson session.
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 7;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * ID of the teacher's lesson session. Nullable field.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 8;
   */
  teacherLessonSessionId?: number;

  /**
   * List of resource IDs associated with the session.
   *
   * @generated from field: repeated string session_resource_ids = 9;
   */
  sessionResourceIds: string[] = [];

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 10;
   */
  startTime?: Timestamp;

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 11;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 12;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  constructor(data?: PartialMessage<CreateStudentLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.CreateStudentLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 8, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "session_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "start_time", kind: "message", T: Timestamp },
    { no: 11, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 12, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentLessonSessionRequest {
    return new CreateStudentLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentLessonSessionRequest {
    return new CreateStudentLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentLessonSessionRequest {
    return new CreateStudentLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentLessonSessionRequest | PlainMessage<CreateStudentLessonSessionRequest> | undefined, b: CreateStudentLessonSessionRequest | PlainMessage<CreateStudentLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateStudentLessonSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.UpdateStudentLessonSessionRequest
 */
export class UpdateStudentLessonSessionRequest extends Message<UpdateStudentLessonSessionRequest> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 student_lesson_session_id = 1;
   */
  studentLessonSessionId?: number;

  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: string lesson_id = 3;
   */
  lessonId = "";

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 4;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * ID of the teacher's lesson session. Nullable field.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 5;
   */
  teacherLessonSessionId?: number;

  /**
   * End time of the lesson session. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp end_time = 6;
   */
  endTime?: Timestamp;

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: optional geneo.lms.db.StudentLessonSessionMetrics metrics = 7;
   */
  metrics?: StudentLessonSessionMetrics;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 12;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  constructor(data?: PartialMessage<UpdateStudentLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.UpdateStudentLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 5, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "metrics", kind: "message", T: StudentLessonSessionMetrics, opt: true },
    { no: 12, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentLessonSessionRequest {
    return new UpdateStudentLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentLessonSessionRequest {
    return new UpdateStudentLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentLessonSessionRequest {
    return new UpdateStudentLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentLessonSessionRequest | PlainMessage<UpdateStudentLessonSessionRequest> | undefined, b: UpdateStudentLessonSessionRequest | PlainMessage<UpdateStudentLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentLessonSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.StudentLessonSessionResponse
 */
export class StudentLessonSessionResponse extends Message<StudentLessonSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 student_lesson_session_id = 4;
   */
  studentLessonSessionId = 0;

  constructor(data?: PartialMessage<StudentLessonSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.StudentLessonSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLessonSessionResponse {
    return new StudentLessonSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLessonSessionResponse {
    return new StudentLessonSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLessonSessionResponse {
    return new StudentLessonSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLessonSessionResponse | PlainMessage<StudentLessonSessionResponse> | undefined, b: StudentLessonSessionResponse | PlainMessage<StudentLessonSessionResponse> | undefined): boolean {
    return proto3.util.equals(StudentLessonSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.GetStudentPreviousLessonSessionRequest
 */
export class GetStudentPreviousLessonSessionRequest extends Message<GetStudentPreviousLessonSessionRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  constructor(data?: PartialMessage<GetStudentPreviousLessonSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.GetStudentPreviousLessonSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentPreviousLessonSessionRequest {
    return new GetStudentPreviousLessonSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentPreviousLessonSessionRequest {
    return new GetStudentPreviousLessonSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentPreviousLessonSessionRequest {
    return new GetStudentPreviousLessonSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentPreviousLessonSessionRequest | PlainMessage<GetStudentPreviousLessonSessionRequest> | undefined, b: GetStudentPreviousLessonSessionRequest | PlainMessage<GetStudentPreviousLessonSessionRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentPreviousLessonSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.StudentPreviousLessonSessionResponse
 */
export class StudentPreviousLessonSessionResponse extends Message<StudentPreviousLessonSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.lesson.learn.apis.StudentPreviousLessonSession data = 4;
   */
  data?: StudentPreviousLessonSession;

  constructor(data?: PartialMessage<StudentPreviousLessonSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.StudentPreviousLessonSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentPreviousLessonSession },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPreviousLessonSessionResponse {
    return new StudentPreviousLessonSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPreviousLessonSessionResponse {
    return new StudentPreviousLessonSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPreviousLessonSessionResponse {
    return new StudentPreviousLessonSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPreviousLessonSessionResponse | PlainMessage<StudentPreviousLessonSessionResponse> | undefined, b: StudentPreviousLessonSessionResponse | PlainMessage<StudentPreviousLessonSessionResponse> | undefined): boolean {
    return proto3.util.equals(StudentPreviousLessonSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.StudentPreviousLessonSession
 */
export class StudentPreviousLessonSession extends Message<StudentPreviousLessonSession> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  /**
   * @generated from field: repeated string completed_resource_ids = 3;
   */
  completedResourceIds: string[] = [];

  constructor(data?: PartialMessage<StudentPreviousLessonSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.StudentPreviousLessonSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "completed_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPreviousLessonSession {
    return new StudentPreviousLessonSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPreviousLessonSession {
    return new StudentPreviousLessonSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPreviousLessonSession {
    return new StudentPreviousLessonSession().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPreviousLessonSession | PlainMessage<StudentPreviousLessonSession> | undefined, b: StudentPreviousLessonSession | PlainMessage<StudentPreviousLessonSession> | undefined): boolean {
    return proto3.util.equals(StudentPreviousLessonSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.GetStudentOngoingLessonListRequest
 */
export class GetStudentOngoingLessonListRequest extends Message<GetStudentOngoingLessonListRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * optional
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * optional
   *
   * @generated from field: int32 chapter_id = 3;
   */
  chapterId = 0;

  /**
   * optional
   *
   * @generated from field: int32 topic_id = 4;
   */
  topicId = 0;

  /**
   * @generated from field: optional int32 section_id = 5;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 page_number = 6;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 items_per_page = 7;
   */
  itemsPerPage?: number;

  constructor(data?: PartialMessage<GetStudentOngoingLessonListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.GetStudentOngoingLessonListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentOngoingLessonListRequest {
    return new GetStudentOngoingLessonListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentOngoingLessonListRequest {
    return new GetStudentOngoingLessonListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentOngoingLessonListRequest {
    return new GetStudentOngoingLessonListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentOngoingLessonListRequest | PlainMessage<GetStudentOngoingLessonListRequest> | undefined, b: GetStudentOngoingLessonListRequest | PlainMessage<GetStudentOngoingLessonListRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentOngoingLessonListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.GetStudentOngoingLessonListResponse
 */
export class GetStudentOngoingLessonListResponse extends Message<GetStudentOngoingLessonListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.lesson.learn.apis.StudentOngoingLessonList data = 4;
   */
  data?: StudentOngoingLessonList;

  constructor(data?: PartialMessage<GetStudentOngoingLessonListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.GetStudentOngoingLessonListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentOngoingLessonList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentOngoingLessonListResponse {
    return new GetStudentOngoingLessonListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentOngoingLessonListResponse {
    return new GetStudentOngoingLessonListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentOngoingLessonListResponse {
    return new GetStudentOngoingLessonListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentOngoingLessonListResponse | PlainMessage<GetStudentOngoingLessonListResponse> | undefined, b: GetStudentOngoingLessonListResponse | PlainMessage<GetStudentOngoingLessonListResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentOngoingLessonListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.lesson.learn.apis.StudentOngoingLessonList
 */
export class StudentOngoingLessonList extends Message<StudentOngoingLessonList> {
  /**
   * @generated from field: repeated geneo.lms.lesson.common.apis.LessonInfo lessons = 1;
   */
  lessons: LessonInfo[] = [];

  /**
   * @generated from field: optional geneo.lms.lesson.common.apis.PaginationResult pagination_data = 5;
   */
  paginationData?: PaginationResult;

  constructor(data?: PartialMessage<StudentOngoingLessonList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.lesson.learn.apis.StudentOngoingLessonList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lessons", kind: "message", T: LessonInfo, repeated: true },
    { no: 5, name: "pagination_data", kind: "message", T: PaginationResult, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentOngoingLessonList {
    return new StudentOngoingLessonList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentOngoingLessonList {
    return new StudentOngoingLessonList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentOngoingLessonList {
    return new StudentOngoingLessonList().fromJsonString(jsonString, options);
  }

  static equals(a: StudentOngoingLessonList | PlainMessage<StudentOngoingLessonList> | undefined, b: StudentOngoingLessonList | PlainMessage<StudentOngoingLessonList> | undefined): boolean {
    return proto3.util.equals(StudentOngoingLessonList, a, b);
  }
}

