// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.admin.content.apis.proto (package geneo.cms.admin.content.upload.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { CloudUpload } from "@protos/learning_management/lms.db_pb";
import { Content_ContentEnum, Resource_ResourceEnum, ResourceCategoryEnum } from "@protos/content_management/content.db_pb";
import { ResourceInfo } from "@protos/content_management/content.common.apis_pb";

/**
 * we will soft delete
 *
 * @generated from message geneo.cms.admin.content.upload.apis.FetchResourceEditDetailsRequest
 */
export class FetchResourceEditDetailsRequest extends Message<FetchResourceEditDetailsRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  constructor(data?: PartialMessage<FetchResourceEditDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.FetchResourceEditDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsRequest {
    return new FetchResourceEditDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceEditDetailsRequest | PlainMessage<FetchResourceEditDetailsRequest> | undefined, b: FetchResourceEditDetailsRequest | PlainMessage<FetchResourceEditDetailsRequest> | undefined): boolean {
    return proto3.util.equals(FetchResourceEditDetailsRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.admin.content.upload.apis.FetchResourceEditDetailsResponse
 */
export class FetchResourceEditDetailsResponse extends Message<FetchResourceEditDetailsResponse> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: string cover_title = 2;
   */
  coverTitle = "";

  /**
   * should be cloud upload
   *
   * @generated from field: optional geneo.lms.db.CloudUpload cover_image_info = 3;
   */
  coverImageInfo?: CloudUpload;

  /**
   * @generated from field: int32 estimated_time_in_min = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 5;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * need to discuss
   *
   * @generated from field: optional geneo.content.db.Content.ContentEnum content_type = 6;
   */
  contentType?: Content_ContentEnum;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload files_info = 7;
   */
  filesInfo: CloudUpload[] = [];

  constructor(data?: PartialMessage<FetchResourceEditDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.FetchResourceEditDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cover_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cover_image_info", kind: "message", T: CloudUpload, opt: true },
    { no: 4, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 6, name: "content_type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum), opt: true },
    { no: 7, name: "files_info", kind: "message", T: CloudUpload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchResourceEditDetailsResponse {
    return new FetchResourceEditDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchResourceEditDetailsResponse | PlainMessage<FetchResourceEditDetailsResponse> | undefined, b: FetchResourceEditDetailsResponse | PlainMessage<FetchResourceEditDetailsResponse> | undefined): boolean {
    return proto3.util.equals(FetchResourceEditDetailsResponse, a, b);
  }
}

/**
 * we will soft delete
 *
 * @generated from message geneo.cms.admin.content.upload.apis.DeleteAdminContentRequest
 */
export class DeleteAdminContentRequest extends Message<DeleteAdminContentRequest> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: int64 admin_id = 2;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: optional geneo.content.db.Resource.ResourceEnum resource_type = 8;
   */
  resourceType?: Resource_ResourceEnum;

  constructor(data?: PartialMessage<DeleteAdminContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.DeleteAdminContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAdminContentRequest {
    return new DeleteAdminContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAdminContentRequest {
    return new DeleteAdminContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAdminContentRequest {
    return new DeleteAdminContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAdminContentRequest | PlainMessage<DeleteAdminContentRequest> | undefined, b: DeleteAdminContentRequest | PlainMessage<DeleteAdminContentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAdminContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.admin.content.upload.apis.DeleteAdminContentResponse
 */
export class DeleteAdminContentResponse extends Message<DeleteAdminContentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  constructor(data?: PartialMessage<DeleteAdminContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.DeleteAdminContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAdminContentResponse {
    return new DeleteAdminContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAdminContentResponse {
    return new DeleteAdminContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAdminContentResponse {
    return new DeleteAdminContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAdminContentResponse | PlainMessage<DeleteAdminContentResponse> | undefined, b: DeleteAdminContentResponse | PlainMessage<DeleteAdminContentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteAdminContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.admin.content.upload.apis.UpsertAdminContentRequest
 */
export class UpsertAdminContentRequest extends Message<UpsertAdminContentRequest> {
  /**
   * @generated from field: optional string resource_id = 1;
   */
  resourceId?: string;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: optional int32 topic_id = 3;
   */
  topicId?: number;

  /**
   * @generated from field: string cover_title = 4;
   */
  coverTitle = "";

  /**
   * should be cloud upload
   *
   * @generated from field: optional geneo.lms.db.CloudUpload cover_image_info = 5;
   */
  coverImageInfo?: CloudUpload;

  /**
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: bool content_lock = 7;
   */
  contentLock = false;

  /**
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 8;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * == course id for backend
   *
   * @generated from field: int32 subject_id = 10;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 section_id = 11;
   */
  sectionId = 0;

  /**
   * Maintain the sequence
   *
   * @generated from field: repeated geneo.lms.db.CloudUpload files_info = 12;
   */
  filesInfo: CloudUpload[] = [];

  /**
   * @generated from field: int64 admin_id = 13;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 14;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<UpsertAdminContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.UpsertAdminContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "cover_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "cover_image_info", kind: "message", T: CloudUpload, opt: true },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "content_lock", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 10, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "files_info", kind: "message", T: CloudUpload, repeated: true },
    { no: 13, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertAdminContentRequest {
    return new UpsertAdminContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertAdminContentRequest {
    return new UpsertAdminContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertAdminContentRequest {
    return new UpsertAdminContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertAdminContentRequest | PlainMessage<UpsertAdminContentRequest> | undefined, b: UpsertAdminContentRequest | PlainMessage<UpsertAdminContentRequest> | undefined): boolean {
    return proto3.util.equals(UpsertAdminContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.admin.content.upload.apis.UpsertAdminContentResponse
 */
export class UpsertAdminContentResponse extends Message<UpsertAdminContentResponse> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.content.common.apis.ResourceInfo resource_info = 2;
   */
  resourceInfo?: ResourceInfo;

  constructor(data?: PartialMessage<UpsertAdminContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.admin.content.upload.apis.UpsertAdminContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_info", kind: "message", T: ResourceInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertAdminContentResponse {
    return new UpsertAdminContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertAdminContentResponse {
    return new UpsertAdminContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertAdminContentResponse {
    return new UpsertAdminContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertAdminContentResponse | PlainMessage<UpsertAdminContentResponse> | undefined, b: UpsertAdminContentResponse | PlainMessage<UpsertAdminContentResponse> | undefined): boolean {
    return proto3.util.equals(UpsertAdminContentResponse, a, b);
  }
}

