import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { CloudUpload } from '@protos/learning_management/lms.db_pb';
import { getMediaBasePath } from '../../../commonUtils/images';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { theme } from '../../../theme/themeProvider';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import { FilePreviewCard } from '../FilePreviewCard';
import { GeneoKatex } from '../Question/GeneoKatex';
import { TextEditor } from '../TextEditor';

const styles: IStyles = {
  root: {
    // height: { xs: pxToRem(441), md: pxTovW(441) },
    // width: { xs: '100%', md: pxTovW(555) },
  },
  instructionBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    // backgroundColor: 'red',
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },

    backgroundColor: '#F6FBFF',
    border: '1px solid #CCE6FE',
    // padding: '10px',
  },
  instructionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: pxToRem(14), md: pxTovW(10) },
    boxSizing: 'border-box',
    // border: '1px solid #CCE6FE',
    width: '100%',

    gap: { xs: pxToRem(20), md: pxTovW(16) },
    // marginBottom: { xs: pxToRem(60) },

    // backgroundColor: 'blue',
    // justifyContent: 'space-between',
  },
  textBox: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '100%',
    flexShrink: 0,
    height: { xs: pxToRem(20), md: pxTovW(40) },
    paddingX: { xs: pxToRem(10), md: pxTovW(18) },
    gap: { xs: pxToRem(6.61), md: pxTovW(6.61) },
    justifyContent: 'space-between',
    // paddingTop: '20px',
  },
};
interface IProps {
  Katexvalue?: string;
  value?: string; // for controlling the value of rich text editor
  onChange?: (e: string | undefined) => void; // onChange function for rich text editor
  AttachmentList?: CloudUpload[]; // shows attachment list if both katexvalue is present
  onRedirect?: () => void;
  isEditable?: boolean; // if present instructionspanel becomes readonly
  editIconDisplay?: boolean;
  customSx?: SxProps;
  title?: string;
}
export const InstructionsPanel = (props: IProps) => {
  const {
    onChange,
    value,
    Katexvalue,
    AttachmentList,
    editIconDisplay,
    isEditable,
    onRedirect,
    customSx,
    title,
  } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(Katexvalue);
  return (
    <Box
      sx={
        customSx
          ? customSx
          : {
              display: 'flex',
              flexGrow: 1,
              maxHeight: { xs: pxToRem(420), lg: pxTovW(666) },
              height: !isEditable
                ? { xs: pxToRem(350), md: pxTovW(755), lg: pxTovW(400) }
                : { xs: pxToRem(338), md: pxTovW(955), lg: pxTovW(555) },
            }
      }
    >
      <Box
        sx={{
          ...styles.instructionBox,
          height: '100%',
        }}
      >
        <Box
          sx={{
            ...styles.instructionCard,

            height:
              !isEditable && AttachmentList && AttachmentList?.length > 0
                ? {
                    xs: `calc(100% - ${pxToRem(170)})`,
                    md: `calc(100% - ${pxTovW(350)})`,
                    lg: `calc(100% - ${pxTovW(220)})`,
                    xl: `calc(100% - ${pxTovW(190)})`,
                  }
                : '100%',
          }}
        >
          <Box
            sx={{
              ...styles.textBox,
            }}
          >
            <Box>
              <Typography variant="h2" fontWeight="bold">
                {title ? title : 'Instructions :'}
              </Typography>
            </Box>
            {editIconDisplay && (
              <Box>
                <IconWrapper
                  name="edit"
                  parentFolder="icons"
                  onClick={onRedirect && onRedirect}
                  type="png"
                  size="md"
                  customSx={{
                    height: { xs: pxToRem(34), md: pxTovW(34) },
                    width: { xs: pxToRem(34), md: pxTovW(34) },
                    cursor: 'pointer',
                  }}
                />
              </Box>
            )}
          </Box>
          {!isEditable ? (
            Katexvalue ? (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  boxSizing: 'border-box',
                  maxHeight: { xs: pxToRem(350), lg: pxTovW(350) },

                  overflow: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <div
                  style={{
                    height: '100% !important',
                    width: '100% !important',
                    overflow: 'scroll !important',
                  }}
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: new GeneoKatex(Katexvalue).parsedHtml(),
                  }}
                ></div>
              </Box>
            ) : (
              <Box></Box>
            )
          ) : (
            <TextEditor value={value} onChange={onChange} />
          )}
        </Box>
        {!isEditable && AttachmentList && AttachmentList?.length > 0 && (
          <Divider
            sx={{
              borderColor: '#CCE6FE !important',
            }}
          />
        )}

        {!isEditable && AttachmentList && AttachmentList?.length > 0 && (
          <Box
            sx={{
              width: '100%',
              // // height: '40%',
              // maxHeight: '40%',
              height: {
                xs: pxToRem(165),
                md: pxTovW(350),
                lg: pxTovW(200),
                xl: pxTovW(185),
              },
              // backgroundColor: 'red',
              display: 'flex',
              padding: { xs: pxToRem(15), md: pxToRem(15) },
              gap: { xs: pxToRem(15), md: pxTovW(15) },
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                boxSizing: 'border-box',
              }}
            >
              <AttachFileIcon
                sx={{
                  color: '#0AA34F !important',
                  height: { xs: pxToRem(12), md: pxTovW(21) },
                  width: { xs: pxToRem(14), md: pxTovW(24) },
                }}
              />
              <Typography
                variant={isMobile ? 'smallText' : 'bodyText'}
                sx={{ color: '#0AA34F !important' }}
              >
                {AttachmentList.length} files
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // flexGrow: 1,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Grid
                container
                columns={{ xs: 1, md: 2, lg: 2 }}
                rowGap={2}
                sx={{
                  height: '100%',
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: { xs: pxToRem(5), md: pxTovW(10) },
                  marginY: pxToRem(5),
                }}
              >
                {AttachmentList.map((elem) => (
                  <Grid item xs={1}>
                    <Box>
                      <FilePreviewCard
                        element={{
                          fileExtensionType: elem.fileExtensionType,
                          downloadUrl: getMediaBasePath(
                            elem.downloadUrl,
                            'schoolnetCdnImagesBucket'
                          ),
                          fileSizeInMb: elem.fileSizeInMb,
                          fileName: elem.fileName,
                          fileType: elem.fileType,
                          filePreviewUrl: getMediaBasePath(
                            elem.previewUrl,
                            'schoolnetCdnImagesBucket'
                          ),
                          gcpFileUrl: getMediaBasePath(
                            elem.gcpFileUrl,
                            'schoolnetCdnImagesBucket'
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
