// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.teacher.apis.proto (package geneo.lms.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message geneo.lms.teacher.apis.FetchTeacherBannersRequest
 */
export class FetchTeacherBannersRequest extends Message<FetchTeacherBannersRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchTeacherBannersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.teacher.apis.FetchTeacherBannersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTeacherBannersRequest {
    return new FetchTeacherBannersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTeacherBannersRequest {
    return new FetchTeacherBannersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTeacherBannersRequest {
    return new FetchTeacherBannersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTeacherBannersRequest | PlainMessage<FetchTeacherBannersRequest> | undefined, b: FetchTeacherBannersRequest | PlainMessage<FetchTeacherBannersRequest> | undefined): boolean {
    return proto3.util.equals(FetchTeacherBannersRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.teacher.apis.FetchTeacherBannersResponse
 */
export class FetchTeacherBannersResponse extends Message<FetchTeacherBannersResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.teacher.apis.Banner banners = 4;
   */
  banners: Banner[] = [];

  constructor(data?: PartialMessage<FetchTeacherBannersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.teacher.apis.FetchTeacherBannersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "banners", kind: "message", T: Banner, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTeacherBannersResponse {
    return new FetchTeacherBannersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTeacherBannersResponse {
    return new FetchTeacherBannersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTeacherBannersResponse {
    return new FetchTeacherBannersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTeacherBannersResponse | PlainMessage<FetchTeacherBannersResponse> | undefined, b: FetchTeacherBannersResponse | PlainMessage<FetchTeacherBannersResponse> | undefined): boolean {
    return proto3.util.equals(FetchTeacherBannersResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.teacher.apis.Banner
 */
export class Banner extends Message<Banner> {
  /**
   * Add banner fields here.
   *
   * @generated from field: int32 banner_id = 1;
   */
  bannerId = 0;

  /**
   * @generated from field: optional string imageUrl = 2;
   */
  imageUrl?: string;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: optional string deeplink_url = 4;
   */
  deeplinkUrl?: string;

  /**
   * @generated from field: bool isActive = 5;
   */
  isActive = false;

  /**
   * @generated from field: int32 class_id = 6;
   */
  classId = 0;

  /**
   * @generated from field: string class = 7;
   */
  class = "";

  /**
   * @generated from field: optional int32 section_id = 8;
   */
  sectionId?: number;

  /**
   * @generated from field: optional string section = 9;
   */
  section?: string;

  /**
   * @generated from field: optional int32 subject_id = 10;
   */
  subjectId?: number;

  /**
   * @generated from field: optional string subject = 11;
   */
  subject?: string;

  /**
   * @generated from field: float score_percent = 12;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<Banner>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.teacher.apis.Banner";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "banner_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "imageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deeplink_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Banner {
    return new Banner().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Banner {
    return new Banner().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Banner {
    return new Banner().fromJsonString(jsonString, options);
  }

  static equals(a: Banner | PlainMessage<Banner> | undefined, b: Banner | PlainMessage<Banner> | undefined): boolean {
    return proto3.util.equals(Banner, a, b);
  }
}

