import {
  clearHomeworkData,
  clearReviewHomeworkData,
  getHomeworkCloseEvent,
  getHomeworkOpenEvent,
  getHomeworkReviewCloseEvent,
  getHomeworkReviewOpenEvent,
  getQuestionOpenEvent,
  getQuestionSubmitEvent,
  homeworkClose,
  homeworkOpen,
  homeworkReviewClose,
  homeworkReviewOpen,
  isHomeworkOpen,
  isReviewHomeworkOpen,
  questionOpen,
  questionSubmit,
} from '@events/student/eventsCreator/homework';
import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';
import { QAttemptResultEnum } from '@protos/geneo_ai/ai.student.events.data_pb';
import { TaskEnum } from '@protos/learning_management/lms.db_pb';

//* ------------------ Homework Events ------------------
//& --------------- Homework Open / Close ---------------
interface HomeworkOpenInterface {
  homeworkId: number | undefined;
  questionIds: string[] | undefined;
  isResume: boolean | undefined;
  isReattempted: boolean | undefined;
  homeworkType: TaskEnum | undefined;
}
export const aiHomeworkOpenEvent = async (
  homeworkProps: HomeworkOpenInterface
) => {
  try {
    const { homeworkId, questionIds, isResume, isReattempted, homeworkType } =
      homeworkProps;

    if (
      homeworkId &&
      questionIds &&
      homeworkType &&
      !isHomeworkOpen({ homeworkId: homeworkId })
    ) {
      homeworkOpen({
        openTimestamp: new Date(),
        homeworkId: homeworkId,
        questionIds: questionIds,
        homeworkType: homeworkType,
      });

      const getEventData = getHomeworkOpenEvent({
        homeworkId: homeworkId,
        isResume: isResume,
        isReattempted: isReattempted,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface HomeworkCloseInterface {
  homeworkId: number | undefined;
}
export const aiHomeworkCloseEvent = async (
  homeworkProps: HomeworkCloseInterface
) => {
  try {
    const { homeworkId } = homeworkProps;

    if (homeworkId && isHomeworkOpen({ homeworkId: homeworkId })) {
      homeworkClose({ closeTimestamp: new Date(), homeworkId: homeworkId });

      const getEventData = getHomeworkCloseEvent({
        homeworkId: homeworkId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      clearHomeworkData({ homeworkId: homeworkId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* ------------------ Question Events ------------------
//& --------------- Question Open / Submit --------------
interface QuestionOpenInterface {
  questionId: string | undefined;
  homeworkId: number | undefined;
}
export const aiQuestionOpenEvent = async (
  questionProps: QuestionOpenInterface
) => {
  try {
    const { questionId, homeworkId } = questionProps;

    if (questionId && homeworkId) {
      questionOpen({
        // openTimestamp: new Date(new Date().getTime() + 10000),
        // openTimestamp: new Date('10-10-2023'),
        openTimestamp: new Date(),
        questionId: questionId,
        homeworkId: homeworkId,
      });

      const getEventData = getQuestionOpenEvent({
        homeworkId: homeworkId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }

      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface QuestionSubmitInterface {
  questionId: string | undefined;
  homeworkId: number | undefined;
  submitStatus: QAttemptResultEnum | undefined;
  answer: string[];
}
export const aiQuestionSubmitEvent = async (
  questionProps: QuestionSubmitInterface
) => {
  try {
    const { questionId, homeworkId, submitStatus, answer } = questionProps;

    if (questionId && homeworkId && submitStatus) {
      questionSubmit({
        closeTimestamp: new Date(),
        questionId: questionId,
        homeworkId: homeworkId,
        status: submitStatus,
        answer: answer,
      });

      const getEventData = getQuestionSubmitEvent({
        homeworkId: homeworkId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* ------------------ Homework Review Events ------------------
//& --------------- Homework Review Open / Close ---------------
export const aiHomeworkReviewOpenEvent = async (homeworkProps: {
  homeworkId: number | undefined;
  questionIds: string[] | undefined;
  homeworkType: TaskEnum | undefined;
}) => {
  try {
    const { homeworkId, questionIds, homeworkType } = homeworkProps;
    if (
      homeworkId &&
      questionIds &&
      homeworkType &&
      !isReviewHomeworkOpen({ homeworkId: homeworkId })
    ) {
      homeworkReviewOpen({
        openTimestamp: new Date(),
        homeworkId: homeworkId,
        questionIds: questionIds,
        homeworkType: homeworkType,
      });

      const getEventData = getHomeworkReviewOpenEvent({
        homeworkId: homeworkId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiHomeworkReviewCloseEvent = async (
  homeworkProps: HomeworkCloseInterface
) => {
  try {
    const { homeworkId } = homeworkProps;

    if (homeworkId && isReviewHomeworkOpen({ homeworkId: homeworkId })) {
      homeworkReviewClose({
        closeTimestamp: new Date(),
        homeworkId: homeworkId,
      });

      const getEventData = getHomeworkReviewCloseEvent({
        homeworkId: homeworkId,
      });
      if (getEventData.timestamp) {
        await addEventRequest({
          key: getEventData.timestamp?.toJsonString(),
          value: getEventData.toJson(),
        });
      }
      clearReviewHomeworkData({ homeworkId: homeworkId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(getEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
