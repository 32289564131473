import { useCommonServiceClientContext } from '@geneo2-web/shared-ui';
import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { useEffect, useState } from 'react';

export interface ILockedInfoParams {
  subjectId: number;
  studentProfileId?: bigint;
  category: Module_ModuleCategoryEnum;
  moduleId?: number;
  lessonId?: string;
  isFeatureAvailable: boolean;
}

const useFetchLockedResourceIds = (params: ILockedInfoParams) => {
  const {
    subjectId,
    studentProfileId,
    category,
    moduleId,
    lessonId,
    isFeatureAvailable,
  } = params;
  const commonServiceClientContext = useCommonServiceClientContext();
  const { LmsCommonAPIServiceV1ClientWithStatusCodeHandler } =
    commonServiceClientContext;
  const [lockedResourceIds, setLockedResourceIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const studentModuleLockInfoFetch = async () => {
    try {
      setError(false);
      setLoading(true);
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.studentModuleLockInfoFetch(
          {
            subjectId: subjectId,
            studentId: studentProfileId,
            moduleId: moduleId,
            category: category,
          }
        );
      if (response?.data) {
        const data = response.data;
        if (data) {
          if (lessonId) {
            const lessonlockedResourceIds =
              data.moduleUpdatedLockInfo?.lessonsLockInfo.find(
                (e) => e.lessonId === lessonId
              )?.lockedResourceIds;
            setLockedResourceIds(lessonlockedResourceIds || []);
          } else {
            setLockedResourceIds(
              data.moduleUpdatedLockInfo?.lockedResourceIds || []
            );
          }
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (studentProfileId && moduleId && isFeatureAvailable) {
      studentModuleLockInfoFetch();
    }
  }, [lessonId, moduleId]);

  return { lockedResourceIds, loading, error };
};

export default useFetchLockedResourceIds;
