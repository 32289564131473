// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.student.apis.proto (package geneo.lms.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchBannerRequest, FetchBannerResponse, GetStudentLessonRecommendationRequest, GetStudentLessonRecommendationResponse, GetStudentResourceRecommendationRequest, GetStudentResourceRecommendationResponse } from "./lms.student.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.student.apis.LmsStudentAPIServiceV1
 */
export const LmsStudentAPIServiceV1 = {
  typeName: "geneo.lms.student.apis.LmsStudentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.student.apis.LmsStudentAPIServiceV1.fetchStudentBanner
     */
    fetchStudentBanner: {
      name: "fetchStudentBanner",
      I: FetchBannerRequest,
      O: FetchBannerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.student.apis.LmsStudentAPIServiceV1.getStudentResourceRecommendation
     */
    getStudentResourceRecommendation: {
      name: "getStudentResourceRecommendation",
      I: GetStudentResourceRecommendationRequest,
      O: GetStudentResourceRecommendationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.student.apis.LmsStudentAPIServiceV1.getStudentLessonRecommendation
     */
    getStudentLessonRecommendation: {
      name: "getStudentLessonRecommendation",
      I: GetStudentLessonRecommendationRequest,
      O: GetStudentLessonRecommendationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

