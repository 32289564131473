import { Disclaimer } from '@geneo2-web/shared-ui';
import { useLocation } from 'react-router-dom';
import { interactionEvent } from '../../Auth/Login/login_logout.events';

export const StudentDisclaimer = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  return (
    <Disclaimer
      eventCollection={{
        tabInteraction: async (name: string | undefined) => {
          if (name)
            await interactionEvent({
              url: '',
              context: 'disclaimer_&_policies',
              name: name,
              pathSegments: pathSegments,
            });
        },
      }}
    />
  );
};
