import { LinearProgress } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';

interface IProps {
  score: number;
  variant: 'small' | 'md' | 'lg';
  customSx?: SxProps;
}

export const ScoreProgressBarLinear = (props: IProps) => {
  const { score, variant, customSx } = props;

  const formattedScore = score > 100 ? 100 : Number(score.toFixed(2));

  return (
    <Box sx={{ position: 'relative', width: '100%', boxSizing: 'border-box' }}>
      <LinearProgress
        variant="determinate"
        sx={{
          height:
            variant === 'small'
              ? { xs: pxToRem(14), md: pxTovW(16) }
              : variant === 'md'
              ? { xs: pxToRem(16), md: pxTovW(24) }
              : { xs: pxToRem(18), md: pxTovW(27) },
          borderRadius: { xs: pxToRem(6), md: pxTovW(6) },
          backgroundColor: '#CBF0DC',
          width: '100%',
          '& .MuiLinearProgress-barColorPrimary': {
            borderRadius: { xs: pxToRem(6), md: pxTovW(6) },
            backgroundImage: 'linear-gradient(90deg, #0AA34F 0%, #22E97C 100%)',
          },
          ...customSx,
        }}
        // color="transparent linear-gradient(90deg, #0AA34F 0%, #22E97C 100%) 0% 0% no-repeat padding-box"
        value={formattedScore}
      />
    </Box>
  );
};
