import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsCommonAPIServiceV1 } from '@protos/learning_management/lms.common.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LmsCommonAPIServiceV1Client = createPromiseClient(
  LmsCommonAPIServiceV1,
  lmsTransport
);

//LmsCommonAPIServiceV1

export const useLmsCommonAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LmsCommonAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
