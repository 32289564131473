// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.student.events.apis.proto (package geneo.ai.student.events.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum geneo.ai.student.events.apis.sessionCloseTypeEnum
 */
export enum sessionCloseTypeEnum {
  /**
   * @generated from enum value: STUDENT_LOGIN_SESSION = 0;
   */
  STUDENT_LOGIN_SESSION = 0,

  /**
   * @generated from enum value: ACTIVE_SESSION = 1;
   */
  ACTIVE_SESSION = 1,

  /**
   * @generated from enum value: STUDENT_RESOURCE_SESSION = 2;
   */
  STUDENT_RESOURCE_SESSION = 2,

  /**
   * @generated from enum value: STUDENT_LESSON_SESSION = 3;
   */
  STUDENT_LESSON_SESSION = 3,

  /**
   * @generated from enum value: STUDENT_HOMEWORK_SESSION = 4;
   */
  STUDENT_HOMEWORK_SESSION = 4,

  /**
   * @generated from enum value: TEACHER_LOGIN_SESSION = 5;
   */
  TEACHER_LOGIN_SESSION = 5,

  /**
   * @generated from enum value: TEACHER_RESOURCE_SESSION = 7;
   */
  TEACHER_RESOURCE_SESSION = 7,

  /**
   * @generated from enum value: TEACHER_LESSON_SESSION = 8;
   */
  TEACHER_LESSON_SESSION = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(sessionCloseTypeEnum)
proto3.util.setEnumType(sessionCloseTypeEnum, "geneo.ai.student.events.apis.sessionCloseTypeEnum", [
  { no: 0, name: "STUDENT_LOGIN_SESSION" },
  { no: 1, name: "ACTIVE_SESSION" },
  { no: 2, name: "STUDENT_RESOURCE_SESSION" },
  { no: 3, name: "STUDENT_LESSON_SESSION" },
  { no: 4, name: "STUDENT_HOMEWORK_SESSION" },
  { no: 5, name: "TEACHER_LOGIN_SESSION" },
  { no: 7, name: "TEACHER_RESOURCE_SESSION" },
  { no: 8, name: "TEACHER_LESSON_SESSION" },
]);

/**
 * @generated from message geneo.ai.student.events.apis.GetSessionIdDataRequest
 */
export class GetSessionIdDataRequest extends Message<GetSessionIdDataRequest> {
  /**
   * @generated from field: repeated geneo.ai.student.events.apis.GetSessionIdDataRequest.sessionTypeRequest session_types = 1;
   */
  sessionTypes: GetSessionIdDataRequest_sessionTypeRequest[] = [];

  constructor(data?: PartialMessage<GetSessionIdDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.GetSessionIdDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_types", kind: "message", T: GetSessionIdDataRequest_sessionTypeRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionIdDataRequest {
    return new GetSessionIdDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionIdDataRequest {
    return new GetSessionIdDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionIdDataRequest {
    return new GetSessionIdDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionIdDataRequest | PlainMessage<GetSessionIdDataRequest> | undefined, b: GetSessionIdDataRequest | PlainMessage<GetSessionIdDataRequest> | undefined): boolean {
    return proto3.util.equals(GetSessionIdDataRequest, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.GetSessionIdDataRequest.sessionTypeRequest
 */
export class GetSessionIdDataRequest_sessionTypeRequest extends Message<GetSessionIdDataRequest_sessionTypeRequest> {
  /**
   * @generated from field: geneo.ai.student.events.apis.sessionCloseTypeEnum type = 1;
   */
  type = sessionCloseTypeEnum.STUDENT_LOGIN_SESSION;

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<GetSessionIdDataRequest_sessionTypeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.GetSessionIdDataRequest.sessionTypeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(sessionCloseTypeEnum) },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionIdDataRequest_sessionTypeRequest {
    return new GetSessionIdDataRequest_sessionTypeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionIdDataRequest_sessionTypeRequest {
    return new GetSessionIdDataRequest_sessionTypeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionIdDataRequest_sessionTypeRequest {
    return new GetSessionIdDataRequest_sessionTypeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionIdDataRequest_sessionTypeRequest | PlainMessage<GetSessionIdDataRequest_sessionTypeRequest> | undefined, b: GetSessionIdDataRequest_sessionTypeRequest | PlainMessage<GetSessionIdDataRequest_sessionTypeRequest> | undefined): boolean {
    return proto3.util.equals(GetSessionIdDataRequest_sessionTypeRequest, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.GetSessionIdDataResponse
 */
export class GetSessionIdDataResponse extends Message<GetSessionIdDataResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ai.student.events.apis.GetSessionIdDataResponse.sessionTypeResponse data = 4;
   */
  data: GetSessionIdDataResponse_sessionTypeResponse[] = [];

  constructor(data?: PartialMessage<GetSessionIdDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.GetSessionIdDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: GetSessionIdDataResponse_sessionTypeResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionIdDataResponse {
    return new GetSessionIdDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionIdDataResponse {
    return new GetSessionIdDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionIdDataResponse {
    return new GetSessionIdDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionIdDataResponse | PlainMessage<GetSessionIdDataResponse> | undefined, b: GetSessionIdDataResponse | PlainMessage<GetSessionIdDataResponse> | undefined): boolean {
    return proto3.util.equals(GetSessionIdDataResponse, a, b);
  }
}

/**
 * @generated from message geneo.ai.student.events.apis.GetSessionIdDataResponse.sessionTypeResponse
 */
export class GetSessionIdDataResponse_sessionTypeResponse extends Message<GetSessionIdDataResponse_sessionTypeResponse> {
  /**
   * @generated from field: geneo.ai.student.events.apis.sessionCloseTypeEnum type = 1;
   */
  type = sessionCloseTypeEnum.STUDENT_LOGIN_SESSION;

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: google.protobuf.Timestamp lastActiveTime = 3;
   */
  lastActiveTime?: Timestamp;

  constructor(data?: PartialMessage<GetSessionIdDataResponse_sessionTypeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ai.student.events.apis.GetSessionIdDataResponse.sessionTypeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(sessionCloseTypeEnum) },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "lastActiveTime", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionIdDataResponse_sessionTypeResponse {
    return new GetSessionIdDataResponse_sessionTypeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionIdDataResponse_sessionTypeResponse {
    return new GetSessionIdDataResponse_sessionTypeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionIdDataResponse_sessionTypeResponse {
    return new GetSessionIdDataResponse_sessionTypeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionIdDataResponse_sessionTypeResponse | PlainMessage<GetSessionIdDataResponse_sessionTypeResponse> | undefined, b: GetSessionIdDataResponse_sessionTypeResponse | PlainMessage<GetSessionIdDataResponse_sessionTypeResponse> | undefined): boolean {
    return proto3.util.equals(GetSessionIdDataResponse_sessionTypeResponse, a, b);
  }
}

