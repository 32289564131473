import { Loader, getEnvConfig } from '@geneo2-web/shared-ui';
import { useEffect } from 'react';

export const MCBSSOLogin = () => {
  const config = getEnvConfig();
  const redirectUri = window.location.href;
  const encodedRedirectUri = encodeURIComponent(redirectUri);
  const ssoLoginUrl = `https://ssolive.myclassboard.com/connect/authorize?client_id=${config.mcbSsoClientId}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=openid%20profile%20offline_access`;

  useEffect(() => {
    console.log(ssoLoginUrl);
    window.location.href = ssoLoginUrl;
  }, []);

  return <Loader />;
};
