// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.self_registration.apis.proto (package geneo.ums.self_registration.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EvaluateSelfRegistrationRequest, EvaluateSelfRegistrationResponse, GetSelfRegistrationReq, GetSelfRegistrationResponse, SelfRegisterTeacherRequest, SelfRegisterTeacherResponse, SendReminderToAdminRequest, SendReminderToAdminResponse, ValidateSchoolCodeRequest, ValidateSchoolCodeResponse, ValidateUserSelfRegistrationRequest, ValidateUserSelfRegistrationResponse } from "./ums.self_registration.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1
 */
export const UmsSelfRegistrationAPIServiceV1 = {
  typeName: "geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.validateUserSelfRegistration
     */
    validateUserSelfRegistration: {
      name: "validateUserSelfRegistration",
      I: ValidateUserSelfRegistrationRequest,
      O: ValidateUserSelfRegistrationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.selfRegisterTeacher
     */
    selfRegisterTeacher: {
      name: "selfRegisterTeacher",
      I: SelfRegisterTeacherRequest,
      O: SelfRegisterTeacherResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.validateSchoolCode
     */
    validateSchoolCode: {
      name: "validateSchoolCode",
      I: ValidateSchoolCodeRequest,
      O: ValidateSchoolCodeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.sendReminderToAdmin
     */
    sendReminderToAdmin: {
      name: "sendReminderToAdmin",
      I: SendReminderToAdminRequest,
      O: SendReminderToAdminResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.getSelfRegistrationRequestList
     */
    getSelfRegistrationRequestList: {
      name: "getSelfRegistrationRequestList",
      I: GetSelfRegistrationReq,
      O: GetSelfRegistrationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Approval action and reject reassons to be included in the request
     *
     * @generated from rpc geneo.ums.self_registration.apis.UmsSelfRegistrationAPIServiceV1.evaluateSelfRegistration
     */
    evaluateSelfRegistration: {
      name: "evaluateSelfRegistration",
      I: EvaluateSelfRegistrationRequest,
      O: EvaluateSelfRegistrationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

