// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/common/common.db.proto (package geneo.common.db, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message geneo.common.db.AcademicYear
 */
export class AcademicYear extends Message<AcademicYear> {
  /**
   * Primary key for academic year
   *
   * @generated from field: optional int32 academic_year_id = 1;
   */
  academicYearId?: number;

  /**
   * Academic year enum value
   *
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 2;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * Start date of the academic year
   *
   * @generated from field: google.protobuf.Timestamp academic_year_start = 3;
   */
  academicYearStart?: Timestamp;

  /**
   * End date of the academic year
   *
   * @generated from field: google.protobuf.Timestamp academic_year_end = 4;
   */
  academicYearEnd?: Timestamp;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<AcademicYear>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.AcademicYear";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "academic_year_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 3, name: "academic_year_start", kind: "message", T: Timestamp },
    { no: 4, name: "academic_year_end", kind: "message", T: Timestamp },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcademicYear {
    return new AcademicYear().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcademicYear {
    return new AcademicYear().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcademicYear {
    return new AcademicYear().fromJsonString(jsonString, options);
  }

  static equals(a: AcademicYear | PlainMessage<AcademicYear> | undefined, b: AcademicYear | PlainMessage<AcademicYear> | undefined): boolean {
    return proto3.util.equals(AcademicYear, a, b);
  }
}

/**
 * Enum for AcademicYearEnum
 *
 * @generated from enum geneo.common.db.AcademicYear.Enum
 */
export enum AcademicYear_Enum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * Academic year 2023-2024
   *
   * @generated from enum value: AY_2023_2024 = 1;
   */
  AY_2023_2024 = 1,

  /**
   * Academic year 2024-2025
   *
   * @generated from enum value: AY_2024_2025 = 2;
   */
  AY_2024_2025 = 2,

  /**
   * Academic year 2025-2026
   *
   * @generated from enum value: AY_2025_2026 = 3;
   */
  AY_2025_2026 = 3,

  /**
   * Academic year 2026-2027
   *
   * @generated from enum value: AY_2026_2027 = 4;
   */
  AY_2026_2027 = 4,

  /**
   * Academic year 2027-2028
   *
   * @generated from enum value: AY_2027_2028 = 5;
   */
  AY_2027_2028 = 5,

  /**
   * Academic year 2028-2029
   *
   * @generated from enum value: AY_2028_2029 = 6;
   */
  AY_2028_2029 = 6,

  /**
   * Academic year 2029-2030
   *
   * @generated from enum value: AY_2029_2030 = 7;
   */
  AY_2029_2030 = 7,

  /**
   * Academic year 2030-2031
   *
   * @generated from enum value: AY_2030_2031 = 8;
   */
  AY_2030_2031 = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(AcademicYear_Enum)
proto3.util.setEnumType(AcademicYear_Enum, "geneo.common.db.AcademicYear.Enum", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "AY_2023_2024" },
  { no: 2, name: "AY_2024_2025" },
  { no: 3, name: "AY_2025_2026" },
  { no: 4, name: "AY_2026_2027" },
  { no: 5, name: "AY_2027_2028" },
  { no: 6, name: "AY_2028_2029" },
  { no: 7, name: "AY_2029_2030" },
  { no: 8, name: "AY_2030_2031" },
]);

/**
 * @generated from message geneo.common.db.Board
 */
export class Board extends Message<Board> {
  /**
   * Primary key for board
   *
   * @generated from field: optional int32 board_id = 1;
   */
  boardId?: number;

  /**
   * Board enum value
   *
   * @generated from field: geneo.common.db.Board.BoardEnum board = 2;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * Board Name
   *
   * @generated from field: string board_name = 3;
   */
  boardName = "";

  /**
   * Short code for the board
   *
   * @generated from field: string board_short_code = 4;
   */
  boardShortCode = "";

  /**
   * Affiliation details of the board
   *
   * @generated from field: string board_affiliation = 5;
   */
  boardAffiliation = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<Board>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.Board";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 3, name: "board_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "board_short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "board_affiliation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Board {
    return new Board().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Board {
    return new Board().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Board {
    return new Board().fromJsonString(jsonString, options);
  }

  static equals(a: Board | PlainMessage<Board> | undefined, b: Board | PlainMessage<Board> | undefined): boolean {
    return proto3.util.equals(Board, a, b);
  }
}

/**
 * Enum for BoardEnum
 *
 * @generated from enum geneo.common.db.Board.BoardEnum
 */
export enum Board_BoardEnum {
  /**
   * Board: undefined
   *
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * Board: CBSE
   *
   * @generated from enum value: CBSE = 1;
   */
  CBSE = 1,

  /**
   * Board: ICSE
   *
   * @generated from enum value: ICSE = 2;
   */
  ICSE = 2,

  /**
   * Board: IB
   *
   * @generated from enum value: IB = 3;
   */
  IB = 3,

  /**
   * Board: IGCSE
   *
   * @generated from enum value: IGCSE = 4;
   */
  IGCSE = 4,

  /**
   * Board: NIOS
   *
   * @generated from enum value: NIOS = 5;
   */
  NIOS = 5,

  /**
   * Board: State - Andhra Pradesh
   *
   * @generated from enum value: STATE_ANDHRAPRADESH = 6;
   */
  STATE_ANDHRAPRADESH = 6,

  /**
   * Board: State - Assam
   *
   * @generated from enum value: STATE_ASSAM = 7;
   */
  STATE_ASSAM = 7,

  /**
   * Board: State - Bihar
   *
   * @generated from enum value: STATE_BIHAR = 8;
   */
  STATE_BIHAR = 8,

  /**
   * Board: State - Rajasthan
   *
   * @generated from enum value: STATE_RAJASTHAN = 9;
   */
  STATE_RAJASTHAN = 9,

  /**
   * Board: State - Manipur
   *
   * @generated from enum value: STATE_MANIPUR = 10;
   */
  STATE_MANIPUR = 10,

  /**
   * Board: State - Chhattisgarh
   *
   * @generated from enum value: STATE_CHHATTISGARH = 11;
   */
  STATE_CHHATTISGARH = 11,

  /**
   * Board: State - Odisha
   *
   * @generated from enum value: STATE_ORISSA = 12;
   */
  STATE_ORISSA = 12,

  /**
   * Board: State - Goa
   *
   * @generated from enum value: STATE_GOA = 13;
   */
  STATE_GOA = 13,

  /**
   * Board: State - Gujarat
   *
   * @generated from enum value: STATE_GUJARAT = 14;
   */
  STATE_GUJARAT = 14,

  /**
   * Board: State - Haryana
   *
   * @generated from enum value: STATE_HARYANA = 15;
   */
  STATE_HARYANA = 15,

  /**
   * Board: State - Himachal Pradesh
   *
   * @generated from enum value: STATE_HIMACHALPRADESH = 16;
   */
  STATE_HIMACHALPRADESH = 16,

  /**
   * Board: State - Jammu and Kashmir
   *
   * @generated from enum value: STATE_JK = 17;
   */
  STATE_JK = 17,

  /**
   * Board: State - Jharkhand
   *
   * @generated from enum value: STATE_JHARKHAND = 18;
   */
  STATE_JHARKHAND = 18,

  /**
   * Board: State - Karnataka
   *
   * @generated from enum value: STATE_KARNATAKA = 19;
   */
  STATE_KARNATAKA = 19,

  /**
   * Board: State - Kerala
   *
   * @generated from enum value: STATE_KERALA = 20;
   */
  STATE_KERALA = 20,

  /**
   * Board: State - Madhya Pradesh
   *
   * @generated from enum value: STATE_MADHYAPRADESH = 21;
   */
  STATE_MADHYAPRADESH = 21,

  /**
   * Board: State - Maharashtra
   *
   * @generated from enum value: STATE_MAHARASHTRA = 22;
   */
  STATE_MAHARASHTRA = 22,

  /**
   * Board: State - Meghalaya
   *
   * @generated from enum value: STATE_MEGHALAYA = 23;
   */
  STATE_MEGHALAYA = 23,

  /**
   * Board: State - Mizoram
   *
   * @generated from enum value: STATE_MIZORAM = 24;
   */
  STATE_MIZORAM = 24,

  /**
   * Board: State - Nagaland
   *
   * @generated from enum value: STATE_NAGALAND = 25;
   */
  STATE_NAGALAND = 25,

  /**
   * Board: State - Punjab
   *
   * @generated from enum value: STATE_PUNJAB = 26;
   */
  STATE_PUNJAB = 26,

  /**
   * Board: State - Tamil Nadu
   *
   * @generated from enum value: STATE_TAMILNADU = 27;
   */
  STATE_TAMILNADU = 27,

  /**
   * Board: State - Tripura
   *
   * @generated from enum value: STATE_TRIPURA = 28;
   */
  STATE_TRIPURA = 28,

  /**
   * Board: State - Uttar Pradesh
   *
   * @generated from enum value: STATE_UTTARPRADESH = 29;
   */
  STATE_UTTARPRADESH = 29,

  /**
   * Board: State - Uttarakhand
   *
   * @generated from enum value: STATE_UTTARAKHAND = 30;
   */
  STATE_UTTARAKHAND = 30,

  /**
   * Board: State - West Bengal
   *
   * @generated from enum value: STATE_WESTBENGAL = 31;
   */
  STATE_WESTBENGAL = 31,
}
// Retrieve enum metadata with: proto3.getEnumType(Board_BoardEnum)
proto3.util.setEnumType(Board_BoardEnum, "geneo.common.db.Board.BoardEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "CBSE" },
  { no: 2, name: "ICSE" },
  { no: 3, name: "IB" },
  { no: 4, name: "IGCSE" },
  { no: 5, name: "NIOS" },
  { no: 6, name: "STATE_ANDHRAPRADESH" },
  { no: 7, name: "STATE_ASSAM" },
  { no: 8, name: "STATE_BIHAR" },
  { no: 9, name: "STATE_RAJASTHAN" },
  { no: 10, name: "STATE_MANIPUR" },
  { no: 11, name: "STATE_CHHATTISGARH" },
  { no: 12, name: "STATE_ORISSA" },
  { no: 13, name: "STATE_GOA" },
  { no: 14, name: "STATE_GUJARAT" },
  { no: 15, name: "STATE_HARYANA" },
  { no: 16, name: "STATE_HIMACHALPRADESH" },
  { no: 17, name: "STATE_JK" },
  { no: 18, name: "STATE_JHARKHAND" },
  { no: 19, name: "STATE_KARNATAKA" },
  { no: 20, name: "STATE_KERALA" },
  { no: 21, name: "STATE_MADHYAPRADESH" },
  { no: 22, name: "STATE_MAHARASHTRA" },
  { no: 23, name: "STATE_MEGHALAYA" },
  { no: 24, name: "STATE_MIZORAM" },
  { no: 25, name: "STATE_NAGALAND" },
  { no: 26, name: "STATE_PUNJAB" },
  { no: 27, name: "STATE_TAMILNADU" },
  { no: 28, name: "STATE_TRIPURA" },
  { no: 29, name: "STATE_UTTARPRADESH" },
  { no: 30, name: "STATE_UTTARAKHAND" },
  { no: 31, name: "STATE_WESTBENGAL" },
]);

/**
 * @generated from message geneo.common.db.TeachMedium
 */
export class TeachMedium extends Message<TeachMedium> {
  /**
   * Primary key for teach medium
   *
   * @generated from field: optional int32 teach_medium_id = 1;
   */
  teachMediumId?: number;

  /**
   * Language used for teaching
   *
   * @generated from field: string language = 2;
   */
  language = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 3;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 4;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 5;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 6;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<TeachMedium>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.TeachMedium";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teach_medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_on", kind: "message", T: Timestamp },
    { no: 4, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 5, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeachMedium {
    return new TeachMedium().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeachMedium {
    return new TeachMedium().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeachMedium {
    return new TeachMedium().fromJsonString(jsonString, options);
  }

  static equals(a: TeachMedium | PlainMessage<TeachMedium> | undefined, b: TeachMedium | PlainMessage<TeachMedium> | undefined): boolean {
    return proto3.util.equals(TeachMedium, a, b);
  }
}

/**
 * @generated from message geneo.common.db.Class
 */
export class Class extends Message<Class> {
  /**
   * Primary key for class
   *
   * @generated from field: optional int32 class_id = 1;
   */
  classId?: number;

  /**
   * Class enum value
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class_ = 2;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Class Name
   *
   * @generated from field: string class_name = 3;
   */
  className = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 5;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 6;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 7;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<Class>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.Class";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "class_", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Class {
    return new Class().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Class {
    return new Class().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Class {
    return new Class().fromJsonString(jsonString, options);
  }

  static equals(a: Class | PlainMessage<Class> | undefined, b: Class | PlainMessage<Class> | undefined): boolean {
    return proto3.util.equals(Class, a, b);
  }
}

/**
 * Enum for ClassEnum
 *
 * @generated from enum geneo.common.db.Class.ClassEnum
 */
export enum Class_ClassEnum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * Class: Pre KG
   *
   * @generated from enum value: CLASS_PRE_KG = 1;
   */
  CLASS_PRE_KG = 1,

  /**
   * Class: KG
   *
   * @generated from enum value: CLASS_KG = 2;
   */
  CLASS_KG = 2,

  /**
   * Class: LKG
   *
   * @generated from enum value: CLASS_LKG = 3;
   */
  CLASS_LKG = 3,

  /**
   * Class: HKG
   *
   * @generated from enum value: CLASS_HKG = 4;
   */
  CLASS_HKG = 4,

  /**
   * Class: 1
   *
   * @generated from enum value: CLASS_1 = 5;
   */
  CLASS_1 = 5,

  /**
   * Class: 2
   *
   * @generated from enum value: CLASS_2 = 6;
   */
  CLASS_2 = 6,

  /**
   * Class: 3
   *
   * @generated from enum value: CLASS_3 = 7;
   */
  CLASS_3 = 7,

  /**
   * Class: 4
   *
   * @generated from enum value: CLASS_4 = 8;
   */
  CLASS_4 = 8,

  /**
   * Class: 5
   *
   * @generated from enum value: CLASS_5 = 9;
   */
  CLASS_5 = 9,

  /**
   * Class: 6
   *
   * @generated from enum value: CLASS_6 = 10;
   */
  CLASS_6 = 10,

  /**
   * Class: 7
   *
   * @generated from enum value: CLASS_7 = 11;
   */
  CLASS_7 = 11,

  /**
   * Class: 8
   *
   * @generated from enum value: CLASS_8 = 12;
   */
  CLASS_8 = 12,

  /**
   * Class: 9
   *
   * @generated from enum value: CLASS_9 = 13;
   */
  CLASS_9 = 13,

  /**
   * Class: 10
   *
   * @generated from enum value: CLASS_10 = 14;
   */
  CLASS_10 = 14,

  /**
   * Class: 11
   *
   * @generated from enum value: CLASS_11 = 15;
   */
  CLASS_11 = 15,

  /**
   * Class: 12
   *
   * @generated from enum value: CLASS_12 = 16;
   */
  CLASS_12 = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(Class_ClassEnum)
proto3.util.setEnumType(Class_ClassEnum, "geneo.common.db.Class.ClassEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "CLASS_PRE_KG" },
  { no: 2, name: "CLASS_KG" },
  { no: 3, name: "CLASS_LKG" },
  { no: 4, name: "CLASS_HKG" },
  { no: 5, name: "CLASS_1" },
  { no: 6, name: "CLASS_2" },
  { no: 7, name: "CLASS_3" },
  { no: 8, name: "CLASS_4" },
  { no: 9, name: "CLASS_5" },
  { no: 10, name: "CLASS_6" },
  { no: 11, name: "CLASS_7" },
  { no: 12, name: "CLASS_8" },
  { no: 13, name: "CLASS_9" },
  { no: 14, name: "CLASS_10" },
  { no: 15, name: "CLASS_11" },
  { no: 16, name: "CLASS_12" },
]);

/**
 * @generated from message geneo.common.db.Subject
 */
export class Subject extends Message<Subject> {
  /**
   * Primary key for subject
   *
   * @generated from field: optional int32 subject_id = 1;
   */
  subjectId?: number;

  /**
   * Subject enum value
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 2;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Subject Name
   *
   * @generated from field: string subject_name = 3;
   */
  subjectName = "";

  /**
   * Description of the subject
   *
   * @generated from field: string subject_description = 4;
   */
  subjectDescription = "";

  /**
   * Indicates if the subject is a language
   *
   * @generated from field: bool subject_is_language = 5;
   */
  subjectIsLanguage = false;

  /**
   * @generated from field: string text_color = 6;
   */
  textColor = "";

  /**
   * @generated from field: optional string icon_url = 7;
   */
  iconUrl?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 9;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 10;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 11;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<Subject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.Subject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 3, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subject_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject_is_language", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "text_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Subject {
    return new Subject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Subject {
    return new Subject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Subject {
    return new Subject().fromJsonString(jsonString, options);
  }

  static equals(a: Subject | PlainMessage<Subject> | undefined, b: Subject | PlainMessage<Subject> | undefined): boolean {
    return proto3.util.equals(Subject, a, b);
  }
}

/**
 * Enum for SubjectEnum
 *
 * @generated from enum geneo.common.db.Subject.SubjectEnum
 */
export enum Subject_SubjectEnum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * Subject: Mathematics
   *
   * @generated from enum value: M = 1;
   */
  M = 1,

  /**
   * Subject: Science
   *
   * @generated from enum value: S = 2;
   */
  S = 2,

  /**
   * Subject: Physics
   *
   * @generated from enum value: SP = 3;
   */
  SP = 3,

  /**
   * Subject: Chemistry
   *
   * @generated from enum value: SC = 4;
   */
  SC = 4,

  /**
   * Subject: Biology
   *
   * @generated from enum value: SB = 5;
   */
  SB = 5,

  /**
   * Subject: English
   *
   * @generated from enum value: E = 6;
   */
  E = 6,

  /**
   * Subject: English Grammar
   *
   * @generated from enum value: EG = 7;
   */
  EG = 7,

  /**
   * Subject: English Literature
   *
   * @generated from enum value: ELIT = 8;
   */
  ELIT = 8,

  /**
   * Subject: English Language
   *
   * @generated from enum value: ELANG = 9;
   */
  ELANG = 9,

  /**
   * Subject: History
   *
   * @generated from enum value: HIST = 10;
   */
  HIST = 10,

  /**
   * Subject: Geography
   *
   * @generated from enum value: GEOG = 11;
   */
  GEOG = 11,

  /**
   * Subject: Information and Communication Technology
   *
   * @generated from enum value: ICT = 12;
   */
  ICT = 12,

  /**
   * Subject: Environmental Studies
   *
   * @generated from enum value: EVS = 13;
   */
  EVS = 13,

  /**
   * Subject: Social Science
   *
   * @generated from enum value: SS = 14;
   */
  SS = 14,

  /**
   * Subject: Civics
   *
   * @generated from enum value: CIV = 15;
   */
  CIV = 15,

  /**
   * Subject: Urdu
   *
   * @generated from enum value: URD = 16;
   */
  URD = 16,

  /**
   * Subject: Hindi
   *
   * @generated from enum value: H = 17;
   */
  H = 17,

  /**
   * Subject: Sanskrit
   *
   * @generated from enum value: SANS = 18;
   */
  SANS = 18,

  /**
   * Subject: Marathi
   *
   * @generated from enum value: MAR = 19;
   */
  MAR = 19,

  /**
   * Subject: Gujarati
   *
   * @generated from enum value: GUJ = 20;
   */
  GUJ = 20,

  /**
   * Subject: Economics
   *
   * @generated from enum value: ECO = 21;
   */
  ECO = 21,

  /**
   * Subject: General Knowledge
   *
   * @generated from enum value: GK = 22;
   */
  GK = 22,

  /**
   * Subject: Business Studies
   *
   * @generated from enum value: BIZ = 23;
   */
  BIZ = 23,

  /**
   * Subject: Accountancy
   *
   * @generated from enum value: ACCT = 24;
   */
  ACCT = 24,

  /**
   * Subject: Statistics
   *
   * @generated from enum value: STATS = 25;
   */
  STATS = 25,

  /**
   * Subject: Logical Reasoning
   *
   * @generated from enum value: LR = 26;
   */
  LR = 26,

  /**
   * Subject: Vocabulary
   *
   * @generated from enum value: VOCAB = 27;
   */
  VOCAB = 27,

  /**
   * Subject: Games
   *
   * @generated from enum value: GAME = 28;
   */
  GAME = 28,

  /**
   * Subject: Aptitude
   *
   * @generated from enum value: APT = 29;
   */
  APT = 29,

  /**
   * Subject: General Studies
   *
   * @generated from enum value: GS = 30;
   */
  GS = 30,

  /**
   * Subject: Foreign Language
   *
   * @generated from enum value: LANG = 31;
   */
  LANG = 31,

  /**
   * Subject: Sample
   *
   * @generated from enum value: SAMPLE = 32;
   */
  SAMPLE = 32,

  /**
   * Subject: Indian Studies
   *
   * @generated from enum value: INDIA = 33;
   */
  INDIA = 33,

  /**
   * Subject: Entertainment
   *
   * @generated from enum value: ENTMT = 34;
   */
  ENTMT = 34,

  /**
   * Subject: Sports
   *
   * @generated from enum value: SPORTS = 35;
   */
  SPORTS = 35,

  /**
   * Subject: SBI PO Exam
   *
   * @generated from enum value: SBI_PO = 36;
   */
  SBI_PO = 36,

  /**
   * Subject: IBPS PO Exam
   *
   * @generated from enum value: IBPS_PO = 37;
   */
  IBPS_PO = 37,

  /**
   * Subject: Bank PO Exam
   *
   * @generated from enum value: BANK_PO = 38;
   */
  BANK_PO = 38,

  /**
   * Subject: Government Exams
   *
   * @generated from enum value: GOVT = 39;
   */
  GOVT = 39,

  /**
   * Subject: Political Science
   *
   * @generated from enum value: PS = 40;
   */
  PS = 40,

  /**
   * Subject: Social and Political Life
   *
   * @generated from enum value: SPL = 41;
   */
  SPL = 41,

  /**
   * Subject: Training
   *
   * @generated from enum value: TRAINING = 42;
   */
  TRAINING = 42,
}
// Retrieve enum metadata with: proto3.getEnumType(Subject_SubjectEnum)
proto3.util.setEnumType(Subject_SubjectEnum, "geneo.common.db.Subject.SubjectEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "M" },
  { no: 2, name: "S" },
  { no: 3, name: "SP" },
  { no: 4, name: "SC" },
  { no: 5, name: "SB" },
  { no: 6, name: "E" },
  { no: 7, name: "EG" },
  { no: 8, name: "ELIT" },
  { no: 9, name: "ELANG" },
  { no: 10, name: "HIST" },
  { no: 11, name: "GEOG" },
  { no: 12, name: "ICT" },
  { no: 13, name: "EVS" },
  { no: 14, name: "SS" },
  { no: 15, name: "CIV" },
  { no: 16, name: "URD" },
  { no: 17, name: "H" },
  { no: 18, name: "SANS" },
  { no: 19, name: "MAR" },
  { no: 20, name: "GUJ" },
  { no: 21, name: "ECO" },
  { no: 22, name: "GK" },
  { no: 23, name: "BIZ" },
  { no: 24, name: "ACCT" },
  { no: 25, name: "STATS" },
  { no: 26, name: "LR" },
  { no: 27, name: "VOCAB" },
  { no: 28, name: "GAME" },
  { no: 29, name: "APT" },
  { no: 30, name: "GS" },
  { no: 31, name: "LANG" },
  { no: 32, name: "SAMPLE" },
  { no: 33, name: "INDIA" },
  { no: 34, name: "ENTMT" },
  { no: 35, name: "SPORTS" },
  { no: 36, name: "SBI_PO" },
  { no: 37, name: "IBPS_PO" },
  { no: 38, name: "BANK_PO" },
  { no: 39, name: "GOVT" },
  { no: 40, name: "PS" },
  { no: 41, name: "SPL" },
  { no: 42, name: "TRAINING" },
]);

/**
 * @generated from message geneo.common.db.BookChapterTopicQuestionsInfo
 */
export class BookChapterTopicQuestionsInfo extends Message<BookChapterTopicQuestionsInfo> {
  /**
   * @generated from field: int32 start_page_no = 1;
   */
  startPageNo = 0;

  constructor(data?: PartialMessage<BookChapterTopicQuestionsInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.BookChapterTopicQuestionsInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_page_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookChapterTopicQuestionsInfo {
    return new BookChapterTopicQuestionsInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookChapterTopicQuestionsInfo {
    return new BookChapterTopicQuestionsInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookChapterTopicQuestionsInfo {
    return new BookChapterTopicQuestionsInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BookChapterTopicQuestionsInfo | PlainMessage<BookChapterTopicQuestionsInfo> | undefined, b: BookChapterTopicQuestionsInfo | PlainMessage<BookChapterTopicQuestionsInfo> | undefined): boolean {
    return proto3.util.equals(BookChapterTopicQuestionsInfo, a, b);
  }
}

/**
 * @generated from message geneo.common.db.Book
 */
export class Book extends Message<Book> {
  /**
   * Primary key for the book
   *
   * @generated from field: optional int32 book_id = 1;
   */
  bookId?: number;

  /**
   * Title of the book
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * Author of the book
   *
   * @generated from field: string author = 3;
   */
  author = "";

  /**
   * Publisher of the book
   *
   * @generated from field: string publisher = 4;
   */
  publisher = "";

  /**
   * ISBN number of the book
   *
   * @generated from field: string isbn_no = 5;
   */
  isbnNo = "";

  /**
   * Year of publication
   *
   * @generated from field: string publishing_year = 6;
   */
  publishingYear = "";

  /**
   * Number of pages in the book
   *
   * @generated from field: int32 no_of_pages = 7;
   */
  noOfPages = 0;

  /**
   * URL of the book's PDF
   *
   * @generated from field: string pdf_url = 8;
   */
  pdfUrl = "";

  /**
   * URL of the book's cover image
   *
   * @generated from field: string cover_image_url = 9;
   */
  coverImageUrl = "";

  /**
   * Version of the book
   *
   * @generated from field: int32 version = 10;
   */
  version = 0;

  /**
   * Foreign key referencing subject.subject_id
   *
   * @generated from field: optional int32 subject_id = 11;
   */
  subjectId?: number;

  /**
   * Foreign key referencing class.class_id
   *
   * @generated from field: optional int32 class_id = 12;
   */
  classId?: number;

  /**
   * Foreign key referencing board.board_id
   *
   * @generated from field: optional int32 board_id = 13;
   */
  boardId?: number;

  /**
   * Foreign key referencing teach_medium.teach_medium_id
   *
   * @generated from field: optional int32 teach_medium_id = 14;
   */
  teachMediumId?: number;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 15;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 16;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 17;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 18;
   */
  modifiedBy?: bigint;

  /**
   * URL of the book's PDF
   *
   * @generated from field: optional string gcp_url = 19;
   */
  gcpUrl?: string;

  constructor(data?: PartialMessage<Book>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.Book";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "publisher", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "isbn_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "publishing_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "no_of_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "cover_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "teach_medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "created_on", kind: "message", T: Timestamp },
    { no: 16, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 17, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 18, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 19, name: "gcp_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Book {
    return new Book().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJsonString(jsonString, options);
  }

  static equals(a: Book | PlainMessage<Book> | undefined, b: Book | PlainMessage<Book> | undefined): boolean {
    return proto3.util.equals(Book, a, b);
  }
}

/**
 * @generated from message geneo.common.db.APIResponse
 */
export class APIResponse extends Message<APIResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: google.protobuf.Any data = 4;
   */
  data?: Any;

  constructor(data?: PartialMessage<APIResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.APIResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: Any },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIResponse {
    return new APIResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIResponse {
    return new APIResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIResponse {
    return new APIResponse().fromJsonString(jsonString, options);
  }

  static equals(a: APIResponse | PlainMessage<APIResponse> | undefined, b: APIResponse | PlainMessage<APIResponse> | undefined): boolean {
    return proto3.util.equals(APIResponse, a, b);
  }
}

/**
 * @generated from enum geneo.common.db.APIResponse.StatusEnum
 */
export enum APIResponse_StatusEnum {
  /**
   * @generated from enum value: STATUS_UNDEFINED = 0;
   */
  STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: STATUS_SUCCESS = 1;
   */
  STATUS_SUCCESS = 1,

  /**
   * @generated from enum value: STATUS_IN_PROGRESS = 2;
   */
  STATUS_IN_PROGRESS = 2,

  /**
   * @generated from enum value: STATUS_NOT_FOUND = 3;
   */
  STATUS_NOT_FOUND = 3,

  /**
   * @generated from enum value: STATUS_FORBIDDEN = 4;
   */
  STATUS_FORBIDDEN = 4,

  /**
   * @generated from enum value: STATUS_UNAUTHORIZED = 5;
   */
  STATUS_UNAUTHORIZED = 5,

  /**
   * @generated from enum value: STATUS_BAD_REQUEST = 6;
   */
  STATUS_BAD_REQUEST = 6,

  /**
   * @generated from enum value: STATUS_INTERNAL_ERROR = 7;
   */
  STATUS_INTERNAL_ERROR = 7,

  /**
   * @generated from enum value: STATUS_MAINTENANCE = 8;
   */
  STATUS_MAINTENANCE = 8,

  /**
   * @generated from enum value: STATUS_FAILURE = 9;
   */
  STATUS_FAILURE = 9,

  /**
   * @generated from enum value: STATUS_ERROR = 10;
   */
  STATUS_ERROR = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(APIResponse_StatusEnum)
proto3.util.setEnumType(APIResponse_StatusEnum, "geneo.common.db.APIResponse.StatusEnum", [
  { no: 0, name: "STATUS_UNDEFINED" },
  { no: 1, name: "STATUS_SUCCESS" },
  { no: 2, name: "STATUS_IN_PROGRESS" },
  { no: 3, name: "STATUS_NOT_FOUND" },
  { no: 4, name: "STATUS_FORBIDDEN" },
  { no: 5, name: "STATUS_UNAUTHORIZED" },
  { no: 6, name: "STATUS_BAD_REQUEST" },
  { no: 7, name: "STATUS_INTERNAL_ERROR" },
  { no: 8, name: "STATUS_MAINTENANCE" },
  { no: 9, name: "STATUS_FAILURE" },
  { no: 10, name: "STATUS_ERROR" },
]);

/**
 * @generated from enum geneo.common.db.APIResponse.CodeEnum
 */
export enum APIResponse_CodeEnum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: OK = 200;
   */
  OK = 200,

  /**
   * @generated from enum value: CREATED = 201;
   */
  CREATED = 201,

  /**
   * @generated from enum value: NO_CONTENT = 204;
   */
  NO_CONTENT = 204,

  /**
   * @generated from enum value: BAD_REQUEST = 400;
   */
  BAD_REQUEST = 400,

  /**
   * @generated from enum value: UNAUTHORIZED = 401;
   */
  UNAUTHORIZED = 401,

  /**
   * @generated from enum value: FORBIDDEN = 403;
   */
  FORBIDDEN = 403,

  /**
   * @generated from enum value: NOT_FOUND = 404;
   */
  NOT_FOUND = 404,

  /**
   * @generated from enum value: INTERNAL_SERVER_ERROR = 500;
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * @generated from enum value: SERVICE_UNAVAILABLE = 503;
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * CUSTOM for resource
   *
   * Access to resource granted
   *
   * @generated from enum value: RESOURCE_ACCESS_GRANTED = 220;
   */
  RESOURCE_ACCESS_GRANTED = 220,

  /**
   * Access to resource denied
   *
   * @generated from enum value: RESOURCE_ACCESS_DENIED = 221;
   */
  RESOURCE_ACCESS_DENIED = 221,

  /**
   * Access to resource not yet available
   *
   * @generated from enum value: RESOURCE_ACCESS_NOT_YET_AVAILABLE = 222;
   */
  RESOURCE_ACCESS_NOT_YET_AVAILABLE = 222,

  /**
   * @generated from enum value: RESOURCE_NOT_FOUND = 223;
   */
  RESOURCE_NOT_FOUND = 223,
}
// Retrieve enum metadata with: proto3.getEnumType(APIResponse_CodeEnum)
proto3.util.setEnumType(APIResponse_CodeEnum, "geneo.common.db.APIResponse.CodeEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 200, name: "OK" },
  { no: 201, name: "CREATED" },
  { no: 204, name: "NO_CONTENT" },
  { no: 400, name: "BAD_REQUEST" },
  { no: 401, name: "UNAUTHORIZED" },
  { no: 403, name: "FORBIDDEN" },
  { no: 404, name: "NOT_FOUND" },
  { no: 500, name: "INTERNAL_SERVER_ERROR" },
  { no: 503, name: "SERVICE_UNAVAILABLE" },
  { no: 220, name: "RESOURCE_ACCESS_GRANTED" },
  { no: 221, name: "RESOURCE_ACCESS_DENIED" },
  { no: 222, name: "RESOURCE_ACCESS_NOT_YET_AVAILABLE" },
  { no: 223, name: "RESOURCE_NOT_FOUND" },
]);

