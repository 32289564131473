import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from '../../commonUtils/resizeUtils';
import { IStyles } from '../../commonUtils/styleUtils';
import { ScoreProgressBar } from '../../components/composites/ScoreProgressBar';
import FeatureIcon from '../../components/elements/FeatureIcon';
import HeaderIcon from '../../components/elements/HeaderIcon';
import ImageWrapper from '../../components/elements/ImageWrapper';
import { NoContentCard } from '../../components/composites/NoContent.tsx';
import { TextEditor } from '../../components/composites/TextEditor';
import { useState } from 'react';
import { Value } from 'react-quill';
import { GeneoKatex } from '../../components/composites/Question/GeneoKatex';
import { InstructionsPanel } from '../../components/composites/InstructionsPanel';
import {
  FileEnum,
  FileExtensionEnum,
} from '@protos/content_management/content.db_pb';
import { CloudUpload } from '@protos/learning_management/lms.db_pb';
// import 'react-quill/dist/quill.snow.css';
const styles: IStyles = {
  itemsWrapper: {
    display: 'flex',
    overflow: {
      xs: 'scroll',
      md: 'initial',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&>div:not(:last-child)': {
      marginRight: {
        xs: pxToRem(10),
        md: pxToRem(20),
      },
    },
  },
};

export const MiscellaneousDocs = () => {
  const [TextEditorValue, setTextEditorValue] = useState<string | undefined>();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          height: '10000px',
          paddingBottom: '50px',
          width: '100%',
          paddingX: pxToRem(5),
          margin: 'auto',
          overflowY: 'scroll',
          gap: { xs: '200px', md: '50px' },

          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            height: { xs: pxToRem(555), md: pxTovW(555) },
            width: { xs: '100vw', md: pxToRem(400), lg: pxTovW(531) },
          }}
        >
          {
            <InstructionsPanel
              isEditable
              value={TextEditorValue}
              onChange={(e) => setTextEditorValue(e)}
            />
          }
        </Box>
        <Box
          sx={{
            height: { xs: pxToRem(555), md: pxTovW(800) },
            width: { xs: '100vw', md: pxToRem(400), lg: pxTovW(531) },
          }}
        >
          {
            <InstructionsPanel
              Katexvalue={TextEditorValue?.toString() || '<p></p>'}
            />
          }
        </Box>
        <Box
          sx={{
            marginTop: '100px',
            height: 'max-content',
            maxHeight: { xs: pxToRem(555), md: pxTovW(666) },
            width: { xs: '90vw', md: '50vw' },
          }}
        >
          {
            <InstructionsPanel
              AttachmentList={AttachmentList}
              Katexvalue={TextEditorValue?.toString() || '<p></p>'}
            />
          }
        </Box>

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            width: '100%',
          }}
        >
          {TextEditorValue?.toString() || ''}
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: new GeneoKatex(
                TextEditorValue?.toString() || ''
              ).parsedHtml(),
            }}
          ></div>
        </Box> */}
      </Box>
      <Box sx={{ backgroundColor: 'lightgrey' }}>
        <Typography variant="h2">Image Wrapper</Typography>
        <ImageWrapper
          styles={{ width: '30vh' }}
          name="lesson-plan-sample"
          type="png"
          parentFolder="tempAssets"
        />
      </Box>
      <Box>
        <Typography variant="h2">Icons</Typography>
        <Box sx={styles.itemsWrapper}>
          <HeaderIcon fileName="teach" type="png" cardText="Teach" />
          <HeaderIcon fileName="new-hw" type="png" cardText="New Homework" />
          <HeaderIcon
            fileName="manage-hw"
            type="png"
            cardText="Manage Homework"
          />
          <HeaderIcon fileName="analytics" type="png" cardText="Analysis" />
        </Box>
      </Box>
      <Box>
        <Typography variant="h2">Feature Icons</Typography>
        <Box sx={styles.itemsWrapper}>
          <FeatureIcon
            fileName="book"
            type="png"
            cardText="Create Your First Homework in 2 Minutes"
            bgColor="#EEFFB3"
          />
          <FeatureIcon
            fileName="provision"
            type="png"
            cardText="Curriculum Aligned Teaching Resources"
            bgColor="#DAF5FF"
          />
          <FeatureIcon
            fileName="homework"
            type="png"
            cardText="Know Your Class (Pre-Assessment)"
            bgColor="#FFEEEE"
          />
          <FeatureIcon
            fileName="exam"
            type="png"
            cardText="Board exam question papers"
            bgColor="#EAF4FC"
          />
        </Box>
        <Box
          sx={{
            width: '20vw',
            display: 'flex',
            flexDirection: 'column',
            gap: '2vw ',
          }}
        >
          <Typography variant="h1" fontWeight="bold">
            Adjust width according to parent container
          </Typography>
          <ScoreProgressBar score={80} variant="lg" />
          <ScoreProgressBar score={40} variant="md" />
          <ScoreProgressBar score={10} variant="small" />
        </Box>
        <NoContentCard
          variant="info"
          icon="cards"
          text="There is no cards available here"
        />
        <NoContentCard
          variant="error"
          icon="error"
          text="Lorem ipsum dolor sit amet consectetur"
        />
      </Box>
    </Box>
  );
};

const AttachmentList: CloudUpload[] = [
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_PDF,
    gcpFileUrl: 'https://example.com/file1.txt',
    fileSizeInMb: 2.5,
    fileName: 'file1',
    fileType: FileEnum.FILE_TYPE_PDF,
  }),
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_PPT,
    gcpFileUrl: 'https://example.com/file1.txt',
    fileSizeInMb: 1.8,
    fileName: 'file2',
    fileType: FileEnum.FILE_TYPE_PRESENTATION,
  }),
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_DOCX,
    gcpFileUrl: 'https://example.com/file1.txt',
    fileSizeInMb: 0.7,
    fileName: 'file3',
    fileType: FileEnum.FILE_TYPE_DOCUMENT,
  }),
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_PNG,
    gcpFileUrl:
      'https://storage.googleapis.com/geneo-content-processed-media/img/wheat-crop-1-cov-9adc9f25ff/wheat-crop-1-cov-9adc9f25ff.jpg?chapter_id=24&lesson_id=lnszbak4zebqb6ubwba&subject_id=24&topic_id=637',
    fileSizeInMb: 3.2,
    fileName: 'file4',
    fileType: FileEnum.FILE_TYPE_IMAGE,
  }),
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_PDF,
    gcpFileUrl: 'https://example.com/file5.xlsx',
    fileSizeInMb: 4.6,
    fileName: 'file5',
    fileType: FileEnum.FILE_TYPE_PDF,
  }),
  new CloudUpload({
    fileExtensionType: FileExtensionEnum.FILE_EXTENSION_PNG,
    gcpFileUrl:
      'https://storage.googleapis.com/geneo-content-processed-media/img/wheat-crop-1-cov-9adc9f25ff/wheat-crop-1-cov-9adc9f25ff.jpg?chapter_id=24&lesson_id=lnszbak4zebqb6ubwba&subject_id=24&topic_id=637',
    fileSizeInMb: 0.9,
    fileName: 'file6',
    fileType: FileEnum.FILE_TYPE_IMAGE,
  }),
];
