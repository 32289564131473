import { shouldIncludeCredentialsInRequest } from './images';

interface IUploadFileRequest {
  file: File;
  signedUrl: string;
}
interface IUploadFileResponse {
  success: boolean;
  message?: string;
}
export const uploadFile = async (
  props: IUploadFileRequest
): Promise<IUploadFileResponse> => {
  const { file, signedUrl } = props;
  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      credentials: shouldIncludeCredentialsInRequest(signedUrl)
        ? 'include'
        : undefined,
    });
    if (response.ok) {
      console.log('File uploaded successfully');
      return { success: true };
    } else {
      console.error('Upload failed:', response.statusText);
      return {
        success: false,
        message: `Upload failed: ${response.statusText}`,
      };
    }
  } catch (error) {
    console.error('Upload error:', error);
    return {
      success: false,
      message: `Upload error: ${error}`,
    };
  }
};
