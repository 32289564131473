import {
  ChipBadge,
  ImageWrapper,
  InfoDisplayPanel,
  LockToggleButton,
  NoContentCard,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  firstLetterImage,
  getHumanReadableTimestampString,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Grid, Typography } from '@mui/material';

import {
  ChapterMetaInfo,
  DownloadedSubject,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDownloadContext } from '../../../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
import { DownloadButtonWrapper } from '../../../../../components/DownloadButtonWrapper';
import { useAppSelector } from '../../../../../reduxStore/reduxHooks';
import { onLessonCardClick } from '../../../../../utils/learn';
import { interactionEvent } from '../../../../Auth/Login/login_logout.events';
import { ReadylessonShimmer } from '../../Shimmer';

interface ITopicLessonList {
  loading: boolean;
  error: boolean;
  lockedData?: ContentLockModuleData;
  showLockPopup: () => void;
}

export const TopicLessonList = (props: ITopicLessonList) => {
  const { loading, error, lockedData, showLockPopup } = props;
  const { topic_id, subject_id, chapter_id } = useParams();
  const topicId = Number(topic_id);
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const dispatch = useDispatch();
  const { isIpadOnly } = useGlobalContext();
  const navigate = useNavigate();
  const moduleLessons = deserify(
    useAppSelector((state) => state.learn.selected_module_lessons)
  );
  const location = useLocation();
  const lessons = moduleLessons?.lessons || [];
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const { downloadResolution } = deserify(
    useAppSelector((state) => state.offline)
  );
  const { addDownload, isDownloading } = useDownloadContext();
  const commonServiceClientContext = useCommonServiceClientContext();

  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    commonServiceClientContext;

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  useEffect(() => {
    if (
      subject_id &&
      chapter_id &&
      topic_id &&
      !isNaN(Number(subject_id)) &&
      !isNaN(Number(chapter_id)) &&
      !isNaN(Number(subject_id))
    ) {
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        const meta = deepClone(downloadedMetaData);
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const offlineData =
            await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
              {
                chapterId: Number(chapter_id),
                topicId: Number(topic_id),
              }
            );
          meta[Number(chapter_id)] = offlineData.data;
        }
        setDownloadedMetaData(meta);
        setFetchingState(false);
      })();
    }
  }, [topicId]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(10), md: pxTovW(10), alignItems: 'center' },
        }}
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ paddingLeft: { xs: pxToRem(20), md: 0 } }}
        >
          Lessons
        </Typography>
        <ChipBadge label={lessons?.length || 0} color="primary" size="small" />
      </Box>
      {loading ? (
        <ReadylessonShimmer />
      ) : error ? (
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      ) : (
        <Box
          sx={{
            paddingLeft: { xs: pxToRem(20), md: pxTovW(0) },
            paddingRight: { xs: pxToRem(20), md: 0 },
            paddingTop: { xs: pxToRem(10), md: pxTovW(10) },
            paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
            marginTop: { xs: pxToRem(10), md: pxTovW(10) },
            // backgroundColor: 'red',
            background: {
              xs: 'linear-gradient(to bottom, white 33%,#EAF4FC 33% 100%)',
              md: 'linear-gradient(to bottom, white 20%,#EAF4FC 20% 80%,white 66% 100%)',
            },
            overflowX: 'scroll',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {lessons?.length === 0 ? (
            <Box>
              <NoContentCard
                variant="white"
                icon="coming-soon-yellow"
                text="No Lessons to show"
              />
            </Box>
          ) : (
            <Grid
              container
              columns={{ xs: 12, md: 2 }}
              sx={{
                height: { md: pxTovW(660) },
                width: { xs: 'max-content', md: '90%', lg: pxTovW(646) },
                display: 'flex',
                justifyContent: 'center',
                overflowY: 'auto',
                paddingY: { md: pxTovW(20) },
                marginX: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
              // sx={{ backgroundColor: 'red' }}
              rowGap={4}
              columnGap={{ xs: 3, md: 0 }}
            >
              {lessons?.map((lesson, lessonIndex) => {
                // const isLocked =
                //   lockedResource &&
                //   lockedResource.children[0].lessonsLockInfo.filter((val) => (
                //     val.lessonId === lesson.lessonId;
                //   );
                // const isLocked =
                //   lockedResource &&
                //   lockedResource.lessonsLockInfo.find(
                //     (val) => val.lessonId === lesson.lessonId
                //   );

                const isLocked =
                  lockedData?.moduleUpdatedLockInfo?.lessonsLockInfo?.find(
                    (val) => val.lessonId === lesson.lessonId
                  );
                return (
                  <Grid
                    item
                    xs="auto"
                    md={1}
                    key={`lesson_${lessonIndex}`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      // backgroundColor: 'red',
                    }}
                  >
                    <InfoDisplayPanel
                      defaultImage="lessonplan-v1"
                      variant={isIpadOnly ? 'large' : 'medium'}
                      mainHeading={lesson.title}
                      iconDetails={[
                        {
                          iconName: 'clock',
                          text: (lesson.estimatedTimeInMin || 0) + ' Min',
                        },
                        {
                          iconName: 'questions',
                          text: lesson.resourceIds.length + ' resources',
                        },
                      ]}
                      rootStyle={{
                        backgroundColor: 'common.white',
                        width: {
                          md: isIpadOnly ? pxTovW(350) : pxTovW(277),
                        },
                        height: {
                          md: isIpadOnly ? pxTovW(420) : pxTovW(314),
                        },
                      }}
                      image={getMediaBasePath(
                        lesson.posterImageUrl,
                        'processedMediaBucket'
                      )}
                      lastSessionTime={
                        lesson.lastSessionTime
                          ? 'Taught ' +
                            getHumanReadableTimestampString(
                              lesson.lastSessionTime
                            )
                          : ''
                      }
                      cardClickHandler={async () => {
                        await interactionEvent({
                          url: 'Student_topic_lesson',
                          context: 'lessons',
                          name: 'LESSON_OPEN',
                        });
                        isContentLockFeature &&
                        isLocked?.lockStatus ===
                          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                          ? showLockPopup()
                          : onLessonCardClick(
                              dispatch,
                              navigate,
                              {
                                lessonId: lesson.lessonId,
                                subjectId,
                                topicId,
                                chapterId,
                              },
                              location.pathname
                            );
                      }}
                      userName={lesson.teacherName}
                      userImage={getMediaBasePath(
                        lesson.teacherProfileImageUrl
                      )}
                      status={<InfoDisplayPanelStatus lesson={lesson} />}
                      isLocked={
                        isContentLockFeature
                          ? isLocked?.lockStatus ===
                            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                          : false
                      }
                    >
                      {!fetchingState && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '15px',
                            right: '15px',
                            display: 'flex',
                            gap: { xs: pxToRem(5), md: pxTovW(5) },
                          }}
                        >
                          {isContentLockFeature && (
                            <LockToggleButton
                              isLocked={
                                lockedData
                                  ? getLessonContentLockInfo(
                                      lesson.lessonId,
                                      lockedData
                                    )?.lockStatus
                                  : false
                              }
                            />
                          )}
                          <DownloadButtonWrapper
                            user={new StudentLoginResponseType(user)}
                            downloadedSubject={
                              downloadedSubject
                                ? new DownloadedSubject(downloadedSubject)
                                : undefined
                            }
                            downloadedMetaData={downloadedMetaData}
                            setDownloadedMetaData={setDownloadedMetaData}
                            subjectId={subjectId}
                            classId={user?.classSectionDetails?.classId}
                            sectionId={user?.classSectionDetails?.sectionId}
                            chapterId={chapterId}
                            topicId={topicId}
                            lessonPlanId={lesson.lessonId}
                            title={`Lesson Plan - ${lesson?.title}`}
                            interactionEventUrl={'Student_topic_lesson'}
                            interactionEventContext={'lesson'}
                            lockedData={lockedData}
                          />
                        </Box>
                      )}
                    </InfoDisplayPanel>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

interface InfoDisplayPanelStatus {
  lesson: LessonInfo;
}
const InfoDisplayPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');
  const editTime = getHumanReadableTimestampString(lesson.modifiedOn)?.split(
    ' '
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name="geneo-blue"
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            getMediaBasePath(lesson.teacherProfileImageUrl) ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      {lesson.teacherName && (
        <Typography
          sx={{ textAlign: 'right' }}
          variant="subText"
          color="text.disabled"
        >
          {taughtTime
            ? 'Taught ' + taughtTime
            : editTime && Number(editTime[0]) < 2
            ? 'Recently Edited'
            : 'Edited ' + editTime?.join(' ')}
        </Typography>
      )}
    </Box>
  );
};

const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lessonsLockInfo.find(
    (val) => val.lessonId === lessonId
  );
};
