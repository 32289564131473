import {
  ActionPopUpSingleButton,
  ContentDetailCard,
  FullWidthSectionList,
  LockToggleButton,
  deserify,
  getMediaBasePath,
  getResourceCategory,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { RecommendationReqSendFromTypeEnum } from '@protos/geneo_ai/ai.student.recommendation.apis_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { onResourceClick } from '../../../../utils/learn';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { aiResourceOpenEvent } from '../../../Learn/utils_learn/learn.events';
import { setHomeRecommendedResourceList } from '../../reducer/homeDashboard.slice';
import SectionListSckeleton, { ShimmerActiveHwCard } from '../../shimmer';
import { useMediaQuery } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ContentLockStatusType, Feature, LessonNodeLock } from '@protos/school_management/school.db_pb';
import { ResourceLock } from '@protos/learning_management/lms.common.apis_pb';
interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}
export default function RecommendedResourcesList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [recommendationId, setRecommendationId] = useState<string | undefined>(
    undefined
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const { AiStudentAPIServiceV1ClientWithStatusCodeHandler, LmsCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { recommended_resources_list } = deserify(
    useAppSelector((state) => state.home)
  );
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const [lockedData, setLockedData] = useState<ResourceLock[]>();
  const [actionPopupState, setActionPopupState] = useState(false);
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });


  useEffect(() => {
    getStudentResourceRecommendation();
  }, [selectedSubjectId]);

  async function getStudentResourceRecommendation() {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      // const response =
      //   await LmsStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendation({
      //     studentId: studentId,
      //     subjectId: selectedSubjectId,
      //   });
      const response =
        await AiStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendationfromGeneoAi(
          {
            studentId: studentId,
            subjectId: selectedSubjectId,
            sendFrom: selectedSubjectId
              ? RecommendationReqSendFromTypeEnum.APP_SELECTED_SUBJECT_HOMEPAGE_RECOMMENDATION
              : RecommendationReqSendFromTypeEnum.APP_HOMEPAGE_RECOMMENDATION,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          dispatch(setHomeRecommendedResourceList(response.data?.resources));
          const resourceIds = response?.data?.resources?.map((resource) => resource.resourceId);
          if (isContentLockFeature && studentId) {
            await fetchLockContent(resourceIds);
          }
          setRecommendationId(response.data.recommendationId);
          return;
        }
      }
      dispatch(setHomeRecommendedResourceList(undefined));
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
      dispatch(setHomeRecommendedResourceList(undefined));
      // setLoading('error');
    }
  }
  const fetchLockContent = async (resourceIds: string[]) => {
    try {
      const response = await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
        {
          personId: studentId,
          personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          subjectId: selectedSubjectId,
          schoolClassSectionId: user?.classSectionDetails?.sectionId,
          resourceIds: resourceIds,
        }
      );
      if (response) {
        setLockedData(response?.resourceInfo);
      }
    }
    catch (err) {
      throw err
    }
  }
  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };
  const getRecommendedResourcesItems = () => {
    if (!recommended_resources_list) {
      return [];
    }
    return recommended_resources_list.map((val, index) => (
      <ContentDetailCard
        isLocked={
          isContentLockFeature ?
            getResourceContentLockInfo(val.resourceId, lockedData)?.lockStatus ===
            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED : false
        }
        locked={
          isContentLockFeature && (
            <LockToggleButton
              isLocked={
                lockedData
                  ? getResourceContentLockInfo
                    (val.resourceId, lockedData
                    )?.lockStatus
                  : false
              }
            />
          )
        }
        tagName={getResourceCategory(val.resourceCategoryType)}
        onClick={async () => {
          await interactionEvent({
            url: 'Student_Home',
            context: 'Recommended_resources',
            name: 'RESOURCE_OPEN',
          });

          await aiResourceOpenEvent(
            val.resourceId,
            ResourceSelectionFromTypeEnum.APP_HOMEPAGE_RECOMMENDATION,
            index + 1,
            recommendationId
          );

          isContentLockFeature &&
            getResourceContentLockInfo(val.resourceId, lockedData)?.lockStatus ===
            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            ? showLockPopup()
            : onResourceClick(
              navigate,
              {
                resourceId: val.resourceId,
              },
              SessionModeEnum.SESSION_MODE_RESOURCE
            );
        }}
        key={'home_content_detail_' + index}
        variant={isIpadOnly ? 'large' : 'medium'}
        image={getMediaBasePath(val.posterImageUrl, 'processedMediaBucket')}
        heading={val.title}
        iconDetails={[
          { iconName: 'clock', text: `${val.estimatedTimeInMin} Min` },
          {
            iconName: resourceTypeName(val.resourceType)?.icon,
            text: resourceTypeName(val.resourceType)?.name,
          },
        ]}
        showVideoIcon={
          val.resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
        }
        rootStyle={{
          backgroundColor: 'common.white',
          height: { md: isIpadOnly ? pxToRem(80) : pxTovW(165) },
          width: { md: isIpadOnly ? pxTovW(570) : pxTovW(457) },
        }}
      />
    ));
  };

  return loading === true ? (
    <SectionListSckeleton fullWidth children={ResourceShimmerArray} />
  ) : (<>
    <FullWidthSectionList
      isError={error}
      hideListCount
      sectionTitle="Recommended Resources"
      items={getRecommendedResourcesItems()}
      noContentMessage={'No Recommended Resources Available'}
    />
    {/* <SectionListSckeleton fullWidth children={OngoingLsShimmerArray} /> */}
    <ActionPopUpSingleButton
      fontSmall={true}
      open={actionPopupState}
      iconName={popupDetails.iconName}
      popupText={popupDetails.popupText}
      handleClose={() => setActionPopupState(false)}
      splitText={popupDetails.splitText}
      ctaHandler={popupDetails.ctaHandler}
      buttontext="OK"
      background="rgba(193, 248, 218, 1)"
    />
  </>
  );
}

const ResourceShimmerArray = [
  <ShimmerActiveHwCard key={1} variant="small" />,
  <ShimmerActiveHwCard key={2} variant="small" />,
  <ShimmerActiveHwCard key={3} variant="small" />,
  <ShimmerActiveHwCard key={4} variant="small" />,
  <ShimmerActiveHwCard key={5} variant="small" />,
];
const getResourceContentLockInfo = (
  resourceId: string,
  contentLockData?: ResourceLock[],
) => {
  return contentLockData?.find(
    (val) => val.resourceId === resourceId
  );
};
