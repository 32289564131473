// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.login.apis.proto (package geneo.ums.login.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AdminLoginResponse, CreateStudentProfileRequest, CreateTeacherProfileRequest, FetchTeacherProfileRequest, GetEmailOTPRequest, GetOTPResponse, GetPhoneOTPRequest, LoginWithEmailPasswordRequest, LoginWithPhonePasswordRequest, LogoutRequest, ResetPasswordFinalRequest, ResetPasswordFinalResponse, ResetPasswordInitRequest, StudentLoginResponse, StudentLoginWithPhoneOrEmailPasswordResponse, StudentProfileFetchRequest, SwitchUserProfileRequest, SwitchUserProfileResponse, TeacherLoginResponse, TeacherLoginWithPhoneOrEmailPasswordResponse, UpdatePasswordRequest, UpdateStudentProfileRequest, UpdateTeacherProfileRequest, UserLoginRequest, UserProfileVerificationRequest, UserProfileVerificationResponse, ValidateUserRequest, ValidateUserResponse, VerifyOTPRequest, VerifyOTPResponse } from "./ums.login.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ums.login.apis.UMSLoginAPIServiceV1
 */
export const UMSLoginAPIServiceV1 = {
  typeName: "geneo.ums.login.apis.UMSLoginAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.createStudentProfile
     */
    createStudentProfile: {
      name: "createStudentProfile",
      I: CreateStudentProfileRequest,
      O: StudentLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.fetchStudentProfile
     */
    fetchStudentProfile: {
      name: "fetchStudentProfile",
      I: StudentProfileFetchRequest,
      O: StudentLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.updateStudentProfile
     */
    updateStudentProfile: {
      name: "updateStudentProfile",
      I: UpdateStudentProfileRequest,
      O: StudentLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.validateUser
     */
    validateUser: {
      name: "validateUser",
      I: ValidateUserRequest,
      O: ValidateUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.userProfileVerification
     */
    userProfileVerification: {
      name: "userProfileVerification",
      I: UserProfileVerificationRequest,
      O: UserProfileVerificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.studentLoginWithUsername
     */
    studentLoginWithUsername: {
      name: "studentLoginWithUsername",
      I: UserLoginRequest,
      O: StudentLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.studentLoginWithPhonePassword
     */
    studentLoginWithPhonePassword: {
      name: "studentLoginWithPhonePassword",
      I: LoginWithPhonePasswordRequest,
      O: StudentLoginWithPhoneOrEmailPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.studentLoginWithEmailPassword
     */
    studentLoginWithEmailPassword: {
      name: "studentLoginWithEmailPassword",
      I: LoginWithEmailPasswordRequest,
      O: StudentLoginWithPhoneOrEmailPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.createTeacherProfile
     */
    createTeacherProfile: {
      name: "createTeacherProfile",
      I: CreateTeacherProfileRequest,
      O: TeacherLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.fetchTeacherProfile
     */
    fetchTeacherProfile: {
      name: "fetchTeacherProfile",
      I: FetchTeacherProfileRequest,
      O: TeacherLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.updateTeacherProfile
     */
    updateTeacherProfile: {
      name: "updateTeacherProfile",
      I: UpdateTeacherProfileRequest,
      O: TeacherLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.teacherLoginWithUsername
     */
    teacherLoginWithUsername: {
      name: "teacherLoginWithUsername",
      I: UserLoginRequest,
      O: TeacherLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.teacherLoginWithPhonePassword
     */
    teacherLoginWithPhonePassword: {
      name: "teacherLoginWithPhonePassword",
      I: LoginWithPhonePasswordRequest,
      O: TeacherLoginWithPhoneOrEmailPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.teacherLoginWithEmailPassword
     */
    teacherLoginWithEmailPassword: {
      name: "teacherLoginWithEmailPassword",
      I: LoginWithEmailPasswordRequest,
      O: TeacherLoginWithPhoneOrEmailPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.getPhoneOTP
     */
    getPhoneOTP: {
      name: "getPhoneOTP",
      I: GetPhoneOTPRequest,
      O: GetOTPResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.getEmailOTP
     */
    getEmailOTP: {
      name: "getEmailOTP",
      I: GetEmailOTPRequest,
      O: GetOTPResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.verifyOTP
     */
    verifyOTP: {
      name: "verifyOTP",
      I: VerifyOTPRequest,
      O: VerifyOTPResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.resetPasswordInit
     */
    resetPasswordInit: {
      name: "resetPasswordInit",
      I: ResetPasswordInitRequest,
      O: GetOTPResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.resetPasswordFinal
     */
    resetPasswordFinal: {
      name: "resetPasswordFinal",
      I: ResetPasswordFinalRequest,
      O: ResetPasswordFinalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.updatePassword
     */
    updatePassword: {
      name: "updatePassword",
      I: UpdatePasswordRequest,
      O: ResetPasswordFinalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.logout
     */
    logout: {
      name: "logout",
      I: LogoutRequest,
      O: ResetPasswordFinalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.switchUserProfile
     */
    switchUserProfile: {
      name: "switchUserProfile",
      I: SwitchUserProfileRequest,
      O: SwitchUserProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ADMIN
     *
     * @generated from rpc geneo.ums.login.apis.UMSLoginAPIServiceV1.adminLoginWithUsername
     */
    adminLoginWithUsername: {
      name: "adminLoginWithUsername",
      I: UserLoginRequest,
      O: AdminLoginResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

