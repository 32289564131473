import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { AiStudentAPIServiceV1 } from '@protos/geneo_ai/ai.student.recommendation.apis_connect';
import { ResponseStatusCodeFunction, studentRecommendationTransport, studentRecommendationTransportWithLogoutHandler } from './transports';

export const AiStudentAPIServiceV1Client = createPromiseClient(
  AiStudentAPIServiceV1,
  studentRecommendationTransport
);

export const useAiStudentAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  AiStudentAPIServiceV1,
  studentRecommendationTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
