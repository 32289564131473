import { JsonValue, PartialMessage } from '@bufbuild/protobuf';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { TeacherEvent } from '@protos/geneo_ai/ai.teacher.events_pb';
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { CommonServiceClientContextData } from './CommonServiceClientProvider';

export interface RequestOfEvents {
  key: string;
  value: JsonValue;
}

// Create a Dexie database instance
class EventDatabase extends Dexie {
  eventRequests: Dexie.Table<RequestOfEvents, string>;

  constructor() {
    super('EventDatabase');

    // Define tables
    this.version(1).stores({
      eventRequests: '&key,&value',
    });

    // Access the eventRequests table
    this.eventRequests = this.table('eventRequests');
  }
}

// Instantiate the database
const db = new EventDatabase();

// Example of using dexie-react-hooks to query the eventRequests table
function useEventRequests() {
  return useLiveQuery(() => {
    return db.eventRequests.toArray();
  }, []);
}

export async function addEventRequest(request: RequestOfEvents) {
  try {
    await db.transaction('rw', db.eventRequests, async function () {
      await db.eventRequests.put(request);
    });

    // console.log('Data added successfully:', request);
  } catch (error) {
    console.error('Error adding data:', error);
  }
}

export async function findEventRequestByParams(
  searchParams: Partial<RequestOfEvents>
) {
  try {
    const result = await db.eventRequests.where(searchParams).toArray();
    console.log(db.eventRequests);
    if (result && result.length > 0) {
      return result; // Return the first matching entry
    } else {
      return [];
    }
  } catch (error) {
    throw new Error('Error finding data: ' + error);
  }
}

export async function findAllEventRequest() {
  try {
    const result = await db.eventRequests.toArray();
    if (result && result.length > 0) {
      return result; // Return the first matching entry
    } else {
      return [];
    }
  } catch (error) {
    throw new Error('Error finding data: ' + error);
  }
}

export async function deleteEventRequestsFromIndexDBByKey(key: string) {
  try {
    await db.transaction('rw', db.eventRequests, async () => {
      // Delete entries based on the provided IDs

      await db.eventRequests.where({ key }).delete();
    });

    // console.log('IndexDB: Entries with key ' + key + ' successfully deleted.');
  } catch (error) {
    console.error('IndexDB: Error deleting entries:', error);

    throw new Error('IndexDB: Error deleting entries: ' + error);
  }
}

export async function callStudentEventsApi(
  context: CommonServiceClientContextData
) {
  const indexDbData = await findAllEventRequest();
  const { StudentEventsAPIServiceV1ClientWithStatusCodeHandler } = context;

  for (let i = 0; i < indexDbData.length; i++) {
    try {
      if (indexDbData[i].value) {
        const dataValue: PartialMessage<StudentEvent> =
          new StudentEvent().fromJson(indexDbData[i].value, {
            ignoreUnknownFields: true,
          });

        const response =
          await StudentEventsAPIServiceV1ClientWithStatusCodeHandler.studentEventProcess(
            dataValue
          );

        if (response) {
          await deleteEventRequestsFromIndexDBByKey(indexDbData[i].key);
          // console.log('Event sent Successfully:', indexDbData[i]);
        }
      }
    } catch (error: any) {
      if (
        !error?.message.includes('Request timed out') &&
        !error?.message.includes('Unverified user')
      ) {
        await deleteEventRequestsFromIndexDBByKey(indexDbData[i].key);
        console.log('Event failed due to internal error');
      } else {
        console.log('Event sent Failed:', indexDbData[i]);
      }
    }
  }
}

export async function callTeacherEventsApi(
  context: CommonServiceClientContextData
) {
  const indexDbData = await findAllEventRequest();
  const { TeacherEventsAPIServiceV1ClientWithStatusCodeHandler } = context;

  // promises.all
  for (let i = 0; i < indexDbData.length; i++) {
    try {
      if (indexDbData[i].value) {
        const dataValue: PartialMessage<TeacherEvent> =
          new TeacherEvent().fromJson(indexDbData[i].value, {
            ignoreUnknownFields: true,
          });

        const response =
          await TeacherEventsAPIServiceV1ClientWithStatusCodeHandler.teacherEventProcess(
            dataValue
          );

        // if (response.isSuccessful) {
        if (response) {
          await deleteEventRequestsFromIndexDBByKey(indexDbData[i].key);
          // console.log('Event sent Successfully:', indexDbData[i]);
        }
      }
    } catch (error: any) {
      if (
        !error?.message.includes('Request timed out') &&
        !error?.message.includes('Unverified user')
      ) {
        await deleteEventRequestsFromIndexDBByKey(indexDbData[i].key);
        console.log('Event failed due to internal error');
      } else {
        console.log('Event sent Failed:', indexDbData[i]);
      }
    }
  }
}
