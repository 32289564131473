import {
  HelpAndSupportText,
  IStyles,
  ImageWrapper,
  InfoPopup,
  InputFieldContainer,
  Loader,
  PrimaryButton,
  deserify,
  findOfflineUserRequestByParamsDexie,
  isValidEmail,
  isValidMobileNumber,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Button, Typography } from '@mui/material';
import {
  LoginTypeEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';

import { Code, ConnectError } from '@bufbuild/connect';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  LOGIN_PASSWORD,
  REGISTRATION,
  VERIFY_DETAILS,
} from '../../../routeHandling/RoutesNomenclature';
import { useAuthentication } from '../../../utils/customHooks';
import ConfigSelector from '../../OfflineScreens/Learn/components/ConfigSelector';
import {
  setLoginEmail,
  setLoginPhone,
  setLoginUID,
  setUserValidationInfo,
} from '../reducer/auth.slice';

const styles: IStyles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: { xs: 'flex-start', md: 'center' },
    alignItems: 'center',
  },
  containerTop: {
    width: '100%',
    // flexBasis: { xs: pxToRem(300) },
    // flexGrow: { md: 1 },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(41) },
    // backgroundColor: 'red',
  },
  containerBotton: {
    marginTop: { xs: pxToRem(75), md: pxTovW(59) },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(25), md: pxTovW(65) },
    mb: { md: pxTovW(163) },
  },
  supportBox: {
    position: { xs: 'absolute', md: 'absolute' },
    bottom: { xs: 0, md: 0 },
    width: '100%',
    bgcolor: 'neutral.turquoise',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: { xs: pxToRem(25), md: pxTovW(25) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(10)}`,
      md: `${pxTovW(20)} ${pxTovW(10)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(4) },
  },
};
export default function EnterUserIdentification() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [credential, setCredential] = useState('');
  const [credentialError, setCredentialError] = useState<string>('');
  const { isOffline } = useDownloadContext();
  const { login_Type, login_email, login_phone, login_uid } = deserify(
    useAppSelector((state) => state.auth)
  );
  const { setSelectedFunction } = useGlobalContext();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const isAuthenticated = useAuthentication();
  const backButtonClick = async () => {
    navigate('/');
  };

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);
  useEffect(() => {
    if (isAuthenticated) navigate(HOME);
    if (login_Type) {
      switch (login_Type) {
        case LoginTypeEnum.LOGIN_TYPE_EMAIL:
          setCredential(login_email || '');
          break;
        case LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER:
          setCredential(login_phone || '');
          break;
        case LoginTypeEnum.LOGIN_TYPE_USERNAME:
          setCredential(login_uid || '');
          break;

        default:
          break;
      }
    }
  }, []);

  const handleCredentialChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCredential(event.target.value);
    setCredentialError('');
  };

  const validateInputs = (): boolean => {
    if (!credential.trim()) {
      setCredentialError(
        'Please enter a valid username, phone number, or email.'
      );
      return false;
    }
    setCredentialError('');
    return true;
  };

  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  async function validationRequest() {
    let loginType;
    const userCredential = credential.trim();
    try {
      setLoading('loading');
      if (isValidMobileNumber(userCredential)) {
        loginType = LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER;
      } else if (isValidEmail(userCredential)) {
        loginType = LoginTypeEnum.LOGIN_TYPE_EMAIL;
      } else {
        loginType = LoginTypeEnum.LOGIN_TYPE_USERNAME;
      }

      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.validateUser({
          loginType,
          userLoginInput: userCredential,
          role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        });

      if (response.status === 200 && response.data.length > 0) {
        if (loginType === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
          if (response.data[0].isActive === 0) {
            dispatch(setLoginUID(userCredential));
            dispatch(setUserValidationInfo(response.data[0]));
            // dispatch(
            //   setToastInfo({
            //     label: 'User not Registered , Please register first',
            //     variant: 'error',
            //     open: true,
            //   })
            // );
            navigate(VERIFY_DETAILS);
            return;
          }
          dispatch(setLoginUID(userCredential));
          dispatch(setUserValidationInfo(response.data[0]));
        } else if (loginType === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER) {
          dispatch(setLoginPhone(userCredential));
          if (response.data.length === 1) {
            dispatch(setUserValidationInfo(response.data[0]));
          }
        } else if (loginType === LoginTypeEnum.LOGIN_TYPE_EMAIL) {
          dispatch(setLoginEmail(userCredential));
          if (response.data.length === 1) {
            dispatch(setUserValidationInfo(response.data[0]));
          }
        }
        setLoading('completed');
        navigate(LOGIN_PASSWORD);
      } else {
        setLoading('error');
        loginType === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
          ? setCredentialError('Invalid Phone Number')
          : loginType === LoginTypeEnum.LOGIN_TYPE_EMAIL
          ? setCredentialError('Invalid Email Id')
          : setCredentialError('Invalid Username');
      }
    } catch (err: any) {
      setLoading('error');

      if (err instanceof ConnectError) {
        if (err.code === Code.Unknown || err.code === Code.Internal) {
          if (loginType === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
            const offlineUser = await findOfflineUserRequestByParamsDexie({
              username: userCredential,
            });
            if (offlineUser) {
              dispatch(setLoginUID(userCredential));
              setLoading('completed');
              navigate(LOGIN_PASSWORD);
              return;
            }
          }
        }
      }
      if (err) {
        const message = err.message
          ? err.message.split(' ').slice(1).join(' ')
          : undefined;
        message
          ? setCredentialError(message)
          : loginType === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
          ? setCredentialError('Invalid Phone Number')
          : loginType === LoginTypeEnum.LOGIN_TYPE_EMAIL
          ? setCredentialError('Invalid Email Id')
          : setCredentialError('Invalid Username');
      }
      setLoading('error');
    }
  }
  const nextClickHandler = () => {
    if (validateInputs()) {
      validationRequest();
    }
  };
  const [actionPopupState, setActionPopupState] = useState(false);
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box
      sx={{
        ...styles.root,
      }}
    >
      <Box sx={styles.containerTop}>
        <Box sx={{ textAlign: { md: 'center' } }}>
          <Typography variant="h1">Start Your</Typography>
          <Typography variant="h1" color="primary.main">
            Learning Journey
          </Typography>
        </Box>
        {isOffline && (
          <ConfigSelector
            rootStyle={{
              marginTop: 0,
              marginBottom: 0,
              width: '100%',
            }}
          />
        )}
        <Box>
          <InputFieldContainer
            helperTextvariant="error"
            topLabel="Enter Username / Phone / Email"
            value={credential}
            onChange={handleCredentialChange}
            helperText={credentialError}
            // nonEditable={true}
            onEnter={() => nextClickHandler()}
          />
        </Box>
      </Box>
      <Box sx={styles.containerBotton}>
        <Box
          sx={{
            width: { xs: '100%', md: pxTovW(517) },
            height: { xs: pxToRem(55), md: pxTovW(77) },
          }}
        >
          <PrimaryButton
            fullWidth
            onClick={() => nextClickHandler()}
            disabled={credential.length === 0}
          >
            Next
          </PrimaryButton>
        </Box>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography variant="h3">First Time User? </Typography>
          <Button sx={{ p: 0 }} onClick={() => navigate(REGISTRATION)}>
            <Typography variant="h3" color="primary.main">
              Register Here
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={styles.supportBox} onClick={() => setActionPopupState(true)}>
        <ImageWrapper
          name="loginSupport"
          parentFolder="icons"
          type="png"
          styles={{
            height: { xs: pxToRem(20), md: pxTovW(25) },
            width: { xs: pxToRem(16), md: pxTovW(20) },
          }}
        />
        <Typography variant="linkText">Help & Support</Typography>
      </Box>

      {/* POPUP */}
      <InfoPopup
        iconName="support2"
        popupText={[
          <Typography variant="h2" fontWeight="bold">
            Help & Support
          </Typography>,
          <HelpAndSupportText />,
        ]}
        background="#007CDC"
        handleClose={() => setActionPopupState(false)}
        open={actionPopupState}
      />
    </Box>
  );
}
