import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LessonTeachAPIServiceV1 } from '@protos/learning_management/lms.lesson.teach.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const LessonTeachAPIServiceV1Client = createPromiseClient(
  LessonTeachAPIServiceV1,
  lmsTransport
);

export const useLessonTeachAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  LessonTeachAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
