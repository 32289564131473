/* eslint-disable @typescript-eslint/no-var-requires */

import { Message } from '@bufbuild/protobuf';

export const LOCAL_STORAGE = (() => {
  if (typeof window !== 'undefined') {
    return window.localStorage;
  } else {
    const storage = require('node-localstorage').LocalStorage;
    return new storage('./node-localstorage/scratch.local');
  }
})() as Storage;

export const SESSION_STORAGE = (() => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage;
  } else {
    const storage = require('node-localstorage').LocalStorage;
    return new storage('./node-localstorage/scratch.session');
  }
})() as Storage;

export function saveMessage(key: string, message: Message, storage: Storage) {
  storage.setItem(key, message.toJsonString());
}

export function getMessage(key: string, message: Message, storage: Storage) {
  const value = storage.getItem(key);
  if (value !== null) {
    return message.fromJsonString(value);
  }
}

export function saveMessageLocal(key: string, message: Message) {
  saveMessage(key, message, LOCAL_STORAGE);
}

export function saveMessageSession(key: string, message: Message) {
  saveMessage(key, message, SESSION_STORAGE);
}

export function getMessageLocal(key: string, message: Message) {
  return getMessage(key, message, LOCAL_STORAGE);
}

export function getMessageSession(key: string, message: Message) {
  return getMessage(key, message, SESSION_STORAGE);
}
