// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/common/common.db.apis.proto (package geneo.common.db.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { AcademicYear, AcademicYear_Enum, Board, Class, Subject, TeachMedium } from "@protos/common/common.db_pb";

/**
 * @generated from message geneo.common.db.apis.AddAcademicYearRequest
 */
export class AddAcademicYearRequest extends Message<AddAcademicYearRequest> {
  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 1;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * Start date of the academic year
   *
   * @generated from field: google.protobuf.Timestamp academic_year_start = 2;
   */
  academicYearStart?: Timestamp;

  /**
   * End date of the academic year
   *
   * @generated from field: google.protobuf.Timestamp academic_year_end = 3;
   */
  academicYearEnd?: Timestamp;

  constructor(data?: PartialMessage<AddAcademicYearRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.AddAcademicYearRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 2, name: "academic_year_start", kind: "message", T: Timestamp },
    { no: 3, name: "academic_year_end", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAcademicYearRequest {
    return new AddAcademicYearRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAcademicYearRequest {
    return new AddAcademicYearRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddAcademicYearRequest {
    return new AddAcademicYearRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddAcademicYearRequest | PlainMessage<AddAcademicYearRequest> | undefined, b: AddAcademicYearRequest | PlainMessage<AddAcademicYearRequest> | undefined): boolean {
    return proto3.util.equals(AddAcademicYearRequest, a, b);
  }
}

/**
 * Add any specific request fields here, if needed.
 *
 * @generated from message geneo.common.db.apis.FetchBoardsRequest
 */
export class FetchBoardsRequest extends Message<FetchBoardsRequest> {
  constructor(data?: PartialMessage<FetchBoardsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchBoardsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBoardsRequest {
    return new FetchBoardsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBoardsRequest {
    return new FetchBoardsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBoardsRequest {
    return new FetchBoardsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBoardsRequest | PlainMessage<FetchBoardsRequest> | undefined, b: FetchBoardsRequest | PlainMessage<FetchBoardsRequest> | undefined): boolean {
    return proto3.util.equals(FetchBoardsRequest, a, b);
  }
}

/**
 * @generated from message geneo.common.db.apis.FetchBoardsResponse
 */
export class FetchBoardsResponse extends Message<FetchBoardsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.common.db.Board data = 4;
   */
  data: Board[] = [];

  constructor(data?: PartialMessage<FetchBoardsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchBoardsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: Board, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchBoardsResponse {
    return new FetchBoardsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchBoardsResponse {
    return new FetchBoardsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchBoardsResponse {
    return new FetchBoardsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchBoardsResponse | PlainMessage<FetchBoardsResponse> | undefined, b: FetchBoardsResponse | PlainMessage<FetchBoardsResponse> | undefined): boolean {
    return proto3.util.equals(FetchBoardsResponse, a, b);
  }
}

/**
 * Add any specific request fields here, if needed.
 *
 * @generated from message geneo.common.db.apis.FetchAcademicYearsRequest
 */
export class FetchAcademicYearsRequest extends Message<FetchAcademicYearsRequest> {
  constructor(data?: PartialMessage<FetchAcademicYearsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchAcademicYearsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAcademicYearsRequest {
    return new FetchAcademicYearsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAcademicYearsRequest {
    return new FetchAcademicYearsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAcademicYearsRequest {
    return new FetchAcademicYearsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAcademicYearsRequest | PlainMessage<FetchAcademicYearsRequest> | undefined, b: FetchAcademicYearsRequest | PlainMessage<FetchAcademicYearsRequest> | undefined): boolean {
    return proto3.util.equals(FetchAcademicYearsRequest, a, b);
  }
}

/**
 * @generated from message geneo.common.db.apis.FetchAcademicYearsResponse
 */
export class FetchAcademicYearsResponse extends Message<FetchAcademicYearsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.common.db.AcademicYear data = 4;
   */
  data: AcademicYear[] = [];

  constructor(data?: PartialMessage<FetchAcademicYearsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchAcademicYearsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AcademicYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAcademicYearsResponse {
    return new FetchAcademicYearsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAcademicYearsResponse {
    return new FetchAcademicYearsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAcademicYearsResponse {
    return new FetchAcademicYearsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAcademicYearsResponse | PlainMessage<FetchAcademicYearsResponse> | undefined, b: FetchAcademicYearsResponse | PlainMessage<FetchAcademicYearsResponse> | undefined): boolean {
    return proto3.util.equals(FetchAcademicYearsResponse, a, b);
  }
}

/**
 * Add any specific request fields here, if needed.
 *
 * @generated from message geneo.common.db.apis.FetchClassesRequest
 */
export class FetchClassesRequest extends Message<FetchClassesRequest> {
  constructor(data?: PartialMessage<FetchClassesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchClassesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClassesRequest {
    return new FetchClassesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClassesRequest {
    return new FetchClassesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClassesRequest {
    return new FetchClassesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClassesRequest | PlainMessage<FetchClassesRequest> | undefined, b: FetchClassesRequest | PlainMessage<FetchClassesRequest> | undefined): boolean {
    return proto3.util.equals(FetchClassesRequest, a, b);
  }
}

/**
 * @generated from message geneo.common.db.apis.FetchClassesResponse
 */
export class FetchClassesResponse extends Message<FetchClassesResponse> {
  /**
   * @generated from field: repeated geneo.common.db.Class classes = 1;
   */
  classes: Class[] = [];

  constructor(data?: PartialMessage<FetchClassesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchClassesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "classes", kind: "message", T: Class, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClassesResponse {
    return new FetchClassesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClassesResponse {
    return new FetchClassesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClassesResponse {
    return new FetchClassesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClassesResponse | PlainMessage<FetchClassesResponse> | undefined, b: FetchClassesResponse | PlainMessage<FetchClassesResponse> | undefined): boolean {
    return proto3.util.equals(FetchClassesResponse, a, b);
  }
}

/**
 * Add any specific request fields here, if needed.
 *
 * @generated from message geneo.common.db.apis.FetchSubjectsRequest
 */
export class FetchSubjectsRequest extends Message<FetchSubjectsRequest> {
  constructor(data?: PartialMessage<FetchSubjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchSubjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectsRequest {
    return new FetchSubjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectsRequest {
    return new FetchSubjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectsRequest {
    return new FetchSubjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectsRequest | PlainMessage<FetchSubjectsRequest> | undefined, b: FetchSubjectsRequest | PlainMessage<FetchSubjectsRequest> | undefined): boolean {
    return proto3.util.equals(FetchSubjectsRequest, a, b);
  }
}

/**
 * @generated from message geneo.common.db.apis.FetchSubjectsResponse
 */
export class FetchSubjectsResponse extends Message<FetchSubjectsResponse> {
  /**
   * @generated from field: repeated geneo.common.db.Subject subjects = 1;
   */
  subjects: Subject[] = [];

  constructor(data?: PartialMessage<FetchSubjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchSubjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subjects", kind: "message", T: Subject, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSubjectsResponse {
    return new FetchSubjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSubjectsResponse {
    return new FetchSubjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSubjectsResponse {
    return new FetchSubjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSubjectsResponse | PlainMessage<FetchSubjectsResponse> | undefined, b: FetchSubjectsResponse | PlainMessage<FetchSubjectsResponse> | undefined): boolean {
    return proto3.util.equals(FetchSubjectsResponse, a, b);
  }
}

/**
 * Add any specific request fields here, if needed.
 *
 * @generated from message geneo.common.db.apis.FetchTeachMediumsRequest
 */
export class FetchTeachMediumsRequest extends Message<FetchTeachMediumsRequest> {
  constructor(data?: PartialMessage<FetchTeachMediumsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchTeachMediumsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTeachMediumsRequest {
    return new FetchTeachMediumsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTeachMediumsRequest {
    return new FetchTeachMediumsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTeachMediumsRequest {
    return new FetchTeachMediumsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTeachMediumsRequest | PlainMessage<FetchTeachMediumsRequest> | undefined, b: FetchTeachMediumsRequest | PlainMessage<FetchTeachMediumsRequest> | undefined): boolean {
    return proto3.util.equals(FetchTeachMediumsRequest, a, b);
  }
}

/**
 * @generated from message geneo.common.db.apis.FetchTeachMediumsResponse
 */
export class FetchTeachMediumsResponse extends Message<FetchTeachMediumsResponse> {
  /**
   * @generated from field: repeated geneo.common.db.TeachMedium teachingMediumBoards = 1;
   */
  teachingMediumBoards: TeachMedium[] = [];

  constructor(data?: PartialMessage<FetchTeachMediumsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.common.db.apis.FetchTeachMediumsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teachingMediumBoards", kind: "message", T: TeachMedium, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTeachMediumsResponse {
    return new FetchTeachMediumsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTeachMediumsResponse {
    return new FetchTeachMediumsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTeachMediumsResponse {
    return new FetchTeachMediumsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTeachMediumsResponse | PlainMessage<FetchTeachMediumsResponse> | undefined, b: FetchTeachMediumsResponse | PlainMessage<FetchTeachMediumsResponse> | undefined): boolean {
    return proto3.util.equals(FetchTeachMediumsResponse, a, b);
  }
}

