import { IStyles } from '@geneo2-web/shared-ui';
import { useLocation } from 'react-router-dom';
import { BackendLogin } from './components/BackendLogin';
import { MCBSSOLogin } from './components/MCBSSOLogin';

const styles: IStyles = {};

export const MCBLogin = () => {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code') || undefined;

  const pageToShow = code ? 'backend-login' : 'sso-login';
  switch (pageToShow) {
    case 'backend-login':
      return <BackendLogin code={code} />;
    case 'sso-login':
      return <MCBSSOLogin />;
    default:
      return null;
  }
};
