import { Box, Dialog, Typography } from '@mui/material';

import { StudentPerformanceInfo } from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { ChangeEvent, useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import PrimaryButton from '../../elements/PrimaryButton';
import { NumericalInputField } from '../NumericalInputField';
interface ISubmissionProps {
  studentData?: StudentPerformanceInfo;
  marks?: number;
}
interface IProps {
  totalMarks: number;
  open: boolean;
  handleClose: () => void;
  submitClickHandler: (props: ISubmissionProps) => void;
}
const styles: IStyles = {
  root: {},
  marksBox: {
    width: { xs: '100%', md: pxTovW(500) },
    mt: { xs: pxToRem(20), md: pxTovW(32) },
    // width: { xs: pxToRem(200), md: pxTovW(573) },
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },

    p: {
      xs: `${pxToRem(20)} ${pxToRem(0)}`,
      md: `${pxTovW(46)} ${pxTovW(0)} ${pxTovW(69)}`,
    },
    // height: { xs: pxToRem(200), md: pxTovW(370) },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(24), md: pxTovW(52) },
    backgroundColor: 'white',
  },
  marksInputBox: {
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(8), md: pxTovW(8) },
    // p: { xs: `${pxToRem(0)} ${pxToRem(20)}`, md: '0' },
  },
  closeBox: {
    border: '5px solid transparent',
    height: { xs: pxToRem(30), md: pxToRem(30) },
    width: { xs: pxToRem(30), md: pxToRem(30) },
    cursor: 'pointer',
    mt: { xs: pxToRem(10) },
  },
  modal: {
    '& .MuiDialog-paper': {
      background: 'transparent',
      width: { xs: '90vw', md: pxTovW(627) },
      maxWidth: 'none',
      boxShadow: 'none',
    },
  },
};

export const MarksSubmitPopup = (props: IProps) => {
  const { open, handleClose, totalMarks, submitClickHandler } = props;

  const [marks, setMarks] = useState<string | undefined>();
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleMarksChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || (Number(value) <= totalMarks && Number(value) >= 0)) {
      setMarks(value);
      setErrorMessage(''); // Clear error message if input is valid
    } else {
      setErrorMessage(`Marks cannot exceed ${totalMarks}`);
    }
  };

  const handleMarksSubmit = () => {
    if (Number(marks) <= totalMarks && Number(marks) >= 0) {
      submitClickHandler({ marks: Number(marks) });
      handleClose();
    } else {
      setErrorMessage(`Marks cannot exceed ${totalMarks}`);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setMarks(undefined);
        handleClose && handleClose();
      }}
      sx={styles.modal}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: { xs: pxToRem(8), md: pxTovW(8) },
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={styles.marksBox}>
          <Typography variant="h2" color="initial">
            Enter Marks
          </Typography>

          <Box sx={styles.marksInputBox}>
            <NumericalInputField
              sx={{
                width: { xs: pxToRem(93), md: 'unset' },
                height: { xs: pxToRem(35), md: 'unset' },
                // backgroundColor: 'blue',
              }}
              variant="outlined"
              value={marks}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // const value = e.target.value;
                // value ? setMarks(Number(value)) : setMarks(undefined);
                handleMarksChange(e);
              }}
            />
            <Typography variant="h4" color="initial">
              out of {totalMarks} Marks
            </Typography>
          </Box>

          {errorMessage && (
            <Typography sx={styles.errorText} variant="body2">
              {errorMessage}
            </Typography>
          )}

          <PrimaryButton
            onClick={() => {
              handleMarksSubmit();
            }}
            sx={{ width: { xs: pxToRem(144), md: pxTovW(245) } }}
          >
            DONE
          </PrimaryButton>
        </Box>
        <ImageWrapper
          name="close-yellow"
          type="png"
          parentFolder="icons"
          onClick={handleClose}
          styles={styles.closeBox}
        />
      </Box>
    </Dialog>
  );
};
