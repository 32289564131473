function getDeviceType() {
  const userAgent = navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return 'android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'ios';
  } else {
    return 'web';
  }
}

export default getDeviceType;
