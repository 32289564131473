import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import { IStyles } from 'libs/shared-ui/src/commonUtils/styleUtils';
interface Iprops {
  statusmark: 'on' | 'off' | 'disconnected';
}
export const AnswerStatusMark = (props: Iprops) => {
  const { statusmark } = props
  const styles: IStyles = {
    status: {
      width: { xs: pxToRem(35), md: pxTovW(35) },
      height: { xs: pxToRem(35), md: pxTovW(35) },
      // background: '#0AA34F',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
  return (
    <>
      {statusmark === 'on' ?
        <Box sx={{ ...styles.status, background: '#0AA34F' }}>
          <CheckOutlinedIcon sx={{
            color: 'white',
            width: { xs: pxToRem(24), md: pxTovW(24) },
            height: { xs: pxToRem(24), md: pxTovW(24) },
          }} />
        </Box>
        : statusmark === 'off' ?
          <Box sx={{ ...styles.status, background: '#F54040' }}>
            <CloseOutlinedIcon sx={{
              color: 'white',
              width: { xs: pxToRem(24), md: pxTovW(24) },
              height: { xs: pxToRem(24), md: pxTovW(24) },
            }} />
          </Box >
          :
          <Box sx={{ ...styles.status, background: '#D9D9D9' }}>
            <Typography
              color='common.white'>
              0</Typography>
          </Box >
      }
    </>
  )
}
