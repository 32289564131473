import { ImageWrapper, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MCB_LOGIN } from '../../../../routeHandling/RoutesNomenclature';

interface IMCBErrorScreen {
  errorMessage: string;
}

export const MCBErrorScreen = (props: IMCBErrorScreen) => {
  const { errorMessage } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '28px',
      }}
    >
      <ImageWrapper
        styles={{
          height: {
            xs: pxToRem(142),
            md: pxTovW(142),
          },
          width: {
            xs: pxToRem(142),
            md: pxTovW(142),
          },
        }}
        name={'error'}
        type="png"
        parentFolder="icons"
      />
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        {errorMessage}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          textDecoration: 'underline',
          color: '#9D9D9D',
          marginTop: '44px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onClick={() => navigate(MCB_LOGIN)}
      >
        Click here to retry
      </Typography>
    </Box>
  );
};
