import { theme } from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import { IStyles } from 'libs/shared-ui/src/commonUtils/styleUtils';
interface IProps {
  status: string;
}
const styles: IStyles = {
  online: {
    width: { xs: pxToRem(7), md: pxTovW(13) },
    background: '#16E817',
    height: { xs: pxToRem(7), md: pxTovW(14) },
    borderRadius: '10px',
    marginY: 'auto',
  },
  offline:
  {
    width: { xs: pxToRem(7), md: pxTovW(13) },
    background: '#F54040',
    height: { xs: pxToRem(7), md: pxTovW(14) },
    borderRadius: '10px',
    marginY: 'auto'
  },
  onBox: {
    display: 'flex',
    justifyContent: 'center',
    width: { xs: pxToRem(53), md: pxTovW(98) },
    border: '1px solid #0AA34F',
    background: '#E4FEF0',
    height: { xs: pxToRem(18), md: pxTovW(38) },
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    borderRadius: '18px'
  },
  offBox: {
    display: 'flex',
    justifyContent: 'center',
    width: { xs: pxToRem(53), md: pxTovW(98) },
    border: '1px solid #F54040',
    background: '#FFE4E4',
    height: { xs: pxToRem(18), md: pxTovW(38) },
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    borderRadius: '18px'
  }
}
export const OnlineStatusTag = (props: IProps) => {
  const { status } = props;
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box sx={status === "online" ? styles.onBox : styles.offBox}>

      <Box
        sx={status === "online" ? styles.online : styles.offline}>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
          // marginY: 'auto'
        }}>
        <Typography variant={largeScreen ? 'h4' : 'smallText'} fontWeight={600} color={status === "online" ? '#0AA34F ' : '#F54040'}>
          {status}
        </Typography>
      </Box >
    </Box >
  )
}
