import { Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';
import { IStyles } from 'libs/shared-ui/src/commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';

const styles: IStyles = {
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 35px #0000000F',
    border: '1px dashed lightgrey',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    padding: {
      xs: `${pxToRem(16)} ${pxToRem(22)}`,
      md: `${pxTovW(20)} ${pxTovW(30)}`,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: pxTovW(5),
    '&>div': {
      display: 'flex',
      gap: pxTovW(5),
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
interface IProps {
  contentList: IContentList[];
  rootStyles?: SxProps;
}
interface IContentList {
  iconName: string;
  heading: string | number;
  subHeading: string;
}
export const InfoBar = (props: IProps) => {
  const { contentList, rootStyles } = props;
  let rootSx = { ...styles.root };
  if (rootStyles) rootSx = { ...rootSx, ...rootStyles };
  return (
    <Box sx={rootSx}>
      {contentList.map((e, index) => (
        <Box key={index} sx={styles.item}>
          <Box>
            <IconWrapper
              name={e.iconName}
              size="md"
              type="png"
              parentFolder="icons"
            />
            <Typography variant="h3" fontWeight={700}>{e.heading}</Typography>
          </Box>
          <Box>
            <Typography variant="smallText">{e.subHeading}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
