import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { CommonDbAPIServiceV1 } from '@protos/common/common.db.apis_connect';
import { ResponseStatusCodeFunction, lmsTransport, lmsTransportWithLogoutHandler } from './transports';

export const CommonDbAPIServiceV1Client = createPromiseClient(
  CommonDbAPIServiceV1,
  lmsTransport
);

//CommonDbAPIServiceV1
export const useCommonDbAPIServiceV1ClientWithStatusCodeHandler = (options: Partial<ConnectTransportOptions>, responseStatusCodeFunction: ResponseStatusCodeFunction) => createPromiseClient(
  CommonDbAPIServiceV1,
  lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
);
