import { getEnvConfig } from './config';

type MediaType =
  | 'resourceContentBucket'
  | 'processedMediaBucket'
  | 'schoolnetCdnImagesBucket'
  | 'schoolnetStaticAssetBucket';

const mediaTypes: MediaType[] = [
  'resourceContentBucket',
  'processedMediaBucket',
  'schoolnetCdnImagesBucket',
  'schoolnetStaticAssetBucket',
];

const doesUrlStartsWithBucketNames = (url: string) => {
  const config = getEnvConfig();
  for (let i = 0; i < mediaTypes.length; i++) {
    const bucketType = mediaTypes[i];
    if (url.startsWith(`/${config[bucketType]}`)) {
      return true;
    }
  }
  return false;
};

export const getMediaBasePath = (path?: string, type?: MediaType) => {
  if (!path) {
    return '';
  }
  const config = getEnvConfig();
  const gcpPrefixUrl = 'https://storage.googleapis.com';
  const storagePrefixUrl = process.env.NX_STORAGE_PREFIX_URL ?? gcpPrefixUrl;
  if (path.startsWith(gcpPrefixUrl)) {
    return path.replace(gcpPrefixUrl, storagePrefixUrl);
  } else if (path.startsWith(storagePrefixUrl)) {
    return path;
  } else if (path.startsWith('http')) {
    return path;
  } else if (type) {
    const bucket = config[type];
    if (doesUrlStartsWithBucketNames(path)) {
      return `${storagePrefixUrl}${path}`;
    } else if (path.startsWith('/')) {
      return `${storagePrefixUrl}/${bucket}${path}`;
    } else {
      return `${storagePrefixUrl}/${bucket}/${path}`;
    }
  } else {
    if (doesUrlStartsWithBucketNames(path)) {
      return `${storagePrefixUrl}${path}`;
    }
  }
  return path;
};

export const shouldIncludeCredentialsInRequest = (url: string) => {
  const backendUrl = process.env.NX_GENEO_BACKEND_URL;
  if (backendUrl && url.startsWith(backendUrl)) {
    return true;
  }
  return false;
};

export const firstLetterImage = (name?: string) => {
  if (!name) {
    return '';
  }
  const firstLetter = name?.charAt(0);

  // Define color variables
  const textColor = '#fff';
  const backgroundColor = '#0AA34F';

  // Create an SVG representing the desired image
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200">
    <rect x="0" y="0" width="200" height="200" fill="${backgroundColor}" />
    <text x="100" y="100" text-anchor="middle" dy="0.35em" font-family="Arial" font-size="100" fill="${textColor}">${firstLetter}</text>
  </svg>`;

  // Convert the SVG to a data URL
  const imageUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
  return imageUrl;
};
