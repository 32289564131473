import {
  Content,
  Content_ContentEnum,
  PageContentInfo,
  Question,
  ResourceTeacherInstruction,
} from '@protos/content_management/content.db_pb';

import {
  bookClose,
  bookOpen,
  clearBookData,
  getBookCloseEvent,
  getBookOpenEvent,
  isBookOpen,
} from '@events/student/eventsCreator/book';

import {
  chapterClose,
  chapterOpen,
  clearChapterData,
  getChapterCloseEvent,
  getChapterOpenEvent,
  isChapterOpen,
} from '@events/student/eventsCreator/chapter';

import {
  clearTopicData,
  getTopicCloseEvent,
  getTopicOpenEvent,
  isTopicOpen,
  topicClose,
  topicOpen,
} from '@events/student/eventsCreator/topic';

import {
  clearLessonPlanData,
  getLessonPlanCloseEvent,
  getLessonPlanOpenEvent,
  isLessonPlanOpen,
  lessonplanClose,
  lessonplanOpen,
} from '@events/student/eventsCreator/lessonplan';

import {
  clearResourceData,
  getResourceCloseEvent,
  getResourceOpenEvent,
  isResourceOpen,
  resourceClose,
  resourceOpen,
} from '@events/student/eventsCreator/resource';

import {
  clearResourcePageData,
  getResourcePageCloseEvent,
  getResourcePageOpenEvent,
  isResourcePageOpen,
  resourcePageClose,
  resourcePageOpen,
} from '@events/student/eventsCreator/resourcePage';

import {
  clearInstructionData,
  clearQuestionData,
  clearResourcePageContentData,
  contentClose,
  contentOpen,
  getContentCloseEvent,
  getContentOpenEvent,
  getContentVideoCloseEvent,
  getResPageInstructionClose,
  getResPageInstructionCloseEvent,
  getResPageQuestionSubmitClose,
  getResPageQuestionSubmitCloseEvent,
  getResourceInstructionOpenEvent,
  getResourceQuestionOpenEvent,
  isInstructionOpen,
  isQuestionOpen,
  isResourcePageContentOpen,
  resourceInstructionOpen,
  resourceQuestionOpen,
} from '@events/student/eventsCreator/resourcePageContent';
import {
  removeChapterSession,
  removeTopicSession,
} from '@events/student/eventsCreator/session';
import {
  addEventRequest,
  isAiDebuggingEnabled,
  isCorrectAnswer,
} from '@geneo2-web/shared-ui';
import { QAttemptResultEnum } from '@protos/geneo_ai/ai.student.events.data_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';

//* --------------- Book Events ---------------
export const aiBookOpenEvent = async (bookId: number | undefined) => {
  try {
    if (bookId && !isBookOpen({ bookId: bookId })) {
      bookOpen({ openTimestamp: new Date(), bookId: bookId });

      const bookOpenEvent = getBookOpenEvent({ bookId: bookId });
      if (bookOpenEvent.timestamp) {
        addEventRequest({
          key: bookOpenEvent.timestamp?.toJsonString(),
          value: bookOpenEvent.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(bookOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiBookCloseEvent = async (bookId: number | undefined) => {
  try {
    if (bookId && isBookOpen({ bookId: bookId })) {
      bookClose({ closeTimestamp: new Date(), bookId: bookId });

      const bookCloseEvent = getBookCloseEvent({ bookId: bookId });
      if (bookCloseEvent.timestamp) {
        await addEventRequest({
          key: bookCloseEvent.timestamp?.toJsonString(),
          value: bookCloseEvent.toJson(),
        });
      }
      clearBookData({ bookId: bookId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(bookCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Chapter Events ---------------
export const aiChapterOpenEvent = async (chapterId: number | undefined) => {
  try {
    if (chapterId && !isChapterOpen({ chapterId: chapterId })) {
      chapterOpen({
        openTimestamp: new Date(),
        chapterId: chapterId,
      });

      const chapterOpenEvent = getChapterOpenEvent({ chapterId: chapterId });
      if (chapterOpenEvent.timestamp) {
        await addEventRequest({
          key: chapterOpenEvent.timestamp?.toJsonString(),
          value: chapterOpenEvent.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(chapterOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiChapterCloseEvent = async (chapterId: number | undefined) => {
  try {
    if (chapterId && isChapterOpen({ chapterId: chapterId })) {
      chapterClose({ closeTimestamp: new Date(), chapterId: chapterId });

      const chapterCloseEvent = getChapterCloseEvent({ chapterId: chapterId });
      if (chapterCloseEvent.timestamp) {
        await addEventRequest({
          key: chapterCloseEvent.timestamp?.toJsonString(),
          value: chapterCloseEvent.toJson(),
        });
      }
      removeChapterSession();
      clearChapterData({ chapterId: chapterId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(chapterCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Topic Events ---------------
export const aiTopicOpenEvent = async (topicId: number) => {
  try {
    if (!isTopicOpen({ topicId: topicId })) {
      topicOpen({
        openTimestamp: new Date(),
        topicId: topicId,
      });

      const topicOpenEvent = getTopicOpenEvent({ topicId: topicId });
      if (topicOpenEvent.timestamp) {
        await addEventRequest({
          key: topicOpenEvent.timestamp?.toJsonString(),
          value: topicOpenEvent.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(topicOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiTopicCloseEvent = async (topicId: number | undefined) => {
  try {
    if (topicId && isTopicOpen({ topicId: topicId })) {
      topicClose({ closeTimestamp: new Date(), topicId: topicId });

      const topicCloseEvent = getTopicCloseEvent({ topicId: topicId });
      if (topicCloseEvent.timestamp) {
        await addEventRequest({
          key: topicCloseEvent.timestamp?.toJsonString(),
          value: topicCloseEvent.toJson(),
        });
      }

      clearTopicData({ topicId: topicId });
      removeTopicSession();

      // await StudentEventsAPIServiceV1Client.studentEventProcess(topicCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Lesson Events ---------------
export const aiLessonOpenEvent = async (lessonId: string | undefined) => {
  try {
    if (lessonId && !isLessonPlanOpen({ lessonId: lessonId })) {
      lessonplanOpen({
        openTimestamp: new Date(),
        lessonId: lessonId,
      });

      const lessonOpenEvent = getLessonPlanOpenEvent({ lessonId: lessonId });
      if (lessonOpenEvent.timestamp) {
        await addEventRequest({
          key: lessonOpenEvent.timestamp?.toJsonString(),
          value: lessonOpenEvent.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(lessonOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiLessonCloseEvent = async (lessonId: string | undefined) => {
  try {
    if (lessonId && isLessonPlanOpen({ lessonId: lessonId })) {
      lessonplanClose({ closeTimestamp: new Date(), lessonId: lessonId });

      const lessonCloseEvent = getLessonPlanCloseEvent({ lessonId: lessonId });
      if (lessonCloseEvent.timestamp) {
        await addEventRequest({
          key: lessonCloseEvent.timestamp?.toJsonString(),
          value: lessonCloseEvent.toJson(),
        });
      }
      clearLessonPlanData({ lessonId: lessonId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(lessonCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Resource Events ---------------
export const aiResourceOpenEvent = async (
  resourceId: string | undefined,
  selectedPath?: ResourceSelectionFromTypeEnum | undefined,
  rank?: number,
  recommendationId?: string
) => {
  try {
    if (resourceId && !isResourceOpen({ resourceId: resourceId })) {
      resourceOpen({
        openTimestamp: new Date(),
        resourceId: resourceId,
        selectedFrom: selectedPath,
        rank: rank,
        recommendationId: recommendationId,
      });

      // console.log('selectedPath:', selectedPath);

      const resourceOpenEvent = getResourceOpenEvent({
        resourceId: resourceId,
      });
      // console.log('resourceOpenEvent:', resourceOpenEvent);
      if (resourceOpenEvent.timestamp) {
        await addEventRequest({
          key: resourceOpenEvent.timestamp?.toJsonString(),
          value: resourceOpenEvent.toJson(),
        });
      }
      // console.log('resourceOpenEvent:', resourceOpenEvent);
      // await StudentEventsAPIServiceV1Client.studentEventProcess(resourceOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiResourceCloseEvent = async (
  resourceId: string | undefined
  // rank?: number,
  // recommendationId?: string
) => {
  try {
    if (resourceId && isResourceOpen({ resourceId: resourceId })) {
      resourceClose({ closeTimestamp: new Date(), resourceId: resourceId });
      const resourceCloseEvent = getResourceCloseEvent({
        resourceId: resourceId,
        // rank: rank,
        // recommendationId: recommendationId,
      });
      if (resourceCloseEvent.timestamp) {
        await addEventRequest({
          key: resourceCloseEvent.timestamp?.toJsonString(),
          value: resourceCloseEvent.toJson(),
        });
      }
      clearResourceData({ resourceId: resourceId });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(resourceCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Resource Page Events ---------------
export const aiResourcePageOpenEvent = async (
  resourceId: string | undefined,
  pageNumber: number
) => {
  try {
    if (
      resourceId &&
      pageNumber &&
      !isResourcePageOpen({
        resourceId: resourceId,
        pageNumber: pageNumber,
      })
    ) {
      resourcePageOpen({
        openTimestamp: new Date(),
        resourceId: resourceId,
        pageNumber: pageNumber,
      });

      const resourcePageOpenEvent = getResourcePageOpenEvent({
        resourceId: resourceId,
        pageNumber: pageNumber,
      });
      if (resourcePageOpenEvent.timestamp) {
        await addEventRequest({
          key: resourcePageOpenEvent.timestamp?.toJsonString(),
          value: resourcePageOpenEvent.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(resourcePageOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiResourcePageCloseEvent = async (
  resourceId: string | undefined,
  pageNumber: number | undefined
) => {
  try {
    if (
      resourceId &&
      pageNumber &&
      isResourcePageOpen({
        resourceId: resourceId,
        pageNumber: pageNumber,
      })
    ) {
      resourcePageClose({
        closeTimestamp: new Date(),
        resourceId: resourceId,
        pageNumber: pageNumber,
      });

      const resourcePageCloseEvent = getResourcePageCloseEvent({
        resourceId: resourceId,
        pageNumber: pageNumber,
      });
      if (resourcePageCloseEvent.timestamp) {
        await addEventRequest({
          key: resourcePageCloseEvent.timestamp?.toJsonString(),
          value: resourcePageCloseEvent.toJson(),
        });
      }
      clearResourcePageData({ resourceId: resourceId, pageNumber: pageNumber });

      // await StudentEventsAPIServiceV1Client.studentEventProcess(resourcePageCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Resource Page Events ---------------
//& -- Content / Question / Instruction (Open / Close) --
interface ContentEvent {
  // contentId?: string;
  resourceId?: string;
  pageNumber?: number;
  pageContent?: PageContentInfo[];

  answer?: string[];
  status?: QAttemptResultEnum;
}
export const aiContentOpenEvent = async (eventProps: ContentEvent) => {
  try {
    const { resourceId, pageNumber } = eventProps;
    let { pageContent } = eventProps;

    if (pageContent)
      pageContent = pageContent.map((val) => new PageContentInfo(val));

    const selectedPageContent = pageNumber
      ? pageContent?.[pageNumber - 1]
      : pageContent?.[0];

    if (selectedPageContent) {
      for (let i = 0; i < selectedPageContent.contents.length; i++) {
        const contentValue = selectedPageContent.contents[i].model.value;
        let respectiveId;
        let chosenType;

        //! Content -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        if (contentValue instanceof Content) {
          respectiveId = contentValue.contentId;
          chosenType = contentValue.contentType;

          if (
            respectiveId &&
            resourceId &&
            pageNumber &&
            !isResourcePageContentOpen({ contentId: respectiveId })
          ) {
            contentOpen({
              openTimestamp: new Date(),
              contentId: respectiveId,
              resourceId: resourceId,
              pageNumber: pageNumber,
              type: chosenType,
            });

            const contentOpenEvent = getContentOpenEvent({
              contentId: respectiveId,
            });
            if (contentOpenEvent.timestamp) {
              await addEventRequest({
                key: contentOpenEvent.timestamp?.toJsonString(),
                value: contentOpenEvent.toJson(),
              });
            }
            // await StudentEventsAPIServiceV1Client.studentEventProcess(contentOpenEvent);
          }
        }
        //! Question -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        else if (contentValue instanceof Question) {
          respectiveId = contentValue.questionId;
          chosenType = contentValue.questionType;

          if (
            respectiveId &&
            resourceId &&
            pageNumber &&
            !isQuestionOpen({ questionId: respectiveId })
          ) {
            resourceQuestionOpen({
              openTimestamp: new Date(),
              questionId: respectiveId,
              resourceId: resourceId,
              pageNumber: pageNumber,
              type: chosenType,
            });

            const questionOpenEvent = getResourceQuestionOpenEvent({
              questionId: respectiveId,
            });
            if (questionOpenEvent.timestamp) {
              await addEventRequest({
                key: questionOpenEvent.timestamp?.toJsonString(),
                value: questionOpenEvent.toJson(),
              });
            }
            // await StudentEventsAPIServiceV1Client.studentEventProcess(questionOpenEvent);
          }
        }
        //! Instruction -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        else if (contentValue instanceof ResourceTeacherInstruction) {
          respectiveId = contentValue.resourceId;

          if (
            respectiveId &&
            resourceId &&
            pageNumber &&
            !isInstructionOpen({ instructionId: respectiveId })
          ) {
            resourceInstructionOpen({
              openTimestamp: new Date(),
              instructionId: respectiveId,
              resourceId: resourceId,
              pageNumber: pageNumber,
            });

            const instructionOpenEvent = getResourceInstructionOpenEvent({
              instructionId: respectiveId,
            });
            if (instructionOpenEvent.timestamp) {
              await addEventRequest({
                key: instructionOpenEvent.timestamp?.toJsonString(),
                value: instructionOpenEvent.toJson(),
              });
            }
            // await StudentEventsAPIServiceV1Client.studentEventProcess(instructionOpenEvent);
          }
        }
      }
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiContentCloseEvent = async (eventProps: ContentEvent) => {
  try {
    const { resourceId, pageNumber, answer, status } = eventProps;
    let { pageContent } = eventProps;

    if (pageContent)
      pageContent = pageContent.map((val) => new PageContentInfo(val));

    const selectedPageContent = pageNumber
      ? pageContent?.[pageNumber - 1]
      : pageContent?.[0];

    if (selectedPageContent) {
      for (let i = 0; i < selectedPageContent.contents.length; i++) {
        const contentValue = selectedPageContent.contents[i].model.value;

        let respectiveId;
        let chosenType;

        //! Content -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        if (contentValue instanceof Content) {
          respectiveId = contentValue.contentId;
          chosenType = contentValue.contentType;

          if (
            respectiveId &&
            pageNumber &&
            isResourcePageContentOpen({ contentId: respectiveId })
          ) {
            contentClose({
              closeTimestamp: new Date(),
              contentId: respectiveId,
            });

            let contentCloseEvent;
            if (chosenType === Content_ContentEnum.CONTENT_TYPE_CONTENT_VIDEO) {
              contentCloseEvent = getContentVideoCloseEvent({
                contentId: respectiveId,
              });
            } else {
              contentCloseEvent = getContentCloseEvent({
                contentId: respectiveId,
              });
            }
            if (contentCloseEvent.timestamp) {
              await addEventRequest({
                key: contentCloseEvent.timestamp?.toJsonString(),
                value: contentCloseEvent.toJson(),
              });
            }
            clearResourcePageContentData({ contentId: respectiveId });

            // await StudentEventsAPIServiceV1Client.studentEventProcess(contentCloseEvent);
          }
        }
        //! Question -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        else if (contentValue instanceof Question) {
          respectiveId = contentValue.questionId;
          chosenType = contentValue.questionType;

          if (
            respectiveId &&
            pageNumber &&
            isQuestionOpen({ questionId: respectiveId })
          ) {
            getResPageQuestionSubmitClose({
              closeTimestamp: new Date(),
              questionId: respectiveId,
            });

            // if not answered then status = 0, answer = []
            const questionSubmitEvent = getResPageQuestionSubmitCloseEvent({
              questionId: respectiveId,
              answer: answer || [],
              status: isCorrectAnswer(contentValue, answer),
            });
            if (questionSubmitEvent.timestamp) {
              await addEventRequest({
                key: questionSubmitEvent.timestamp?.toJsonString(),
                value: questionSubmitEvent.toJson(),
              });
            }
            clearQuestionData({ questionId: respectiveId });

            // await StudentEventsAPIServiceV1Client.studentEventProcess(questionSubmitEvent);
          }
        }
        //! Instruction -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
        else if (contentValue instanceof ResourceTeacherInstruction) {
          respectiveId = contentValue.resourceId;

          if (
            respectiveId &&
            pageNumber &&
            isInstructionOpen({ instructionId: respectiveId })
          ) {
            getResPageInstructionClose({
              closeTimestamp: new Date(),
              instructionId: respectiveId,
            });

            const instructionCloseEvent = getResPageInstructionCloseEvent({
              instructionId: respectiveId,
            });
            if (instructionCloseEvent.timestamp) {
              await addEventRequest({
                key: instructionCloseEvent.timestamp?.toJsonString(),
                value: instructionCloseEvent.toJson(),
              });
            }
            clearInstructionData({ instructionId: respectiveId });

            // await StudentEventsAPIServiceV1Client.studentEventProcess(instructionCloseEvent);
          }
        }
      }
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//& --------------- Content Question Submit -----------------------
interface QuestionData {
  questionId: string;
  answer: string[];
  // status: QAttemptResultEnum;

  pageContent?: PageContentInfo[];
  pageNumber?: number;
}
export const aiContentQuestionSubmitEvent = async (props: QuestionData) => {
  try {
    const { questionId, answer, pageNumber } = props;
    let { pageContent } = props;

    if (pageContent)
      pageContent = pageContent.map((val) => new PageContentInfo(val));

    const selectedPageContent = pageNumber
      ? pageContent?.[pageNumber - 1]
      : pageContent?.[0];

    selectedPageContent?.contents.forEach(async (con) => {
      const contentValue = con.model.value;
      if (contentValue instanceof Question) {
        if (questionId) {
          getResPageQuestionSubmitClose({
            closeTimestamp: new Date(),
            questionId: questionId,
          });

          const questionSubmitEvent = getResPageQuestionSubmitCloseEvent({
            questionId: questionId,
            answer: answer,
            status: isCorrectAnswer(contentValue, answer),
          });
          if (questionSubmitEvent.timestamp) {
            await addEventRequest({
              key: questionSubmitEvent.timestamp?.toJsonString(),
              value: questionSubmitEvent.toJson(),
            });
          }
          clearQuestionData({ questionId: questionId });
          // await StudentEventsAPIServiceV1Client.studentEventProcess(questionSubmitEvent);
        }
      }
    });
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
