import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import contentImage from '../../assets/tempAssets/lessonImage1.png';
import { IStyles } from '../../commonUtils/styleUtils';
import { AnswerStatusMark } from '../../components/elements/AnswerStatusMark';
import { ConnectedClassIndicator } from '../../components/elements/ConnectedClassIndicator';
import { ConnectedStudentsInfoBadge } from '../../components/elements/ConnectedStudentsInfoBadge';
import { OnlineStatusTag } from '../../components/elements/OnlineStatusTag';
import { RemoteNumber } from '../../components/elements/RemoteNumber';
import { SquareImageAvatar } from '../../components/elements/SquareImageAvatar';
import { StatusRadioMark } from '../../components/elements/StatusRadioMark';
import { StepsBadge } from '../../components/elements/StepsBadge';
import { ToggleSwitch } from '../../components/elements/ToggleSwitch';
const styles: IStyles = {
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const ConnectedClasscomponents = () => {
  const [isToggled, setToggled] = useState(false);

  const handleToggle = () => {
    setToggled(!isToggled);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FDFBFB',
        height: '100%',
        width: '100%',
        gap: '20px',
      }}
    >
      <Typography variant="h2">ToggleSwitch component</Typography>
      <ToggleSwitch checked={isToggled} handleChange={handleToggle} />
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#FDFBFB',
          height: '100%',
          width: 'max-content',
          border: '1px solid grey',
          borderRadius: '20px',
          padding: '20px',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <SquareImageAvatar
          size="large"
          name="saivenu"
          ImageSource={contentImage}
          online={isToggled}
        />
        <OnlineStatusTag status={isToggled ? 'online' : 'offline'} />
        <AnswerStatusMark statusmark={isToggled ? 'on' : 'off'} />
        <StatusRadioMark status={isToggled ? 'on' : 'off'} />
      </Box>
      <Typography variant="h2">RemoteNumber component</Typography>
      <RemoteNumber number={1} size="large" />
      <Typography variant="h3">Size- large</Typography>
      <RemoteNumber number={1} size="small" />
      <Typography variant="h3">Size- small </Typography>
      <Typography variant="h2">SquareImageAvatar component</Typography>
      <SquareImageAvatar
        size="large"
        name="saivenu"
        ImageSource={contentImage}
        online={true}
      />
      <SquareImageAvatar size="large" name="saivenu" />
      <Typography variant="h3">Size- large</Typography>
      <SquareImageAvatar
        size="small"
        name="saivenu"
        ImageSource={contentImage}
        online={true}
      />
      <SquareImageAvatar size="small" name="saivenu" />
      <Typography variant="h3">Size- small</Typography>
      <Typography variant="h2">OnlineStatusTag Component</Typography>
      <OnlineStatusTag status="online" />
      <Typography variant="h3">Status - online</Typography>
      <OnlineStatusTag status="offline" />
      <Typography variant="h3">Status - offline</Typography>
      <Typography variant="h2">StatusRadioMark component</Typography>
      <StatusRadioMark status="off" />
      <StatusRadioMark status="on" />

      <Typography variant="h2">AnswerStatusMark component</Typography>
      <AnswerStatusMark statusmark="on" />
      <AnswerStatusMark statusmark="off" />
      <AnswerStatusMark statusmark="disconnected" />

      <Typography variant="h2">ConnectedStudentsInfoBadge component</Typography>
      <ConnectedStudentsInfoBadge connectedStudents={30} totalStudents={40} />

      <Typography variant="h2">StepsBadge component</Typography>
      <StepsBadge number={1} />
      <StepsBadge number={2} backgroundColor="#E8F5FF" bordercolor=" #007CDC" />
      <StepsBadge number={3} backgroundColor="#FFE4E4" bordercolor=" #F54040" />
      <StepsBadge number={4} backgroundColor="#E7FDF1" bordercolor=" #0AA34F" />

      <Typography variant="h2">ConnectedClassIndicator component</Typography>
      <ConnectedClassIndicator variant="wifi" isOnline />
      <ConnectedClassIndicator variant="globe" isOnline />
      <ConnectedClassIndicator variant="class" isOnline />
      <ConnectedClassIndicator variant="class" />
    </Box>
  );
};
