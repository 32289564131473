import { deserify } from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';
import { SubmissionType } from '@protos/learning_management/lms.db_pb';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { ActiveHomework } from './component/ActiveHomework';
import { CustomHomework } from './component/CustomHomework';

export const ActiveHw = () => {
  const selected_homework_data = deserify(
    useAppSelector((state) => state.homework.selected_homework_data)
  );
  const submissionType = selected_homework_data?.SubmissionType;

  return (
    <Box>
      {submissionType === SubmissionType.SUBMISSION_ONLINE ||
      submissionType === SubmissionType.SUBMISSION_OFFLINE ? (
        <CustomHomework />
      ) : (
        <ActiveHomework />
      )}
      {/* {true?
        <CustomHomework />
        : <ActiveHomework />
      } */}
    </Box>
  );
};
